import {
  handleUrl,
  myHeadersWithToken,
  myHeadersWithTokenContentType,
} from '../global';
import {
  DetailProps,
  GetAllInspectionFormsProps,
  GetInspectionFormProps,
  NewFormData,
} from './types';

const addInspectionForm = (data: NewFormData): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/inspection-forms`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const getInspectionForm = (id: number): Promise<GetInspectionFormProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/inspection-forms/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const getAllInspectionForms = (
  templates = false
): Promise<GetAllInspectionFormsProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/inspection-forms?templates=${templates}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const updateInspectionForm = (
  id: number,
  data: NewFormData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/inspection-forms/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const deleteInspectionForm = (id: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/inspection-forms/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export default {
  addInspectionForm,
  getInspectionForm,
  getAllInspectionForms,
  updateInspectionForm,
  deleteInspectionForm,
};
