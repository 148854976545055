/* eslint-disable camelcase */
import {
  handleUrl,
  myHeadersWithToken,
  myHeadersWithTokenContentType,
  myHeadersWithoutToken,
} from '../global';
import {
  DetailProps,
  EditUserProps,
  GetAllUsersProps,
  GetFirstAccessProps,
  GetUserProps,
  GuideTourProps,
  IdwallSdkProps,
  RegistrationData,
  TokenProps,
  UserProps,
} from './types';

export const CreateUser = (data: UserProps): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/users`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const EditUser = (
  id: number,
  data: EditUserProps
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/users/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const changeUserImages = (
  id: number,
  data: FormData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PATCH',
    headers: myHeadersWithToken(),
    body: data,
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/users/${id}/images`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const GetAllUsers = (
  page: number,
  size: number
): Promise<GetAllUsersProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/users?page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const GetUser = (id: number): Promise<GetUserProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/users/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const GetUserData = (): Promise<Response> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/users/me/data`, requestOptions).then(
    (response) => response
  );
};

export const PutGuideTour = (
  id: number,
  guide_tour?: string
): Promise<GuideTourProps> => {
  const data = JSON.stringify({ guide_tour });
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: data,
  };

  const BaseURL = handleUrl();

  return fetch(`${BaseURL}/api/V1/users/${id}/guide-tour`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getFirstAccess = (email: string): Promise<GetFirstAccessProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/users/registration-status?email=${email}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getNewToken = (email: string): Promise<DetailProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/users/email-code?email=${email}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const sendToken = (data: TokenProps): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/users/email-validation`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const sendUserData = (
  data: RegistrationData
): Promise<GetFirstAccessProps> => {
  const requestOptions = {
    method: 'PATCH',
    headers: myHeadersWithoutToken(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/users/register-finish`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const sendIdwallSdk = (data: IdwallSdkProps): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithoutToken(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/users/document-validation`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const acceptTermsOfUse = (
  company: string,
  id: number
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithToken(),
  };
  const dinamicUrl = process.env.REACT_APP_API_BASE_URL || '';
  const url = dinamicUrl.replace('*', company);
  return fetch(`${url}/api/V1/users/${id}/accept-terms-of-use`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export default {
  CreateUser,
  EditUser,
  changeUserImages,
  GetAllUsers,
  GetUser,
  PutGuideTour,
  GetUserData,
  getFirstAccess,
  getNewToken,
  sendToken,
  sendIdwallSdk,
  sendUserData,
};
