import { Box, Button, CardMedia, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { UploadBox } from '../../../components/UI/Box';

export const GalleryContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '32px',
  marginTop: 20,
}));

export const Upload = styled(UploadBox)(() => ({
  width: '300px',
  minHeight: '220px',
  gap: '8px',
}));

export const InsideBox = styled(Box)(() => ({
  cursor: 'pointer',
  textAlign: 'center',
}));

export const UploadIcon = styled(Typography)(({ theme }) => ({
  '& span': { fontSize: '72px', color: theme.palette.primary.main },
}));

export const UploadTitle = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '14px',
}));

export const UploadSubtitle = styled(Typography)(() => ({
  fontSize: '14px',
}));

export const PhotoContainer = styled(Box)(() => ({
  borderRadius: '30px',
  backgroundColor: '#bcbcbc66',
}));

export const FlexReverseBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row-reverse',
}));

export const StyledCardMedia = styled(CardMedia)(() => ({
  width: '300px',
  objectFit: 'contain',
  backgroundSize: 'contain',
  height: '220px',
  borderRadius: '30px',
  cursor: 'pointer',
}));

export const PathologiesBox = styled(Box)(() => ({
  margin: '-52px 12px 0',
  zIndex: '10',
  backgroundColor: '#1A1A1A',
  borderRadius: '50%',
  padding: '4px',
  color: '#EDEFF2',
  width: '40px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '2px solid #707070',
  fontWeight: '600',
  cursor: 'default',
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
  margin: '0 0 40px',
  width: '400px',
  backgroundColor: theme.palette.primary.dark,
}));
