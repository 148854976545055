import {
  ClientKind,
  Concept,
  ConservationState,
  convertClientKind,
  convertConcept,
  convertConservationState,
  convertEvaluationType,
  convertEvaluationTypeBank,
  convertFormPropertyType,
  convertProfileUsers,
  convertPropertyType,
  convertPropertyUse,
  convertQuestionType,
  convertRegistrationUf,
  convertReportFunction,
  convertReportGoal,
  convertStandard,
  convertStandard400K,
  EvaluationType,
  EvaluationTypeBank,
  FormPropertyType,
  ProfileUsers,
  PropertyType,
  PropertyUse,
  QuestionType,
  RegistrationUf,
  ReportFunction,
  ReportGoal,
  Standard,
} from '../api/enumerations';

export interface SelectProps {
  value: number;
  label: string;
}

export const selectEvaluationType = (): SelectProps[] => {
  const enumValues = Object.values(EvaluationType).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: EvaluationType = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertEvaluationType(enumKey),
    };
  });
};

export const selectEvaluationTypeBank = (): SelectProps[] => {
  const enumValues = Object.values(EvaluationTypeBank).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: EvaluationTypeBank = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertEvaluationTypeBank(enumKey),
    };
  });
};

export const selectClientKind = (): SelectProps[] => {
  const enumValues = Object.values(ClientKind).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: ClientKind = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertClientKind(enumKey),
    };
  });
};

export const selectFormPropertyType = (): SelectProps[] => {
  const enumValues = Object.values(FormPropertyType).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: FormPropertyType = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertFormPropertyType(enumKey),
    };
  });
};

export const selectProfileUser = (): SelectProps[] => {
  const enumValues = Object.values(ProfileUsers).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: ProfileUsers = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertProfileUsers(enumKey),
    };
  });
};

export const selectPropertyType = (): SelectProps[] => {
  const enumValues = Object.values(PropertyType).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: PropertyType = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertPropertyType(enumKey),
    };
  });
};

export const selectPropertyUse = (): SelectProps[] => {
  const enumValues = Object.values(PropertyUse).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: PropertyUse = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertPropertyUse(enumKey),
    };
  });
};

export const selectQuestionType = (): SelectProps[] => {
  const enumValues = Object.values(QuestionType).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: QuestionType = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertQuestionType(enumKey),
    };
  });
};

export const selectRegistrationUf = (): SelectProps[] => {
  const enumValues = Object.values(RegistrationUf).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: RegistrationUf = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertRegistrationUf(enumKey),
    };
  });
};

export const selectReportFunction = (): SelectProps[] => {
  const enumValues = Object.values(ReportFunction).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: ReportFunction = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertReportFunction(enumKey),
    };
  });
};

export const selectReportGoal = (): SelectProps[] => {
  const enumValues = Object.values(ReportGoal).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: ReportGoal = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertReportGoal(enumKey),
    };
  });
};

export const selectConcept = (): SelectProps[] => {
  const enumValues = Object.values(Concept).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: Concept = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertConcept(enumKey),
    };
  });
};

export const selectConservation = (): SelectProps[] => {
  const enumValues = Object.values(ConservationState).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: ConservationState = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertConservationState(enumKey),
    };
  });
};

export const selectStandard = (): SelectProps[] => {
  const enumValues = Object.values(Standard).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: Standard = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertStandard(enumKey),
    };
  });
};

export const selectStandard400K = (): SelectProps[] => {
  const enumValues = Object.values(Standard).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: Standard = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertStandard400K(enumKey),
    };
  });
};
