/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
import { Box, IconButton } from '@mui/material';
import { AdvancedMarker, Map, useMap } from '@vis.gl/react-google-maps';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  IconFullscreenExitMS,
  IconFullscreenMS,
} from '../../../../constants/icons';

interface CustomMapProps {
  searchMap?: boolean;
  address?: string;
  pinPlace: google.maps.LatLngLiteral;
  setPinPlace?: (place: google.maps.LatLngLiteral) => void;
  draggable: boolean;
}

export default function CustomMap({
  searchMap,
  address,
  pinPlace,
  setPinPlace,
  draggable,
}: CustomMapProps): JSX.Element {
  const [isExpanded, setIsExpanded] = useState(false);
  const center = useMemo(() => pinPlace, [pinPlace]);
  const map = useMap();

  const handleDragEnd = useCallback(
    (event: google.maps.MapMouseEvent): void => {
      if (map && setPinPlace && event.latLng) {
        setPinPlace({
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        });
      }
    },
    [map, setPinPlace]
  );

  const handleGetCoord = (
    predictions: google.maps.places.AutocompletePrediction[] | null,
    status: google.maps.places.PlacesServiceStatus
  ): void => {
    if (status !== google.maps.places.PlacesServiceStatus.OK) {
      return;
    }
    if (predictions && setPinPlace && map) {
      const result = predictions[0];
      const getPlace = new google.maps.places.PlacesService(map);

      getPlace.getDetails({ placeId: result.place_id }, (place) => {
        if (place?.geometry?.location) {
          const latitude = place.geometry.location.lat();
          const longitude = place.geometry.location.lng();
          const coord = { lat: latitude, lng: longitude };
          map.panTo(coord);
          setPinPlace(coord);
        }
      });
    }
  };

  useEffect(() => {
    if (!map || !address) return;
    const service = new google.maps.places.AutocompleteService();
    service.getPlacePredictions(
      { input: address, componentRestrictions: { country: 'br' } },
      handleGetCoord
    );
  }, [searchMap]);

  useEffect(() => {
    if (map) {
      map.panTo(pinPlace);
    }
  }, [pinPlace]);

  return (
    <Box className={`map-container ${isExpanded ? 'expanded' : ''}`}>
      <Map
        mapId="93ba1580047addb8"
        defaultZoom={16}
        defaultCenter={center}
        streetViewControl={false}
        mapTypeId="satellite"
        className="customMap"
        fullscreenControl={false}
        minZoom={4}
      >
        <AdvancedMarker
          position={pinPlace}
          draggable={draggable}
          onDragEnd={handleDragEnd}
        />
      </Map>
      <IconButton
        onClick={() => setIsExpanded(!isExpanded)}
        className="expand-button"
        aria-label={isExpanded ? 'Reduzir mapa' : 'Expandir mapa'}
        sx={{ borderRadius: '5%' }}
      >
        {isExpanded ? IconFullscreenExitMS : IconFullscreenMS}
      </IconButton>
    </Box>
  );
}
