import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PipelineContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  gap: '26px',
  height: '75vh',
  minHeight: '500px',
  padding: '20px',
  overflowX: 'scroll',
  '&::-webkit-scrollbar': {
    height: '15px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  '&::-webkit-scrollbar-track-piece:start': {
    backgroundColor: 'white',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: '2px 3px 6px #00000033',
    margin: '0 380px',
    borderRadius: '10px',
  },
  '::-webkit-scrollbar-track-piece:end': {
    backgroundColor: theme.palette.common.white,
    borderRadius: '10px',
  },
}));
