import { Box, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SectionBox = styled(Box)(() => ({
  margin: '40px 0',
  display: 'flex',
  gap: '10px',
  justifyContent: 'space-between',
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    height: '45px',
    border: 'none',
    '& :hover': {
      color: theme.palette.primary.main,
    },
    '& .MuiButtonBase-root.MuiToggleButton-root': {
      '&.Mui-selected': {
        color: '#FAFCFF',
        backgroundColor: theme.palette.primary.dark,
        border: 'none',
        borderRadius: '4px',
        '& :hover': {
          color: '#FAFCFF',
          backgroundColor: theme.palette.primary.dark,
          opacity: '80%',
        },
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
  })
);

export const DashboardContainer = styled(Box)(() => ({
  margin: '40px 0 0',
  display: 'flex',
  gap: '8px',
  flexWrap: 'wrap',
}));
