import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContainer = styled(Box)(() => ({
  padding: '2rem 3rem',
  minWidth: 520,
}));

export const ButtonBox = styled(Box)(() => ({
  marginLeft: 'auto',
}));

export const FormStyled = styled(Box)(() => ({
  margin: '20px 0',
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
}));
