import {
  Box,
  Button,
  ButtonBase,
  Modal,
  Typography,
  alpha,
  styled,
} from '@mui/material';

export const StyledModal = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& .MuiBox-root': {
    border: 'none',
    outline: 'none',
  },
}));

export const StyledButtonText = styled(ButtonBase)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontWeight: 'bold',
  fontSize: 16,
  '&:hover': {
    color: alpha(theme.palette.primary.dark, 0.7),
  },
}));

export const StyledTypographyGrid = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  color: theme.palette.primary.dark,
  marginBottom: '16px',
}));

export const StyledDivLogo = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  width: 111,
  height: 96,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 32,
  '& img': {
    width: 96,
  },
}));

export const StyledButtonContained = styled(Button)(({ theme }) => ({
  width: 345,
  height: 48,
  color: theme.palette.common.white,
  background: theme.palette.primary.dark,
  borderRadius: 30,
  fontSize: 16,
  fontWeight: 'bold',
  textTransform: 'lowercase',
  '&:hover': {
    background: alpha(theme.palette.primary.dark, 0.7),
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  padding: '48px 64px',
  width: 662,
  borderRadius: '20px',
  background: theme.palette.common.white,
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: 28,
  outlined: 'none',
  marginBottom: '16px',
}));
