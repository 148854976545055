import { Box, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContainer = styled(Box)(() => ({
  padding: '2rem 3rem',
  minWidth: 720,
}));

export const ButtonBox = styled(Box)(() => ({
  marginLeft: 'auto',
}));

export const FormStyled = styled(Box)(() => ({
  margin: '20px 0',
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
}));

export const StyledCheckbox = styled(Checkbox)(() => ({
  '& .MuiSvgIcon-root': {
    fontSize: 24,
  },
}));

export const StyledPatternFormat = {
  width: '100%',
  '& .MuiInputBase-root': { borderRadius: '16px' },
  'input:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0px 1000px #F5F5F4 inset',
  },
};
