import { InputBase, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  borderRadius: '32px',
  border: `1px solid ${theme.palette.primary.dark}`,
  padding: '0 18px',
  '& .MuiInputBase-input': {
    width: '200px',
    padding: '10px 0 10px',
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('md')]: {
      width: '200px',
      '&:focus': {
        width: '300px',
      },
    },
  },
}));

export const SearchButton = styled(Button)(({ theme }) => ({
  margin: '0 8px',
  cursor: 'pointer',
  border: 0,
  backgroundColor: 'transparent',
  color: theme.palette.primary.dark,
  width: 'auto',
  fontWeight: '600',
  fontSize: '1rem',
  padding: '6px',
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
  },
}));
