/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useState, ReactNode } from 'react';

interface AttachmentFilesProps {
  iptuLink: string;
  setIptuLink: (link: string) => void;
  registerLink: string;
  setRegisterLink: (link: string) => void;
}

export const AttachmentFilesContext = createContext({} as AttachmentFilesProps);

interface AttachmentFilesProviderProps {
  children: ReactNode;
}

export function AttachmentFilesProvider({
  children,
}: AttachmentFilesProviderProps): JSX.Element {
  const [iptuLink, setIptuLink] = useState('');
  const [registerLink, setRegisterLink] = useState('');

  return (
    <AttachmentFilesContext.Provider
      value={{
        iptuLink,
        setIptuLink,
        registerLink,
        setRegisterLink,
      }}
    >
      {children}
    </AttachmentFilesContext.Provider>
  );
}
