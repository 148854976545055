export const Constants = {
  user: 'Usuário',
  tour: 'tour',
  guidedTour: 'tour guiado',
  logout: 'sair',
  changeAvatar: 'alterar foto',
  selectImg: 'selecione uma foto do seu dispositivo',
  imgType: '(JPEG, PNG e WEBP somente)',
  chooseAvatar: 'ou escolha um dos nossos avatares:',
  back: 'voltar',
  cancel: 'cancelar',
  save: 'salvar',
};
