/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { formsAPI } from '../../api';
import { ClientKind, StepTour } from '../../api/enumerations';
import { InspectionFormData } from '../../api/forms/types';
import logoBb from '../../assets/images/bankLogo/logo-bb.svg';
import logoCaixa from '../../assets/images/bankLogo/logo-caixa.svg';
import { TitleBox } from '../../components/UI/Box';
import { SectionTitle, TitleTypography } from '../../components/UI/Typography';
import { Constants } from '../../constants/forms';
import {
  IconAddMS,
  IconBookmarkMS,
  IconChecklistRtlMS,
} from '../../constants/icons';
import { GlobalContext } from '../../context/global';
import { checkCardColor, convertDate } from '../../helpers';
import useErrorMessage from '../../hooks/useErrorMessage';
import { useTour } from '../../hooks/useTour';
import {
  BankFlag,
  CardContainer,
  CardLabel,
  CardSubtitle,
  FlexCenterBox,
  FormButton,
  TypeFlag,
} from './styles';

export function Forms(): JSX.Element {
  const [formsData, setFormsData] = useState<InspectionFormData[]>([]);

  const { setErrorMessage, setOpenSnackbar, setSnackbarMessage, company } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();
  const navigate = useNavigate();

  const getDataCallback = useCallback(async () => {
    try {
      const response = await formsAPI.getAllInspectionForms();

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }
      setFormsData(response.data);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [company]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  const { tourCompletion, setTutorialStep, setTourOn } = useTour();

  useEffect(() => {
    if (!tourCompletion.form.complete && !tourCompletion.skipTour.form) {
      setTutorialStep(StepTour.FORMSTART);
      setTourOn(true);
    }
  }, []);

  return (
    <>
      <TitleBox>
        <TitleTypography>{Constants.forms}</TitleTypography>
      </TitleBox>
      <FlexCenterBox>
        <FormButton id="new-form-btn" onClick={() => navigate('/forms/new')}>
          {IconAddMS}
          {Constants.newForm}
        </FormButton>
      </FlexCenterBox>
      <Grid
        container
        spacing={5}
        sx={{ paddingLeft: '20px' }}
        className="saved-forms"
      >
        <Grid item xs={12}>
          <SectionTitle sx={{ marginTop: '20px' }}>
            {IconBookmarkMS}
            {Constants.saved}
          </SectionTitle>
        </Grid>
        {formsData.map((data) => (
          <Grid item xs={4} key={data.id}>
            <Link
              to={`/forms/${data?.id}/edit`}
              style={{ textDecoration: 'none' }}
              key={data.id}
            >
              <CardContainer>
                <TypeFlag
                  sx={{
                    backgroundColor: checkCardColor(data.client_kind),
                  }}
                />
                {data.client_kind === ClientKind.BRASIL_BANK && (
                  <BankFlag image={logoBb} title="logo banco do brasil" />
                )}
                {data.client_kind === ClientKind.CAIXA && (
                  <BankFlag image={logoCaixa} title="logo caixa" />
                )}
                <CardLabel>
                  {IconChecklistRtlMS}
                  {data.title}
                </CardLabel>
              </CardContainer>
            </Link>
            <CardSubtitle>
              {Constants.lastModified} {convertDate(data.updated_at)}
            </CardSubtitle>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
