import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PlanBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '12px',
  border: '1px solid #D8AAEF',
  padding: '32px',
  textAlign: 'center',
  marginBottom: '40px',
}));

export const PlanTitle = styled(Typography)(() => ({
  fontSize: '32px',
  color: '#914FB2',
  fontWeight: '600',
  marginBottom: '20px',
}));

export const PlanText = styled(Typography)(() => ({
  fontSize: '24px',
}));

export const LeftBox = styled(Box)(() => ({
  width: '100%',
  textAlign: 'left',
  margin: '20px 0 0 40px',
}));

export const BoldTypography = styled(Typography)(() => ({
  fontSize: '21px',
  fontWeight: '600',
  margin: '10px 0',
}));

export const SubtitleTypography = styled(Typography)(() => ({
  marginLeft: '32px',
  fontSize: '14px',
}));

export const ListItem = styled('li')(() => ({
  color: '#2E383F',
  fontSize: '18px',
  '::marker': {
    fontSize: '24px',
    color: '#914FB2',
  },
  '& span': {
    marginLeft: '-10px',
  },
}));
