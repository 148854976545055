import { Box, ButtonBase, MenuItem, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CardContainer = styled(Box)(() => ({
  margin: '4px',
  padding: '6px',
  borderBottom: '1px solid #0000003b',
  display: 'flex',
  justifyContent: 'space-between',
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
}));

export const NumberTypography = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: '600',
  color: '#004D43',
}));

export const Title = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}));

export const MenuButton = styled(ButtonBase)(() => ({
  borderRadius: '50%',
  padding: '0 2px',
  '&:hover': {
    '& span': {
      fontSize: '26px',
      fontWeight: '600',
    },
    padding: '0 1px',
  },
  '& .MuiTouchRipple-root': {
    color: '#00B39B99',
  },
}));

export const CheckboxStyle = {
  padding: '4px',
};
