/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, TextField, Typography } from '@mui/material';
import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { PatternFormat } from 'react-number-format';
import { useNavigate, useParams } from 'react-router-dom';

import { errorReportAPI } from '../../../../api';
import { StatusCode } from '../../../../api/enumerations';
import { ErrorElementData } from '../../../../api/errorReport/types';
import sampleAPI, { getOriginalElementImages } from '../../../../api/sample';
import { PicturesData } from '../../../../api/sample/types';
import {
  CustomTextField,
  MultilineTextField,
  NumericTextField,
  PreffixNumericTextField,
} from '../../../../components/CustomInput';
import StandardMap from '../../../../components/Maps/StandardMap';
import AllPicturesSlider from '../../../../components/Sections/Sample/AllPicturesSlider';
import { TitleTypography } from '../../../../components/UI/Typography';
import { Constants } from '../../../../constants/errorReport';
import {
  IconEditDocumentMS,
  IconFactCheckMS,
  IconPhotoLibraryMS,
} from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import { formatPhone } from '../../../../helpers';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import useSearchCep from '../../../../hooks/useSearchCep';
import {
  BoxContainer,
  SectionBox,
  SectionTitle,
  SubmitBox,
  TitleBox,
} from '../styles';
import {
  BoldTypography,
  CepStyle,
  CoordContainer,
  FlexBox,
  InputText,
  MapButton,
} from './styles';

type LatLngLiteral = google.maps.LatLngLiteral;
interface ElementProps {
  elementData: ErrorElementData;
  errorFindings: string | undefined;
}

export function Element({
  elementData,
  errorFindings,
}: ElementProps): JSX.Element {
  const [address, setAddress] = useState('');
  const [addressNumber, setAddressNumber] = useState('');
  const [addressComplement, setAddressComplement] = useState('');
  const [cep, setCep] = useState('');
  const [city, setCity] = useState('');
  const [district, setDistrict] = useState('');
  const [uf, setUf] = useState('');
  const [totalArea, setTotalArea] = useState(0);
  const [parkingLots, setParkingLots] = useState(0);
  const [sellingPrice, setSellingPrice] = useState(0);
  const [rooms, setRooms] = useState(0);
  const [communityValue, setCommunityValue] = useState(0);
  const [advertiser, setAdvertiser] = useState('');
  const [advertiserContact, setAdvertiserContact] = useState('');
  const [propertyLink, setPropertyLink] = useState('');
  const [pinPlace, setPinPlace] = useState<LatLngLiteral>();
  const [searchMap, setSearchMap] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [findings, setFindings] = useState('');

  const [elementImages, setElementImages] = useState<PicturesData[]>([]);
  const [allPictures, setAllPictures] = useState<PicturesData[]>([]);
  const [loadingMorePictures, setLoadingMorePictures] = useState(false);
  const [updatedPictures, setUpdatedPictures] = useState(true);

  const navigate = useNavigate();
  const { id } = useParams();
  const reportId = parseInt(id as string, 10);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();
  const { handleSearchCep } = useSearchCep();

  const getDataCallback = useCallback(async () => {
    setCep(elementData.zipcode || '');
    setAddress(elementData.street);
    setAddressNumber(elementData.number);
    setCity(elementData.city);
    setDistrict(elementData.district);
    setTotalArea(elementData.area);
    setAddressComplement(elementData.address_complement || '');
    setUf(elementData.state || '');
    setParkingLots(elementData.parking_spaces);
    setRooms(elementData.bedrooms);
    setSellingPrice(elementData.sell_price);
    setCommunityValue(elementData.condominium_fee);
    setAdvertiser(elementData.advertiser?.name || '');
    setAdvertiserContact(elementData.advertiser?.phone || '');
    setPropertyLink(elementData.link || '');
    setPinPlace({ lat: elementData.latitude, lng: elementData.longitude });

    if (errorFindings) {
      setFindings(errorFindings);
    }
  }, [elementData]);

  const getElementImages = useCallback(async () => {
    try {
      const { detail, data } = await getOriginalElementImages(Number(id));

      if (detail.description) {
        throw new Error(detail.description);
      }

      if (detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (data) {
        setElementImages(data);
      }
    } catch (err) {
      setSnackbarMessage(getErrorMessage(err));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, []);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  useEffect(() => {
    getElementImages();
  }, []);

  const verifyCep = async (cep: string): Promise<void> => {
    const addressData = await handleSearchCep(cep);
    if (addressData) {
      setCep(addressData.cep);
      setCity(addressData.city);
      setDistrict(addressData.district);
      setAddress(addressData.address);
      setUf(addressData.uf);
    }
  };

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();

    if (findings.length < 3) {
      setSnackbarMessage('Adicione observações!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    if (!pinPlace) {
      setSnackbarMessage('Confirme a localização no mapa');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const updatedElement = {
      zipcode: cep,
      street: address,
      number: addressNumber,
      address_complement: addressComplement,
      city,
      district,
      state: uf,
      area: totalArea,
      parking_spaces: parkingLots,
      bedrooms: rooms,
      sell_price: sellingPrice,
      condominium_fee: communityValue,
      advertiser: JSON.stringify({
        name: advertiser,
        phone: advertiserContact,
      }),
      link: propertyLink,
      latitude: pinPlace.lat,
      longitude: pinPlace.lng,
      facade_image: elementData.facade_image || false,
    };

    const updateError = {
      findings,
      finished: isUpdated,
      sample_description: updatedElement,
    };

    try {
      const response = await errorReportAPI.updateErrorReport(
        reportId,
        updateError
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage(
        isUpdated ? 'Erro finalizado!' : 'Dados alterados com sucesso!'
      );
      setErrorMessage(false);
      setOpenSnackbar(true);
      setIsUpdated(true);
      if (isUpdated) navigate('/error-report');
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const getMorePictures = async (): Promise<void> => {
    setLoadingMorePictures(true);

    try {
      const response = await sampleAPI.getAllElementPictures(elementData.id);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Não foi possível carregar as fotos.');
      }
      setAllPictures(response.data);
      setLoadingMorePictures(false);
      setUpdatedPictures(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      setLoadingMorePictures(false);
    }
  };

  return (
    <BoxContainer>
      <TitleBox>
        <TitleTypography>
          {IconFactCheckMS}
          {Constants.element}
        </TitleTypography>
      </TitleBox>
      <Grid
        container
        spacing={2}
        component="form"
        id="edit-element"
        onSubmit={handleSubmit}
      >
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <PatternFormat
                required
                format="#####-###"
                customInput={TextField}
                id="cep"
                label="CEP"
                color="secondary"
                value={cep}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  verifyCep(e.target.value)
                }
                sx={CepStyle}
              />
            </Grid>
            <Grid item xs={8}>
              <CustomTextField
                required
                id="address"
                label="logradouro"
                value={address}
                setValue={setAddress}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                required
                id="address-number"
                label="número"
                value={addressNumber}
                setValue={setAddressNumber}
              />
            </Grid>
            <Grid item xs={8}>
              <CustomTextField
                id="address-complement"
                label="complemento"
                value={addressComplement}
                setValue={setAddressComplement}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                required
                id="city"
                label="cidade"
                value={city}
                setValue={setCity}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                required
                id="district"
                label="bairro"
                value={district}
                setValue={setDistrict}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                required
                id="uf"
                label="estado"
                value={uf}
                setValue={setUf}
              />
            </Grid>
            <Grid item xs={4}>
              <NumericTextField
                id="total-area"
                label="area total"
                suffix=" m²"
                decimalSeparator=","
                decimalScale={2}
                maxLength={18}
                value={totalArea}
                setValue={setTotalArea}
              />
            </Grid>
            <Grid item xs={4}>
              <NumericTextField
                id="parking-lots"
                label="vagas de garagem"
                suffix=" vagas"
                maxLength={9}
                value={parkingLots}
                setValue={setParkingLots}
              />
            </Grid>
            <Grid item xs={4}>
              <NumericTextField
                id="rooms"
                label="quartos*"
                suffix=" quartos"
                maxLength={12}
                value={rooms}
                setValue={setRooms}
              />
            </Grid>
            <Grid item xs={6}>
              <PreffixNumericTextField
                id="selling-price"
                label="preço de venda"
                prefix="R$ "
                decimalSeparator=","
                decimalScale={2}
                maxLength={18}
                value={sellingPrice}
                setValue={setSellingPrice}
              />
            </Grid>
            <Grid item xs={6}>
              <PreffixNumericTextField
                id="community-value"
                label="valor do condomínio"
                prefix="R$ "
                decimalSeparator=","
                decimalScale={2}
                maxLength={18}
                value={communityValue}
                setValue={setCommunityValue}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                required
                id="advertiser"
                label="anunciante"
                value={advertiser}
                setValue={setAdvertiser}
              />
            </Grid>
            <Grid item xs={6}>
              <InputText
                required
                id="advertiser-contact"
                label="contato anunciante"
                value={advertiserContact}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setAdvertiserContact(formatPhone(e.target.value))
                }
                inputProps={{ maxLength: 15 }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                id="property-link"
                label="link do imóvel"
                value={propertyLink}
                setValue={setPropertyLink}
              />
            </Grid>
          </Grid>
        </Grid>
        {pinPlace && (
          <Grid item xs={12} lg={6}>
            <StandardMap
              searchMap={searchMap}
              address={`${address} ${addressNumber} ${district} ${city}`}
              pinPlace={pinPlace}
              setPinPlace={setPinPlace}
            />
            <CoordContainer>
              <FlexBox>
                <BoldTypography>{Constants.lat}</BoldTypography>
                <Typography>{pinPlace.lat}</Typography>
              </FlexBox>
              <FlexBox>
                <BoldTypography>{Constants.lng}</BoldTypography>
                <Typography>{pinPlace.lng}</Typography>
              </FlexBox>
            </CoordContainer>
            <MapButton onClick={() => setSearchMap(!searchMap)}>
              {Constants.searchMap}
            </MapButton>
          </Grid>
        )}
      </Grid>
      <SectionBox>
        <SectionTitle>{IconPhotoLibraryMS} Fotos</SectionTitle>
        <Grid container spacing={1} sx={{ mt: '20px' }}>
          {elementImages.map((pic) => (
            <Grid item xs={2} key={pic.id}>
              <AllPicturesSlider
                file={pic}
                images={allPictures.length ? allPictures : elementImages}
                getMorePictures={getMorePictures}
                loading={loadingMorePictures}
                isUpdated={updatedPictures}
              />
            </Grid>
          ))}
        </Grid>
      </SectionBox>
      <SectionBox>
        <SectionTitle mb="20px">
          {IconEditDocumentMS}
          {Constants.findings}
        </SectionTitle>
        <MultilineTextField
          id="findings"
          label="observações"
          value={findings}
          setValue={setFindings}
        />
      </SectionBox>
      <SubmitBox>
        {isUpdated ? (
          <Button form="edit-element" type="submit">
            {Constants.finish}
          </Button>
        ) : (
          <Button form="edit-element" type="submit">
            {Constants.save}
          </Button>
        )}
      </SubmitBox>
    </BoxContainer>
  );
}
