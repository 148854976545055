/* eslint-disable max-len */
import { Driver } from 'driver.js';

import { InitialModalTour } from '../../Modal/InitialModalTour';

interface InspectionTourProps {
  driverObj: Driver | null;
}

export function InspectionTour({
  driverObj,
}: InspectionTourProps): JSX.Element {
  return (
    <InitialModalTour
      title="Vamos dar início a"
      pageName="Vistoria"
      subtext1="Nesta tela, teremos acesso a inserção de informações sobre a vistoria, como fotos, cômodos e preenchimento do formulário com perguntas referentes ao imóvel."
      driverObj={driverObj}
      stepTourName="inspection"
    />
  );
}
