import { Box, ButtonBase, Dialog, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)(() => ({
  padding: '32px 0',
}));

export const StyledButton = styled(ButtonBase)(({ theme }) => ({
  fontSize: '18px',
  color: theme.palette.primary.main,
  '&:hover': {
    fontWeight: '600',
    borderBottom: '1px solid #B038FA',
  },
}));

export const DialogContainer = styled(Box)(() => ({
  minWidth: 600,
  minHeight: 200,
  padding: '40px 40px',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
}));

export const TextTypography = styled(Typography)(() => ({
  textAlign: 'center',
  fontSize: '22px',
  fontWeight: '600',
  color: '#595959',
}));

export const ButtonBox = styled(Box)(() => ({
  margin: '20px 0',
  display: 'flex',
  gap: '40px',
  justifyContent: 'center',
}));

export const AlertTypography = styled(Typography)(() => ({
  color: '#595959',
  textAlign: 'center',
}));

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: 16,
    backgroundColor: '#FAFCFF',
  },
}));
