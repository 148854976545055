export const Constants = {
  agenda: 'Agenda',
  month: 'mês',
  year: 'ano',
  view: 'visualização',
  today: 'Hoje',
  searchEngineer: 'buscar vistoriador',
  todayButtonHint: 'ir para o dia atual',
  blockAgenda: 'bloquear agenda',
  user: 'usuário',
  startDate: 'data de início',
  start: 'início',
  endDate: 'data final',
  end: 'fim',
  observation: 'observação',
  addObs: 'adicione uma observação',
  cancel: 'cancelar bloqueio',
  createBlock: 'criar bloqueio',
  editBlock: 'editar bloqueio',
  deleteBlock: 'excluir bloqueio',
};

export const calendarViewOptions = [
  { value: 0, label: 'dia' },
  { value: 1, label: 'semana' },
  { value: 2, label: 'mês' },
];

export const views = ['timeGridDay', 'timeGridWeek', 'dayGridMonth'];

export const months = [
  { value: 0, label: 'Janeiro' },
  { value: 1, label: 'Fevereiro' },
  { value: 2, label: 'Março' },
  { value: 3, label: 'Abril' },
  { value: 4, label: 'Maio' },
  { value: 5, label: 'Junho' },
  { value: 6, label: 'Julho' },
  { value: 7, label: 'Agosto' },
  { value: 8, label: 'Setembro' },
  { value: 9, label: 'Outubro' },
  { value: 10, label: 'Novembro' },
  { value: 11, label: 'Dezembro' },
];
