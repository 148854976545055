import { Box, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const InputBox = styled(Box)(() => ({
  margin: '30px 0',
  display: 'flex',
  justifyContent: 'space-between',
  gap: '36px',
}));

export const CoordGrid = styled(Grid)(() => ({
  marginTop: '-12px',
  display: 'flex',
  flexWrap: 'wrap',
}));

export const BoldTypography = styled(Typography)(() => ({
  fontWeight: '600',
}));

export const StyledTextField = styled(TextField)(() => ({
  width: '100%',
  '& .MuiInputBase-root': { borderRadius: '16px' },
}));
