import {
  getLocalStorageCompany,
  getLocalStorageToken,
  removeLocalStorageCompany,
  removeLocalStorageToken,
} from '../helpers/localStorage';

export const handleUrl = (): string => {
  const staticUrl = process.env.REACT_APP_API_STATIC_URL || '';
  const dinamicUrl = process.env.REACT_APP_API_BASE_URL || '';

  const company = getLocalStorageCompany();
  if (company !== null) {
    return dinamicUrl.replace('*', company.subdomain);
  }
  removeLocalStorageToken();
  removeLocalStorageCompany();
  return staticUrl;
};

export const myHeadersWithTokenContentType = (): Headers => {
  const token = getLocalStorageToken();
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');
  return myHeaders;
};

export const myHeadersWithToken = (): Headers => {
  const token = getLocalStorageToken();
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  return myHeaders;
};

export const myHeadersWithoutToken = (): Headers => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  return myHeaders;
};
