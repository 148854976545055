/* eslint-disable react-hooks/exhaustive-deps */
import { Box, LinearProgress, Pagination } from '@mui/material';
import { useContext, useEffect } from 'react';

import { UserData } from '../../../api/users/types';
import SearchBox from '../../../components/SearchBox';
import { PagesContainer, TitleBox } from '../../../components/UI/Box';
import { Constants } from '../../../constants/users';
import { GlobalContext } from '../../../context/global';
import { PageTitle } from '../styles';
import useUsers from './hooks';
import { FilterBox, LoadingBox, TableBox } from './styles';
import TableUser from './Table';
import UserDialog from './UserDialog';

export default function Users(): JSX.Element {
  const {
    setLoading,
    loading,
    users,
    searchTerm,
    setSearchTerm,
    searchedUsers,
    setSearchedUsers,
    page,
    setPage,
    totalPages,
    handleNextPage,
  } = useUsers();
  const { company } = useContext(GlobalContext);

  const updateUsers = (): void => {
    setPage(1);
    setLoading(true);
  };

  useEffect(() => {
    updateUsers();
  }, [company]);

  return (
    <Box>
      <TitleBox>
        <PageTitle>{Constants.panel}</PageTitle>
        <UserDialog updateTable={updateUsers} userRole={company?.role} />
      </TitleBox>
      <TableBox>
        <FilterBox>
          <SearchBox<UserData>
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            initialDatas={users}
            setResults={setSearchedUsers}
          />
        </FilterBox>
        {loading ? (
          <LoadingBox>
            <LinearProgress />
          </LoadingBox>
        ) : (
          <>
            <TableUser
              updateTable={updateUsers}
              tableData={searchedUsers.length !== 0 ? searchedUsers : users}
              userRole={company?.role}
            />
            <PagesContainer>
              <Pagination
                page={page}
                count={
                  searchedUsers.length !== 0
                    ? Math.ceil(Number(searchedUsers.length) / 10)
                    : totalPages
                }
                onChange={(_, page) => handleNextPage(page)}
              />
            </PagesContainer>
          </>
        )}
      </TableBox>
    </Box>
  );
}
