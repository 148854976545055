import { Box, Button, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '30px',
    backgroundColor: '#FAFCFF',
    minHeight: '600px',
    padding: '32px 60px',
    [theme.breakpoints.down('lg')]: {
      minWidth: '700px',
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: '1200px',
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: '1400px',
    },
  },
  '& .MuiDialog-paperScrollPaper': {
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.dark,
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '&::-webkit-scrollbar-track': {
      margin: '60px 0',
    },
  },
}));

export const DialogButton = styled(Button)(({ theme }) => ({
  width: '280px',
  margin: '20px 0',
  backgroundColor: '#FAFCFF',
  fontWeight: 600,
  fontSize: 15,
  lineHeight: '20px',
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: 10,
  color: theme.palette.primary.main,
  display: 'flex',
  gap: 8,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
  '&:active': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const DialogContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

export const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  gap: '40px',
  justifyContent: 'right',
}));
