export const Constants = {
  totalArea: 'área total:',
  privateArea: 'área privativa',
  landArea: 'área do terreno',
  constructedArea: 'área construída:',
  rooms: 'quartos:',
  toilets: 'banheiros:',
  suites: 'suítes:',
  parkingLots: 'vagas de garagem:',
  propertyAge: 'idade do imóvel:',
  conservation: 'estado de conservação do imóvel:',
  standard: 'padrão construtivo:',
  years: ' anos',
  year: ' ano',
};
