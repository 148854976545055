/* eslint-disable react-hooks/exhaustive-deps */
import { driver } from 'driver.js';
import {
  ChangeEvent,
  DragEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { pictureAPI, roomAPI, workOrderAPI } from '../../../api';
import { Status, StatusCode, StepTour } from '../../../api/enumerations';
import { GalleryPictureData } from '../../../api/pictures/types';
import BackdropLoading from '../../../components/BackdropLoading';
import { Title } from '../../../components/Sections/Title';
import { BoxContainer } from '../../../components/UI/Box';
import { BackButton } from '../../../components/UI/Button';
import { GridContainer } from '../../../components/UI/Grid';
import { SectionTitle } from '../../../components/UI/Typography';
import {
  IconAddPhotoAlternateMS,
  IconArrowCircleLeftMS,
  IconDoorFrontMS,
  IconPhotoLibraryMS,
} from '../../../constants/icons';
import { Constants } from '../../../constants/inspection';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage } from '../../../helpers';
import { driverConfig } from '../../../helpers/driver/config';
import { gallerySteps } from '../../../helpers/driver/steps';
import { useTour } from '../../../hooks/useTour';
import useUploadFile from '../../../hooks/useUploadFile';
import DeletePictureDialog from '../DeletePictureDialog';
import {
  FlexReverseBox,
  GalleryContainer,
  InsideBox,
  PathologiesBox,
  PhotoContainer,
  StyledCardMedia,
  Upload,
  UploadIcon,
  UploadSubtitle,
  UploadTitle,
} from './styles';

export default function InspectionPhotoGallery(): JSX.Element {
  const [edit, setEdit] = useState(false);
  const [roomName, setRoomName] = useState('');
  const [page, setPage] = useState(1);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const [pictures, setPictures] = useState<GalleryPictureData[]>([]);
  const [loading, setLoading] = useState(false);
  const picturesPerPage = 12;

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { dragActive, handleDrag, handleDrop, handleFileUpload } =
    useUploadFile();

  const navigate = useNavigate();
  const { id, inspection, room } = useParams();
  const osId = parseInt(id as string, 10);
  const inspectionId = parseInt(inspection as string, 10);
  const roomId = parseInt(room as string, 10);

  const navigateBack = (): void => {
    navigate(`/home/property/${osId}/inspection/${inspectionId}/rooms`);
  };

  const navigateToPicture = (pictureId: number): void => {
    navigate(
      `/home/property/${osId}/inspection/${inspectionId}/room/${roomId}/image-details/${pictureId}`
    );
  };

  const getDataCallback = useCallback(async () => {
    if (page === lastCalledPage) {
      return;
    }

    if (page === 1) {
      try {
        const response = await roomAPI.getRoom(roomId);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data) {
          throw new Error('Não foi possível carregar o nome do cômodo.');
        }

        setRoomName(response.data.name);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
        navigateBack();
      }

      try {
        const response = await workOrderAPI.getWorkOrder(osId);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        if (
          response.data.status === Status.ENTRANCE ||
          response.data.status === Status.PEPT ||
          response.data.status === Status.SCHEDULE
        ) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        if (response.data.status === Status.INSPECTION) {
          setEdit(true);
        }
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
        navigate('/home');
      }
    }

    try {
      const response = await pictureAPI.getAllPictures(
        roomId,
        page,
        picturesPerPage
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setPictures([...pictures, ...response.data]);
      setLastCalledPage(page);

      if (response.detail.total_pages && response.detail.total_pages > page) {
        setPage(page + 1);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      navigateBack();
    }
  }, [page]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback, page]);

  const handleSubmitPhotos = async (files: FileList): Promise<void> => {
    setLoading(true);

    const formData = new FormData();
    Array.from(files).map((file) => formData.append('pictures', file));
    formData.append('room_id', roomId.toString());

    try {
      const response = await pictureAPI.addPicture(formData);
      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }
      setSnackbarMessage('Fotos adicionadas com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      setLoading(false);
      navigate(
        // eslint-disable-next-line max-len
        `/home/property/${osId}/inspection/${inspectionId}/room/${roomId}/image-details/new/${response.data.first_id}`
      );
    } catch (error) {
      setLoading(false);
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const dropFile = (e: DragEvent<HTMLDivElement>): void => {
    const files = handleDrop(e);
    if (files) {
      handleSubmitPhotos(files);
    }
  };

  const uploadFile = (e: ChangeEvent<HTMLInputElement>): void => {
    const files = handleFileUpload(e);
    if (files) {
      handleSubmitPhotos(files);
    }
  };

  const handleDeletePhoto = async (pictureId: number): Promise<void> => {
    try {
      const response = await pictureAPI.deletePicture(pictureId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Foto e patologias deletadas!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      const filterPictures = pictures.filter((el) => el.id !== pictureId);
      setPictures(filterPictures);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const {
    tourCompletion,
    setTourCompletion,
    setTutorialStep,
    setTourOn,
    setTourSelection,
    setToBeContinued,
  } = useTour();

  const galleryDriverObj = driver({
    ...driverConfig,
    steps: gallerySteps,
    onNextClick: () => {
      if (galleryDriverObj.isLastStep()) {
        setTourCompletion((prev) => {
          const newTourCompletion = { ...prev };
          newTourCompletion.lastStepSeen = StepTour.INSPECTIONFINISH;
          const inspectionIndex = newTourCompletion.inspection.findIndex(
            (item) => item.stage === 'inspectionGallery'
          );
          if (inspectionIndex !== -1) {
            const updatedInspectionIndex = {
              ...newTourCompletion.inspection[inspectionIndex],
            };
            updatedInspectionIndex.complete = true;
            newTourCompletion.inspection[inspectionIndex] =
              updatedInspectionIndex;
          }
          return newTourCompletion;
        });
        if (
          tourCompletion.inspection[0].complete &&
          tourCompletion.inspection[1].complete &&
          tourCompletion.inspection[2].complete &&
          tourCompletion.inspection[3].complete &&
          tourCompletion.inspection[4].complete
        ) {
          setTutorialStep((step) => step + 1);
          setTourOn(true);
        }
      }
      galleryDriverObj.moveNext();
    },
    onCloseClick: () => {
      setTourSelection(false);
      setToBeContinued(false);
      galleryDriverObj?.destroy();
    },
    onDestroyStarted: () => {
      setTourSelection(false);
      setToBeContinued(false);
      galleryDriverObj?.destroy();
    },
  });

  useEffect(() => {
    setTutorialStep(StepTour.INSPECTIONSTART);

    if (edit) {
      if (
        !tourCompletion.inspection[5].complete &&
        !tourCompletion.skipTour.inspection
      ) {
        setTimeout(() => {
          galleryDriverObj.drive();
        }, 500);
      }
    }
  }, [tourCompletion, edit]);

  return (
    <GridContainer>
      <BackButton onClick={navigateBack} id="back-btn">
        {IconArrowCircleLeftMS}
      </BackButton>
      <BoxContainer>
        <Title title={roomName} icon={IconDoorFrontMS} />
        <SectionTitle>
          {IconPhotoLibraryMS}
          {Constants.photoGalley}
        </SectionTitle>
        <GalleryContainer id="box-container">
          {edit && (
            <Upload
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={dropFile}
            >
              <label htmlFor="uploadMorePhotos">
                <input
                  accept="image/*"
                  multiple
                  id="uploadMorePhotos"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => uploadFile(e)}
                />
                {!dragActive ? (
                  <InsideBox>
                    <UploadIcon>{IconAddPhotoAlternateMS}</UploadIcon>
                    <UploadTitle>{Constants.morePhotos}</UploadTitle>
                    <UploadSubtitle>{Constants.fileType}</UploadSubtitle>
                  </InsideBox>
                ) : (
                  <UploadTitle>{Constants.dragText}</UploadTitle>
                )}
              </label>
            </Upload>
          )}
          {pictures?.map((photo) => (
            <PhotoContainer key={photo.id}>
              {edit && (
                <FlexReverseBox>
                  <DeletePictureDialog
                    id="btn-delete"
                    outlinedButton={false}
                    modalCallback={handleDeletePhoto}
                    pictureId={photo.id}
                    text={Constants.photoAndPathologies}
                  />
                </FlexReverseBox>
              )}
              <StyledCardMedia
                image={photo.file}
                title={photo.name || ''}
                onClick={() => navigateToPicture(photo.id)}
              />
              <FlexReverseBox>
                <PathologiesBox id="total">
                  {photo.total_pathologies}
                </PathologiesBox>
              </FlexReverseBox>
            </PhotoContainer>
          ))}
        </GalleryContainer>
      </BoxContainer>
      <BackdropLoading open={loading} />
    </GridContainer>
  );
}
