import { Backdrop, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
}));

export const StyledBackdrop = styled(Backdrop)(() => ({
  backgroundColor: 'rgba(0,0,0,0.7)',
  zIndex: '300',
}));
