/* eslint-disable */
import { Dispatch, SetStateAction } from 'react';
import { TourData } from '../../api/users/types';

export function updateTourDataFormat(tourData: any) {
  if (!tourData.hasOwnProperty('lastStepSeen')) {
    tourData.lastStepSeen = 0;
  }
  const simpleFields = [
    'onBoarding',
    'pipeline',
    'pept',
    'entrance',
    'schedule',
    'calculation',
    'report',
    'revision',
    'archived',
    'configuration',
    'form',
    'agenda',
    'errorReport',
  ];
  simpleFields.forEach((field) => {
    if (typeof tourData[field] === 'boolean') {
      tourData[field] = { complete: tourData[field] };
    }
  });
  if (typeof tourData.skipTour === 'boolean') {
    tourData.skipTour = {
      onBoarding: false,
      pipeline: false,
      pept: false,
      entrance: false,
      schedule: false,
      inspection: false,
      sampleCreate: false,
      calculation: false,
      report: false,
      revision: false,
      archived: false,
      configuration: false,
      form: false,
      agenda: false,
      errorReport: false,
    };
  }
  if (Array.isArray(tourData.inspection)) {
    tourData.inspection = tourData.inspection.map((item: any) => {
      if (item.hasOwnProperty('checked')) {
        return {
          stage: item.stage,
          complete: item.checked,
          step: 0,
        };
      }
      return item;
    });
  }
  if (typeof tourData.sampleCreate === 'boolean') {
    tourData.sampleCreate = [
      { stage: 'sampleCreate', complete: tourData.sampleCreate, step: 0 },
      { stage: 'addElement', complete: false, step: 0 },
    ];
  } else if (Array.isArray(tourData.sampleCreate)) {
    tourData.sampleCreate = tourData.sampleCreate.map((item: any) => {
      if (item.hasOwnProperty('checked')) {
        return {
          stage: item.stage,
          complete: item.checked,
          step: 0,
        };
      }
      return item;
    });
  }

  tourData.skipTour = tourData.skipTour;

  return tourData;
}

export const updateSkipTour = (
  setTourCompletion: Dispatch<SetStateAction<TourData>>,
  field: keyof TourData['skipTour'],
  value: boolean
) => {
  setTourCompletion((prevState) => ({
    ...prevState,
    skipTour: {
      ...prevState.skipTour,
      [field]: value,
    },
  }));
};
