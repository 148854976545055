import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IExportButton {
  responsive: 'true' | 'false';
}

export const ExportButton = styled(Button)<IExportButton>(
  ({ theme, responsive }) => ({
    minWidth: '360px',
    border: `1px solid ${theme.palette.primary.dark}`,
    color: theme.palette.primary.dark,
    span: {
      marginRight: '8px',
    },
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.dark,
    },
    ...(responsive === 'true'
      ? {
          [theme.breakpoints.down('lg')]: {
            minWidth: '220px',
          },
        }
      : {
          maxWidth: '220px',
        }),
  })
);
