import {
  handleUrl,
  myHeadersWithToken,
  myHeadersWithTokenContentType,
} from '../global';
import {
  AddPictureProps,
  DetailProps,
  GetPictureProps,
  GetPicturesProps,
  SelectInspectionPicturesData,
} from './types';

const addPicture = (data: FormData): Promise<AddPictureProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithToken(),
    body: data,
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/pictures`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const getAllPictures = (
  id: number,
  page: number,
  size: number
): Promise<GetPicturesProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/rooms/${id}/pictures?page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const getLastPictures = (id: number): Promise<GetPicturesProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}/last-pictures`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const getPicture = (id: number): Promise<GetPictureProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/pictures/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const updatePicture = (id: number, data: FormData): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
    body: data,
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/pictures/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const selectInspectionPictures = (
  data: SelectInspectionPicturesData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/pictures/select`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const deletePicture = (id: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/pictures/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export default {
  addPicture,
  getAllPictures,
  getLastPictures,
  getPicture,
  updatePicture,
  selectInspectionPictures,
  deletePicture,
};
