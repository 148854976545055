import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledButton = styled(Button)(({ theme }) => ({
  width: 250,
  backgroundColor: theme.palette.primary.dark,
  '&:hover': {
    backgroundColor: '',
    filter: 'brightness(85%)',
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: '210px',
  },
}));
