/* eslint-disable react/require-default-props */
import { Box, Checkbox } from '@mui/material';
import { APIProvider } from '@vis.gl/react-google-maps';
import { useLocation } from 'react-router-dom';

import { CoordinateEventData } from '../../../api/workOrders/types';
import { IconLocationOnMS, IconTaskAltMS } from '../../../constants/icons';
import { formatDateAndTime } from '../../../helpers';
import { useStoragedJwt } from '../../../hooks/useDecodedJwt';
import CustomMap from './CustomMap';
import {
  BoxContainer,
  CheckboxFormControl,
  PinText,
  StyledTypography,
} from './styles';

interface StandardMapProps {
  searchMap?: boolean;
  address?: string;
  pinPlace: google.maps.LatLngLiteral;
  setPinPlace?: (place: google.maps.LatLngLiteral) => void;
  lasCoordinateEvent?: CoordinateEventData | null;
  checkLocation?: boolean;
  handleCheckLocation?: () => Promise<void>;
  checkLocationDate?: string;
  draggable?: boolean;
}

export default function StandardMap({
  searchMap,
  address,
  pinPlace,
  setPinPlace,
  lasCoordinateEvent,
  checkLocation,
  handleCheckLocation,
  checkLocationDate,
  draggable = true,
}: StandardMapProps): JSX.Element {
  const location = useLocation();
  const user = useStoragedJwt();

  return (
    <BoxContainer id="pin">
      <APIProvider
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
        libraries={['places', 'marker']}
      >
        <CustomMap
          searchMap={searchMap}
          address={address}
          pinPlace={pinPlace}
          setPinPlace={setPinPlace}
          draggable={draggable}
        />
      </APIProvider>
      {setPinPlace ? (
        <PinText>Mova o pin caso a localização esteja incorreta</PinText>
      ) : (
        <Box>
          {checkLocation && (
            <Box>
              {location.pathname.includes('inspection') &&
                (checkLocationDate ? (
                  <StyledTypography error="true">
                    {IconTaskAltMS}
                    Localização atualizada por {user?.user.name} em:{' '}
                    {formatDateAndTime(checkLocationDate)}
                  </StyledTypography>
                ) : (
                  <Box display="flex" justifyContent="space-between">
                    <StyledTypography>
                      {IconLocationOnMS}
                      Localização pendente de confirmação
                    </StyledTypography>
                    <CheckboxFormControl
                      label="confirmar localização"
                      control={<Checkbox color="primary" />}
                      onChange={handleCheckLocation}
                    />
                  </Box>
                ))}
              {lasCoordinateEvent &&
                !location.pathname.includes('inspection') && (
                  <StyledTypography error="true">
                    {IconTaskAltMS}
                    Localização atualizada por {
                      lasCoordinateEvent?.user.name
                    }{' '}
                    em: {formatDateAndTime(lasCoordinateEvent?.created_at)}
                  </StyledTypography>
                )}
            </Box>
          )}
        </Box>
      )}
    </BoxContainer>
  );
}
