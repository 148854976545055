import { handleUrl } from '../global';
import { DetailProps, TokenProps } from './types';

const firstAccessEmail = (email: string): Promise<DetailProps> => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/reset-password/${email}`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      return JSON.parse(result);
    })
    .catch((error) => new Error(error));
};

const firstAccessToken = (
  email: string,
  token: string
): Promise<TokenProps> => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/reset-password/${email}/${token}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      return JSON.parse(result);
    })
    .catch((error) => new Error(error));
};

const resetPassword = (
  token: string,
  password: FormData
): Promise<DetailProps> => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: password,
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/reset-password`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      return JSON.parse(result);
    })
    .catch((error) => new Error(error));
};

const recoverPasswordEmail = (email: string): Promise<DetailProps> => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/reset-password/${email}?first_access=false`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      return JSON.parse(result);
    })
    .catch((error) => new Error(error));
};

export default {
  firstAccessEmail,
  firstAccessToken,
  recoverPasswordEmail,
  resetPassword,
};
