import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';

import { ProgressBox } from './components/ProgressBox';
import { TourProvider, useTour } from './hooks/useTour';
import { AppRoutes } from './routes';
import { theme } from './styles/theme';

export default function App(): JSX.Element {
  const { isProgressOpen } = useTour();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <TourProvider>
          <AppRoutes />
          {isProgressOpen && <ProgressBox />}
        </TourProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}
