import { Box, FormControlLabel, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxContainer = styled(Box)(({ theme }) => ({
  border: `2px solid ${theme.palette.primary.dark}`,
  borderRadius: '12px',
  padding: '32px 40px',
  marginTop: '40px',
}));

export const BoxTitle = styled(Box)(() => ({
  position: 'absolute',
  marginTop: '-54px',
  backgroundColor: '#F2F2F2',
  paddingLeft: '4px',
}));

export const StyledLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiTypography-root': {
    fontWeight: '600',
    color: theme.palette.primary.dark,
  },
  '&:hover': {
    '& .MuiButtonBase-root': {
      color: '#d485eed1',
    },
  },
}));

export const StyledTextField = styled(TextField)(() => ({
  width: '100%',
  '& .MuiInputBase-root': { borderRadius: '16px' },
}));

export const ButtonsSpace = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-around',
  marginTop: '20px',
}));
