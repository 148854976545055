import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContainer = styled(Box)(() => ({
  width: '700px',
  display: 'flex',
  flexDirection: 'column',
  padding: '48px',
}));

export const OutlinedButton = styled(Button)(({ theme }) => ({
  width: '300px',
  textTransform: 'lowercase',
  color: theme.palette.primary.dark,
  backgroundColor: 'transparent',
  border: `1.5px solid ${theme.palette.primary.dark}`,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  '&:disabled': {
    color: theme.palette.primary.dark,
  },
  '&:active': {
    backgroundColor: theme.palette.primary.dark,
  },
}));
