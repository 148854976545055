/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
import { Box, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { Constants } from '../../../../constants/samples';
import { SelectProps } from '../../../../constants/selectOptions';
import { CitiesOptionsContainer, Option } from './styles';

interface FilterInputProps {
  label: string;
  selectOptions: SelectProps[];
  selectedOption: string;
  setSelectedOption: (value: string) => void;
  disabled: boolean;
  sampleId?: number;
  setSampleId?: (value: number | undefined) => void;
}

export default function FilterInput({
  label,
  selectOptions,
  selectedOption,
  setSelectedOption,
  disabled,
  sampleId,
  setSampleId,
}: FilterInputProps): JSX.Element {
  const [options, setOptions] = useState<SelectProps[]>(selectOptions);
  const [showList, setShowList] = useState<boolean>(false);
  const filterContainerRef = useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (!selectOptions) return;
    if (searchTerm.length === 0) {
      setSelectedOption('');
      setOptions(selectOptions);
      return;
    }
    if (searchTerm.length > 0) {
      const searchedOptions = selectOptions.filter((option) => {
        if (option.label) {
          const optionLabelLowerCase = option.label.toLowerCase();
          const searchTermLowerCase = searchTerm.toLowerCase();
          return optionLabelLowerCase.includes(searchTermLowerCase);
        }
        return null;
      });
      setOptions(searchedOptions);
    }
  }, [selectOptions, searchTerm, selectedOption]);

  useEffect(() => {
    if (selectedOption.length === 0) {
      setSearchTerm('');
      if (setSampleId) {
        setSampleId(undefined);
      }
    }
  }, [selectedOption]);

  useEffect(() => {
    if (sampleId) {
      const sample = selectOptions.find((option) => option.value === sampleId);
      if (sample) {
        setSearchTerm(sample.label);
        setSelectedOption(sample.label);
      }
    }
  }, [sampleId]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        filterContainerRef.current &&
        !filterContainerRef.current.contains(event.target as Node)
      ) {
        setShowList(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [filterContainerRef]);

  const handleOptionClick = (option: SelectProps): void => {
    setSelectedOption(option.label);
    if (setSampleId) {
      setSampleId(option.value);
    }
    setSearchTerm(option.label);
    setShowList(false);
  };

  return (
    <Box width="100%" position="relative" ref={filterContainerRef}>
      <TextField
        placeholder={Constants.searchField}
        label={label}
        type="search"
        id="autocomplete-input"
        color="secondary"
        inputProps={{ 'aria-label': 'pesquisar', maxLength: 11 }}
        value={searchTerm}
        disabled={disabled}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
        sx={{
          width: '100%',
          '& .MuiInputBase-root': { borderRadius: '16px' },
          'input:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0px 1000px #F5F5F4 inset',
          },
        }}
        onFocus={() => setShowList(true)}
      />
      {showList && (
        <CitiesOptionsContainer>
          {options.length > 0 ? (
            options.map((option) => (
              <Option
                key={option.value}
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
              </Option>
            ))
          ) : (
            <Option
              disabled
              sx={{
                color: '#7c7c7c',
                backgroundColor: '#EDEDED',
                '&:hover': { background: '#EDEDED' },
                cursor: 'default',
              }}
            >
              {Constants.noOptions}
            </Option>
          )}
        </CitiesOptionsContainer>
      )}
    </Box>
  );
}
