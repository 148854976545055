import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';
import { Container } from '@mui/material';
import { useContext, useEffect } from 'react';

import ligandoLuz from '../../assets/images/Ilustra_LigandoLuz.webp';
import Snackbar from '../../components/Snackbar';
import { GlobalContext } from '../../context/global';
import { getErrorMessage } from '../../helpers';
import { BoxShadowContainer } from './styles';

export function DeleteAccount(): JSX.Element {
  const { formCreated, error } = useHubspotForm({
    portalId: '23577649',
    formId: '2526c574-bd66-4cb5-8c6c-6a0f126cf9e6',
    target: '#hubspotForm',
  });

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  useEffect(() => {
    if (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [error, setSnackbarMessage, setErrorMessage, setOpenSnackbar]);

  return (
    <Container sx={{ padding: '85px 345px' }}>
      <BoxShadowContainer>
        <img
          src={ligandoLuz}
          alt="Imagem de robô roxo com a logo do PROTO ligando o sistema de luzes"
        />

        <h1>Baixar dados da conta</h1>

        <span>
          Por favor, informe seu e-mail e telefone que nossa equipe entrará em
          contato com o processo de exclusão de conta
        </span>

        <div>{formCreated && <div id="hubspotForm" />}</div>
      </BoxShadowContainer>
      {openSnackbar && <Snackbar />}
    </Container>
  );
}
