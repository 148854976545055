/* eslint-disable */
import {
  Box,
  CircularProgress,
  Modal,
  Paper,
  Switch,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { ChangeEvent, useCallback, useContext, useState } from 'react';

import { workOrderAPI } from '../../../api';
import { FilledButton, OutlinedButton } from '../../../components/UI/Button';
import { SectionTitle } from '../../../components/UI/Typography';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage } from '../../../helpers';
import useGeneral from '../../../hooks/useGeneral';
import { BoldCell, CellContent, CellHeader, RowHeader, StyledTable, StyledTooltip } from './styles';

interface ForceFactorsProps {
  refNumber: number | undefined;
  factors: string[] | null | undefined;
}

export function ForceFactors({
  refNumber,
  factors,
}: ForceFactorsProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const [forceFactors, setForceFactors] = useState(
    factors || ['Área Construída']
  );
  const [loading, setLoading] = useState(false);
  const { osId, navigateHome } = useGeneral();
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);
  const values = [
    { index: 0, name: 'fator de setor censitário', value: 'Localização' },
    { index: 1, name: 'fator de padrão construtivo', value: 'Padrão' },
    { index: 2, name: 'fator de área', value: 'Área Construída' },
    { index: 3, name: 'fator de vaga de garagem', value: 'Vaga' },
    { index: 4, name: 'fator de idade', value: 'Idade Estimada' },
    {
      index: 5,
      name: 'fator de estado de preservação',
      value: 'Estado de Conservação',
    },
    { index: 6, name: 'fator de área do terreno', value: 'Terreno' },
  ];
  const handleForceFactors = useCallback(async (): Promise<void> => {
    setLoading(true);
    const data = {
      force_factors: forceFactors,
    };
    try {
      const { detail } = await workOrderAPI.sendForceFactors(osId, data);
      if (detail.description) {
        throw new Error(detail.description);
      }
      if (detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }
      setLoading(false);
      setErrorMessage(false);
      setSnackbarMessage(`OS ${refNumber} aprovada`);
      setOpenSnackbar(true);
      navigateHome();
    } catch (error) {
      setLoading(false);
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  }, [forceFactors]);
  return (
    <>
      <FilledButton onClick={handleOpen} type="button" id="recalculate">
        recalcular
      </FilledButton>
      <Modal
        open={open}
        onClose={handleClose}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          component={Paper}
          sx={{ padding: 6, width: '700px', borderRadius: '16px' }}
          id="factors-dialog"
        >
          <SectionTitle color="GrayText">forçar fatores</SectionTitle>
          <TableContainer sx={{ marginTop: '4px' }}>
            <StyledTable>
              <TableHead>
                <RowHeader>
                  <CellHeader> </CellHeader>
                  <CellHeader>nome</CellHeader>
                  <CellHeader>forçar fator</CellHeader>
                </RowHeader>
              </TableHead>
              <TableBody>
                {values.map((value) => (
                  <TableRow key={value.index}>
                    <CellContent>{value.index}</CellContent>
                    <BoldCell>{value.name}</BoldCell>
                    <CellContent>
                      {value.index === 2 ? (
                        <StyledTooltip title="fator obrigatório para cálculo">
                          <Switch checked />
                        </StyledTooltip>
                      ) : (
                        <Switch
                          checked={forceFactors.includes(value.value)}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            if (e.target.checked) {
                              setForceFactors([...forceFactors, value.value]);
                            } else {
                              const deleteChoice = forceFactors?.filter(
                                (element) => {
                                  return element !== value.value;
                                }
                              );
                              setForceFactors(deleteChoice);
                            }
                          }}
                        />
                      )}
                    </CellContent>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '32px',
            }}
          >
            <OutlinedButton type="button" onClick={handleClose} id="cancel-btn">
              cancelar
            </OutlinedButton>
            <FilledButton type="button" onClick={handleForceFactors}>
              {loading ? <CircularProgress /> : 'confirmar'}
            </FilledButton>
          </div>
        </Box>
      </Modal>
    </>
  );
}
