/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { sampleAPI, workOrderAPI } from '../../../api';
import {
  EvaluationType,
  PropertyType,
  Status,
  StatusCode,
} from '../../../api/enumerations';
import { RoomData } from '../../../api/rooms/types';
import { ElementData } from '../../../api/sample/types';
import { WorkOrderData } from '../../../api/workOrders/types';
import { getErrorMessage, validateFiles } from '../../../helpers';
import { useAttachment } from '../../../hooks/useAttachmentFiles';
import useGeneral from '../../../hooks/useGeneral';
import { usePropertyData } from '../../../hooks/usePropertyData';
import { useRooms } from '../../../hooks/useRooms';
import useSnackbar from '../../../hooks/useSnackbar';

type LatLngLiteral = google.maps.LatLngLiteral;

interface ICompleteSections {
  propertyDetails: boolean;
  sample: boolean;
}

interface SampleHook {
  handleApproveConservation(): Promise<void>;
  checkApprovedSample(): void;
  handleSearchSample(): Promise<void>;
  propertyData: WorkOrderData | undefined;
  disableInfo: boolean;
  age: number;
  setAge: (value: number) => void;
  conservation: number;
  setConservation: (value: number) => void;
  buildingStandard: number;
  setBuildingStandard: (value: number) => void;
  loading: boolean;
  showMap: boolean;
  pinPlace: LatLngLiteral | undefined;
  disapprovedElements: number[];
  setDisapprovedElements: (value: number[]) => void;
  sampleData: ElementData[] | undefined;
  completeElements: ElementData[] | undefined;
  incompleteElements: ElementData[] | undefined;
  getSample: () => Promise<void>;
  showModal: boolean;
  evaluationTypeIsFactors: boolean;
  page: number;
  setPage: (value: number) => void;
  view: string;
  handleView: (event: React.MouseEvent<HTMLElement>, view: string) => void;
  minElements: string;
  complete: boolean;
  setComplete: (value: boolean) => void;
  incomplete: boolean;
  setIncomplete: (value: boolean) => void;
  rooms: RoomData[];
  handleDeleteSample: () => Promise<void>;
  supplyFactor: number;
  setSupplyFactor: (value: number) => void;
  isSupplyEditionDisabled: boolean;
  setIsSupplyEditionDisabled: (value: boolean) => void;
  deactivateFilters: () => void;
  osId: number;
  extrapolationsErrors: string;
  isConfirmChangesLoading: boolean;
  setIsConfirmChangesLoading: (value: boolean) => void;
  showAddressModal: boolean;
  setShowAddressModal: (value: boolean) => void;
  handleNewSupplyFactor: () => Promise<void>;
  loadingDelete: boolean;
  loadingPage: boolean;
  enableEditing: boolean;
  setEnableEditing: (value: boolean) => void;
  client: string;
  setClient: (value: string) => void;
  solicitor: string;
  setSolicitor: (value: string) => void;
  goal: number;
  setGoal: (value: number) => void;
  propertyFunction: number;
  setPropertyFunction: (value: number) => void;
  propertyUse: number;
  setPropertyUse: (value: number) => void;
  handleFileUpload: (
    e: ChangeEvent<HTMLInputElement>,
    value: string
  ) => Promise<void>;
  registerFileName: string;
  iptuFileName: string;
  downloadIptu: string;
  downloadRegister: string;
  registerNumber: string;
  setRegisterNumber: (value: string) => void;
  concept: number;
  setConcept: (value: number) => void;
  zone: number;
  setZone: (value: number) => void;
  judicialDistrict: string;
  setJudicialDistrict: (value: string) => void;
  registrationUf: number;
  setRegistrationUf: (value: number) => void;
  propertyRooms: number;
  setPropertyRooms: (value: number) => void;
  toilets: number;
  setToilets: (value: number) => void;
  suites: number;
  setSuites: (value: number) => void;
  handleSubmit: (e: React.FormEvent) => Promise<void>;
  submitLoading: boolean;
  lastElementEdited: number | undefined;
  setLastElementEdited: (value: number | undefined) => void;
  maxRadius: number;
  setMaxRadius: (value: number) => void;
  parkingError: boolean;
  setParkingError: (value: boolean) => void;
  factorsMax: number;
  inferenceMax: number;
  completedSections: ICompleteSections;
}

const useSampleCreation = (): SampleHook => {
  const [age, setAge] = useState(0);
  const [buildingStandard, setBuildingStandard] = useState(1);
  const [conservation, setConservation] = useState(1);
  const [maxRadius, setMaxRadius] = useState<number>(0.5);
  const [sampleRadius, setSampleRadius] = useState<number>(2.5);
  const [disableInfo, setDisableInfo] = useState(false);
  const [pinPlace, setPinPlace] = useState<LatLngLiteral>();
  const [sampleData, setSampleData] = useState<ElementData[]>();
  const [completeElements, setCompleteElements] = useState<ElementData[]>();
  const [incompleteElements, setIncompleteElements] = useState<ElementData[]>();
  const [disapprovedElements, setDisapprovedElements] = useState<number[]>([]);

  const [page, setPage] = useState(1);
  const [view, setView] = useState('card');
  const [minElements, setMinElements] = useState('');
  const [complete, setComplete] = useState(false);
  const [incomplete, setIncomplete] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [supplyFactor, setSupplyFactor] = useState(6);
  const [isSupplyEditionDisabled, setIsSupplyEditionDisabled] = useState(false);
  const [extrapolationsErrors, setExtrapolationsErrors] = useState('');
  const [isConfirmChangesLoading, setIsConfirmChangesLoading] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [modalWasShown, setModalWasShown] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [lastElementEdited, setLastElementEdited] = useState<number>();
  const [parkingError, setParkingError] = useState(false);

  const [enableEditing, setEnableEditing] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [completedSections, setCompletedSections] = useState<ICompleteSections>(
    {
      propertyDetails: false,
      sample: false,
    }
  );

  // Editable data
  const [client, setClient] = useState('');
  const [solicitor, setSolicitor] = useState('');
  const [goal, setGoal] = useState(0);
  const [propertyFunction, setPropertyFunction] = useState(0);
  const [propertyUse, setPropertyUse] = useState(0);
  const [registerFileName, setRegisterFileName] = useState('');
  const [iptuFileName, setIptuFileName] = useState('');
  const [registerNumber, setRegisterNumber] = useState('');
  const [concept, setConcept] = useState(0);
  const [zone, setZone] = useState(0);
  const [judicialDistrict, setJudicialDistrict] = useState('');
  const [registrationUf, setRegistrationUf] = useState(0);
  const [downloadIptu, setDownloadIptu] = useState('');
  const [downloadRegister, setDownloadRegister] = useState('');
  const [propertyRooms, setPropertyRooms] = useState(0);
  const [toilets, setToilets] = useState(0);
  const [suites, setSuites] = useState(0);

  const { iptuLink, setIptuLink, registerLink, setRegisterLink } =
    useAttachment();
  const { propertyData, getDataCallback } = usePropertyData({
    status: Status.SAMPLE_CREATION,
  });
  const { osId } = useGeneral();
  const { handleSnackbar } = useSnackbar();
  const { handleRooms, rooms } = useRooms();

  const evaluationTypeIsFactors =
    propertyData?.evaluation_type === EvaluationType.AUTOFACTORS ||
    propertyData?.evaluation_type === EvaluationType.SIMPFACTORS;

  const factorsMax = 12;
  const factorsMin = 5;
  const inferenceMax = 70;
  const inferenceMin = 16;
  // Minimum elements to consider parking lots in the avm calculation.
  const minConsiderParking = 20;
  const minRequired = evaluationTypeIsFactors ? factorsMin : inferenceMin;

  const getMinElements = (totalElements: number): void => {
    if (evaluationTypeIsFactors) {
      if (factorsMax - totalElements > 0) {
        setMinElements(`${factorsMax - totalElements}`);
      }
    }
    if (!evaluationTypeIsFactors) {
      if (inferenceMax - totalElements > 0) {
        setMinElements(`${inferenceMax - totalElements}`);
      }
    }
  };

  const getSample = async (): Promise<void> => {
    setShowMap(false);
    try {
      const response = await sampleAPI.getSample(osId, 1, 105);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('A amostra não pode ser carregada.');
      }

      if (
        response.data.sample_extrapolations_errors &&
        response.data.sample_extrapolations_errors.length > 0
      ) {
        setExtrapolationsErrors(response.data.sample_extrapolations_errors);
      } else {
        setExtrapolationsErrors('');
      }

      if (response.data.max_radius) {
        setSampleRadius(response.data.max_radius);
        setMaxRadius(response.data.max_radius);
      }

      const sample = response.data.items;
      setSampleData(sample);
      setMinElements('');
      const filteredSamples: number[] = sample
        .filter((data) => data.approved === false)
        .map((data) => sample.indexOf(data) + 1);
      setDisapprovedElements(filteredSamples);
      setIsSupplyEditionDisabled(true);
      setShowMap(true);
      setLoading(false);
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
      setLoading(false);
    }
  };

  const getSampleData = async (): Promise<void> => {
    let sample = false;

    if (propertyData) {
      setAge(propertyData?.age);
      setConservation(propertyData?.preservation_state);
      setBuildingStandard(propertyData?.constructive_standard);
      setDownloadIptu(propertyData.iptu_document || '');
      setDownloadRegister(propertyData.real_estate_registry || '');

      setClient(propertyData.client_name);
      setSolicitor(propertyData.requester_name);
      setGoal(propertyData.report_goal);
      setPropertyFunction(propertyData.report_function);
      setPropertyUse(propertyData.use);
      setRegisterNumber(propertyData.registration_number);
      setConcept(propertyData.communities_kind);
      setZone(propertyData.zone);
      setJudicialDistrict(propertyData.judicial_district);
      setRegistrationUf(propertyData.uf);
      setPropertyRooms(propertyData.bedrooms);
      setToilets(propertyData.bathrooms);
      setSuites(propertyData.suites);

      if (propertyData.inspection) {
        handleRooms(propertyData.inspection.id);
      }
      if (propertyData.samples) {
        sample = true;
      }
      if (propertyData.latitude && propertyData.longitude) {
        setPinPlace({
          lat: Number(propertyData.latitude),
          lng: Number(propertyData.longitude),
        });
      }

      setLoadingPage(false);
    }

    if (propertyData?.supply_factor) {
      setSupplyFactor(propertyData.supply_factor);
    }

    if (sample) {
      setLoading(true);
      getSample();
    }
  };

  useEffect(() => {
    getSampleData();
  }, [propertyData]);

  useEffect(() => {
    if (propertyData) {
      getDataCallback();
    }
  }, [iptuLink, registerLink]);

  useEffect(() => {
    if (lastElementEdited) {
      setPage(lastElementEdited);
      setLastElementEdited(undefined);
    }
  }, [sampleData]);

  const handleApproveConservation = async (): Promise<void> => {
    const data = {
      age,
      preservation_state: conservation,
      constructive_standard: buildingStandard,
    };

    try {
      const response = await workOrderAPI.approveWorkOrder(osId, data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      handleSnackbar('Dados alterados com sucesso!', false);
      setDisableInfo(true);
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    }
  };

  const updateGoogleMap = useCallback(
    async (osPin: LatLngLiteral, samplePins: ElementData[]): Promise<void> => {
      const markers = samplePins.map((sample) => {
        return `${sample.description.latitude}, ${sample.description.longitude} |`;
      });
      const sampleMarkers = markers.join(' ');

      let zoom = 14;
      if (sampleRadius < 1.5) zoom = 15;
      if (sampleRadius < 1.0) zoom = 16;

      const mapFile = await fetch(
        // eslint-disable-next-line max-len
        `https://maps.googleapis.com/maps/api/staticmap?center=${osPin.lat},${osPin.lng}&style=feature:poi|element:labels|visibility:off&zoom=${zoom}&size=580x480&scale=2&markers=color:0xe67615|${osPin.lat},${osPin.lng}&markers=color:0xae38f9|size:tiny|${sampleMarkers}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      )
        .then((r) => r.blob())
        .then(
          (bFile) => new File([bFile], 'map-elements', { type: 'image/png' })
        );

      const mapSatelliteFile = await fetch(
        // eslint-disable-next-line max-len
        `https://maps.googleapis.com/maps/api/staticmap?center=${osPin.lat},${osPin.lng}&style=feature:poi|element:labels|visibility:off&zoom=${zoom}&size=580x480&scale=2&maptype=satellite&markers=color:0xe67615|${osPin.lat},${osPin.lng}&markers=color:0xae38f9|size:tiny|${sampleMarkers}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      )
        .then((r) => r.blob())
        .then(
          (bFile) =>
            new File([bFile], 'satellite-map-elements', { type: 'image/png' })
        );

      const mapImageData = new FormData();
      mapImageData.append('google_maps', mapFile);
      mapImageData.append('satellite_view', mapSatelliteFile);

      try {
        const response = await workOrderAPI.addGoogleMapsImagesWithPins(
          osId,
          mapImageData
        );

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        handleSnackbar('A OS pode ser aprovada!', false);
        setShowModal(true);
      } catch (error) {
        handleSnackbar(getErrorMessage(error), true);
      }
    },
    []
  );

  const checkApprovedSample = (): void => {
    if (!disableInfo) {
      handleSnackbar('Aprove o estado de conservação da OS!', true);
      return;
    }

    if (sampleData) {
      if (sampleData.length < minRequired) {
        handleSnackbar(`Mínimo ${minRequired} elementos para prosseguir`, true);
        return;
      }
    }

    if (disapprovedElements.length > 0) {
      handleSnackbar(
        `Aprove o estado de conservação dos seguintes elementos: ${disapprovedElements.join(
          ', '
        )}.`,
        true
      );
      return;
    }

    if (sampleData && pinPlace) {
      setIsConfirmChangesLoading(true);

      updateGoogleMap(pinPlace, sampleData).then(() => {
        setIsConfirmChangesLoading(false);
        if (
          !evaluationTypeIsFactors &&
          propertyData?.real_estate_kind === PropertyType.APARTMENT &&
          sampleData.length < minConsiderParking
        ) {
          setParkingError(true);
        } else {
          setParkingError(false);
        }
      });
    } else {
      handleSnackbar('Algo deu errado, tente novamente.', true);
    }
  };

  const handleSearchSample = async (): Promise<void> => {
    if (!disableInfo) {
      handleSnackbar(
        'Você precisa aprovar o estado de conservação para buscar amostras',
        true
      );
      return;
    }
    setIsSupplyEditionDisabled(true);
    setLoading(true);
    setExtrapolationsErrors('');

    let sample = false;

    try {
      const response = await sampleAPI.checkSample(
        osId,
        supplyFactor,
        maxRadius
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data) {
        setExtrapolationsErrors(response.data.extrapolations_errors);
      }

      sample = true;
    } catch (error) {
      setLoading(false);
      setIsSupplyEditionDisabled(false);
      handleSnackbar(getErrorMessage(error), true);
    }

    if (sample) {
      getSample();
    }
  };

  const handleView = (
    event: React.MouseEvent<HTMLElement>,
    newView: string
  ): void => {
    setView(newView);
  };

  const reset = (): void => {
    setSampleData(undefined);
    setCompleteElements(undefined);
    setIncompleteElements(undefined);
    setDisapprovedElements([]);
    setPage(1);
    setView('card');
    setMinElements('');
    setIsSupplyEditionDisabled(false);
    setExtrapolationsErrors('');
  };

  const handleDeleteSample = async (): Promise<void> => {
    setLoadingDelete(true);
    try {
      const response = await sampleAPI.deleteSample(propertyData?.id ?? -1);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }
      reset();
      setExtrapolationsErrors('');
      handleSnackbar('Amostra excluída com sucesso!', false);
      setLoadingDelete(false);
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
      setLoadingDelete(false);
    }
  };

  const deactivateFilters = (): void => {
    setComplete(false);
    setIncomplete(false);
  };

  useEffect(() => {
    setShowModal(false);
    if (propertyData && sampleData?.length) {
      getMinElements(sampleData.length);
    }
    if (sampleData) {
      if (
        !modalWasShown &&
        propertyData?.real_estate_kind === PropertyType.APARTMENT
      ) {
        const filteredAddress: ElementData[] = sampleData.filter(
          (data) =>
            data.description.street === propertyData.street &&
            data.description.number === propertyData.number
        );
        if (filteredAddress.length < 1) {
          setShowAddressModal(true);
          setModalWasShown(true);
        }
      }
      const filteredCompleteElements: ElementData[] = sampleData.filter(
        (data) =>
          (data.description.level === 1 && data.approved) ||
          (!data.description.level && data.approved)
      );
      setCompleteElements(filteredCompleteElements);

      const filteredIncompleteElements: ElementData[] = sampleData.filter(
        (data) =>
          !(
            (data.description.level === 1 && data.approved) ||
            (!data.description.level && data.approved)
          )
      );
      setIncompleteElements(filteredIncompleteElements);
    }
  }, [sampleData, disapprovedElements]);

  const handleNewSupplyFactor = useCallback(async (): Promise<void> => {
    if (supplyFactor < 0 || supplyFactor > 100) {
      handleSnackbar('O valor de oferta deve ser entre 0 e 100.', true);
      return;
    }

    try {
      const res = await workOrderAPI.updateSupplyFactor(osId, supplyFactor);

      if (res.detail.description) {
        throw new Error(res.detail.description);
      }

      if (res.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      handleSnackbar('Fator de oferta editado com sucesso!', false);
      setIsSupplyEditionDisabled(true);
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    }
  }, [supplyFactor]);

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>,
    fileType: string
  ): Promise<void> => {
    if (!e.target.files?.item(0) || !validateFiles(e.target.files[0].type)) {
      handleSnackbar('Formato incorreto, selecione uma imagem ou pdf', true);
      return;
    }

    const file = e.target.files[0];

    const formData = new FormData();
    if (fileType === 'register') {
      formData.append('real_estate_registry', file);
    }
    if (fileType === 'iptu') {
      formData.append('iptu_document', file);
    }

    try {
      const response = await workOrderAPI.editWorkOrder(osId, formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      handleSnackbar('Dados alterados com sucesso!', false);

      if (fileType === 'register') {
        setRegisterLink(file.name);
        setRegisterFileName(file.name);
      }
      if (fileType === 'iptu') {
        setIptuLink(file.name);
        setIptuFileName(file.name);
      }
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    }
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    if (
      concept === 0 ||
      goal === 0 ||
      propertyFunction === 0 ||
      propertyUse === 0 ||
      registrationUf === 0
    ) {
      handleSnackbar('Os dados de seleção são obrigatórios', true);
      return;
    }

    const formData = new FormData();
    formData.append('client_name', client);
    formData.append('requester_name', solicitor);
    formData.append('report_goal', goal.toString());
    formData.append('report_function', propertyFunction.toString());
    formData.append('use', propertyUse.toString());
    formData.append('registration_number', registerNumber);
    formData.append('communities_kind', concept.toString());
    formData.append('zone', zone.toString());
    formData.append('judicial_district', judicialDistrict);
    formData.append('uf', registrationUf.toString());
    formData.append('bedrooms', propertyRooms.toString());
    formData.append('bathrooms', toilets.toString());
    formData.append('suites', suites.toString());

    try {
      setSubmitLoading(true);
      const response = await workOrderAPI.editWorkOrder(osId, formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      handleSnackbar('Dados alterados com sucesso!', false);
      setSubmitLoading(false);
      setEnableEditing(false);
      getDataCallback();
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    if (disableInfo) {
      setCompletedSections((prev) => ({ ...prev, propertyDetails: true }));
    } else {
      setCompletedSections((prev) => ({ ...prev, propertyDetails: false }));
    }
  }, [disableInfo]);

  useEffect(() => {
    if (
      sampleData &&
      disapprovedElements.length === 0 &&
      (sampleData.length === minRequired || sampleData.length > minRequired)
    ) {
      setCompletedSections((prev) => ({ ...prev, sample: true }));
    } else {
      setCompletedSections((prev) => ({ ...prev, sample: false }));
    }
  }, [sampleData, disapprovedElements]);

  return {
    handleApproveConservation,
    checkApprovedSample,
    handleSearchSample,
    propertyData,
    disableInfo,
    age,
    setAge,
    conservation,
    setConservation,
    buildingStandard,
    setBuildingStandard,
    loading,
    showMap,
    sampleData,
    completeElements,
    incompleteElements,
    getSample,
    pinPlace,
    disapprovedElements,
    setDisapprovedElements,
    showModal,
    evaluationTypeIsFactors,
    page,
    setPage,
    view,
    handleView,
    minElements,
    complete,
    setComplete,
    incomplete,
    setIncomplete,
    rooms,
    handleDeleteSample,
    setSupplyFactor,
    isSupplyEditionDisabled,
    setIsSupplyEditionDisabled,
    supplyFactor,
    deactivateFilters,
    osId,
    extrapolationsErrors,
    isConfirmChangesLoading,
    setIsConfirmChangesLoading,
    showAddressModal,
    setShowAddressModal,
    handleNewSupplyFactor,
    loadingDelete,
    loadingPage,
    enableEditing,
    setEnableEditing,
    client,
    setClient,
    solicitor,
    setSolicitor,
    goal,
    setGoal,
    propertyFunction,
    setPropertyFunction,
    propertyUse,
    setPropertyUse,
    handleFileUpload,
    registerFileName,
    iptuFileName,
    downloadIptu,
    downloadRegister,
    registerNumber,
    setRegisterNumber,
    concept,
    setConcept,
    zone,
    setZone,
    judicialDistrict,
    setJudicialDistrict,
    registrationUf,
    setRegistrationUf,
    propertyRooms,
    setPropertyRooms,
    toilets,
    setToilets,
    suites,
    setSuites,
    handleSubmit,
    submitLoading,
    lastElementEdited,
    setLastElementEdited,
    maxRadius,
    setMaxRadius,
    parkingError,
    setParkingError,
    factorsMax,
    inferenceMax,
    completedSections,
  };
};

export default useSampleCreation;
