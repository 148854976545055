import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CardContainer = styled(Box)(() => ({
  padding: '8px 16px',
  borderBottom: '1px solid #D7D8DB',
  display: 'flex',
  justifyContent: 'space-between',
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
}));

export const NumberTypography = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: '600',
  color: theme.palette.primary.dark,
}));

export const TitleTypography = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  padding: '4px',
  fontSize: '18px',
}));
