import { useContext, useState } from 'react';

import { userAPI } from '../../../api';
import { EmailIcon } from '../../../components/InputIcon';
import { Constants } from '../../../constants/firstAccess';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage, validateEmail } from '../../../helpers';
import { FilledButton, InputBox, StyledTextField } from './styles';

interface EmailProps {
  handleNextStage: (value: number) => void;
  setEmailId: (email: string) => void;
}

export default function FirstAccessEmail({
  handleNextStage,
  setEmailId,
}: EmailProps): JSX.Element {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const handleSendEmail = async (): Promise<void> => {
    if (emailError) {
      setSnackbarMessage('Digite seu e-mail cadastrado.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const trimEmail = email.trim();

    try {
      const response = await userAPI.getFirstAccess(trimEmail);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setEmailId(trimEmail);
      handleNextStage(response.data.activation_step);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  };

  return (
    <InputBox>
      <StyledTextField
        required
        id="email"
        variant="standard"
        InputProps={EmailIcon}
        placeholder={Constants.emailPlaceholder}
        error={emailError}
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
          !validateEmail(e.target.value)
            ? setEmailError(true)
            : setEmailError(false);
        }}
      />
      <FilledButton onClick={handleSendEmail}>{Constants.submit}</FilledButton>
    </InputBox>
  );
}
