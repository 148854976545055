import { FirstAccessStages } from '../../api/enumerations';
import LoginLayout from '../../components/LoginLayout';
import { Constants } from '../../constants/firstAccess';
import useFirstAccess from './hooks';
import { Stage, Title } from './styles';

export default function FirstAccess(): JSX.Element {
  const { stageNumber, component } = useFirstAccess();

  return (
    <LoginLayout>
      <>
        <Stage>
          {stageNumber !== FirstAccessStages.SUCCESS &&
            Constants.stage.replace('**', `${stageNumber}`)}
        </Stage>
        {component?.title && <Title>{component?.title}</Title>}
        {component?.component}
      </>
    </LoginLayout>
  );
}
