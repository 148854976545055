import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContainer = styled(Box)(({ theme }) => ({
  padding: '2rem 3rem',
  width: '1200px',
  minHeight: '300px',
  background: theme.palette.primary.light,
}));

export const ButtonBox = styled(Box)(() => ({
  justifyContent: 'end',
  display: 'flex',
  gap: 21,
  marginTop: '32px',
}));

export const FormStyled = styled(Box)(() => ({
  margin: '20px 0',
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
}));
