import {
  Box,
  Grid,
  Snackbar,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)(() => ({
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
}));

export const TitleBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '4px 0 20px 20px',
  alignItems: 'center',
  gap: '20px',
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
}));

export const ScrollTypography = styled(Typography)(() => ({
  color: '#F2F2F2',
}));

export const ToastTypography = styled(Typography)(() => ({
  display: 'flex',
  gap: '4px',
  color: '#FAFCFF',
  '& span': { color: '#62aa41' },
}));

export const StyledToast = styled(Snackbar)(() => ({
  '.MuiSnackbarContent-root': { minWidth: 'fit-content' },
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    margin: '0 20px',
    marginTop: '-12px',
    border: 'none',
    '& .MuiButtonBase-root.MuiToggleButton-root': {
      backgroundColor: 'transparent',
      border: 'none',
      padding: '4px',
      borderRadius: '18px',
      '&.Mui-selected': {
        color: theme.palette.primary.main,
      },
    },
    '& .pipe': {
      '& span': {
        fontSize: '30px',
      },
    },
    '& .lista': {
      '& span': {
        fontSize: '26px',
        padding: '0 2px',
      },
    },
    '& :hover': {
      color: theme.palette.primary.dark,
    },
  })
);
