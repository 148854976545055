/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
import { Box } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { samplesListAPI } from '../../../api';
import { EvaluationType } from '../../../api/enumerations';
import { ElementData } from '../../../api/sample/types';
import { SampleList } from '../../../api/samplesLists/types';
import { WorkOrderData } from '../../../api/workOrders/types';
import { AddElement } from '../../../components/Sections/Sample/AddElement';
import { UploadExcel } from '../../../components/Sections/Sample/UploadExcel';
import { CloseIcon, RoundedButton } from '../../../components/UI/Button';
import { StyledDialog } from '../../../components/UI/Dialog';
import { DialogTitle } from '../../../components/UI/Typography';
import { IconCloseMS } from '../../../constants/icons';
import { Constants } from '../../../constants/sampleCreation';
import { SelectProps } from '../../../constants/selectOptions';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useGeneral from '../../../hooks/useGeneral';
import useSnackbar from '../../../hooks/useSnackbar';
import { StyledTooltip } from '../styles';
import { SaveElements } from './SaveElements';
import { UseSavedElements } from './UseSavedElements';

interface ElementsManagemntProps {
  propertyData: WorkOrderData;
  sampleData: ElementData[] | undefined;
  osId?: number;
  updateSample: () => Promise<void>;
}

export default function ElementsManagemnt({
  propertyData,
  sampleData,
  osId,
  updateSample,
}: ElementsManagemntProps): JSX.Element {
  const [regionNamesList, setRegionNamesList] = useState<SelectProps[]>([]);

  const factorsMax = 12;
  const inferenceMax = 70;
  const evaluationTypeIsFactors =
    propertyData?.evaluation_type === EvaluationType.AUTOFACTORS ||
    propertyData?.evaluation_type === EvaluationType.SIMPFACTORS;
  const isDisabled =
    sampleData && evaluationTypeIsFactors
      ? sampleData?.length >= factorsMax
      : sampleData?.length === inferenceMax;

  const { handleSnackbar } = useSnackbar();
  const { getErrorMessage } = useErrorMessage();
  const dialog = useGeneral();

  const filterRegionsName = (data: SampleList[]): SelectProps[] => {
    const formatRegionsNameList = Array.from(
      new Set(
        data.map((el) => ({
          value: el.id,
          label: el.region_name,
        }))
      )
    );

    return formatRegionsNameList;
  };

  const getRegionsName = useCallback(async () => {
    const params = {
      city:
        propertyData.city !== '' && propertyData.uf !== 0
          ? propertyData.city
          : undefined,
      realEstateKind:
        propertyData.real_estate_kind !== 0
          ? propertyData.real_estate_kind
          : undefined,
      uf: propertyData.uf !== 0 ? propertyData.uf : undefined,
    };

    try {
      const response = await samplesListAPI.getSamplesLists(params);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      const formattedRegionsNameList = filterRegionsName(response.data);

      setRegionNamesList(formattedRegionsNameList);
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    }
  }, []);

  useEffect(() => {
    getRegionsName();
  }, [propertyData]);

  return (
    <>
      <Box display="flex" gap="30px" marginTop="40px">
        <StyledTooltip
          title="Quantidade máxima de elementos alcançada"
          placement="top"
          disableHoverListener={!isDisabled}
        >
          <span style={{ width: '100%' }}>
            <RoundedButton
              disableTouchRipple
              onClick={dialog.handleOpen}
              disabled={isDisabled}
              model="dark"
            >
              {Constants.addElement}
            </RoundedButton>
          </span>
        </StyledTooltip>
        <SaveElements
          workOrderUf={propertyData.uf}
          workOrderCity={propertyData.city}
          workOrderPropertyType={propertyData.real_estate_kind}
          sampleData={sampleData}
          regionNamesList={regionNamesList}
          isDisabled={isDisabled}
          maxElements={evaluationTypeIsFactors ? factorsMax : inferenceMax}
          osId={osId}
          updateSample={updateSample}
          updateRegionList={getRegionsName}
        />
        <UseSavedElements
          workOrderUf={propertyData.uf}
          workOrderCity={propertyData.city}
          workOrderPropertyType={propertyData.real_estate_kind}
          sampleData={sampleData}
          regionNamesList={regionNamesList}
          isDisabled={isDisabled}
          maxElements={evaluationTypeIsFactors ? factorsMax : inferenceMax}
          osId={osId}
          updateSample={updateSample}
        />
      </Box>
      <StyledDialog
        open={dialog.open}
        onClose={dialog.handleClose}
        aria-labelledby="Choose add elements option"
      >
        <Box padding="2rem 3rem">
          <CloseIcon onClick={dialog.handleClose}>{IconCloseMS}</CloseIcon>
          <DialogTitle>{Constants.addElement}</DialogTitle>
          <Box display="flex" flexDirection="column" gap="16px" width="400px">
            <AddElement
              osNumber={osId}
              updateSample={updateSample}
              highPrice={propertyData.sample_sell_price_above_400k}
              propertyType={propertyData.real_estate_kind}
              locationInfo={{
                city: propertyData.city,
                uf: propertyData.uf,
              }}
              closePrevDialog={dialog.handleClose}
            />
            <UploadExcel
              workOrderId={osId}
              updateSample={updateSample}
              closePrevDialog={dialog.handleClose}
            />
          </Box>
        </Box>
      </StyledDialog>
    </>
  );
}
