import { Grid } from '@mui/material';
import { useState } from 'react';

import { IconCloseMS } from '../../constants/icons';
import { useTour } from '../../hooks/useTour';
import {
  ButtonText,
  CloseButton,
  Container,
  PageName,
  StyledModal,
  SubTextFinish,
  Title,
} from './styles';

interface FinishModalTourProps {
  title: string;
  pageName: string;
}

export function FinishModalTour({
  title,
  pageName,
}: FinishModalTourProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(true);

  const { setProgressOpen, setToBeContinued, setTourOn } = useTour();

  const handleClose = (): void => {
    setIsOpen(false);
    setTourOn(false);
    setToBeContinued(false);
  };

  const handleExplorerQuickTour = (): void => {
    handleClose();
    setProgressOpen(true);
  };

  return (
    <StyledModal open={isOpen} onClose={handleClose}>
      <Container>
        <CloseButton onClick={handleClose}>{IconCloseMS}</CloseButton>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          marginBottom={4}
        >
          <Grid item>
            <Title>{title}</Title>
          </Grid>
          <Grid item marginTop={-1}>
            <PageName>{pageName}</PageName>
          </Grid>
        </Grid>
        <Grid container direction="column" marginBottom={4}>
          <Grid item>
            <SubTextFinish>
              Agora você pode escolher qualquer uma das opções do menu
              &apos;Tour Guiado&apos; e fazer o seu próprio tour personalizado
              conforme sua necessidade.
            </SubTextFinish>
            <br />
          </Grid>
          <Grid item>
            <SubTextFinish>
              Fique à vontade para escolher como deseja seguir.
            </SubTextFinish>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" padding={2}>
          <Grid item>
            <ButtonText onClick={handleClose}>fechar</ButtonText>
          </Grid>
          <Grid item>
            <ButtonText onClick={handleExplorerQuickTour}>
              explorar tour guiado
            </ButtonText>
          </Grid>
        </Grid>
      </Container>
    </StyledModal>
  );
}
