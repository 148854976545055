import { Box, styled } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  width: 662,
  // height: 469,
  backgroundColor: theme.palette.common.white,
  borderRadius: '20px',
  borderColor: 'transparent',
  padding: 30,

  position: 'relative',

  left: -630,
  top: '75px',

  '& .ponta': {
    position: 'absolute',
    width: 0,
    height: 0,
    borderLeft: '25px solid transparent',
    borderRight: '25px solid transparent',
    borderBottom: `50px solid ${theme.palette.common.white}`,
    left: '-50px',
    top: '44%',
    transform: 'rotate(-90deg) translateX(-50%)',
  },

  [theme.breakpoints.down('xl')]: {
    left: '-110px !important',
    top: '42px !important',
  },

  '@media (width: 1920px)': {
    left: '-310px',
  },

  '@media (width: 1680px)': {
    left: '-190px',
  },

  '@media (width: 1024px)': {
    left: '60px !important',
    top: '152px !important',

    width: 600,
  },
}));

export const SkipButton = styled('button')(({ theme }) => ({
  background: theme.palette.common.white,
  color: theme.palette.primary.dark,
  fontWeight: 'bold',
  fontSize: '16px',
  padding: '16px',
  borderRadius: '8px',
  border: 'none',
  outline: 'none',
  textTransform: 'capitalize',
  top: '-95%',
  right: '-170%',
  position: 'absolute',

  [theme.breakpoints.between(1600, 1920)]: {
    right: '-100%',
  },

  [theme.breakpoints.down('xl')]: {
    top: '-70%',
    right: '-50%',
  },

  '&:hover': {
    opacity: '0.8',
    cursor: 'pointer',
  },
}));
