import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SuccessTypography = styled(Typography)(() => ({
  display: 'flex',
  gap: '4px',
  color: '#FAFCFF',
  '& span': { color: '#62aa41' },
}));

export const ErrorTypography = styled(Typography)(() => ({
  display: 'flex',
  gap: '4px',
  color: '#FAFCFF',
  '& span': { color: '#ff0f0f' },
}));
