import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GalleryContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '32px',
  marginTop: 40,
}));

export const FlexCenterBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const TitleBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '20px',
  alignItems: 'center',
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: '28px',
  fontWeight: '600',
  textTransform: 'uppercase',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  '& span': { fontSize: '26px', color: theme.palette.primary.main },
}));

export const EditButton = styled(Button)(({ theme }) => ({
  width: 282,
  height: 40,
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: 10,
  color: theme.palette.primary.main,
  fontWeight: 600,
  fontSize: 15,
  backgroundColor: '#FAFCFF',
  display: 'flex',
  gap: 8,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    '& span': {
      color: '#FAFCFF',
    },
  },
  '&:disabled': {
    color: theme.palette.primary.main,
  },
}));
