import { Box, Button, MenuItem, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogButton = styled(Button)(({ theme }) => ({
  width: '264px',
  height: '56px',
  borderRadius: '10px',
  backgroundColor: theme.palette.primary.dark,
  '@media (max-height: 820px)': {
    height: '48px',
  },
}));

export const DialogContainer = styled(Box)(() => ({
  minWidth: '500px',
  maxWidth: '540px',
  minHeight: '400px',
  padding: '40px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiInputBase-root': {
    borderRadius: '16px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
    fontSize: '2rem',
  },
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
  '&:hover': { backgroundColor: '#d485eed1' },
}));
