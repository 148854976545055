import { ButtonBase } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AlertButton = styled(ButtonBase)(() => ({
  backgroundColor: '#FF872E',
  color: '#FAFCFF',
  borderRadius: '4px',
  width: '62px',
  height: '32px',
  fontSize: '18px',
  display: 'flex',
  gap: '4px',
  '&:hover': {
    backgroundColor: '#FF872EB3',
  },
}));

export const CheckedButton = styled(ButtonBase)(() => ({
  backgroundColor: '#58147F',
  color: '#FAFCFF',
  borderRadius: '4px',
  width: '62px',
  height: '32px',
  fontSize: '18px',
  display: 'flex',
  gap: '4px',
  '&:hover': {
    backgroundColor: '#58147FB3',
  },
}));
