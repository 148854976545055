/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@mui/material';
import { useCallback, useContext, useEffect } from 'react';

import { companyAPI } from '../../../api';
import { RegisterStage } from '../../../api/enumerations';
import { GlobalContext } from '../../../context/global';
import { RegistrationIdentificationContext } from '../../../context/registrationIdentification';
import { getErrorMessage } from '../../../helpers';
import { InputBox } from './styles';

interface DocumentsProps {
  handleNextStage: (stage: RegisterStage) => void;
}
export default function Documents({
  handleNextStage,
}: DocumentsProps): JSX.Element {
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { identificationEmail } = useContext(RegistrationIdentificationContext);

  const handleSubmitIdWall = useCallback(
    async (idwallNumber: IdwallToken) => {
      if (idwallNumber) {
        const data = {
          email: identificationEmail,
          idwall_number: idwallNumber?.token,
        };
        try {
          const { detail } = await companyAPI.sendIdwallNumber(data);

          if (detail.description) {
            throw new Error(detail.description);
          }

          handleNextStage(RegisterStage.IDENTITY_VERIFICATION);
        } catch (error) {
          setSnackbarMessage(getErrorMessage(error));
          setOpenSnackbar(true);
          setErrorMessage(true);
        }
      }
    },
    [
      handleNextStage,
      identificationEmail,
      setErrorMessage,
      setOpenSnackbar,
      setSnackbarMessage,
    ]
  );

  type IdwallToken = {
    token: string;
  };

  useEffect(() => {
    window.idwSDKWeb({
      token: process.env.REACT_APP_IDWALL_SDK_WEB_TOKEN,
      onComplete: (token: IdwallToken) => {
        handleSubmitIdWall(token);
      },
      onError: (error: any) => {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      },
    });
  }, [
    handleNextStage,
    handleSubmitIdWall,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
  ]);

  return (
    <InputBox>
      <Box data-idw-sdk-web />
    </InputBox>
  );
}
