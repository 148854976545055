/* eslint-disable react/require-default-props */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, TextFieldProps } from '@mui/material';
import { DesktopDatePicker, DesktopTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { useContext, useEffect, useState } from 'react';

import { calendarBlockageAPI } from '../../../api';
import { StatusCode } from '../../../api/enumerations';
import { CalendarScheduleData } from '../../../api/inspections/types';
import { UserData } from '../../../api/users/types';
import { ButtonsSpace } from '../../../components/UI/Box';
import {
  CloseIcon,
  FilledButton,
  OutlinedButton,
} from '../../../components/UI/Button';
import { StyledDialog } from '../../../components/UI/Dialog';
import { DialogTitle } from '../../../components/UI/Typography';
import { Constants } from '../../../constants/agenda';
import { IconCloseMS } from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import { formatTime } from '../../../helpers';
import useErrorMessage from '../../../hooks/useErrorMessage';
import {
  DialogButton,
  DialogContainer,
  StyledMenuItem,
  StyledTextField,
} from './styles';

export interface IEventData extends CalendarScheduleData {
  color: string;
}

interface BlockAgendaDialogProps {
  usersData: UserData[];
  open: boolean;
  setOpen: (value: boolean) => void;
  updateEvents: boolean;
  setUpdateEvents: (value: boolean) => void;
  blockage?: IEventData;
}
export default function BlockAgendaDialog({
  usersData,
  open,
  setOpen,
  updateEvents,
  setUpdateEvents,
  blockage,
}: BlockAgendaDialogProps): JSX.Element {
  const [user, setUser] = useState<number>(usersData[0].id);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [startTime, setStartTime] = useState<Dayjs>(dayjs());
  const [endTime, setEndTime] = useState<Dayjs>(dayjs());
  const [formattedStartHour, setFormattedStartHour] = useState('');
  const [formattedEndHour, setFormattedEndHour] = useState('');
  const [observation, setObservation] = useState('');
  const [blockageId, setBlockageId] = useState<number>();

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);

  const handleStartTime = (newValue: Dayjs | null): void => {
    if (newValue) {
      const endTime = dayjs(newValue).add(1, 'hours');
      setStartTime(newValue);
      setEndTime(endTime);
      setFormattedStartHour(formatTime(newValue));
      setFormattedEndHour(formatTime(endTime));
    }
  };

  const handleEndTime = (newValue: Dayjs | null): void => {
    if (newValue) {
      setEndTime(newValue);
      setFormattedEndHour(formatTime(newValue));
    }
  };

  const handleBlockage = async (): Promise<void> => {
    if (!startDate || !endDate || !formattedStartHour || !formattedEndHour) {
      setSnackbarMessage('Os campos de data e hora são obrigatórios.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const blockageData = {
      start_at: `${dayjs(startDate).format(
        'YYYY-MM-DD'
      )}T${formattedStartHour}`,
      finish_at: `${dayjs(endDate).format('YYYY-MM-DD')}T${formattedEndHour}`,
      observations: observation,
      user_id: user,
    };

    if (blockage) {
      try {
        const response = await calendarBlockageAPI.updateBlockage(
          blockage.id,
          blockageData
        );

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setSnackbarMessage('Bloqueio atualizado com sucesso!');
        setErrorMessage(false);
        setOpenSnackbar(true);
        setUpdateEvents(!updateEvents);
        handleClose();
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }
    } else {
      try {
        const response = await calendarBlockageAPI.createBlockage(blockageData);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setSnackbarMessage('Bloqueio realizado com sucesso!');
        setErrorMessage(false);
        setOpenSnackbar(true);
        setUpdateEvents(!updateEvents);
        handleClose();
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }
    }
  };

  useEffect(() => {
    if (blockage) {
      setBlockageId(blockage.id);
      setUser(blockage.user.id);
      setStartDate(dayjs(blockage.start_at));
      setEndDate(dayjs(blockage.finish_at));
      setStartTime(dayjs(blockage.start_at));
      setEndTime(dayjs(blockage.finish_at));
      setFormattedStartHour(formatTime(dayjs(blockage.start_at)));
      setFormattedEndHour(formatTime(dayjs(blockage.finish_at)));
      setObservation(blockage.observations || '');
    }
  }, [blockage]);

  const handleDeleteBlockage = async (): Promise<void> => {
    if (!blockageId) {
      setSnackbarMessage('Algo deu errado, tente novamente.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      handleClose();
      return;
    }

    try {
      const response = await calendarBlockageAPI.deleteBlockage(blockageId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Bloqueio removido com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      setUpdateEvents(!updateEvents);
      handleClose();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <>
      {!blockage && (
        <DialogButton onClick={handleOpen}>
          {Constants.blockAgenda}
        </DialogButton>
      )}
      <StyledDialog open={open} onClose={handleClose}>
        <DialogContainer>
          <CloseIcon onClick={handleClose}>{IconCloseMS}</CloseIcon>
          <DialogTitle>{Constants.blockAgenda}</DialogTitle>
          <StyledTextField
            select
            label={Constants.user}
            color="primary"
            value={user}
            disabled={!!blockageId}
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            onChange={(e) => setUser(Number(e.target.value))}
          >
            {usersData.map((option) => (
              <StyledMenuItem key={option.id} value={option.id}>
                {option.name}
              </StyledMenuItem>
            ))}
          </StyledTextField>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={Constants.startDate}
                  value={startDate}
                  format="DD-MM-YYYY"
                  disablePast
                  slots={{
                    textField:
                      StyledTextField as React.ComponentType<TextFieldProps>,
                  }}
                  onChange={(e) => setStartDate(e)}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopTimePicker
                  label={Constants.start}
                  value={startTime}
                  ampm={false}
                  slots={{
                    textField:
                      StyledTextField as React.ComponentType<TextFieldProps>,
                  }}
                  onChange={handleStartTime}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={8}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={Constants.endDate}
                  value={endDate}
                  format="DD-MM-YYYY"
                  disablePast
                  slots={{
                    textField:
                      StyledTextField as React.ComponentType<TextFieldProps>,
                  }}
                  onChange={(e) => setEndDate(e)}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopTimePicker
                  label={Constants.end}
                  value={endTime}
                  ampm={false}
                  slots={{
                    textField:
                      StyledTextField as React.ComponentType<TextFieldProps>,
                  }}
                  onChange={handleEndTime}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <StyledTextField
            multiline
            rows={4}
            label={Constants.observation}
            color="primary"
            placeholder={Constants.addObs}
            value={observation}
            onChange={(e) => setObservation(e.target.value)}
          />
          <ButtonsSpace>
            <OutlinedButton
              width="ms"
              model="main"
              onClick={blockageId ? handleDeleteBlockage : handleClose}
            >
              {blockageId ? Constants.deleteBlock : Constants.cancel}
            </OutlinedButton>
            <FilledButton width="ms" model="main" onClick={handleBlockage}>
              {blockageId ? Constants.editBlock : Constants.createBlock}
            </FilledButton>
          </ButtonsSpace>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
