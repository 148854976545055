import { CepResponse } from './types';

const searchCep = (cep: string): Promise<CepResponse> => {
  return fetch(`https://viacep.com.br/ws/${cep}/json`)
    .then((response) => response.text())
    .then((result) => {
      return JSON.parse(result);
    })
    .catch((error) => new Error(error));
};

export default {
  searchCep,
};
