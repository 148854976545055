import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)(() => ({
  backgroundColor: '#F2F2F2',
}));

export const OutletContainer = styled(Box)(() => ({
  maxWidth: '1920px',
  margin: '0 auto',
  minHeight: '680px',
  position: 'relative',
}));
