import { TextField } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { PatternFormat } from 'react-number-format';

import { userAPI } from '../../../api';
import { FirstAccessStages, StatusCode } from '../../../api/enumerations';
import { Constants } from '../../../constants/firstAccess';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage } from '../../../helpers';
import {
  InputBox,
  StyledButtonBase,
  StyledPatternFormat,
  StyledTypography,
  SubmitButton,
  TimerTypography,
} from './styles';

interface AuthenticationProps {
  handleNextStage: (value: number) => void;
  email: string;
}

export default function FirstAccessAuthentication({
  handleNextStage,
  email,
}: AuthenticationProps): JSX.Element {
  const [emailToken, setEmailToken] = useState('');
  const [disableNewToken, setDisableNewToken] = useState(true);
  const [seconds, setSeconds] = useState(60);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const handleSendToken = async (): Promise<void> => {
    if (emailToken === '') {
      setSnackbarMessage('Insira o código de 6 dígitos.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const data = {
      email,
      code: emailToken.replace(/\s/g, ''),
    };

    try {
      const response = await userAPI.sendToken(data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Conta ativada com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      handleNextStage(FirstAccessStages.REGISTER);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  };

  const handleSendNewToken = async (): Promise<void> => {
    try {
      const response = await userAPI.getNewToken(email);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Um código foi enviado para seu email!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      setDisableNewToken(true);
      setSeconds(60);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  };

  useEffect(() => {
    if (seconds === 0) {
      setDisableNewToken(false);
    }
    if (seconds < 61 && seconds > 0) {
      setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
  }, [seconds]);

  return (
    <InputBox>
      <StyledTypography>{Constants.addEmail}</StyledTypography>
      <PatternFormat
        format="### ###"
        mask="&#8212;"
        customInput={TextField}
        variant="standard"
        id="token-input"
        helperText="insira o código de 6 dígitos"
        color="secondary"
        value={emailToken}
        onChange={(e) => setEmailToken(e.target.value)}
        sx={StyledPatternFormat}
      />
      <TimerTypography>
        {seconds}
        {Constants.left}
      </TimerTypography>
      <StyledButtonBase
        disableTouchRipple
        disabled={disableNewToken}
        onClick={handleSendNewToken}
      >
        {Constants.newToken}
      </StyledButtonBase>
      <SubmitButton onClick={handleSendToken}>{Constants.check}</SubmitButton>
    </InputBox>
  );
}
