import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SampleText = styled(Typography)(({ theme }) => ({
  width: '100%',
  margin: '20px 0 -10px',
  textAlign: 'center',
  fontSize: '21px',
  fontWeight: '600',
  color: theme.palette.primary.dark,
}));
