import { FormControlLabel, Radio } from '@mui/material';
import { useContext, useState } from 'react';

import { CompanyData } from '../../../api/login/types';
import { GlobalContext } from '../../../context/global';
import {
  removeLocalStorageToken,
  setLocalStorageCompany,
} from '../../../helpers/localStorage';
import { FilledButton } from '../../UI/Button';
import { StyledDialog } from '../../UI/Dialog';
import {
  ButtonBox,
  DialogContainer,
  OptionsBox,
  TextTypography,
  TitleTypography,
} from './styles';

interface LoginUserData {
  companies: CompanyData[];
  email: string;
  id: number;
  name: string | null;
  session_id: number;
}

interface CompaniesModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  setLoading: (value: boolean) => void;
  userData: LoginUserData;
  modalCallback: (comp: CompanyData) => void;
}

export default function CompaniesDialog({
  open,
  setOpen,
  setLoading,
  userData,
  modalCallback,
}: CompaniesModalProps): JSX.Element {
  const [companyData, setCompanyData] = useState<CompanyData>();
  const [navigateRealPrice, setNavigateRealPrice] = useState<boolean>(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage, setCompany } =
    useContext(GlobalContext);

  const chooseCompany = (): void => {
    if (navigateRealPrice) {
      setSnackbarMessage('Continue seu login na Real Price.');
      setErrorMessage(false);
      setOpenSnackbar(true);
      const link = document.createElement('a');
      link.href = 'http://realprice.protoai.com.br';
      setTimeout(() => {
        link.click();
      }, 2000);
      removeLocalStorageToken();
      return;
    }

    if (!companyData) {
      setSnackbarMessage('Selecione uma empresa');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    setLocalStorageCompany(companyData);
    setCompany(companyData);
    setLoading(true);
    modalCallback(companyData);
    setOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (
      event.target.value === 'realprice-st' ||
      event.target.value === 'realprice'
    ) {
      setNavigateRealPrice(true);
    } else {
      setNavigateRealPrice(false);
      const compOptions = userData.companies.filter(
        (element) => element.subdomain === event.target.value
      );
      setCompanyData(compOptions[0]);
    }
  };

  const getFirstName = (): string => {
    const firstName = userData.name?.split(' ')[0];
    if (firstName) return firstName;
    return '';
  };

  return (
    <StyledDialog open={open} aria-labelledby="company selection dialog">
      <DialogContainer>
        <TitleTypography>Olá, {getFirstName()}!</TitleTypography>
        <TextTypography>
          Você gostaria de acessar as informações de qual empresa?
        </TextTypography>
        <OptionsBox>
          {userData.companies.map((company) => (
            <FormControlLabel
              key={company.name}
              value={company.subdomain}
              control={<Radio onChange={(e) => handleChange(e)} />}
              label={company.name}
            />
          ))}
        </OptionsBox>
        <ButtonBox>
          <FilledButton onClick={chooseCompany}>Entrar</FilledButton>
        </ButtonBox>
      </DialogContainer>
    </StyledDialog>
  );
}
