/* eslint-disable max-len */
export const Constants = {
  actions: 'ações',
  addElement: 'adicionar elemento',
  cancel: 'cancelar',
  city: 'cidade',
  createdAt: 'data de criação',
  downloadModel: 'download do modelo para preenchimento',
  item: 'item',
  newSample: 'nova amostra',
  noElements: 'Não há elementos salvos no momento',
  noSamples: 'Não há amostras salvas no momento',
  noOptions: 'opção não encontrada',
  realState: 'tipo do imóvel',
  samples: 'Amostras',
  save: 'salvar',
  search: 'buscar',
  searchField: 'pesquisar',
  selectFile: 'escolher arquivo',
  sendFile: 'enviar arquivo',
  uf: 'estado',
  uploadExcel: 'upload em excel',
  uploadElements: 'upload planilha de elementos',
  uploadSpreadsheet: 'upload de planilha',
  sampleName: 'nome',
  warning:
    ' Há elemento(s) não processado(s) devido a localização em cidade/estado divergente do avaliando e/ou sem coordenadas',
};
