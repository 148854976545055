/* eslint-disable max-len */
import { InitialModalTour } from '../../Modal/InitialModalTour';

export function CalculationTour(): JSX.Element {
  return (
    <InitialModalTour
      title="Vamos dar início à"
      pageName="Processando cálculo"
      subtext1="Nessa tela você conseguira visualizar as informações da OS enquanto o cálculo estiver sendo processado ou não encontrou algum modelo de cálculo válido."
      stepTourName="calculation"
    />
  );
}
