import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TitleBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '60px',
  alignItems: 'center',
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
}));

export const OSNumber = styled(Typography)(({ theme }) => ({
  minWidth: '210px',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  backgroundColor: theme.palette.primary.dark,
  borderRadius: '10px',
  padding: '4px 16px',
  color: '#FAFCFF',
}));

export const CreatedStyle = styled(Typography)(({ theme }) => ({
  minWidth: '210px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '10px',
  border: `2px solid ${theme.palette.primary.dark}`,
  borderRadius: '10px',
  padding: '4px 16px',
  color: theme.palette.primary.dark,
  fontWeight: '600',
}));

export const FlexGrid = styled(Grid)(() => ({
  display: 'flex',
  gap: '8px',
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
}));

export const DescriptionTypography = styled(Typography)(() => ({
  color: '#CE273B',
  fontWeight: '600',
}));

export const SubmitBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '60px 0',
}));

export const SectionBox = styled(Box)(() => ({
  margin: '40px 0',
}));

export const SectionTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '21px',
  fontWeight: '600',
  cursor: 'pointer',
  textTransform: 'capitalize',
  '& span': { color: theme.palette.primary.main },
  [theme.breakpoints.down('xl')]: {
    fontSize: '18px',
  },
}));

export const BoxContainer = styled(Box)(() => ({
  margin: '40px',
}));
