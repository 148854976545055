/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { companyAPI } from '../../api';
import logo from '../../assets/images/proto-logo.png';
import { CustomPatternFormat } from '../../components/CustomInput';
import Snackbar from '../../components/Snackbar';
import { Constants } from '../../constants/createAccount';
import { GlobalContext } from '../../context/global';
import { RegistrationIdentificationContext } from '../../context/registrationIdentification';
import { cleanValue } from '../../helpers';
import { removeVerifiedCep, setVerifiedCep } from '../../helpers/cepVerified';
import {
  removeLocalStorageCompany,
  removeLocalStorageToken,
} from '../../helpers/localStorage';
import useErrorMessage from '../../hooks/useErrorMessage';
import useSnackbar from '../../hooks/useSnackbar';
import {
  Container,
  InputBox,
  Logo,
  StyledText,
  SubmitButton,
  Title,
} from './styles';

export default function CreateAccount(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [cep, setCep] = useState('');

  const { openSnackbar } = useContext(GlobalContext);
  const { reset, setToastMessage } = useContext(
    RegistrationIdentificationContext
  );
  const { getErrorMessage } = useErrorMessage();
  const { handleSnackbar } = useSnackbar();

  const navigate = useNavigate();

  useEffect(() => {
    removeLocalStorageToken();
    removeLocalStorageCompany();
    reset();
    removeVerifiedCep();
  }, []);

  const navigateToLogin = (): void => navigate('/login');

  const verifyCep = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    setLoading(true);
    const cepValue = cleanValue(cep);
    if (cepValue.length < 8) {
      handleSnackbar('CEP inválido', true);
      setLoading(false);
      return;
    }

    try {
      const { detail, data } = await companyAPI.checkCoverage(cepValue);

      if (detail.description) {
        throw new Error(detail.description);
      }

      if (data) {
        setVerifiedCep(cep);
        setLoading(false);

        if (data.message && data.message.length > 0) {
          setToastMessage(data.message);
        }

        navigate('/create-account/register');
      }
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
      setLoading(false);
    }
  };
  return (
    <Container>
      <Logo onClick={navigateToLogin}>
        <img src={logo} alt="Logo do Proto" />
      </Logo>
      <Title>{Constants.welcome}</Title>
      <InputBox
        component="form"
        id="verify-cep"
        onSubmit={verifyCep}
        padding="90px 92px"
      >
        <StyledText>{Constants.verifyCEP}</StyledText>
        <Box>
          <CustomPatternFormat
            required
            minLength={8}
            id="cep"
            label={Constants.cep}
            value={cep}
            setValue={setCep}
            format="#####-###"
          />
        </Box>
        <SubmitButton form="verify-cep" type="submit" disabled={loading}>
          {loading ? (
            <CircularProgress color="inherit" size={22} />
          ) : (
            Constants.confirm
          )}
        </SubmitButton>
      </InputBox>
      {openSnackbar && <Snackbar />}
    </Container>
  );
}
