import {
  Box,
  ButtonBase,
  CardMedia,
  IconButton,
  Typography,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const RoomsContainer = styled(Box)(() => ({
  margin: '20px 4px',
  display: 'flex',
  gap: '36px',
  flexDirection: 'column',
}));

export const InputContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '36px',
}));

export const ResponsiblePicture = styled(Box)(() => ({
  minWidth: '160px',
  width: '250px',
  display: 'flex',
  flexDirection: 'column',
}));

export const ResponsibleCardMedia = styled(CardMedia)(({ theme }) => ({
  width: '100%',
  height: '115px',
  borderRadius: '12px',
  backgroundSize: 'contain',
  border: `2px solid ${theme.palette.primary.dark}`,
  backgroundRepeat: 'no-repeat',
  backgroundColor: '#E6E6E6',
}));

export const RelativeBox = styled(Box)(() => ({
  position: 'relative',
  minWidth: '160px',
  width: '250px',
  cursor: 'pointer',
}));

export const FlexReverseBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row-reverse',
}));

export const StyledIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  margin: '-8px -8px 0',
  width: ' 32px',
  height: '32px',
  color: '#914FB2',
  zIndex: '20',
  backgroundColor: '#F2F2F2',
  border: '2px solid #914FB2',
  fontWeight: 'bold',
  '&:hover': {
    border: '3px solid #914FB2',
    backgroundColor: '#F2F2F2',
  },
}));

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    textAlign: 'justify',
    fontSize: '12px',
    padding: '8px',
    fontWeight: 'normal',
    border: '1px solid #dadde9',
    boxShadow: '0px 3px 6px #00000029',
    marginTop: '8px !important',
    maxWidth: '180px',
  },
}));

export const StyledCardMedia = styled(CardMedia)(() => ({
  width: '100%',
  height: '115px',
  borderRadius: '12px',
  backgroundSize: 'cover',
  filter: 'brightness(70%)',
}));

export const StyledCardText = styled(Typography)(({ theme }) => ({
  width: '100%',
  height: '100%',
  top: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  fontSize: '24px',
  color: '#FAFCFF',
  fontWeight: '600',
  padding: '0 4px',
  textAlign: 'center',
  [theme.breakpoints.down('xl')]: {
    fontSize: '21px',
  },
}));

export const ViewPhotosButton = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  width: '425px',
  minWidth: '120px',
  height: '115px',
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '12px',
  fontSize: '18px',
  fontWeight: '600',
  color: theme.palette.primary.main,
  '& span': { color: theme.palette.primary.main, fontSize: '32px' },
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '& span': { color: theme.palette.common.white },
  },
}));

export const DownloadButton = styled(ButtonBase)(({ theme }) => ({
  alignSelf: 'start',
  marginTop: 6,
  gap: '6px',
  fontSize: '14px',
  color: theme.palette.primary.main,
  fontWeight: '600',
  cursor: 'pointer',
  '&:disabled': {
    filter: 'opacity(50%)',
  },
}));
