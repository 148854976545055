/* eslint-disable react/require-default-props */
import { ChoicesData, QuestionData } from '../../../../../api/forms/types';
import { IconCloseMS } from '../../../../../constants/icons';
import {
  OptionIcon,
  OptionCloseIcon,
  StyledMenuItem,
  JumpTextField,
  TitleTextField,
} from './styles';

interface ChoiceInputProps {
  choiceData: ChoicesData;
  choices: ChoicesData[];
  setChoices: (choices: ChoicesData[]) => void;
  jump: boolean;
  icon: JSX.Element;
  questions: QuestionData[];
  editQuestion?: QuestionData;
}

export default function ChoiceInput({
  choiceData,
  choices,
  setChoices,
  jump,
  icon,
  questions,
  editQuestion,
}: ChoiceInputProps): JSX.Element {
  let questionsList = questions;
  if (editQuestion) {
    questionsList = questions.filter((element) => {
      return element.id !== editQuestion.id;
    });
  }

  return (
    <>
      <OptionIcon>{icon}</OptionIcon>
      <TitleTextField
        id="opcao"
        label="opção"
        color="secondary"
        value={choiceData.title}
        onChange={(e) => {
          const editChoice = choices.find((element) => {
            return element.id === choiceData.id;
          });

          if (editChoice) {
            editChoice.title = e.target.value;
            const newData = [...choices];
            setChoices(newData);
          }
        }}
      />
      {jump && (
        <JumpTextField
          id="jump"
          select
          label="pular para"
          color="secondary"
          value={choiceData.jump_to}
          onChange={(e) => {
            const editChoice = choices.find((element) => {
              return element.id === choiceData.id;
            });

            if (editChoice) {
              editChoice.jump_to = Number(e.target.value);
              const newData = [...choices];
              setChoices(newData);
            }
          }}
        >
          {questionsList.map((question) => (
            <StyledMenuItem key={question.id} value={question.id}>
              {question.title}
            </StyledMenuItem>
          ))}
        </JumpTextField>
      )}
      <OptionCloseIcon
        onClick={() => {
          const deleteChoice = choices.filter((element) => {
            return element.id !== choiceData.id;
          });
          setChoices(deleteChoice);
        }}
      >
        {IconCloseMS}
      </OptionCloseIcon>
    </>
  );
}
