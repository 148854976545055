import { useContext } from 'react';

import { workOrderAPI } from '../api';
import { StatusCode } from '../api/enumerations';
import { GlobalContext } from '../context/global';
import useErrorMessage from './useErrorMessage';
import useGeneral from './useGeneral';

interface UseCancelWorkOrderHook {
  handleCancelWorkOrder: (cardId?: number) => Promise<void>;
}

export function useCancelWorkOrder(): UseCancelWorkOrderHook {
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();
  const { osId, navigateHome } = useGeneral();

  const handleCancelWorkOrder = async (cardId?: number): Promise<void> => {
    try {
      const response = await workOrderAPI.cancelWorkOrder(cardId || osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('A OS foi cancelada!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      navigateHome();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };
  return {
    handleCancelWorkOrder,
  };
}
