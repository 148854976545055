/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import {
  TableBody,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import {
  BoldCell,
  CellCaption,
  CellContent,
  CellHeader,
  RowCaption,
  RowHeader,
  StyledTable,
} from '../styles';

interface TableValues {
  value: string | number;
  label?: string;
  bold?: boolean;
  width?: string;
  align?: TableCellProps['align'];
}

interface ReportsTableProps {
  ariaLabel: string;
  totalCol: number;
  title: string;
  headerData?: TableValues[];
  bodyData?: TableValues[][];
  labelValueData?: TableValues[];
}

export default function ReportsTable({
  ariaLabel,
  totalCol,
  title,
  headerData,
  bodyData,
  labelValueData,
}: ReportsTableProps): JSX.Element {
  return (
    <TableContainer>
      <StyledTable aria-label={ariaLabel}>
        <TableHead>
          <RowCaption>
            <CellCaption colSpan={totalCol}>{title}</CellCaption>
          </RowCaption>
          {headerData && (
            <RowHeader>
              {headerData.map((header) => (
                <CellHeader
                  align="center"
                  minwidth={header.width}
                  key={header.value}
                >
                  {header.value}
                </CellHeader>
              ))}
            </RowHeader>
          )}
        </TableHead>
        {bodyData && (
          <TableBody>
            {bodyData.map((row, i) => (
              <TableRow key={i}>
                {row.map((data) =>
                  data.bold ? (
                    <BoldCell
                      align="center"
                      key={data.label ? data.label : data.value}
                    >
                      {data.value}
                    </BoldCell>
                  ) : (
                    <CellContent
                      align="center"
                      key={data.label ? data.label : data.value}
                    >
                      {data.value}
                    </CellContent>
                  )
                )}
              </TableRow>
            ))}
          </TableBody>
        )}
        {labelValueData && (
          <TableBody>
            {labelValueData.map((row) => (
              <TableRow key={row.label}>
                <BoldCell align="left">{row.label}</BoldCell>
                <CellContent align={row.align ? row.align : 'right'}>
                  {row.value}
                </CellContent>
              </TableRow>
            ))}
          </TableBody>
        )}
      </StyledTable>
    </TableContainer>
  );
}
