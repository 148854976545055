import { Box, Button, CardMedia, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { UploadBox } from '../../../components/UI/Box';

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '21px',
  color: theme.palette.common.black,
  margin: '0',
}));

export const StyledCardMedia = styled(CardMedia)(() => ({
  borderRadius: '30px',
  height: '140px',
}));

export const SelectPhotoBox = styled(UploadBox)(() => ({
  height: '140px',
  padding: '0 12px',
  cursor: 'pointer',
}));

export const UploadIcon = styled(Typography)(({ theme }) => ({
  '& span': { fontSize: '60px', color: theme.palette.primary.main },
  [theme.breakpoints.down('lg')]: {
    '& span': { fontSize: '50px' },
  },
}));

export const UploadSubtitle = styled(Typography)(() => ({
  fontSize: 'clamp(10px, 1vw, 14px)',
  textAlign: 'center',
}));

export const FlexReverseBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row-reverse',
}));

export const OutlineButton = styled(Button)(() => ({
  width: 282,
  height: 40,
  border: '2px solid #B038FA',
  borderRadius: 10,
  color: '#B038FA',
  fontWeight: 600,
  fontSize: 15,
  backgroundColor: '#FAFCFF',
  display: 'flex',
  gap: 8,
  '&:hover': {
    backgroundColor: '#B038FA',
    '& span': {
      color: '#FAFCFF',
    },
  },
  '&:disabled': {
    color: '#B038FA',
  },
}));
