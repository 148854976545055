import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { samplesListAPI } from '../../../api';
import { StatusCode } from '../../../api/enumerations';
import {
  CustomTextField,
  SelectTextField,
} from '../../../components/CustomInput';
import { CloseIcon, FilledButton } from '../../../components/UI/Button';
import { StyledDialog } from '../../../components/UI/Dialog';
import { DialogTitle } from '../../../components/UI/Typography';
import { IconCloseMS } from '../../../constants/icons';
import { Constants } from '../../../constants/samples';
import {
  selectPropertyType,
  selectRegistrationUf,
} from '../../../constants/selectOptions';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useGeneral from '../../../hooks/useGeneral';
import useSnackbar from '../../../hooks/useSnackbar';
import { ButtonBox, DialogContainer, FormStyled } from './styles';

export function AddSample(): JSX.Element {
  const [regionName, setRegionName] = useState('');
  const [city, setCity] = useState('');
  const [uf, setUf] = useState(0);
  const [realEstateKind, setRealEstateKind] = useState(0);

  const navigate = useNavigate();
  const dialog = useGeneral();
  const { handleSnackbar } = useSnackbar();
  const { getErrorMessage } = useErrorMessage();

  const reset = (): void => {
    setRegionName('');
    setCity('');
    setUf(0);
    setRealEstateKind(0);
    dialog.handleClose();
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    if (uf === 0 || realEstateKind === 0) {
      handleSnackbar('Os dados de seleção são obrigatórios.', true);
      return;
    }

    const data = {
      region_name: regionName,
      real_estate_kind: realEstateKind,
      city,
      uf,
    };

    try {
      const response = await samplesListAPI.addSampleList(data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente');
      }

      handleSnackbar('Amostra criada com sucesso', false);
      navigate(`/samples/${response.data.id}`);
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    }
  };

  return (
    <>
      <FilledButton width="md" onClick={dialog.handleOpen}>
        {Constants.newSample}
      </FilledButton>
      <StyledDialog
        open={dialog.open}
        onClose={reset}
        aria-labelledby="Add new sample"
      >
        <DialogContainer>
          <CloseIcon onClick={reset}>{IconCloseMS}</CloseIcon>
          <DialogTitle>{Constants.newSample}</DialogTitle>
          <FormStyled component="form" id="add-sample" onSubmit={handleSubmit}>
            <CustomTextField
              required
              id="region-name"
              label={Constants.sampleName}
              value={regionName}
              setValue={setRegionName}
            />
            <CustomTextField
              required
              id="city"
              label={Constants.city}
              value={city}
              setValue={setCity}
            />
            <SelectTextField
              required
              id="registration-uf"
              label={Constants.uf}
              value={uf}
              setValue={setUf}
              selectOptions={selectRegistrationUf()}
            />
            <SelectTextField
              required
              id="property-type"
              label="tipo do imóvel"
              value={realEstateKind}
              setValue={setRealEstateKind}
              selectOptions={selectPropertyType()}
            />
            <ButtonBox>
              <FilledButton form="add-sample" type="submit">
                {Constants.save}
              </FilledButton>
            </ButtonBox>
          </FormStyled>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
