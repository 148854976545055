import jwtDecode, { JwtPayload } from 'jwt-decode';

import { CompanyData } from '../api/login/types';
import { getLocalStorageToken } from '../helpers/localStorage';

interface UserData {
  companies: CompanyData[];
  accepted_terms_of_use: false;
  email: string;
  id: number;
  name: string;
  session_id: number;
}

interface TokenProps extends JwtPayload {
  exp: number;
  user: UserData;
}

interface StoragedJwtHook {
  expired: boolean;
  user: UserData;
}

export function useStoragedJwt(): StoragedJwtHook | null {
  const jwtToken = getLocalStorageToken();

  if (!jwtToken) {
    return null;
  }

  const decoded = jwtDecode<TokenProps>(jwtToken);
  const expirationTime = decoded.exp;
  const now = new Date().getTime() / 1000;
  const expired = now > expirationTime;

  const storagedData = {
    expired,
    user: decoded.user,
  };

  return storagedData;
}
