import { IconKeyboardDoubleArrowUpMS } from '../../../../constants/icons';
import { StyledButton } from './styles';

export function UpButton(): JSX.Element {
  return (
    <StyledButton
      onClick={() => {
        document.getElementById('top')?.scrollIntoView();
      }}
    >
      {IconKeyboardDoubleArrowUpMS}
    </StyledButton>
  );
}
