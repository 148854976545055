import { Box, Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const InputBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#F5F5F4',
  boxShadow: '0px 1px 2px #61335429',
  padding: '50px clamp(40px, 5.2vw, 100px) 0',
  width: '50%',
  maxWidth: 930,
}));

export const ButtonBox = styled(Box)(() => ({
  margin: '32px 0 62px',
}));

export const StyledText = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: 20,
  marginBottom: '1rem',
}));

export const Label = styled(Typography)(() => ({
  fontSize: 20,
  '& span': {
    fontStyle: 'italic',
    marginLeft: '0.5rem',
  },
}));

export const StyledGrid = styled(Grid)(() => ({
  margin: '21px 0',
  display: 'flex',
  alignItems: 'center',
}));

export const Resend = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  fontWeight: 600,
  width: '100%',
}));
