/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useContext, useState } from 'react';

import Attachments from '../../../../api/attachments';
import { StatusCode } from '../../../../api/enumerations';
import { IconAttachFileMS } from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import { validateFiles } from '../../../../helpers';
import { useAttachment } from '../../../../hooks/useAttachmentFiles';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import { CustomTextField } from '../../../CustomInput';
import { Constants } from '../constants';
import {
  AttachFileButon,
  CancelButton,
  DialogTitle,
  InputBox,
  SubmitBox,
  SubmitButton,
  UploadBox,
} from '../styles';

interface EditAttachmentsProps {
  setEdit: (value: boolean) => void;
  file: string;
  osId: number | undefined;
}

export default function EditAttachments({
  setEdit,
  file,
  osId,
}: EditAttachmentsProps): JSX.Element {
  const [description, setDescription] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileAttached, setFileAttached] = useState<File>();

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { setIptuLink, setRegisterLink } = useAttachment();
  const { getErrorMessage } = useErrorMessage();

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files?.item(0) || !validateFiles(e.target.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const uploadedFile = e.target.files[0];
    setFileName(uploadedFile.name);
    setFileAttached(uploadedFile);
  };

  const handleSubmit = async (): Promise<void> => {
    if (!fileAttached) {
      setSnackbarMessage('O upload do arquivo é obrigatório');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const formData = new FormData();
    if (file === 'register') {
      formData.append('real_estate_registry', fileAttached);
    }
    if (file === 'IPTU') {
      formData.append('iptu_document', fileAttached);
    }

    if (osId) {
      try {
        const response = await Attachments.editWorkOrder(osId, formData);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setSnackbarMessage('Dados alterados com sucesso!');
        setErrorMessage(false);
        setOpenSnackbar(true);
        if (file === 'register') {
          setRegisterLink(fileAttached.lastModified.toString());
        }
        if (file === 'IPTU') {
          setIptuLink(fileAttached.lastModified.toString());
        }
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }
    }
    setEdit(false);
  };

  return (
    <>
      <DialogTitle>
        {IconAttachFileMS}
        {Constants.edit} {description}
      </DialogTitle>
      <UploadBox>
        <CustomTextField
          id="file-description"
          label="descrição"
          value={description}
          setValue={setDescription}
          maxLength={60}
        />
        <InputBox>
          <CustomTextField
            disabled
            id="file-name"
            label="arquivo"
            value={fileName}
            setValue={setFileName}
          />
          <label htmlFor="uploadEditAttachment">
            <input
              accept={'image/*,application/pdf'}
              id="uploadEditAttachment"
              type="file"
              style={{ display: 'none' }}
              onChange={async (e: ChangeEvent<HTMLInputElement>) =>
                handleFileUpload(e)
              }
            />
            <AttachFileButon component="span">
              {Constants.selectFile}
            </AttachFileButon>
          </label>
        </InputBox>
        <SubmitBox>
          <CancelButton onClick={() => setEdit(false)}>
            {Constants.cancel}
          </CancelButton>
          <SubmitButton onClick={() => handleSubmit()}>
            {Constants.edit}
          </SubmitButton>
        </SubmitBox>
      </UploadBox>
    </>
  );
}
