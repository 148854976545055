import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import loginBg from '../../assets/images/login/login.webp';

export const ContainerGrid = styled(Grid)(() => ({
  display: 'flex',
  width: '100%',
  minHeight: '100vh',
  height: '100%',
  justifyContent: 'center',
  backgroundBlendMode: 'screen',
  backgroundAttachment: 'fixed',
  background:
    // eslint-disable-next-line max-len
    ' linear-gradient(315deg, #520679 2%, #841EBE66 20%, #EB0AAF66 34%, #EB0AAF66 49%, #520679 72%)',
}));

export const LoginGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.light,
  minHeight: '720px',
  maxWidth: 700,
  width: '40vw',
  minWidth: 516,
  borderRadius: '20px',
  margin: '20px 0 20px 30px',
}));

export const LoginImage = styled(Grid)(() => ({
  backgroundImage: `url(${loginBg})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  flex: 1,
}));

export const Logo = styled(Box)(() => ({
  marginBottom: '4vh',
  backgroundColor: '#CC35FF',
  padding: '0.7rem 0.5rem',
  position: 'relative',
  top: '-20px',
  img: {
    width: 140,
  },
}));
