import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    margin: '0 20px 20px 0',
    height: '45px',
    border: 'none',
    textTransform: 'lowercase',
    '& :hover': {
      color: theme.palette.primary.main,
    },
    '& .MuiButtonBase-root.MuiToggleButton-root': {
      '&.Mui-selected': {
        color: '#FAFCFF',
        backgroundColor: theme.palette.primary.dark,
        border: 'none',
        borderRadius: '4px',
        '& :hover': {
          color: '#FAFCFF',
          backgroundColor: theme.palette.primary.dark,
          opacity: '80%',
        },
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
          opacity: '80%',
        },
      },
    },
  })
);

export const StyledToggleButton = styled(ToggleButton)(() => ({
  textTransform: 'lowercase',
}));
