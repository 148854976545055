/* eslint-disable react/require-default-props */
import { CircularProgress } from '@mui/material';
import { useContext, useState } from 'react';

import { errorReportAPI } from '../../../api';
import { StatusCode } from '../../../api/enumerations';
import { NewReport } from '../../../api/errorReport/types';
import { IconEditNoteMS } from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useGeneral from '../../../hooks/useGeneral';
import { MultilineTextField } from '../../CustomInput';
import { FilledButton, OutlinedButton } from '../../UI/Button';
import { DialogTitle } from '../../UI/Typography';
import { ButtonBox, DialogContainer, StyledDialog } from './styles';

interface ReportErrorDialogProps {
  element?: boolean;
  sampleId?: number;
  open: boolean;
  setOpen: (value: boolean) => void;
  updateSample?: () => void;
}

export function ReportErrorDialog({
  element,
  sampleId,
  open,
  setOpen,
  updateSample,
}: ReportErrorDialogProps): JSX.Element {
  const [report, setReport] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const { setErrorMessage, setOpenSnackbar, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();
  const { osId, navigateHome } = useGeneral();

  const handleClose = (): void => setOpen(false);

  const handleSubmit = async (): Promise<void> => {
    setLoadingSubmit(true);
    if (!report) {
      setSnackbarMessage('Preencha o motivo do erro!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoadingSubmit(false);
      return;
    }

    const reportData: NewReport = {
      error_description: report,
      work_order_id: osId,
      report_source: element ? 1 : 2,
    };

    if (element) {
      reportData.sample_id = sampleId;
    }

    try {
      const response = await errorReportAPI.addReport(reportData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Erro reportado!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      setLoadingSubmit(false);
      handleClose();
      if (element && updateSample) {
        updateSample();
      } else {
        navigateHome();
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoadingSubmit(false);
    }
  };

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="error report dialog"
    >
      <DialogContainer>
        <DialogTitle>
          {IconEditNoteMS}
          Reportar erro
        </DialogTitle>
        <MultilineTextField
          id="report"
          placeholder="Escreva aqui o motivo do erro"
          value={report}
          setValue={setReport}
        />
        <ButtonBox>
          <OutlinedButton width="sm" onClick={handleClose}>
            cancelar
          </OutlinedButton>
          <FilledButton
            width="sm"
            onClick={handleSubmit}
            disabled={loadingSubmit}
          >
            {loadingSubmit ? <CircularProgress size="24px" /> : 'enviar'}
          </FilledButton>
        </ButtonBox>
      </DialogContainer>
    </StyledDialog>
  );
}
