import {
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Typography,
  styled,
} from '@mui/material';

export const StyledModalContainer = styled(Modal)(() => ({
  '& .MuiModal-backdrop': {
    backgroundColor: 'transparent',
  },

  '& .MuiGrid-root': {
    border: 'none',
    outline: 'none',
  },
}));

export const StyledGridContainer = styled(Grid)(({ theme }) => ({
  position: 'fixed',
  top: '19rem',
  left: 150,
  zIndex: 201,
  padding: '12px 24px',
  borderRadius: 8,
  boxShadow: '0 3px 6px gray',
  backgroundColor: theme.palette.common.white,
  width: 430,
  height: '25em',
  overflowY: 'scroll',

  '&.drawerOpen': {
    left: 300,
  },

  '&.disabled': {
    cursor: 'not-allowed',
    color: `!important #ccc`,
  },
  '& button > svg': {
    color: theme.palette.primary.dark,
  },
}));

export const DrawerTitle = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  color: theme.palette.primary.dark,
  marginBottom: 16,
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  color: theme.palette.primary.dark,
  '& span': {
    color: theme.palette.primary.dark,
    borderRadius: '50%',
    '&:disabled': {
      color: theme.palette.secondary.light,
    },
  },
  'p.disabled': {
    cursor: 'not-allowed',
    color: theme.palette.secondary.light,
  },
  '&.PrivateSwitchBase-input': {},
}));

export const ExpandButton = styled(IconButton)(({ theme }) => ({
  // color: theme.palette.primary.dark,
  // background: 'red',
  '& svg': {
    color: theme.palette.primary.dark,
  },
}));

export const TextButton = styled('button')(({ theme }) => ({
  background: 'transparent',
  border: 'none',
  outline: 'none',
  padding: '12px',
  cursor: 'pointer',

  font: 'bold 16px Roboto',
  color: theme.palette.primary.dark,

  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

export const Separator = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.primary.dark}`,
  width: '100%',
  margin: '8px 8px 16px 8px',
}));

export const PercentageLineAndValue = styled('span')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  gap: '8px',
  color: theme.palette.primary.dark,
  marginBottom: '1rem',
}));
