import { Status } from '../../../api/enumerations';
import { WorkOrderData } from '../../../api/workOrders/types';
import { usePropertyData } from '../../../hooks/usePropertyData';

interface PeptHook {
  propertyData: WorkOrderData | undefined;
  loadingPage: boolean;
}

const usePept = (): PeptHook => {
  const { propertyData, loadingPage } = usePropertyData({
    status: Status.PEPT,
  });

  return {
    propertyData,
    loadingPage,
  };
};

export default usePept;
