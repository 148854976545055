import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { StyledButton } from '../styles';

export const CardContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#FAFCFF',
  borderRadius: '12px',
  boxShadow: '3px 3px 6px #8B8C8E',
  textAlign: 'center',
  height: '780px',
  '&:hover': {
    border: `1px solid ${theme.palette.primary.dark}`,
    '.plan-title': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  width: '100%',
  backgroundColor: '#914FB2',
  borderRadius: '12px 12px 0 0',
  height: '70px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '24px',
  fontWeight: '600',
  color: theme.palette.common.white,
  position: 'relative',
}));

export const LineThroughTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.danger,
  position: 'relative',
  fontWeight: '600',
  textDecoration: 'line-through',
  textAlign: 'left',
  marginLeft: 32,
}));

export const DescriptionBox = styled(Box)(() => ({
  height: '320px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const BoldTypography = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: '600',
  margin: '10px 0',
}));

export const ValueTypography = styled(Typography)(({ theme }) => ({
  fontSize: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '12px',
  fontWeight: '600',
  marginBottom: 10,
  [theme.breakpoints.up('xl')]: {
    fontSize: '52px',
  },
}));

export const TotalTypography = styled(Typography)(() => ({
  fontSize: '14px',
  color: '#B038FA',
  fontWeight: '600',
}));

export const ListBox = styled(Box)(() => ({
  height: '240px',
  margin: '20px 0',
}));

export const RoundButton = styled(StyledButton)(() => ({
  borderRadius: '30px',
}));

export const LimitTypography = styled(Typography)(() => ({
  fontSize: '36px',
  padding: '32px 0',
  fontWeight: '600',
  margin: '10px 0',
}));

export const PlanList = styled('li')(() => ({
  textAlign: 'left',
  fontWeight: '600',
  color: '#2E383F',
  fontSize: '18px',
  '::marker': {
    fontSize: '24px',
    color: '#914FB2',
  },
  '& span': {
    marginLeft: '-10px',
  },
}));

export const PopularStamp = styled(Box)(({ theme }) => ({
  height: '64px',
  width: '64px',
  position: 'absolute',
  right: 10,
  top: 10,
  background: theme.palette.common.white,
  borderRadius: 50,
  display: 'flex',
  alignItems: 'center',
  fontSize: 11,
  border: '2px solid #914FB2',
  fontWeight: 600,
  color: '#914FB2',
  boxShadow: '2px 1px 9px #914FB2',
  transform: 'rotate(12deg)',
}));
