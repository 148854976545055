/* eslint-disable react/require-default-props */
import { IconButton, InputAdornment } from '@mui/material';

import {
  IconKeyMS,
  IconPersonMS,
  IconVisibilityMS,
  IconVisibilityOffMS,
} from '../../constants/icons';

export const EmailIcon = {
  startAdornment: (
    <InputAdornment position="start">{IconPersonMS}</InputAdornment>
  ),
};

interface PasswordIconProps {
  color?: string;
}

export function PasswordIcon({ color }: PasswordIconProps): JSX.Element {
  return (
    <InputAdornment position="start" sx={{ color: color || '' }}>
      {IconKeyMS}
    </InputAdornment>
  );
}

interface IconVisibilityProps {
  showPassword: boolean;
  setShowPassword: (isShowPassword: boolean) => void;
  color?: string;
}

export function IconVisibility({
  setShowPassword,
  showPassword,
  color,
}: IconVisibilityProps): JSX.Element {
  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => setShowPassword(!showPassword)}
        onMouseDown={(e) => e.preventDefault()}
        edge="end"
        sx={{
          color: color || '',
          margin: '0 6px',
          '& span': { fontSize: '22px' },
        }}
      >
        {showPassword ? IconVisibilityMS : IconVisibilityOffMS}
      </IconButton>
    </InputAdornment>
  );
}
