import {
  Box,
  Button,
  ButtonBase,
  Modal,
  Typography,
  alpha,
  styled,
} from '@mui/material';

export const StyledModal = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',

  '& .MuiBox-root': {
    border: 'none',
    outline: 'none',
  },
}));

export const CloseButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  top: '-10px',
  left: '589px',

  '& span': {
    top: '-10px',
    left: '589px',
    color: theme.palette.primary.main,
    width: '14px',
    height: '14px',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },

  '@media (width: 1024px)': {
    left: 520,
  },
}));

export const Container = styled(Box)(({ theme }) => ({
  width: 662,
  backgroundColor: theme.palette.common.white,
  borderRadius: '20px',
  borderColor: 'transparent',
  padding: 30,

  position: 'relative',

  '& .ponta': {
    position: 'absolute',
    width: 0,
    height: 0,
    borderLeft: '25px solid transparent',
    borderRight: '25px solid transparent',
    borderBottom: `50px solid ${theme.palette.common.white}`,
    left: '-50px',
    top: '44%',
    transform: 'rotate(-90deg) translateX(-50%)',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: 28,
  outlined: 'none',
}));

export const PageName = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: 48,
  fontWeight: 'bold',
}));

export const SubText = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  color: theme.palette.primary.dark,
  paddingBottom: 24,
  zIndex: 9999,
}));

export const SubTextFinish = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  color: theme.palette.primary.dark,
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: 345,
  height: 48,
  color: theme.palette.common.white,
  background: theme.palette.primary.dark,
  borderRadius: '30px',
  fontSize: 16,
  fontWeight: 'bold',
  textTransform: 'lowercase',

  position: 'absolute',
  bottom: '43px',

  '&:hover': {
    background: alpha(theme.palette.primary.dark, 0.7),
  },
}));

export const ButtonText = styled(ButtonBase)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  fontSize: 16,
  '&:hover': {
    color: alpha(theme.palette.primary.main, 0.7),
  },
}));

export const SkipTourButton = styled('button')(({ theme }) => ({
  background: theme.palette.common.white,
  color: theme.palette.primary.dark,
  fontWeight: 'bold',
  fontSize: '16px',
  padding: '16px',
  borderRadius: '8px',
  border: 'none',
  outline: 'none',
  textTransform: 'capitalize',
  position: 'absolute',
  top: '-50%',
  right: '-72.5%',

  [theme.breakpoints.down('xl')]: {
    top: '-45%',
    right: '-50%',
  },

  '&:hover': {
    opacity: '0.8',
    cursor: 'pointer',
  },
}));

export const SkipButton = styled('button')(({ theme }) => ({
  background: theme.palette.common.white,
  color: theme.palette.primary.dark,
  fontWeight: 'bold',
  fontSize: '16px',
  padding: '16px',
  borderRadius: '8px',
  border: 'none',
  outline: 'none',
  textTransform: 'capitalize',
  top: '-95%',
  right: '-175%',
  position: 'absolute',

  [theme.breakpoints.down('xl')]: {
    top: '-70%',
    right: '-50%',
  },

  '&:hover': {
    opacity: '0.8',
    cursor: 'pointer',
  },
}));
