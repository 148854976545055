import { InputAdornment } from '@mui/material';

import { IconSearchMS } from '../../constants/icons';
import { Container, SearchButton, StyledInputBase } from './styles';

interface SearchBoxProps<T> {
  searchTerm: string;
  setSearchTerm(value: string): void;
  initialDatas: T[];
  setResults(value: T[]): void;
}

export default function SearchBox<T>({
  searchTerm,
  setSearchTerm,
  initialDatas,
  setResults,
}: SearchBoxProps<T>): JSX.Element {
  const handleSearch = (): void => {
    if (searchTerm.length === 0) {
      setResults([]);
    } else {
      const filteredResults = initialDatas?.filter((data) =>
        Object.values(data as { [key: string]: string }).some((value) => {
          if (typeof value === 'string') {
            return value.toLowerCase().includes(searchTerm.toLowerCase());
          }
          return false;
        })
      );

      if (filteredResults) {
        setResults(filteredResults);
      }
      setSearchTerm('');
    }
  };
  return (
    <Container id="search-user">
      <StyledInputBase
        placeholder="buscar nesta página"
        inputProps={{ 'aria-label': 'pesquisar', maxLength: 20 }}
        startAdornment={
          <InputAdornment position="start" sx={{ color: '#520679' }}>
            {IconSearchMS}
          </InputAdornment>
        }
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <SearchButton variant="text" onClick={handleSearch}>
        Buscar
      </SearchButton>
    </Container>
  );
}
