import { Box, Button, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const InputBox = styled(Box)(() => ({
  width: '75%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '80px',
  margin: 'clamp(42px, 7.9vh, 82px) 0',
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  fontSize: '18px',
  width: '100%',
  marginBottom: '40px',
  '& .MuiInputBase-input': {
    color: theme.palette.common.black,
    fontSize: '18px',
  },
  '& .MuiInputBase-root.MuiInput-root:before': {
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
  '& .MuiInputBase-root.MuiInput-root:after': {
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
  '& .MuiInputBase-root.MuiInput-root.Mui-error:before': {
    borderBottom: '1px solid #d32f2f',
  },
  '& .MuiInputBase-root.MuiInput-root.Mui-error:after': {
    borderBottom: '1px solid #d32f2f',
  },
  '& .MuiInputBase-root.MuiInput-root:hover:before': {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  '& .MuiInputBase-root.MuiInput-root:hover:after': {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  'input:hover::-webkit-input-placeholder': { color: 'transparent' },
  'input:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0px 1000px #F2F2F2 inset',
    paddingLeft: '10px',
  },
}));

export const FilledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.secondary.main,
  textTransform: 'uppercase',
  borderRadius: '16px',
  '&:hover': {
    backgroundColor: '#9B7359',
    filter: 'brightness(85%)',
  },
}));
