/* eslint-disable react/require-default-props */
import { Box, Grid, TableBody, TableHead, TableRow } from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';

import { Status } from '../../../../api/enumerations';
import {
  AvmInferenceData,
  WorkOrderData,
} from '../../../../api/workOrders/types';
import { Constants } from '../../../../constants/report';
import { formatFloatNumberDecimal, formatReal } from '../../../../helpers';
import useGeneral from '../../../../hooks/useGeneral';
import LineBar from '../../../Charts/LineBar';
import LineInterval from '../../../Charts/LineInterval';
import LineScatter from '../../../Charts/LineScatter';
import ScatterTrendline from '../../../Charts/ScatterTrendline';
import { ExportSample } from '../../../ExportSample';
import { ArbitraryValue } from '../ArbitraryValue';
import ReportsTable from '../ReportsTable';
import {
  BoldCell,
  CellCaption,
  CellContent,
  GridContainer,
  GridTable,
  ReportTitle,
  RowCaption,
  RowHeader,
  StyledTable,
} from '../styles';
import {
  presentComplementaryHeader,
  presentComplementaryInfo,
  presentCorrHeader,
  presentFundamentationHeader,
  presentHypothesisData,
  presentHypothesisHeader,
  presentLinearRegression,
  presentNormalityHeader,
  presentOutliers,
  presentPrecisionData,
  presentReason,
  presentResultData,
  presentResultHeader,
  presentScoresData,
  presentStatistic,
  presentStatisticHeader,
  presentVarianceData,
  presentVarianceHeader,
  presentWasteNormality,
} from './presenter';
import { Recalculate } from './Recalculate';
import { CellCaptionBorder, CellHeader, CellMinWidth } from './styles';

export interface InferenceTableProps {
  tableData: AvmInferenceData;
  chartCallback?: (img: HTMLCanvasElement, title: string) => Promise<void>;
  refNumber?: number | undefined;
  transformations?: string[] | null;
  propertyData: WorkOrderData;
}

export function InferenceTable({
  tableData,
  chartCallback,
  refNumber,
  transformations,
  propertyData,
}: InferenceTableProps): JSX.Element {
  const [propertyValue, setPropertyValue] = useState<number>(
    tableData.rounded_market_value
  );
  const [arbitraryReason, setArbitraryReason] = useState<string>();
  const statusIsReport = propertyData.status === Status.REPORT;

  const { osId } = useGeneral();

  useEffect(() => {
    if (propertyData.arbitrary_value)
      setPropertyValue(propertyData.arbitrary_value);
    if (propertyData.arbitrary_value_reason)
      setArbitraryReason(propertyData.arbitrary_value_reason);
  }, [propertyData]);

  const handlePropertyValue = useCallback(
    async (value: number, reason: string): Promise<void> => {
      setPropertyValue(value);
      setArbitraryReason(reason);
    },
    []
  );

  return (
    <>
      <Box display="flex" gap={3}>
        <ExportSample osId={osId} />
        {refNumber && transformations && (
          <Recalculate
            refNumber={refNumber}
            transformations={transformations}
          />
        )}
      </Box>
      {statusIsReport && (
        <ArbitraryValue
          propertyData={propertyData}
          initialValue={tableData.rounded_market_value}
          arbitraryCallback={handlePropertyValue}
        />
      )}
      <GridContainer container>
        <GridTable item xs={12}>
          <ReportTitle>{Constants.reportTitle}</ReportTitle>
        </GridTable>
        <GridTable item xs={12}>
          <StyledTable>
            <TableHead>
              <RowCaption>
                <CellCaption colSpan={2} sx={{ fontSize: '24px' }}>
                  {formatReal(propertyValue)}
                  {propertyValue !== tableData.rounded_market_value &&
                    ' (arbitrado)'}
                </CellCaption>
              </RowCaption>
            </TableHead>
          </StyledTable>
        </GridTable>
        {arbitraryReason &&
          propertyValue !== tableData.mean_total_value_estimate && (
            <GridTable item xs={12}>
              <ReportsTable
                ariaLabel="motivo da arbitrariedade"
                totalCol={1}
                title="Motivo da arbitrariedade de valor"
                bodyData={presentReason(arbitraryReason)}
              />
            </GridTable>
          )}
        <GridTable item xs={12}>
          <ReportsTable
            ariaLabel="resultado da avaliação"
            totalCol={5}
            title={Constants.evaluationResult}
            headerData={presentResultHeader(tableData)}
            bodyData={presentResultData(tableData)}
          />
        </GridTable>
        <GridTable item xs={12}>
          <ReportsTable
            ariaLabel="tabela de estatística"
            totalCol={2}
            title={Constants.inferenceStatistic}
            headerData={presentStatisticHeader}
            labelValueData={presentStatistic(tableData)}
          />
        </GridTable>
        <GridTable item xs={12}>
          <ReportsTable
            ariaLabel="tabela normalidade dos resíduos"
            totalCol={3}
            title={Constants.infNormality}
            headerData={presentNormalityHeader}
            bodyData={presentWasteNormality(tableData)}
          />
        </GridTable>
        <GridTable item xs={12}>
          <ReportsTable
            ariaLabel="tabela informações complementares"
            totalCol={2}
            title={Constants.infComplementary}
            headerData={presentComplementaryHeader}
            labelValueData={presentComplementaryInfo(tableData)}
          />
        </GridTable>
        <GridTable item xs={12}>
          <ReportsTable
            ariaLabel="tabela outliers"
            totalCol={2}
            title={Constants.infOutliers}
            labelValueData={presentOutliers()}
          />
        </GridTable>
        <GridTable item xs={12}>
          <ReportsTable
            ariaLabel="tabela análise de variância"
            totalCol={5}
            title={Constants.infVariance}
            headerData={presentVarianceHeader}
            bodyData={tableData.variance_analysis.map((row) =>
              presentVarianceData(row)
            )}
          />
        </GridTable>
        <GridTable item xs={12}>
          <ReportsTable
            ariaLabel="tabela testes de hipóteses"
            totalCol={4}
            title={Constants.infHypothesis}
            headerData={presentHypothesisHeader}
            bodyData={tableData.hypothesis_tests.map((row) =>
              presentHypothesisData(row)
            )}
          />
        </GridTable>
        <GridTable item xs={12}>
          <StyledTable aria-label="tabela equação de regressão linear múltipla">
            <TableHead>
              <RowCaption>
                <CellCaption>{Constants.infEquation}</CellCaption>
              </RowCaption>
            </TableHead>
            <TableBody>
              <TableRow>
                <BoldCell align="left">{tableData.equation}</BoldCell>
              </TableRow>
            </TableBody>
          </StyledTable>
        </GridTable>
        <GridTable item xs={12}>
          <StyledTable aria-label="tabela de correlação">
            <TableHead>
              <RowCaption>
                <CellCaption colSpan={5}>
                  {Constants.infCorrelation}
                </CellCaption>
              </RowCaption>
            </TableHead>
            <TableBody>
              {tableData.corr_matrix.map((row) => (
                <Fragment key={row.name}>
                  <RowHeader>
                    {presentCorrHeader(row.name).map((data) => (
                      <CellHeader align="center" key={data.label}>
                        {data.value}
                      </CellHeader>
                    ))}
                  </RowHeader>
                  {row.data.map((cell) => (
                    <TableRow key={cell.name}>
                      <BoldCell align="left">{cell.name}</BoldCell>
                      <CellMinWidth align="center">
                        {formatFloatNumberDecimal(cell.isolated)}
                      </CellMinWidth>
                    </TableRow>
                  ))}
                </Fragment>
              ))}
            </TableBody>
          </StyledTable>
        </GridTable>
        <GridTable item xs={12}>
          <StyledTable aria-label="tabela fundamentação e precisão">
            <TableHead>
              <RowCaption>
                <CellCaptionBorder>{Constants.infFundNbr}</CellCaptionBorder>
                <CellCaption>{Constants.infPrecNbr}</CellCaption>
              </RowCaption>
            </TableHead>
            <TableBody>
              <TableRow>
                <BoldCell align="left">
                  Grau {tableData.scores.reasoning_level}
                </BoldCell>
                <BoldCell align="left">
                  Grau {tableData.scores.precision_level}
                </BoldCell>
              </TableRow>
            </TableBody>
          </StyledTable>
        </GridTable>
        <GridTable item xs={12}>
          <StyledTable aria-label="grau de fundamentação do caso de modelos de regressão linear">
            <TableHead>
              <RowCaption>
                <CellCaption colSpan={6}>
                  {Constants.infFundamentation}
                </CellCaption>
              </RowCaption>
              <RowHeader>
                {presentFundamentationHeader.map((header) => (
                  <CellHeader align="center" key={header.label}>
                    {header.label}
                  </CellHeader>
                ))}
              </RowHeader>
            </TableHead>
            <TableBody>
              {presentLinearRegression.map((item, index) => (
                <TableRow key={item.item}>
                  <CellContent align="center" sx={{ minWidth: '60px' }}>
                    {item.item}
                  </CellContent>
                  <CellContent align="center">{item.description}</CellContent>
                  <CellContent align="center">{item.III}</CellContent>
                  <CellContent align="center">{item.II}</CellContent>
                  <CellContent align="center">{item.I}</CellContent>
                  <BoldCell align="center" sx={{ color: '#B038FA' }}>
                    {tableData.scores.item_points[index]}
                  </BoldCell>
                </TableRow>
              ))}
              <TableRow>
                <BoldCell colSpan={5}>{Constants.sum}</BoldCell>
                <BoldCell align="center">
                  {tableData.scores.total_points}
                </BoldCell>
              </TableRow>
            </TableBody>
          </StyledTable>
        </GridTable>
        <GridTable item xs={12}>
          <StyledTable aria-label="tabela grau de fundamentação">
            <TableHead>
              <RowCaption>
                <CellCaption colSpan={6}>
                  {Constants.factorsFundScore}
                </CellCaption>
              </RowCaption>
            </TableHead>
            <TableBody>
              {presentScoresData().map((row, i) => (
                <TableRow key={row[i].label}>
                  {row.map((data) => (
                    <CellContent align="center" key={data.label}>
                      {data.value}
                    </CellContent>
                  ))}
                </TableRow>
              ))}
              <RowHeader>
                <CellHeader colSpan={3} sx={{ textAlign: 'left' }}>
                  {Constants.total}
                </CellHeader>
                <CellHeader>{tableData.scores.total_points}</CellHeader>
              </RowHeader>
              <RowCaption>
                <CellCaptionBorder
                  colSpan={3}
                  sx={{
                    fontSize: '18px',
                    textAlign: 'left',
                  }}
                >
                  {Constants.fundResult}
                </CellCaptionBorder>
                <CellCaption>{tableData.scores.reasoning_level}</CellCaption>
              </RowCaption>
              <TableRow>
                <CellContent colSpan={6}>
                  {Constants.fundObervationInference}
                </CellContent>
              </TableRow>
            </TableBody>
          </StyledTable>
        </GridTable>
        <GridTable item xs={12}>
          <StyledTable>
            <TableHead>
              <RowCaption>
                <CellCaptionBorder rowSpan={2}>Descrição</CellCaptionBorder>
                <CellCaptionBorder colSpan={3}>
                  Grau de Precisão
                </CellCaptionBorder>
                <CellCaption rowSpan={2}>Pontos Obtidos</CellCaption>
              </RowCaption>
              <RowCaption>
                <CellCaptionBorder>III</CellCaptionBorder>
                <CellCaptionBorder>II</CellCaptionBorder>
                <CellCaptionBorder>I</CellCaptionBorder>
              </RowCaption>
            </TableHead>
            <TableBody>
              <TableRow>
                {presentPrecisionData(tableData.scores.precision_level).map(
                  (data) => (
                    <BoldCell
                      align="center"
                      key={data.label}
                      sx={{ minWidth: '140px' }}
                    >
                      {data.value}
                    </BoldCell>
                  )
                )}
              </TableRow>
            </TableBody>
          </StyledTable>
        </GridTable>
        <Grid item xs={12}>
          <ReportTitle>{Constants.infGraphs}</ReportTitle>
        </Grid>
        <Grid item xs={6}>
          <ScatterTrendline
            chart={tableData.obs_vs_predicted_plot}
            bisector
            chartCallback={chartCallback}
          />
        </Grid>
        <Grid item xs={6}>
          <LineScatter
            chart={tableData.normalized_residuals_plot}
            chartCallback={chartCallback}
          />
        </Grid>
        <Grid item xs={6}>
          <LineBar
            chart={tableData.residuals_histogram_plot}
            chartCallback={chartCallback}
          />
        </Grid>
        <Grid item xs={6}>
          <ScatterTrendline
            chart={tableData.cooks_distance_plot}
            chartCallback={chartCallback}
          />
        </Grid>
        {tableData.independent_variables_plots.map((plot) => (
          <Grid item xs={6} key={plot.x_axis_title}>
            <LineInterval chart={plot} chartCallback={chartCallback} />
          </Grid>
        ))}
        <Grid item xs={6}>
          <LineInterval
            chart={tableData.total_price_plot}
            chartCallback={chartCallback}
          />
        </Grid>
      </GridContainer>
    </>
  );
}
