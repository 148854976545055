import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  borderRadius: '10px',
  width: '100%',
  backgroundColor: '#E6E6E6',
  height: '476px',
  [theme.breakpoints.down('xl')]: {
    height: '450px',
  },
}));
