/* eslint-disable react/require-default-props */
import { Checkbox, Table, TableBody, TableHead } from '@mui/material';

import { PropertyType } from '../../../../api/enumerations';
import { SaveElementData } from '../../../../api/sample/types';
import { CustomFormControl } from '../../../../components/CustomInput';
import { Constants } from '../../../../constants/samples';
import { presentTableHead } from '../../../SamplesList/Sample/ElementTable/presenter';
import { Cell, Row, StyledTableCell, StyledTableContainer } from './styles';

interface ElementTableProps {
  tableData: SaveElementData[] | undefined;
  propertyType: PropertyType | undefined;
  disabledCheckAll?: boolean;
  checkAll: boolean;
  setCheckAll: (value: boolean) => void;
  checkedElements: SaveElementData[];
  handleCheckElement: (value: SaveElementData) => void;
}

export function ElementTable({
  tableData,
  propertyType,
  disabledCheckAll,
  checkAll,
  setCheckAll,
  checkedElements,
  handleCheckElement,
}: ElementTableProps): JSX.Element {
  return (
    <StyledTableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <Row className="table">
            <Cell align="center" small="true">
              <CustomFormControl
                isChecked={checkAll}
                setIsChecked={setCheckAll}
                disabled={disabledCheckAll}
                auto
              />
            </Cell>
            <Cell align="center" small="true">
              {Constants.item}
            </Cell>
            {presentTableHead(propertyType).map((cell) => (
              <Cell
                align="center"
                key={cell.id}
                small={cell.small ? 'true' : 'false'}
              >
                {cell.label}
              </Cell>
            ))}
          </Row>
        </TableHead>
        <TableBody>
          {tableData?.map((element, index) => (
            <Row key={element.id}>
              <StyledTableCell align="center" button="true">
                <Checkbox
                  color="primary"
                  checked={checkedElements.some((el) => el === element)}
                  onChange={() => handleCheckElement(element)}
                />
              </StyledTableCell>
              <StyledTableCell align="center">{index + 1}</StyledTableCell>
              {presentTableHead(propertyType, element).map((cell) => (
                <StyledTableCell key={cell.id} align="center">
                  {cell.value}
                </StyledTableCell>
              ))}
            </Row>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}
