import { Button } from '@mui/base';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SampleContainer = styled(Box)(({ theme }) => ({
  border: '1px solid #D7D8DB',
  backgroundColor: theme.palette.common.white,
  padding: '20px 40px',
  marginTop: '32px',
}));

export const LabelBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
}));

export const IdLabel = styled(Box)(() => ({
  fontWeight: '600',
  fontSize: '18px',
  backgroundColor: '#ECF0F6',
  width: 180,
  height: 36,
  borderTopRightRadius: 8,
  borderBottomRightRadius: 8,
  color: '#2B3550',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  top: -20,
  left: -40,
}));

export const UnusedLabel = styled(Box)(({ theme }) => ({
  fontWeight: '600',
  fontSize: '18px',
  backgroundColor: '#78909C',
  color: theme.palette.common.white,
  borderRadius: '2px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  padding: '0 20px',
  top: -20,
}));

export const EmptyBox = styled(Box)(() => ({
  width: 180,
  position: 'relative',
  top: -20,
  right: -40,
}));

export const InputTitle = styled(Typography)(() => ({
  paddingBottom: '4px',
  fontSize: '14px',
}));

export const StyledInput = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '18px',
  borderBottom: '1px solid #8B8C8E',
  inlineSize: 'fit-content',
  minWidth: '100px',
  padding: '0 8px',
  minHeight: '28px',
}));

export const SectionBox = styled(Box)(() => ({
  margin: '40px 0',
}));

export const SectionTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '21px',
  fontWeight: '600',
  margin: '20px 0',
  cursor: 'pointer',
  '& span': { color: theme.palette.primary.main },
}));

export const ElementButton = styled(Button)(({ theme }) => ({
  width: '100%',
  maxWidth: 210,
  height: 32,
  backgroundColor: theme.palette.common.white,
  fontSize: 15,
  fontWeight: '600',
  lineHeight: '20px',
  border: `2px solid ${theme.palette.primary.dark}`,
  borderRadius: 10,
  color: theme.palette.primary.dark,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  gap: 8,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  '&:disabled': {
    opacity: '0.5',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.dark,
      cursor: 'auto',
    },
  },
}));
