import {
  handleUrl,
  myHeadersWithToken,
  myHeadersWithTokenContentType,
} from '../global';
import {
  AddSampleListData,
  AddSampleListParams,
  CitiesListProps,
  DetailProps,
  ElementsListProps,
  GetCitiesParams,
  GetSamplesListsProps,
  ImportElementsProps,
  SampleListProps,
} from './types';

const addSampleList = (data: AddSampleListData): Promise<SampleListProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/saved-samples-lists`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const deleteSampleList = (sampleId: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/saved-samples-lists/${sampleId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const getSamplesLists = (
  params: AddSampleListParams
): Promise<GetSamplesListsProps> => {
  const { uf, city, realEstateKind, searchTerm, page, size } = params;
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  let url = `${BaseUrl}/api/V1/saved-samples-lists?`;

  const paramsUrl = [];

  if (page && size) paramsUrl.push(`page=${page}`, `size=${size}`);
  if (uf) paramsUrl.push(`uf=${uf}`);
  if (city) paramsUrl.push(`city=${city}`);
  if (realEstateKind) paramsUrl.push(`real_estate_kind=${realEstateKind}`);
  if (searchTerm) paramsUrl.push(`region_name=${searchTerm}`);

  url += paramsUrl.join('&');

  return fetch(`${url}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const getSampleList = (sampleId: number): Promise<SampleListProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/saved-samples-lists/${sampleId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const addSampleElement = (
  sampleId: number,
  data: FormData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithToken(),
    body: data,
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/saved-samples-lists/${sampleId}/saved-samples`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const deleteSampleElement = (
  sampleId: number,
  elementId: number
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/saved-samples-lists/${sampleId}/saved-samples/${elementId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const getSampleElements = (sampleId: number): Promise<ElementsListProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/saved-samples-lists/${sampleId}/saved-samples`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const importSampleElements = (
  sampleId: number,
  data: FormData
): Promise<ImportElementsProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithToken(),
    body: data,
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/saved-samples-lists/${sampleId}/saved-samples/import-from-xlsx`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const getCitiesList = (params?: GetCitiesParams): Promise<CitiesListProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  let url = `${BaseUrl}/api/V1/saved-samples-lists/cities`;

  if (params) {
    const { name, uf } = params;

    if (uf) url += `&uf=${uf}`;
    if (name) url += `&name=${name}`;
  }

  return fetch(`${url}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export default {
  addSampleList,
  deleteSampleList,
  getSamplesLists,
  getSampleList,
  addSampleElement,
  deleteSampleElement,
  getSampleElements,
  importSampleElements,
  getCitiesList,
};
