export const Constants = {
  stage: 'Etapa ** de 4',
  accessText: 'Verifique seu email com o código de acesso!',
  emailPlaceholder: 'digite seu e-mail cadastrado',
  submit: 'enviar',
  check: 'verificar',
  cancel: 'cancelar',
  addEmail: 'Insira o código de 6 dígitos enviado para seu e-mail.',
  timer: 'você poderá solicitar um novo código em ',
  seconds: '0:',
  left: ' segundos restantes',
  newToken: 'solicitar novo código',
  newPassword: 'Altere sua senha',
  passwordText: 'Digite uma senha para concluir o login',
  save: 'salvar',
  continue: 'continuar',
  crea: 'CREA',
  cau: 'CAU',
  rg: 'RG',
  cnh: 'CNH',
  registerText: 'Precisamos de algumas informações para realizar seu cadastro:',
  name: 'nome completo',
  phone: 'digite seu telefone',
  password: 'digite a nova senha',
  confirmPassword: 'confirme a nova senha',
  passwordHelp:
    'mínimo de 8 caracteres com letra mínuscula, maiúscula e número',
  confirmHelp: 'as senhas devem ser iguais',
  documentText: 'Qual documento você quer usar?',
  dragText: 'solte os arquivos aqui',
  uploadText: 'arraste e solte os arquivos',
  fileType: '(JPG, GIF e PNG somente)',
  docSuccess: 'Documento e fotos foram recebidos com sucesso.',
  redirect:
    'Assim que as informações forem validadas você receberá um e-mail com o resultado da análise.',
};
