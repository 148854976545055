import { useContext, useState } from 'react';

import { roomAPI } from '../../../../api';
import { RoomData } from '../../../../api/rooms/types';
import { CustomTextField } from '../../../../components/CustomInput';
import { CloseIcon, FilledButton } from '../../../../components/UI/Button';
import { StyledDialog } from '../../../../components/UI/Dialog';
import { DialogTitle } from '../../../../components/UI/Typography';
import { IconCloseMS, IconEditMS } from '../../../../constants/icons';
import { Constants } from '../../../../constants/inspection';
import { GlobalContext } from '../../../../context/global';
import { getErrorMessage } from '../../../../helpers';
import useGeneral from '../../../../hooks/useGeneral';
import { StyledMenuItem, ButtonBox, DialogContainer } from './styles';

export interface EditRoomDialogProps {
  room: RoomData;
  rooms: RoomData[];
  setRooms: (rooms: RoomData[]) => void;
}

export default function EditRoomDialog({
  room,
  rooms,
  setRooms,
}: EditRoomDialogProps): JSX.Element {
  const [name, setName] = useState(room.name);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const dialog = useGeneral();

  const handleUpdateRoom = async (): Promise<void> => {
    const editedName = {
      name,
      room_type_id: room.room_type_id,
    };

    try {
      const response = await roomAPI.updateRoom(room.id, editedName);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Cômodo atualizado!');
      setErrorMessage(false);
      setOpenSnackbar(true);

      const editRoom = rooms.find((element) => element.id === room.id);

      if (editRoom) {
        editRoom.name = name;
        const newData = [...rooms];
        setRooms(newData);
      }

      dialog.handleClose();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <>
      <StyledMenuItem disableTouchRipple onClick={dialog.handleOpen}>
        {IconEditMS}
        {Constants.editRoom}
      </StyledMenuItem>
      <StyledDialog open={dialog.open} onClose={dialog.handleClose}>
        <DialogContainer>
          <CloseIcon onClick={dialog.handleClose}>{IconCloseMS}</CloseIcon>
          <DialogTitle>
            {IconEditMS}
            {Constants.editRoom}
          </DialogTitle>
          <CustomTextField
            id="name"
            label="nome cômodo"
            maxLength={18}
            value={name}
            setValue={setName}
          />
          <ButtonBox>
            <FilledButton onClick={handleUpdateRoom}>
              {Constants.save}
            </FilledButton>
          </ButtonBox>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
