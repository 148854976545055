export const Constants = {
  inspection: 'Vistoria',
  imageDetails: 'Detalhes da imagem',
  download: 'baixar imagem',
  pathologies: 'Registro de patologias',
  pathologiesText: 'Adicione as patologias do cômodo selecionado',
  noPathologies: 'Imagem não possui patologias',
  addPathologies: 'adicionar patologias',
  submitImage: 'confirmar edição de fotos',
  roomPathologies: 'Adicione fotos das patologias existentes no cômodo',
  dragText: 'solte os arquivos aqui',
  uploadText: 'arraste e solte os arquivos',
  fileType: '(JPG, GIF e PNG somente)',
  selectPhotos: 'selecionar fotos',
  photoPathologies: 'Selecione as patologias existentes na foto',
  cancel: 'cancelar',
  morePathologies: 'selecione mais patologias',
  thisPathology: 'esta patologia',
  photoAndPathologies: 'esta foto e suas patologias',
};

export const DefaultPathologyOptions = [
  {
    kind: 1,
    name: 'ausência',
    checked: false,
  },
  {
    kind: 2,
    name: 'bolhas',
    checked: false,
  },
  {
    kind: 3,
    name: 'bolor',
    checked: false,
  },
  {
    kind: 4,
    name: 'carbonatação',
    checked: false,
  },
  {
    kind: 5,
    name: 'desbotamento',
    checked: false,
  },
  {
    kind: 6,
    name: 'desgaste',
    checked: false,
  },
  {
    kind: 7,
    name: 'destacamento',
    checked: false,
  },
  {
    kind: 8,
    name: 'eflorescência',
    checked: false,
  },
  {
    kind: 9,
    name: 'empolamento',
    checked: false,
  },
  {
    kind: 10,
    name: 'fenda, fissura, rachadura e trinca',
    checked: false,
  },
  {
    kind: 11,
    name: 'gretamento',
    checked: false,
  },
  {
    kind: 12,
    name: 'infiltração',
    checked: false,
  },
  {
    kind: 13,
    name: 'manchas',
    checked: false,
  },
  {
    kind: 14,
    name: 'porosidade',
    checked: false,
  },
  {
    kind: 15,
    name: 'recalque',
    checked: false,
  },
  {
    kind: 16,
    name: 'rompimento',
    checked: false,
  },
  {
    kind: 17,
    name: 'sinais de umidade',
    checked: false,
  },
  {
    kind: 18,
    name: 'sujidades',
    checked: false,
  },
  {
    kind: 19,
    name: 'umidade',
    checked: false,
  },
  {
    kind: 20,
    name: 'outros',
    checked: false,
  },
];
