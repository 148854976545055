import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const FilledButton = styled(Button)(() => ({
  borderRadius: '16px',
  width: '100%',
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '25px',
    backgroundColor: theme.palette.common.white,
    minWidth: '860px',
    padding: '60px 80px',
    minHeight: '500px',
  },
  '& .MuiDialog-paperScrollPaper': {
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.dark,
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '&::-webkit-scrollbar-track': {
      margin: '60px 0',
    },
  },
}));

export const IconButtonBase = styled(ButtonBase)(({ theme }) => ({
  minHeight: '30px',
  display: 'flex',
  gap: '8px',
  padding: '2px 4px',
  borderRadius: '8px',
  alignItems: 'center',
  fontSize: '16px',
  '& span': { color: theme.palette.primary.main },
  '&:hover': {
    '& span': {
      fontSize: '26px',
    },
    fontWeight: '600',
  },
}));

export const ShortAnswerTextField = styled(TextField)(() => ({
  width: '100%',
  '& .MuiInputBase-root': { borderRadius: '16px' },
}));

export const FlexCenterGrid = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const OptionIcon = styled(Typography)(() => ({
  marginRight: '10px',
}));

export const OptionCloseIcon = styled(IconButton)(() => ({
  height: '40px',
  marginLeft: '10px',
}));

export const FlexButtonBox = styled(Box)(() => ({
  display: 'flex',
  gap: '40px',
  justifyContent: 'space-around',
  padding: '20px 40px 0',
}));
