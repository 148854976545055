import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Stage = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  marginTop: '-18px',
  color: theme.palette.primary.main,
}));

export const Title = styled(Typography)(() => ({
  fontSize: '32px',
  margin: '20px 0',
  fontWeight: 600,
}));
