/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { driver } from 'driver.js';
import { ChangeEvent, DragEvent, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { pictureAPI } from '../../../api';
import { StepTour } from '../../../api/enumerations';
import BackdropLoading from '../../../components/BackdropLoading';
import { BoxContainer, SectionBox } from '../../../components/UI/Box';
import { BackButton, StandardButton } from '../../../components/UI/Button';
import { GridContainer } from '../../../components/UI/Grid';
import { SectionTitle } from '../../../components/UI/Typography';
import {
  IconAddPhotoAlternateMS,
  IconArrowCircleLeftMS,
  IconPhotoLibraryMS,
} from '../../../constants/icons';
import { Constants, InspectionTips } from '../../../constants/inspection';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage } from '../../../helpers';
import { driverConfig } from '../../../helpers/driver/config';
import { inspectionAddPhotosSteps } from '../../../helpers/driver/steps';
import { useTour } from '../../../hooks/useTour';
import useUploadFile from '../../../hooks/useUploadFile';
import {
  StyledDivider,
  TipsBox,
  TipsText,
  TipsTitle,
  Upload,
  UploadIcon,
  UploadSubtitle,
  UploadText,
} from './styles';

export default function InspectionAddPhotos(): JSX.Element {
  const [loading, setLoading] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { dragActive, handleDrag, handleDrop, handleFileUpload } =
    useUploadFile();

  const {
    tourCompletion,
    setTourCompletion,
    setTutorialStep,
    toBeContinued,
    setTourSelection,
    setToBeContinued,
  } = useTour();

  const navigate = useNavigate();
  const { id, inspection, room } = useParams();
  const osId = parseInt(id as string, 10);
  const inspectionId = parseInt(inspection as string, 10);
  const roomId = parseInt(room as string, 10);

  const navigateBack = (): void => {
    navigate(`/home/property/${osId}/inspection/${inspectionId}/rooms`);
  };

  const handleSubmitPhotos = async (files: FileList): Promise<void> => {
    setLoading(true);

    const formData = new FormData();
    Array.from(files).map((file) => formData.append('pictures', file));
    formData.append('room_id', roomId.toString());

    try {
      const response = await pictureAPI.addPicture(formData);
      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }
      setSnackbarMessage('Fotos adicionadas com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      setLoading(false);
      navigate(
        `/home/property/${osId}/inspection/${inspectionId}/room/${roomId}/image-details/new/${response.data.first_id}`
      );
    } catch (error) {
      setLoading(false);
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const dropFile = (e: DragEvent<HTMLDivElement>): void => {
    const files = handleDrop(e);
    if (files) {
      handleSubmitPhotos(files);
    }
  };

  const uploadFile = (e: ChangeEvent<HTMLInputElement>): void => {
    const files = handleFileUpload(e);
    if (files) {
      handleSubmitPhotos(files);
    }
  };

  const addPhotosDriverObj = driver({
    ...driverConfig,
    steps: inspectionAddPhotosSteps,
    onPrevClick: () => {
      setTourCompletion((prev) => {
        const newTourCompletion = { ...prev };
        const inspectionIndex = newTourCompletion.inspection.findIndex(
          (item) => item.stage === 'inspectionRoomsAddPhotos'
        );
        if (inspectionIndex !== -1) {
          const updatedInspectionIndex = {
            ...newTourCompletion.inspection[inspectionIndex],
          };
          updatedInspectionIndex.complete = true;
          newTourCompletion.inspection[inspectionIndex] =
            updatedInspectionIndex;
        }
        return newTourCompletion;
      });
    },
    onNextClick: () => {
      setTourCompletion((prev) => {
        const newTourCompletion = { ...prev };
        const inspectionIndex = newTourCompletion.inspection.findIndex(
          (item) => item.stage === 'inspectionRoomsAddPhotos'
        );
        if (inspectionIndex !== -1) {
          const updatedInspectionIndex = {
            ...newTourCompletion.inspection[inspectionIndex],
          };
          updatedInspectionIndex.complete = true;
          newTourCompletion.inspection[inspectionIndex] =
            updatedInspectionIndex;
        }
        return newTourCompletion;
      });
      addPhotosDriverObj.moveNext();
    },
    onCloseClick: () => {
      setTourCompletion((prev) => {
        const newTourCompletion = { ...prev };
        const inspectionIndex = newTourCompletion.inspection.findIndex(
          (item) => item.stage === 'inspectionRoomsAddPhotos'
        );
        if (inspectionIndex !== -1) {
          const updatedInspectionIndex = {
            ...newTourCompletion.inspection[inspectionIndex],
          };
          updatedInspectionIndex.complete = true;
          newTourCompletion.inspection[inspectionIndex] =
            updatedInspectionIndex;
        }
        return newTourCompletion;
      });
      setTourSelection(false);
      setToBeContinued(false);
      addPhotosDriverObj.destroy();
    },
    onDestroyStarted: () => {
      setTourSelection(false);
      setToBeContinued(false);
      addPhotosDriverObj.destroy();
    },
  });

  useEffect(() => {
    setTutorialStep(StepTour.INSPECTIONSTART);
    if (
      !tourCompletion.inspection[2].complete &&
      !tourCompletion.skipTour.inspection
    ) {
      addPhotosDriverObj.drive();
    }
  }, [tourCompletion, toBeContinued]);

  return (
    <GridContainer>
      <BackButton onClick={navigateBack}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer>
        <SectionTitle>
          {IconPhotoLibraryMS}
          {Constants.addPhotos}
        </SectionTitle>
        <Upload
          id="upload-box"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={dropFile}
        >
          <UploadIcon>{IconAddPhotoAlternateMS}</UploadIcon>
          <UploadText>
            {dragActive ? Constants.dragText : Constants.uploadText}
          </UploadText>
          <UploadSubtitle>{Constants.fileType}</UploadSubtitle>
          <label htmlFor="uploadPictures">
            <input
              accept="image/*"
              multiple
              id="uploadPictures"
              type="file"
              style={{ display: 'none' }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => uploadFile(e)}
            />
            {!dragActive && (
              <StandardButton component="span">
                {IconAddPhotoAlternateMS}
                {Constants.selectPhotos}
              </StandardButton>
            )}
          </label>
        </Upload>
        <SectionBox>
          <StyledDivider role="presentation">
            {Constants.dividerTitle}
          </StyledDivider>
          <Grid container spacing={2}>
            {InspectionTips.map((tips) => (
              <Grid key={tips.title} item xs={4}>
                <TipsBox>
                  <TipsTitle>{tips.title}</TipsTitle>
                  <TipsText>{tips.text}</TipsText>
                </TipsBox>
              </Grid>
            ))}
          </Grid>
        </SectionBox>
      </BoxContainer>
      <BackdropLoading open={loading} />
    </GridContainer>
  );
}
