import { Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)(() => ({
  paddingLeft: '3rem',
}));

export const ReportTitle = styled(Typography)(({ theme }) => ({
  margin: '28px 0',
  fontSize: '21px',
  fontWeight: '600',
  color: '#707070',
  textTransform: 'uppercase',
  borderBottom: `2px solid ${theme.palette.primary.main}`,
  width: 'fit-content',
}));

export const ShortAnswerTextField = styled(TextField)(() => ({
  width: '100%',
  marginBottom: '8px',
  '& .MuiInputBase-root': { borderRadius: '16px' },
}));

export const FlexCenterGrid = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
  margin: '20px',
}));
