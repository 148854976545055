import { Box, Button, Dialog, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: 16,
    backgroundColor: '#FAFCFF',
  },
}));

export const DialogContainer = styled(Box)(() => ({
  minWidth: 600,
  minHeight: 200,
  padding: '40px 40px',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
}));

export const TextTypography = styled(Typography)(() => ({
  textAlign: 'center',
  fontSize: '22px',
  fontWeight: '600',
  color: '#595959',
}));

export const AlertTypography = styled(Typography)(() => ({
  color: '#595959',
  textAlign: 'center',
}));

export const ButtonBox = styled(Box)(() => ({
  margin: '20px 0',
  display: 'flex',
  gap: '40px',
  justifyContent: 'center',
}));

export const PeptButton = styled(Button)(() => ({
  border: '2px solid #E96778',
  color: '#E2475C',
  backgroundColor: 'transparent',
  fontWeight: '600',
  marginRight: 16,
  '&:hover': {
    backgroundColor: '#E96778',
  },
  '&:active': {
    backgroundColor: '#E96778',
  },
}));
