/* eslint-disable max-len */
import { InitialModalTour } from '../../Modal/InitialModalTour';

export function SampleCreationTour(): JSX.Element {
  return (
    <InitialModalTour
      title="Vamos dar início à"
      pageName="Criação de amostra"
      subtext1="Nesta tela nós fazermos a busca dos elementos e estimaremos/aprovaremos suas características."
      stepTourName="sampleCreate"
    />
  );
}
