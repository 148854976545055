import {
  Box,
  Button,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const InputBox = styled(Box)(({ theme }) => ({
  width: '70%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
  marginBottom: '36px',
  [theme.breakpoints.down('xl')]: {
    width: '75%',
    gap: '12px',
  },
}));

export const StyledTypography = styled(Typography)(() => ({
  textAlign: 'center',
  color: '#707070',
  margin: '0 -30px',
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  fontSize: '18px',
  width: '100%',
  marginBottom: '20px',
  '& .MuiInputBase-input': {
    color: theme.palette.common.black,
    fontSize: '16px',
  },
  '& .MuiInputBase-root.MuiInput-root:before': {
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
  '& .MuiInputBase-root.MuiInput-root:after': {
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
  '& .MuiInputBase-root.MuiInput-root.Mui-error:before': {
    borderBottom: '1px solid #d32f2f',
  },
  '& .MuiInputBase-root.MuiInput-root.Mui-error:after': {
    borderBottom: '1px solid #d32f2f',
  },
  '& .MuiInputBase-root.MuiInput-root:hover:before': {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  '& .MuiInputBase-root.MuiInput-root:hover:after': {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  'input:hover::-webkit-input-placeholder': { color: 'transparent' },
  'input:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0px 1000px #F2F2F2 inset',
    paddingLeft: '10px',
  },
}));

export const StyledPatternFormat = {
  width: '100%',
  marginBottom: '20px',
  '& .MuiInputBase-input': {
    color: '#2E383F',
    fontSize: '16px',
  },
  '& .MuiInputBase-root.MuiInput-root:before': {
    borderBottom: '1px solid #8D9295',
  },
  '& .MuiInputBase-root.MuiInput-root:after': {
    borderBottom: '1px solid #8D9295',
  },
  '& .MuiInputBase-root.MuiInput-root:hover:before': {
    borderBottom: '1px solid #613354',
  },
  '& .MuiInputBase-root.MuiInput-root:hover:after': {
    borderBottom: '1px solid #613354',
  },
  'input:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0px 1000px #F2F2F2 inset',
  },
};

export const MultiFormControl = styled(FormControlLabel)(({ theme }) => ({
  margin: '8px 0',
  width: '80px',
  '& .MuiButtonBase-root': {
    padding: '4px',
  },
  '&:hover': {
    '& .MuiButtonBase-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiTypography-root': {
      fontWeight: '600',
      fontSize: '18px',
    },
  },
  '& .MuiTypography-root': {
    fontSize: '18px',
    '&.Mui-disabled': {
      color: '#595959',
    },
  },
}));

export const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 20,
  width: '100%',
}));

export const OutlinedButton = styled(Button)(({ theme }) => ({
  maxWidth: '220px',
  border: `1.5px solid ${theme.palette.secondary.main}`,
  color: theme.palette.secondary.main,
  fontWeight: '600',
  backgroundColor: 'transparent',
  textTransform: 'uppercase',
  borderRadius: '16px',
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export const FilledButton = styled(Button)(({ theme }) => ({
  maxWidth: '220px',
  backgroundColor: theme.palette.secondary.main,
  textTransform: 'uppercase',
  borderRadius: '16px',
  '&:hover': {
    backgroundColor: '#9B7359',
    filter: 'brightness(85%)',
  },
}));
