import { IconTaskAltMS } from '../../../../constants/icons';
import {
  CardContainer,
  FlexBox,
  IconTypography,
  NumberTypography,
  TitleTypography,
} from './styles';

interface QuestionItemProps {
  title: string;
  questionNum: number;
  checkAnswer: boolean;
}

export function QuestionItem({
  title,
  questionNum,
  checkAnswer,
}: QuestionItemProps): JSX.Element {
  return (
    <CardContainer>
      <FlexBox>
        <NumberTypography>{questionNum}. </NumberTypography>
        <TitleTypography>{title}</TitleTypography>
      </FlexBox>
      <FlexBox>
        {checkAnswer && <IconTypography>{IconTaskAltMS}</IconTypography>}
      </FlexBox>
    </CardContainer>
  );
}
