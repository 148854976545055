import { Box, ButtonBase } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)(() => ({
  border: '1px solid #A9A4A4',
  display: 'flex',
  height: '100%',
  maxHeight: '56px',
  borderRadius: 16,
  overflow: 'hidden',
}));

interface StyledButtonProps {
  clicked: 'true' | 'false';
}

export const StyledButton = styled(ButtonBase)<StyledButtonProps>(
  ({ theme, clicked }) => {
    const isClicked = clicked === 'true';
    return {
      width: '50%',
      border: 'none',
      cursor: 'pointer',
      fontSize: 'clamp(16px, 0.93vw, 18px)',
      fontWeight: isClicked ? 600 : '',
      color: isClicked ? theme.palette.common.white : '#C4C4C4',
      backgroundColor: isClicked
        ? theme.palette.primary.main
        : theme.palette.common.white,
    };
  }
);
