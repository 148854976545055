/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';

import { sampleAPI } from '../api';
import { Status } from '../api/enumerations';
import { ElementData } from '../api/sample/types';
import { GlobalContext } from '../context/global';
import { getErrorMessage } from '../helpers';
import useGeneral from './useGeneral';
import { usePropertyData } from './usePropertyData';

interface SampleDataProps {
  status: Status;
}

const useSampleData = ({
  status,
}: SampleDataProps): {
  sampleData: ElementData[] | undefined;
} => {
  const [sampleData, setSampleData] = useState<ElementData[]>();

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { osId } = useGeneral();
  const { propertyData } = usePropertyData({ status });

  const getSample = async (): Promise<void> => {
    let sample = false;

    if (propertyData?.samples) sample = true;

    if (sample) {
      try {
        const { data, detail } = await sampleAPI.getSample(osId, 1, 105);

        if (detail.description)
          throw new Error('Algo deu errado, tente novamente');

        if (!data) throw new Error('A amostra não pode ser carregada');

        setSampleData(data.items);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }
    }
  };

  useEffect(() => {
    getSample();
  }, [propertyData]);

  return { sampleData };
};

export default useSampleData;
