/* eslint-disable react/require-default-props */
import { EvaluationType, PropertyType } from '../../../api/enumerations';
import {
  AvmFactorsData,
  AvmInferenceData,
  WorkOrderData,
} from '../../../api/workOrders/types';
import { FactorsTable } from './FactorsTable';
import { InferenceTable } from './InferenceTable';

interface ReportInfoProps {
  propertyData: WorkOrderData;
  getCharts?: (img: HTMLCanvasElement, title: string) => Promise<void>;
  forceFactors?: string[];
  forceTransformations?: string[];
}

export function ReportInfo({
  propertyData,
  getCharts,
  forceFactors,
  forceTransformations,
}: ReportInfoProps): JSX.Element {
  return propertyData?.evaluation_type === EvaluationType.AUTOFACTORS ||
    propertyData?.evaluation_type === EvaluationType.SIMPFACTORS ? (
    <FactorsTable
      tableData={propertyData?.avm_report as AvmFactorsData}
      chartCallback={getCharts}
      forceFactors={forceFactors}
      propertyIsHouse={propertyData.real_estate_kind === PropertyType.HOUSE}
      highPrice={propertyData.sample_sell_price_above_400k || false}
      refNumber={propertyData?.reference_number}
      propertyData={propertyData}
    />
  ) : (
    <InferenceTable
      tableData={propertyData?.avm_report as AvmInferenceData}
      chartCallback={getCharts}
      refNumber={propertyData?.reference_number}
      transformations={forceTransformations}
      propertyData={propertyData}
    />
  );
}
