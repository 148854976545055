import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SelectedText = styled(Typography)(({ theme }) => ({
  fontSize: '28px',
  color: theme.palette.common.black,
}));

export const FlexCenterBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  margin: '20px 0',
}));
