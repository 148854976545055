/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import chartTrendline from 'chartjs-plugin-trendline';
import React, { useEffect, useRef } from 'react';
import { Scatter } from 'react-chartjs-2';

import { ChartBox, ChartContainer, ChartTitle } from '../styles';

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  chartTrendline
);

interface AxisProps {
  x: number;
  y: number;
}

interface LineScatterProps {
  chart: {
    chart_title: string;
    x_axis_title: string;
    y_axis_title: string;
    upper_line_data: {
      title: string;
      color: string;
      data: AxisProps[];
    };
    zero_line_data: {
      title: string;
      color: string;
      data: AxisProps[];
    };
    lower_line_data: {
      title: string;
      color: string;
      data: AxisProps[];
    };
    scatter_data: {
      color: string;
      data: AxisProps[];
    };
  };
  chartCallback?: (img: HTMLCanvasElement, title: string) => Promise<void>;
}

const LineScatter = React.memo(({ chart, chartCallback }: LineScatterProps) => {
  const scatterData = chart.scatter_data.data;
  const chartTitle = JSON.parse(`["${chart.chart_title}"]`);

  const chartRef =
    useRef<ChartJS<'scatter', { x: number; y: number }[], number>>(null);

  const handleSaveImage = (): void => {
    if (chartRef?.current) {
      const { canvas } = chartRef.current;

      if (chartCallback) {
        chartCallback(canvas, chart.chart_title);
      }
    }
  };

  useEffect(() => {
    if (chartRef !== null) handleSaveImage();
  }, [chartRef]);

  const options = {
    animation: {
      duration: 0,
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: chart.y_axis_title,
          font: {
            size: 16,
          },
        },
        min: chart.lower_line_data.data[0].y - 0.5,
        max: chart.upper_line_data.data[1].y + 0.5,
      },
      x: {
        title: {
          display: true,
          text: chart.x_axis_title,
          font: {
            size: 16,
          },
        },
        min: Math.floor(chart.zero_line_data.data[0].x / 100) * 100,
        max: Math.ceil(chart.zero_line_data.data[1].x / 100) * 100,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const data = {
    datasets: [
      {
        data: scatterData.map((e) => ({
          x: e.x,
          y: e.y,
        })),
        backgroundColor: chart.scatter_data.color,
        TrendlineLinear: 'none',
      },
      {
        data: chart.lower_line_data.data.map((e) => ({
          x: e.x,
          y: e.y,
        })),
        backgroundColor: chart.lower_line_data.color,
        pointRadius: 0,
        trendlineLinear: {
          colorMin: chart.lower_line_data.color,
          colorMax: chart.lower_line_data.color,
          lineStyle: 'solid',
          width: 2,
        },
      },
      {
        data: chart.zero_line_data.data.map((e) => ({
          x: e.x,
          y: e.y,
        })),
        backgroundColor: chart.zero_line_data.color,
        pointRadius: 0,
        trendlineLinear: {
          colorMin: chart.zero_line_data.color,
          colorMax: chart.zero_line_data.color,
          lineStyle: 'solid',
          width: 2,
        },
      },
      {
        data: chart.upper_line_data.data.map((e) => ({
          x: e.x,
          y: e.y,
        })),
        backgroundColor: chart.upper_line_data.color,
        pointRadius: 0,
        trendlineLinear: {
          colorMin: chart.upper_line_data.color,
          colorMax: chart.upper_line_data.color,
          lineStyle: 'solid',
          width: 2,
        },
      },
    ],
  };

  return (
    <ChartContainer>
      <ChartTitle>{chartTitle}</ChartTitle>
      <ChartBox>
        <Scatter ref={chartRef} options={options} data={data} />
      </ChartBox>
    </ChartContainer>
  );
});

export default LineScatter;
