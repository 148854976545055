/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { QuestionData } from '../../../../../api/forms/types';
import { checkQuestionIcon } from '../../../../../helpers';
import {
  CardContainer,
  FlexBox,
  NumberTypography,
  StyledCheckbox,
  Title,
} from './styles';

interface SelectedQuestionCardProps {
  question: QuestionData;
  orderNumber: number;
  checkedQ: QuestionData[];
  setCheckedQ: (checkedQ: QuestionData[]) => void;
  checkAll: boolean;
}

export function SelectedQuestionCard({
  question,
  orderNumber,
  checkedQ,
  setCheckedQ,
  checkAll,
}: SelectedQuestionCardProps): JSX.Element {
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (question.blocked) {
      setDisabled(true);
    }
  }, []);

  useEffect(() => {
    if (checkAll) {
      return;
    }
    if (checked) {
      setCheckedQ([...checkedQ, question]);
    } else {
      const deleteCheckedQ = checkedQ.filter((element) => {
        return element.id !== question.id;
      });
      setCheckedQ(deleteCheckedQ);
    }
  }, [checked]);

  useEffect(() => {
    if (!question.blocked) {
      setChecked(checkAll);
    }
  }, [checkAll]);

  return (
    <CardContainer>
      <FlexBox>
        <NumberTypography>{orderNumber}. </NumberTypography>
        <Title>
          {checkQuestionIcon(question.type)}
          {question.title}
        </Title>
      </FlexBox>
      <StyledCheckbox
        color="primary"
        checked={checked}
        disabled={disabled}
        onChange={(event) => {
          setChecked(event.target.checked);
        }}
      />
    </CardContainer>
  );
}
