import { Button } from '@mui/base';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContainer = styled(Box)(({ theme }) => ({
  padding: '2.5rem 3rem',
  background: theme.palette.primary.light,
}));

export const GridTable = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

export const TableTitle = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  fontSize: '21px',
  fontWeight: '600',
  marginTop: '31px',
  '&::first-letter': {
    textTransform: 'uppercase',
  },
}));

export const GridButton = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 21,
}));

export const ArrowButton = styled(Button)(({ theme }) => ({
  padding: '8px',
  display: 'flex',
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '8px',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  '&:disabled': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    filter: 'opacity(40%)',
    cursor: 'auto',
  },
  cursor: 'pointer',
}));

export const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  marginTop: '16px',
  gap: 21,
  justifyContent: 'end',
}));
