import { handleUrl, myHeadersWithTokenContentType } from '../global';
import { BlockageData, DetailProps, UpdateBlockageData } from './types';

const createBlockage = (data: BlockageData): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/calendar-blockages`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const updateBlockage = (
  id: number,
  data: UpdateBlockageData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/calendar-blockages/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const deleteBlockage = (id: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/calendar-blockages/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export default {
  createBlockage,
  updateBlockage,
  deleteBlockage,
};
