import { useState } from 'react';

import useGeneral from '../../../hooks/useGeneral';
import { usePept } from '../../../hooks/usePept';
import { MultilineTextField } from '../../CustomInput';
import { FilledButton, OutlinedButton } from '../../UI/Button';
import {
  AlertTypography,
  ButtonBox,
  DialogContainer,
  PeptButton,
  StyledDialog,
  TextTypography,
} from './styles';

interface PeptDialogProps {
  osId: number;
  referenceNumber: number | undefined;
}

export function PeptDialog({
  osId,
  referenceNumber,
}: PeptDialogProps): JSX.Element {
  const [observation, setObservation] = useState('');

  const { handleSendToPept } = usePept();
  const dialog = useGeneral();

  const handleConfirm = (): void => {
    handleSendToPept(osId, referenceNumber, observation);
    dialog.handleClose();
  };

  return (
    <>
      <PeptButton id="pept-button" onClick={dialog.handleOpen}>
        enviar para pendência técnica
      </PeptButton>
      <StyledDialog open={dialog.open} onClose={dialog.handleClose}>
        <DialogContainer>
          <TextTypography>Motivo:</TextTypography>
          <MultilineTextField
            id="pept-reason"
            placeholder="Escreva aqui o motivo para enviar para pendência técnica"
            value={observation}
            setValue={setObservation}
          />
          <ButtonBox>
            <OutlinedButton width="sm" onClick={dialog.handleClose}>
              Cancelar
            </OutlinedButton>
            <FilledButton width="sm" onClick={handleConfirm}>
              Salvar
            </FilledButton>
          </ButtonBox>
          <AlertTypography>
            Atenção! Esta ação não poderá ser desfeita
          </AlertTypography>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
