/* eslint-disable max-lines */
/* eslint-disable max-len */
import { TableCellProps } from '@mui/material';

import {
  AggHomogenizationData,
  AggregatedFactors,
  AvmFactorsData,
  FactorData,
  InputData,
} from '../../../../api/workOrders/types';
import {
  convertConservation,
  convertStandard400K,
} from '../../../../constants/report';
import {
  convertPercentage,
  formatFloatNumberDecimal,
  formatReal,
} from '../../../../helpers';

interface TableValues {
  value: string | number;
  label?: string;
  bold?: boolean;
  width?: string;
  align?: TableCellProps['align'];
}

interface PriceTableValues {
  label: string;
  value: string | number;
  secondLabel: string;
  secondValue: string | number;
}

interface FundamentationData {
  item: number;
  description: string;
  third: string;
  second: string;
  first: string;
  point: number;
}

interface FundamentationValues {
  data: FundamentationData[];
  total_score: number;
}

export const presentReason = (reason: string): TableValues[][] => {
  return [
    [
      {
        label: '1',
        value: reason,
      },
    ],
  ];
};

export const presentResultHeader = (
  tableData: AvmFactorsData
): TableValues[] => {
  return [
    {
      value: 'Valor Unitário Médio',
    },
    {
      value: `${formatReal(
        tableData.aggregated_homogenization.sanitized_mean
      )}`,
    },
    {
      value: 'Amplitude do Modelo',
    },
    {
      value: 'Valor Total Médio',
    },
    {
      value: `${formatReal(tableData.aggregated_homogenization.market_value)}`,
    },
  ];
};

export const presentResultData = (
  tableData: AvmFactorsData
): TableValues[][] => {
  return [
    [
      {
        label: '1',
        value: 'Valor Unitário Mínimo',
        bold: true,
      },
      {
        label: '2',
        value: `${formatReal(tableData.aggregated_homogenization.lower_limit)}`,
      },
      {
        label: '3',
        value: `${formatFloatNumberDecimal(
          tableData.aggregated_homogenization.lower_limit_confidence * 100
        )}
        %`,
      },
      {
        label: '4',
        value: 'Valor Total Mínimo',
        bold: true,
      },
      {
        label: '5',
        value: `${formatReal(
          tableData.aggregated_homogenization.lower_limit_total
        )}`,
      },
    ],
    [
      {
        label: '1',
        value: 'Valor Unitário Máximo',
        bold: true,
      },
      {
        label: '2',
        value: `${formatReal(tableData.aggregated_homogenization.upper_limit)}`,
      },
      {
        label: '3',
        value: `${formatFloatNumberDecimal(
          tableData.aggregated_homogenization.upper_limit_confidence * 100
        )}
        %`,
      },
      {
        label: '4',
        value: 'Valor Total Máximo',
        bold: true,
      },
      {
        label: '5',
        value: `${formatReal(
          tableData.aggregated_homogenization.upper_limit_total
        )}`,
      },
    ],
  ];
};

export const presentFactorsInitial = (
  data: AvmFactorsData
): PriceTableValues[] => {
  return [
    {
      label: 'Preço total médio',
      value: `R$ ${formatFloatNumberDecimal(
        data.aggregated_input_data.mean_total_price
      )}`,
      secondLabel: 'Valor unitário médio com fator oferta',
      secondValue: `R$ ${formatFloatNumberDecimal(
        data.aggregated_input_data.mean_ppsm_with_offer
      )}`,
    },
    {
      label: 'Preço unitário médio',
      value: `R$ ${formatFloatNumberDecimal(
        data.aggregated_input_data.mean_ppsm
      )}`,
      secondLabel: 'Desvio padrão com fator oferta',
      secondValue: `R$ ${formatFloatNumberDecimal(
        data.aggregated_input_data.std_ppsm_with_offer
      )}`,
    },
    {
      label: 'Desvio padrão',
      value: `R$ ${formatFloatNumberDecimal(
        data.aggregated_input_data.std_ppsm
      )}`,
      secondLabel: 'Coeficiente de variação',
      secondValue: `${formatFloatNumberDecimal(
        data.aggregated_input_data.coeff_ppsm_with_offer * 100
      )} %`,
    },
  ];
};

export const presentEntranceHeader = (
  propertyIsHouse: boolean
): TableValues[] => {
  return propertyIsHouse
    ? [
        {
          value: '',
          width: '40px',
        },
        {
          value: 'valor total',
          width: '140px',
        },
        {
          value: 'área construída',
          width: '100px',
        },
        {
          value: 'área do terreno',
          width: '100px',
        },
        {
          value: 'padrão construtivo',
          width: '140px',
        },
        {
          value: 'idade',
          width: '80px',
        },
        {
          value: 'estado de conservação',
          width: '140px',
        },
        {
          value: 'setor censitário',
          width: '140px',
        },
        {
          value: 'vagas de garagem',
          width: '100px',
        },
      ]
    : [
        {
          value: '',
          width: '40px',
        },
        {
          value: 'valor total',
          width: '140px',
        },
        {
          value: 'área privativa',
          width: '100px',
        },
        {
          value: 'padrão construtivo',
          width: '140px',
        },
        {
          value: 'idade',
          width: '80px',
        },
        {
          value: 'estado de conservação',
          width: '140px',
        },
        {
          value: 'setor censitário',
          width: '140px',
        },
        {
          value: 'vagas de garagem',
          width: '100px',
        },
      ];
};

export const presentEntranceData = (
  data: InputData,
  i: number,
  propertyIsHouse: boolean,
  highPrice = false
): TableValues[] => {
  return propertyIsHouse
    ? [
        {
          label: '1',
          value: i + 1,
        },
        {
          label: '2',
          value: data.total_price
            ? `R$ ${formatFloatNumberDecimal(data.total_price)}`
            : 0,
        },
        {
          label: '3',
          value: data.area,
        },
        {
          label: '4',
          value: data.land_area ? data.land_area : '',
        },
        {
          label: '5',
          value: highPrice
            ? convertStandard400K(data.constructive_pattern)
            : data.constructive_pattern,
        },
        {
          label: '6',
          value: data.age,
        },
        {
          label: '7',
          value: `${data.preservation_status} - ${convertConservation(
            data.preservation_status
          )}`,
        },
        {
          label: '8',
          value: `R$ ${formatFloatNumberDecimal(data.ibge_income)}`,
        },
        {
          label: '9',
          value: data.parking_spaces,
        },
      ]
    : [
        {
          label: '1',
          value: i + 1,
        },
        {
          label: '2',
          value: data.total_price
            ? `R$ ${formatFloatNumberDecimal(data.total_price)}`
            : 0,
        },
        {
          label: '3',
          value: data.area,
        },
        {
          label: '4',
          value: highPrice
            ? convertStandard400K(data.constructive_pattern)
            : data.constructive_pattern,
        },
        {
          label: '5',
          value: data.age,
        },
        {
          label: '6',
          value: `${data.preservation_status} - ${convertConservation(
            data.preservation_status
          )}`,
        },
        {
          label: '7',
          value: `R$ ${formatFloatNumberDecimal(data.ibge_income)}`,
        },
        {
          label: '8',
          value: data.parking_spaces,
        },
      ];
};

export const presentFactorsHeader = (
  propertyIsHouse: boolean
): TableValues[] => {
  return propertyIsHouse
    ? [
        {
          value: '',
          width: '40px',
        },
        {
          value: 'área construída',
          width: '100px',
        },
        {
          value: 'área do terreno',
          width: '100px',
        },
        {
          value: 'padrão construtivo',
          width: '100px',
        },
        {
          value: 'idade estimada',
          width: '100px',
        },
        {
          value: 'estado de conservação',
          width: '100px',
        },
        {
          value: 'setor censitário',
          width: '100px',
        },
        {
          value: 'vagas de garagem',
          width: '100px',
        },
        {
          value: 'valor homogeneizado (R$/m²)',
          width: '200px',
        },
        {
          value: 'unitário saneado (R$/m²)',
          width: '140px',
        },
        {
          value: 'intervalo de ajuste',
          width: '140px',
        },
      ]
    : [
        {
          value: '',
          width: '40px',
        },
        {
          value: 'área privativa',
          width: '100px',
        },
        {
          value: 'padrão construtivo',
          width: '100px',
        },
        {
          value: 'idade estimada',
          width: '100px',
        },
        {
          value: 'estado de conservação',
          width: '100px',
        },
        {
          value: 'setor censitário',
          width: '100px',
        },
        {
          value: 'vagas de garagem',
          width: '100px',
        },
        {
          value: 'valor homogeneizado (R$/m²)',
          width: '200px',
        },
        {
          value: 'unitário saneado (R$/m²)',
          width: '140px',
        },
        {
          value: 'intervalo de ajuste',
          width: '140px',
        },
      ];
};

export const presentFactorsData = (
  data: FactorData,
  i: number,
  propertyIsHouse: boolean
): TableValues[] => {
  return propertyIsHouse
    ? [
        {
          label: '1',
          value: i + 1,
        },
        {
          label: '2',
          value: formatFloatNumberDecimal(data.area_factor),
        },
        {
          label: '3',
          value: formatFloatNumberDecimal(data.land_area_factor),
        },
        {
          label: '4',
          value: formatFloatNumberDecimal(data.constructive_pattern_factor),
        },
        {
          label: '5',
          value: formatFloatNumberDecimal(data.age_factor),
        },
        {
          label: '6',
          value: formatFloatNumberDecimal(data.preservation_status_factor),
        },
        {
          label: '7',
          value: formatFloatNumberDecimal(data.ibge_income_factor),
        },
        {
          label: '8',
          value: formatFloatNumberDecimal(data.parking_spaces_factor),
        },
        {
          label: '9',
          value: `R$ ${formatFloatNumberDecimal(data.homogenized_price)}`,
        },
        {
          label: '10',
          value: `R$ ${formatFloatNumberDecimal(data.sanitized_price)}`,
        },
        {
          label: '11',
          value: formatFloatNumberDecimal(data.admissible_adj_interval),
        },
      ]
    : [
        {
          label: '1',
          value: i + 1,
        },
        {
          label: '2',
          value: formatFloatNumberDecimal(data.area_factor),
        },
        {
          label: '3',
          value: formatFloatNumberDecimal(data.constructive_pattern_factor),
        },
        {
          label: '4',
          value: formatFloatNumberDecimal(data.age_factor),
        },
        {
          label: '5',
          value: formatFloatNumberDecimal(data.preservation_status_factor),
        },
        {
          label: '6',
          value: formatFloatNumberDecimal(data.ibge_income_factor),
        },
        {
          label: '7',
          value: formatFloatNumberDecimal(data.parking_spaces_factor),
        },
        {
          label: '8',
          value: `R$ ${formatFloatNumberDecimal(data.homogenized_price)}`,
        },
        {
          label: '9',
          value: `R$ ${formatFloatNumberDecimal(data.sanitized_price)}`,
        },
        {
          label: '10',
          value: formatFloatNumberDecimal(data.admissible_adj_interval),
        },
      ];
};

export const presentCoeficientHeaderForceFactors = [
  {
    label: '',
    width: '40px',
  },
  {
    label: 'nome',
    width: '232px',
  },
  {
    label: 'PPMQ médio',
    width: '140px',
  },
  {
    label: 'PPMQ padrão',
    width: '140px',
  },
  {
    label: 'coeficiente',
    width: '120px',
  },
  {
    label: 'validação de coeficiente',
    width: '132px',
  },
  {
    label: 'coeficiente utilizado',
    width: '132px',
  },
  {
    label: 'forçar fator',
    width: '120px',
  },
];

export const presentCoeficientHeader = [
  {
    label: '',
    width: '40px',
  },
  {
    label: 'nome',
    width: '240px',
  },
  {
    label: 'média',
    width: '140px',
  },
  {
    label: 'desvio padrão',
    width: '140px',
  },
  {
    label: 'coeficiente de variação',
    width: '140px',
  },
  {
    label: 'validação de coeficiente',
    width: '140px',
  },
  {
    label: 'coeficiente utilizado',
    width: '140px',
  },
];

export const presentAggregatedFactorName: { [key: string]: string } = {
  area_factor: 'fator de área privativa',
  land_area_factor: 'fator de área total',
  constructive_pattern_factor: 'fator de padrão construtivo',
  age_factor: 'fator de idade estimada',
  preservation_status_factor: 'fator de estado de conservação',
  ibge_income_factor: 'fator de setor censitário',
  parking_spaces_factor: 'fator de vagas de garagem',
};

export const presentAggregatedFactorHouseName: { [key: string]: string } = {
  area_factor: 'fator de área construída',
  land_area_factor: 'fator de área do terreno',
  constructive_pattern_factor: 'fator de padrão construtivo',
  age_factor: 'fator de idade estimada',
  preservation_status_factor: 'fator de estado de conservação',
  ibge_income_factor: 'fator de setor censitário',
  parking_spaces_factor: 'fator de vagas de garagem',
};

export const presentAggregatedFactorAptoName: { [key: string]: string } = {
  area_factor: 'fator de área construída',
  constructive_pattern_factor: 'fator de padrão construtivo',
  age_factor: 'fator de idade estimada',
  preservation_status_factor: 'fator de estado de conservação',
  ibge_income_factor: 'fator de setor censitário',
  parking_spaces_factor: 'fator de vagas de garagem',
};

export const presentAggregatedFactorData = (
  data: AggregatedFactors
): TableValues[] => {
  return [
    {
      label: '1',
      value: `R$ ${formatFloatNumberDecimal(data.mean_ppsm)}`,
    },

    {
      label: '2',
      value: `R$ ${formatFloatNumberDecimal(data.std_ppsm)}`,
    },

    {
      label: '3',
      value: `${formatFloatNumberDecimal(data.coeff * 100)} %`,
    },
    {
      label: '4',
      value: data.coeff_validation ? 'verdadeiro' : 'falso',
    },
    {
      label: '5',
      value: data.coeff_utilized ? 'verdadeiro' : 'falso',
    },
  ];
};

export const presentFundamentationHeader = [
  {
    label: 'item',
  },
  {
    label: 'descrição',
  },
  {
    label: 'grau III',
  },
  {
    label: 'grau II',
  },
  {
    label: 'grau I',
  },
  {
    label: 'pontos',
  },
];

export const presentFundamentationData = (
  data: AvmFactorsData
): FundamentationValues => {
  return {
    data: [
      {
        item: 1,
        description: 'Caracterização do imóvel avaliando',
        third: 'Completa quanto a todos os fatores analisados',
        second: 'Completa quanto aos fatores utilizados no tratamento',
        first: 'Adoção de situação paradigma',
        point: data.scores.items[0].points,
      },
      {
        item: 2,
        description:
          'Quantidade mínima de dados de mercado, efetivamente utilizados',
        third: '12',
        second: '5',
        first: '3',
        point: data.scores.items[1].points,
      },
      {
        item: 3,
        description: 'Identificação dos dados de mercado',
        third:
          'Apresentação de informações relativas a todas as características dos dados analisados, com foto e características observadas pelo autor do laudo',
        second:
          'Apresentação de informações relativas a todas as características dos dados analisados',
        first:
          'Apresentação de informações relativas a todas as características dos dados correspondentes aos fatores utilizados',
        point: data.scores.items[2].points,
      },
      {
        item: 4,
        description:
          'Intervalo admissível de ajuste para o conjunto de fatores',
        third: '0,80 a 1,25',
        second: '0,50 a 2,00',
        first: '0,40 a 2,50ª',
        point: data.scores.items[4].points,
      },
    ],
    total_score:
      data.scores.items[0].points +
      data.scores.items[1].points +
      data.scores.items[2].points +
      data.scores.items[4].points,
  };
};

export const presentFundamentationTotal = (): TableValues[][] => {
  return [
    [
      {
        label: '1',
        value: 'graus',
      },
      {
        label: '2',
        value: 'III',
      },
      {
        label: '3',
        value: 'II',
      },
      {
        label: '4',
        value: 'I',
      },
    ],
    [
      {
        label: '1',
        value: 'pontos mínimos',
      },
      {
        label: '2',
        value: '10',
      },
      {
        label: '3',
        value: '6',
      },
      {
        label: '4',
        value: '4',
      },
    ],
    [
      {
        label: '1',
        value:
          'itens obrigatórios e apresentação do laudo na modalidade completo',
      },
      {
        label: '2',
        value: 'itens 2 e 4 no Grau III, com os demais no mínimo no Grau II ',
      },
      {
        label: '3',
        value:
          'itens 2 e 4 no mínimo no Grau II e os demais no mínimo no Grau I',
      },
      {
        label: '4',
        value: 'todos, no mínimo no Grau I',
      },
    ],
  ];
};

export const presentSanitizedData = (
  data: AggHomogenizationData
): TableValues[] => {
  return [
    {
      label: 'elementos saneados',
      value: data.sanitized_elements,
    },
    {
      label: 'média saneada',
      value: `R$ ${formatFloatNumberDecimal(data.sanitized_mean)}`,
    },
    {
      label: 'desvio padrão',
      value: `R$ ${formatFloatNumberDecimal(data.sanitized_std)}`,
    },
    {
      label: 'limite inferior (-30%)',
      value: `R$ ${formatFloatNumberDecimal(data.sanitized_lower_mean)}`,
    },
    {
      label: 'limite superior (+30%)',
      value: `R$ ${formatFloatNumberDecimal(data.sanitized_upper_mean)}`,
    },
    {
      label: 't de student',
      value: formatFloatNumberDecimal(data.t_student),
    },
    {
      label: 'limite máximo',
      value: `R$ ${formatFloatNumberDecimal(data.upper_limit)}`,
    },
    {
      label: 'limite mínimo',
      value: `R$ ${formatFloatNumberDecimal(data.lower_limit)}`,
    },
    {
      label: 'intervalo de confiabilidade',
      value: `${convertPercentage(data.confidence_interval)} %`,
    },
    {
      label: 'nível de precisão',
      value: data.precision_level,
    },
  ];
};

export const presentBlendingData = (
  data: AggHomogenizationData
): TableValues[] => {
  return [
    {
      label: 'elementos homogeneizados',
      value: data.homogenized_elements,
    },
    {
      label: 'média homogeneizada',
      value: `R$ ${formatFloatNumberDecimal(data.homogenized_mean)}`,
    },
    {
      label: 'limite inferior (-30%)',
      value: `R$ ${formatFloatNumberDecimal(data.homogenized_lower_mean)}`,
    },
    {
      label: 'limite superior (+30%)',
      value: `R$ ${formatFloatNumberDecimal(data.homogenized_upper_mean)}`,
    },
  ];
};

export const presentValueData = (
  data: AggHomogenizationData,
  propertyIsHouse: boolean
): TableValues[] => {
  return [
    {
      label: propertyIsHouse ? 'área construída' : 'área privativa',
      value: formatFloatNumberDecimal(data.area),
    },
    {
      label: 'valor de mercado',
      value: `R$ ${formatFloatNumberDecimal(data.market_value)}`,
    },
    {
      label: 'valor de mercado com arredondamento de +1%',
      value: `R$ ${formatFloatNumberDecimal(
        data.market_value_rounded_1pct_plus
      )}`,
    },
    {
      label: 'valor de mercado com arredondamento de -1%',
      value: `R$ ${formatFloatNumberDecimal(
        data.market_value_rounded_1pct_minus
      )}`,
    },
    {
      label: 'valor de mercado arredondado',
      value: `R$ ${formatFloatNumberDecimal(data.rounded_market_value)}`,
    },
  ];
};
