import { Box, Divider, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

export const InputBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#F5F5F4',
  boxShadow: '0px 1px 2px #61335429',
  padding: '90px clamp(80px, 12vw, 230px) 0',
  width: '70%',
  maxWidth: 1300,
}));

export const ButtonBox = styled(Box)(() => ({
  margin: '62px 0',
}));

export const StyledDivider = styled(Divider)(() => ({
  margin: '40px 0',
  width: '100%',
}));

export const Terms = styled(Typography)(() => ({
  width: '100%',
}));

export const ContainerSubdomainInput = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 6,
  width: '50%',
  marginBottom: 12,
}));

export const ExampleURLProto = styled('span')(() => ({
  color: '#914FB2',
  fontWeight: 'bold',
}));

export const SpanInfo = styled('span')(() => ({
  color: '#B038FA',
  marginTop: 16,
}));

export const StyledTextField = styled(TextField)(() => ({
  width: '100%',
  '& .MuiInputBase-root': { borderRadius: '16px' },
  'input:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0px 1000px #F5F5F4 inset',
  },
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  margin: '0 2px',
  color: theme.palette.primary.main,
  fontWeight: '600',
  textDecoration: 'none',
}));
