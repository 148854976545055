/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from '@mui/material';
import { driver } from 'driver.js';
import {
  ChangeEvent,
  DragEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { pathologyAPI, pictureAPI, workOrderAPI } from '../../../api';
import { Status, StatusCode, StepTour } from '../../../api/enumerations';
import { AllPathologiesData } from '../../../api/pathologies/types';
import { PictureData } from '../../../api/pictures/types';
import { MultilineTextField } from '../../../components/CustomInput';
import { Loading } from '../../../components/Loading';
import { Title } from '../../../components/Sections/Title';
import { BoxContainer, SubmitBox } from '../../../components/UI/Box';
import { BackButton } from '../../../components/UI/Button';
import { GridContainer } from '../../../components/UI/Grid';
import { SectionTitle } from '../../../components/UI/Typography';
import {
  IconAddPhotoAlternateMS,
  IconArrowBackMS,
  IconArrowCircleLeftMS,
  IconArrowForwardMS,
  IconAssignmentMS,
  IconDesignServicesMS,
  IconDoorFrontMS,
  IconDownloadMS,
} from '../../../constants/icons';
import { Constants } from '../../../constants/imageDetails';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage } from '../../../helpers';
import { driverConfig } from '../../../helpers/driver/config';
import { inspectionImageDetailsSteps } from '../../../helpers/driver/steps';
import useGeneral from '../../../hooks/useGeneral';
import { useTour } from '../../../hooks/useTour';
import useUploadFile from '../../../hooks/useUploadFile';
import DeletePictureDialog from '../DeletePictureDialog';
import Pathologies from './Pathologies';
import {
  DownloadButton,
  FlexEndBox,
  FlexReverseBox,
  GalleryContainer,
  ImageCard,
  InsideBox,
  PhotoContainer,
  PicBackButton,
  PicForwardButton,
  SectionBox,
  SectionContainer,
  SectionSubtitle,
  StyledCardMedia,
  SubmitButton,
  Upload,
  UploadIcon,
  UploadSubtitle,
  UploadText,
} from './styles';

export default function ImageDetails(): JSX.Element {
  const [edit, setEdit] = useState(false);
  const [roomName, setRoomName] = useState('');
  const [observation, setObservation] = useState('');
  const [isPictureNew, setIsPictureNew] = useState(false);
  const [newPathologyId, setNewPathologyId] = useState<number>();
  const [pictureData, setPictureData] = useState<PictureData>();
  const [pathologiesData, setPathologiesData] = useState<AllPathologiesData[]>(
    []
  );
  const [page, setPage] = useState(1);
  const [lastCalledPage, setLastCalledPage] = useState(0);

  const [loading, setLoading] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { open, handleClose, handleOpen, osId, params, checkPathname } =
    useGeneral();
  const { dragActive, handleDrag, handleDrop, handleFileUpload } =
    useUploadFile();
  const navigate = useNavigate();

  const newImage = checkPathname('new');
  const inspectionId = parseInt(params.inspection as string, 10);
  const roomId = parseInt(params.room as string, 10);
  const pictureId = parseInt(params.imageId as string, 10);
  const picturesPerPage = 11;

  const { handleDownloadFile } = useGeneral();

  const {
    tourCompletion,
    setTourCompletion,
    setTutorialStep,
    setTourSelection,
    setToBeContinued,
  } = useTour();

  const navigateBack = (): void => {
    navigate(
      `/home/property/${osId}/inspection/${inspectionId}/room/${roomId}/gallery`
    );
  };

  const resetData = (): void => {
    setObservation('');
    setPathologiesData([]);
    setLastCalledPage(0);
    setPage(1);
  };

  const navigatePreviousImage = (): void => {
    navigate(
      `/home/property/${osId}/inspection/${inspectionId}/room/${roomId}/image-details/${pictureData?.next_id}`
    );
    resetData();
  };

  const navigateNextImage = (): void => {
    navigate(
      `/home/property/${osId}/inspection/${inspectionId}/room/${roomId}/image-details/${pictureData?.previous_id}`
    );
    resetData();
  };

  const getDataCallback = useCallback(async () => {
    if (page === lastCalledPage) {
      return;
    }

    if (page === 1) {
      try {
        const response = await pictureAPI.getPicture(pictureId);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setPictureData(response.data);
        setRoomName(response.data.room.name);

        if (response.data.observations) {
          setObservation(response.data.observations);
        }
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }

      try {
        const response = await workOrderAPI.getWorkOrder(osId);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        if (
          response.data.status === Status.ENTRANCE ||
          response.data.status === Status.PEPT ||
          response.data.status === Status.SCHEDULE
        ) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        if (response.data.status === Status.INSPECTION) {
          setEdit(true);
        }
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
        navigate('/home');
      }
    }

    try {
      const response = await pathologyAPI.getAllPathologies(
        pictureId,
        page,
        picturesPerPage
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setPathologiesData([...pathologiesData, ...response.data]);
      setLastCalledPage(page);

      if (response.detail.total_pages && response.detail.total_pages > page) {
        setPage(page + 1);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [page, pictureId]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback, page, pictureId]);

  const openPathologyModal = (pictureId: number): void => {
    setNewPathologyId(pictureId);
    handleOpen();
  };

  const handleSubmitPhotos = async (files: FileList): Promise<void> => {
    setLoading(true);
    const formData = new FormData();
    Array.from(files).map((file) =>
      formData.append('pathology_pictures', file)
    );
    formData.append('picture_id', pictureId.toString());

    try {
      const response = await pathologyAPI.addPathology(formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Fotos adicionadas com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      setIsPictureNew(true);
      setLoading(false);
      setNewPathologyId(response.data.first_id);
      handleOpen();
    } catch (error) {
      setLoading(false);
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const dropFile = (e: DragEvent<HTMLDivElement>): void => {
    const files = handleDrop(e);
    if (files) {
      handleSubmitPhotos(files);
    }
  };

  const uploadFile = (e: ChangeEvent<HTMLInputElement>): void => {
    const files = handleFileUpload(e);
    if (files) {
      handleSubmitPhotos(files);
    }
  };

  const handleDeletePhoto = async (pictureId: number): Promise<void> => {
    try {
      const response = await pictureAPI.deletePicture(pictureId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Foto e patologias deletadas!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      navigate(
        `/home/property/${osId}/inspection/${inspectionId}/room/${roomId}/gallery`
      );
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleOpenNextModal = async (pictureId: number): Promise<void> => {
    handleOpen();
    setNewPathologyId(pictureId);
    // setTimeout(() => {}, 2000);
  };

  const handleDeletePathology = async (pathologyId: number): Promise<void> => {
    try {
      const response = await pathologyAPI.deletePathology(pathologyId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      const filteredPathologies = pathologiesData.filter((element) => {
        return element.id !== pathologyId;
      });

      setPathologiesData(filteredPathologies);

      setSnackbarMessage('Patologia deletada!');
      setErrorMessage(false);
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleSubmit = async (): Promise<void> => {
    const formData = new FormData();
    formData.append('observations', observation);

    try {
      const response = await pictureAPI.updatePicture(pictureId, formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Edições realizadas com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      if (checkPathname('new') && pictureData?.next_id) {
        resetData();
        navigate(
          // eslint-disable-next-line max-len
          `/home/property/${osId}/inspection/${inspectionId}/room/${roomId}/image-details/new/${pictureData.next_id}`
        );
      } else {
        navigateBack();
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const imageDetailsDriverObj = driver({
    ...driverConfig,
    steps: inspectionImageDetailsSteps,
    onPrevClick: () => {
      setTourCompletion((prev) => {
        const newTourCompletion = { ...prev };
        const inspectionIndex = newTourCompletion.inspection.findIndex(
          (item) => item.stage === 'inspectionRoomsImageDetails'
        );
        if (inspectionIndex !== -1) {
          const updatedInspectionIndex = {
            ...newTourCompletion.inspection[inspectionIndex],
          };
          updatedInspectionIndex.complete = true;
          newTourCompletion.inspection[inspectionIndex] =
            updatedInspectionIndex;
        }
        return newTourCompletion;
      });
      imageDetailsDriverObj.movePrevious();
    },
    onNextClick: () => {
      setTourCompletion((prev) => {
        const newTourCompletion = { ...prev };
        const inspectionIndex = newTourCompletion.inspection.findIndex(
          (item) => item.stage === 'inspectionRoomsImageDetails'
        );
        if (inspectionIndex !== -1) {
          const updatedInspectionIndex = {
            ...newTourCompletion.inspection[inspectionIndex],
          };
          updatedInspectionIndex.complete = true;
          newTourCompletion.inspection[inspectionIndex] =
            updatedInspectionIndex;
        }
        return newTourCompletion;
      });
      imageDetailsDriverObj.moveNext();
    },
    onCloseClick: () => {
      setTourSelection(false);
      setToBeContinued(false);
      imageDetailsDriverObj.destroy();
    },
    onDestroyStarted: () => {
      setTourSelection(false);
      setToBeContinued(false);
      imageDetailsDriverObj?.destroy();
    },
  });

  useEffect(() => {
    setTutorialStep(StepTour.INSPECTIONSTART);

    if (
      !tourCompletion.inspection[3].complete &&
      !tourCompletion.skipTour.inspection &&
      edit
    ) {
      imageDetailsDriverObj.drive();
    }
  }, [tourCompletion, edit]);

  return (
    <GridContainer>
      <BackButton onClick={navigateBack}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer>
        <Title title={roomName} icon={IconDoorFrontMS} />
        <SectionTitle>
          {IconAssignmentMS}
          {Constants.imageDetails}
        </SectionTitle>
        <SectionContainer>
          <SectionBox>
            {pictureData && (
              <>
                {!newImage && (
                  <>
                    <PicBackButton
                      disabled={!pictureData.next_id}
                      onClick={() => {
                        navigatePreviousImage();
                      }}
                    >
                      {IconArrowBackMS}
                    </PicBackButton>
                    <FlexEndBox>
                      <PicForwardButton
                        disabled={!pictureData.previous_id}
                        onClick={() => navigateNextImage()}
                      >
                        {IconArrowForwardMS}
                      </PicForwardButton>
                    </FlexEndBox>
                  </>
                )}
                <PhotoContainer>
                  <ImageCard
                    sx={{ marginTop: newImage ? '32px' : '' }}
                    image={pictureData.file}
                    title={pictureData.name || ''}
                  />
                </PhotoContainer>
                <FlexEndBox>
                  <DownloadButton
                    onClick={() =>
                      handleDownloadFile(
                        pictureData.file,
                        pictureData.name || 'imagem'
                      )
                    }
                  >
                    {IconDownloadMS}
                    {Constants.download}
                  </DownloadButton>
                </FlexEndBox>
              </>
            )}
            {edit ? (
              <div id="observation-desc">
                <MultilineTextField
                  id="observation"
                  label="observações"
                  value={observation}
                  setValue={setObservation}
                />
              </div>
            ) : (
              <Typography>Observações: {observation}</Typography>
            )}
            <SectionSubtitle>
              {IconDesignServicesMS}
              {Constants.pathologies}
            </SectionSubtitle>
            {edit ? (
              <Typography>{Constants.pathologiesText}</Typography>
            ) : (
              pathologiesData.length === 0 && (
                <Typography>{Constants.noPathologies}</Typography>
              )
            )}
            <GalleryContainer id="pathologies">
              {edit && (
                <Upload
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={dropFile}
                >
                  <label htmlFor="uploadPathologies">
                    <input
                      accept="image/*"
                      multiple
                      id="uploadPathologies"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        uploadFile(e)
                      }
                    />
                    {!dragActive ? (
                      <InsideBox>
                        <UploadIcon>{IconAddPhotoAlternateMS}</UploadIcon>
                        <UploadText>{Constants.uploadText}</UploadText>
                        <UploadSubtitle>{Constants.fileType}</UploadSubtitle>
                      </InsideBox>
                    ) : (
                      <UploadText>{Constants.dragText}</UploadText>
                    )}
                  </label>
                </Upload>
              )}
              {pathologiesData?.map((photo) => (
                <PhotoContainer key={photo.id}>
                  {edit && (
                    <FlexReverseBox>
                      <DeletePictureDialog
                        outlinedButton={false}
                        modalCallback={handleDeletePathology}
                        pictureId={photo.id}
                        text={Constants.thisPathology}
                      />
                    </FlexReverseBox>
                  )}
                  <StyledCardMedia
                    image={photo.file}
                    title="patologias"
                    onClick={() => openPathologyModal(photo.id)}
                  />
                </PhotoContainer>
              ))}
            </GalleryContainer>
          </SectionBox>
        </SectionContainer>
        <SubmitBox>
          {pictureData && edit && (
            <DeletePictureDialog
              id="delete"
              outlinedButton
              modalCallback={handleDeletePhoto}
              pictureId={pictureData.id}
              text={Constants.photoAndPathologies}
            />
          )}
          {edit && (
            <SubmitButton id="confirm-btn" onClick={handleSubmit}>
              {Constants.submitImage}
            </SubmitButton>
          )}
        </SubmitBox>
        {open && newPathologyId && (
          <Pathologies
            isOpen={open}
            setOpenModal={handleOpen}
            setCloseModal={handleClose}
            pathologyId={newPathologyId}
            isPictureNew={isPictureNew}
            nextModalCallback={handleOpenNextModal}
            pictureId={pictureId}
            setPathologies={setPathologiesData}
            edit={edit}
          />
        )}
      </BoxContainer>
      <Loading open={loading} onClose={() => setLoading(false)} />
    </GridContainer>
  );
}
