import { InputAdornment } from '@mui/material';

import { IconSearchMS } from '../../constants/icons';
import { StyledInputBase } from './styles';

interface PipelineSearchProps {
  setSearchField: (value: string) => void;
}

export function PipelineSearch({
  setSearchField,
}: PipelineSearchProps): JSX.Element {
  return (
    <StyledInputBase
      placeholder="Pesquisar"
      inputProps={{ 'aria-label': 'pesquisar', maxLength: 11 }}
      type="search"
      startAdornment={
        <InputAdornment position="start" sx={{ color: '#B038FA' }}>
          {IconSearchMS}
        </InputAdornment>
      }
      onChange={(e) => {
        if (e.target.value.length > 0) {
          setSearchField(e.target.value);
        }
        if (e.target.value.length === 0) {
          setSearchField('');
        }
      }}
      onFocus={(e) => {
        e.target.value = '';
        setSearchField('');
      }}
    />
  );
}
