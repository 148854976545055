import { TextField } from '@mui/material';
import { PatternFormat } from 'react-number-format';

import { EmailIcon } from '../../components/InputIcon';
import LoginLayout from '../../components/LoginLayout';
import { IconRefreshMS } from '../../constants/icons';
import { Constants } from '../../constants/recoverPassword';
import { validateEmail } from '../../helpers';
import ChangePassword from './ChangePassword';
import useRecoverPassword from './hooks';
import {
  ButtonBox,
  FilledButton,
  InputBox,
  InputTitle,
  OutlinedButton,
  StyledButtonBase,
  StyledPatternFormat,
  StyledTextField,
  SubmitButton,
  TimerTypography,
} from './styles';

export default function RecoverPassword(): JSX.Element {
  const {
    handleSendEmail,
    handleRefreshToken,
    handleSendToken,
    handleClose,
    email,
    setEmail,
    emailError,
    setEmailError,
    hasToken,
    emailToken,
    setEmailToken,
    disableNewToken,
    tempToken,
    seconds,
    openModal,
    setOpenModal,
  } = useRecoverPassword();

  return (
    <LoginLayout>
      <>
        {hasToken ? (
          <InputBox component="form" id="token" onSubmit={handleSendToken}>
            <InputTitle>{Constants.recoverPassword}</InputTitle>
            <PatternFormat
              required
              format="### ###"
              mask="&#8212;"
              customInput={TextField}
              variant="standard"
              id="token-input"
              helperText="insira o código de 6 dígitos"
              color="secondary"
              value={emailToken}
              onChange={(e) => setEmailToken(e.target.value)}
              sx={StyledPatternFormat}
            />
            <TimerTypography>
              {Constants.timer}
              <br />
              {seconds}
              {Constants.seconds}
            </TimerTypography>
            <StyledButtonBase
              disableTouchRipple
              disabled={disableNewToken}
              onClick={handleRefreshToken}
            >
              {IconRefreshMS}
              <span>{Constants.newToken}</span>
            </StyledButtonBase>
            <SubmitButton form="token" type="submit" disabled={emailError}>
              {Constants.check}
            </SubmitButton>
          </InputBox>
        ) : (
          <InputBox
            component="form"
            id="email-verification"
            onSubmit={handleSendEmail}
          >
            <InputTitle>{Constants.recoverPassword}</InputTitle>
            <StyledTextField
              required
              id="email"
              variant="standard"
              InputProps={EmailIcon}
              placeholder={Constants.emailPlaceholder}
              error={emailError}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                !validateEmail(e.target.value)
                  ? setEmailError(true)
                  : setEmailError(false);
              }}
            />
            <ButtonBox>
              <OutlinedButton onClick={handleClose}>
                {Constants.cancel}
              </OutlinedButton>
              <FilledButton
                form="email-verification"
                type="submit"
                disabled={emailError}
              >
                {Constants.check}
              </FilledButton>
            </ButtonBox>
          </InputBox>
        )}
        {openModal && (
          <ChangePassword
            isOpen={openModal}
            setOpenModal={setOpenModal}
            tempToken={tempToken}
          />
        )}
      </>
    </LoginLayout>
  );
}
