import {
  Box,
  Button,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: '28px',
  fontWeight: '600',
  textTransform: 'uppercase',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  '& span': { fontSize: '26px', color: theme.palette.primary.main },
}));

export const PlanContainer = styled(Box)(() => ({
  margin: '40px 80px',
}));

export const BoldTypography = styled(Typography)(() => ({
  fontSize: '21px',
  fontWeight: '600',
  margin: '10px 0',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#914FB2',
  borderRadius: '0px',
  minWidth: '160px',
  width: '100%',
  height: '54px',
  fontSize: '18px',
  fontWeight: '600',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export const CouponTextfield = styled(TextField)(() => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#914FB2',
    },
  },
  '& .MuiInputBase-input': {
    padding: '16px 14px',
  },
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    margin: '30px 0 40px 0',
    height: '56px',
    borderColor: theme.palette.primary.dark,
    borderRadius: '60px',
    backgroundColor: theme.palette.primary.dark,
    '& :hover': {
      color: theme.palette.primary.dark,
    },
    '& .MuiButtonBase-root.MuiToggleButton-root': {
      color: '#FAFCFF',
      '&.Mui-selected': {
        color: theme.palette.primary.dark,
        backgroundColor: '#FAFCFF',
        border: '2px solid',
        borderRadius: '60px',
      },
    },
  })
);

export const StyledToggleButton = styled(ToggleButton)(() => ({
  textTransform: 'capitalize',
  width: '140px',
  fontSize: '28px',
  borderRadius: '60px',
}));
