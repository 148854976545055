/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  TextField,
} from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { companyAPI } from '../../../api';
import { RegisterStage, StatusCode } from '../../../api/enumerations';
import {
  AlphaNumericTextfield,
  CustomPatternFormat,
  CustomTextField,
  SelectTextField,
} from '../../../components/CustomInput';
import { StandardButton } from '../../../components/UI/Button';
import { Constants } from '../../../constants/createAccount';
import { selectRegistrationUf } from '../../../constants/selectOptions';
import { GlobalContext } from '../../../context/global';
import { RegistrationIdentificationContext } from '../../../context/registrationIdentification';
import {
  formatLicenseDocument,
  getErrorMessage,
  getRegistrationUfPosition,
  handleNavigate,
  validatePassword,
  validateUserName,
} from '../../../helpers';
import useSearchCep from '../../../hooks/useSearchCep';
import PasswordStrength from './PasswordStrength';
import {
  ButtonBox,
  ContainerSubdomainInput,
  ExampleURLProto,
  InputBox,
  SpanInfo,
  StyledDivider,
  StyledLink,
  StyledTextField,
  Terms,
} from './styles';
import Toggle from './Toggle';

interface RegisterFormProps {
  handleNextStage: (stage: RegisterStage) => void;
  verifiedCep: string;
}

export default function RegisterForm({
  handleNextStage,
  verifiedCep,
}: RegisterFormProps): JSX.Element {
  const [firstToggle, setFirstToggle] = useState('CNPJ');
  const [secondToggle, setSecondToggle] = useState('CAU');
  const [acceptTerms, setAcceptTerms] = useState(false);

  const [company, setCompany] = useState('');
  const [subdomain, setSubdomain] = useState('');
  const [responsible, setResponsible] = useState('');
  const [cpf, setCpf] = useState('');
  const [cnpj, setCnpj] = useState('');

  const [telephone, setTelephone] = useState('');
  const [licenseDocument, setLicenseDocument] = useState('');
  const [password, setPassword] = useState('');

  const [address, setAddress] = useState('');
  const [addressNumber, setAddressNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [district, setDistrict] = useState('');
  const [uf, setUf] = useState(0);
  const [city, setCity] = useState('');

  const [repeatPassword, setRepeatPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [repeatPasswordError, setRepeatPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { identificationEmail, setPhoneNumber } = useContext(
    RegistrationIdentificationContext
  );
  const { handleSearchCep } = useSearchCep();

  const handleCep = useCallback(
    async (cep: string) => {
      const addressData = await handleSearchCep(cep);
      if (addressData) {
        setUf(getRegistrationUfPosition(addressData.uf));
        setCity(addressData.city);
        setDistrict(addressData.district);
        setAddress(addressData.address);
      }
    },
    [handleSearchCep]
  );

  useEffect(() => {
    handleCep(verifiedCep);
  }, [verifiedCep]);

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setLoading(true);

      if (!validateUserName(responsible)) {
        setSnackbarMessage('Nome inválido');
        setErrorMessage(true);
        setOpenSnackbar(true);
        setLoading(false);
        return;
      }

      if (uf === 0) {
        setSnackbarMessage('Os dados de seleção são obrigatórios');
        setErrorMessage(true);
        setOpenSnackbar(true);
        setLoading(false);
        return;
      }

      if (
        passwordError ||
        repeatPasswordError ||
        password.length === 0 ||
        repeatPassword.length === 0
      ) {
        setSnackbarMessage('Digite uma senha válida');
        setErrorMessage(true);
        setOpenSnackbar(true);
        setLoading(false);
        return;
      }

      if (password !== repeatPassword) {
        setSnackbarMessage('As senhas não coincidem');
        setErrorMessage(true);
        setOpenSnackbar(true);
        setLoading(false);
        return;
      }

      const companyData = {
        document_type: firstToggle === Constants.cpf ? 0 : 1,
        document: firstToggle === Constants.cpf ? cpf : cnpj,
        subdomain,
        name: company,
        email: identificationEmail,
        phone_number: telephone,
        license_type: secondToggle === Constants.crea ? 0 : 1,
        license_document: formatLicenseDocument(licenseDocument),
        zipcode: verifiedCep,
        address,
        address_number: addressNumber,
        address_complement: complement,
        address_district: district,
        uf,
        city,
        responsible_person: {
          name: responsible,
          email: identificationEmail,
          document: cpf,
          password,
          password_confirmation: repeatPassword,
        },
      };

      try {
        const { detail } = await companyAPI.createCompany(companyData);

        if (detail.description) {
          throw new Error(detail.description);
        }

        if (detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }
        setPhoneNumber(telephone);
        setLoading(false);
        handleNextStage(RegisterStage.AUTHENTICATION);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setOpenSnackbar(true);
        setErrorMessage(true);
        setLoading(false);
      }
    },
    [
      responsible,
      uf,
      passwordError,
      repeatPasswordError,
      password,
      repeatPassword,
      firstToggle,
      cnpj,
      subdomain,
      company,
      identificationEmail,
      telephone,
      secondToggle,
      licenseDocument,
      verifiedCep,
      address,
      addressNumber,
      complement,
      district,
      city,
      cpf,
      setSnackbarMessage,
      setErrorMessage,
      setOpenSnackbar,
      setPhoneNumber,
      handleNextStage,
    ]
  );

  return (
    <InputBox component="form" id="register-user" onSubmit={handleSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={3} lg={2.5}>
          <Toggle
            value={firstToggle}
            setValue={setFirstToggle}
            options={[Constants.cnpj, Constants.cpf]}
          />
        </Grid>
        <Grid item xs={4} lg={3.5}>
          {firstToggle === Constants.cnpj ? (
            <CustomPatternFormat
              required
              minLength={14}
              id="cnpj"
              label={Constants.cnpj}
              value={cnpj}
              setValue={setCnpj}
              format="##.###.###/####-##"
            />
          ) : (
            <CustomPatternFormat
              required
              minLength={11}
              id="cpf"
              label={Constants.cpf}
              value={cpf}
              setValue={setCpf}
              format="###.###.###-##"
            />
          )}
        </Grid>
        <Grid item xs={5} lg={6}>
          <TextField
            id="company"
            label={
              firstToggle === Constants.cpf
                ? Constants.fullName
                : Constants.company
            }
            required
            onInvalid={(event: ChangeEvent<HTMLInputElement>) =>
              event.target.setCustomValidity('Preencha este campo')
            }
            onInput={(event: ChangeEvent<HTMLInputElement>) =>
              event.target.setCustomValidity('')
            }
            color="secondary"
            value={company}
            onChange={(e) => {
              setCompany(e.target.value);
              if (firstToggle === Constants.cpf) setResponsible(e.target.value);
            }}
            sx={{
              width: '100%',
              '& .MuiInputBase-root': { borderRadius: '16px' },
              'input:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0px 1000px #F5F5F4 inset',
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ContainerSubdomainInput>
            <CustomTextField
              id="subdomain"
              label="subdomínio"
              value={subdomain}
              setValue={setSubdomain}
              placeholder="exemplo"
            />
            <ExampleURLProto>.protoai.com.br</ExampleURLProto>
          </ContainerSubdomainInput>
          <SpanInfo>{Constants.subdomain}</SpanInfo>
        </Grid>
      </Grid>
      <StyledDivider />
      <Grid container spacing={4}>
        <Grid item xs={8.5}>
          <CustomTextField
            required
            id="responsible"
            label={Constants.responsible}
            value={responsible}
            setValue={setResponsible}
          />
        </Grid>
        <Grid item xs={3.5}>
          <CustomPatternFormat
            required
            minLength={11}
            id="cpf"
            label={Constants.cpf}
            value={cpf}
            setValue={setCpf}
            format="###.###.###-##"
          />
        </Grid>
        <Grid item xs={8.5}>
          <CustomTextField
            required
            disabled
            id="main-email"
            label={Constants.email}
            value={identificationEmail}
          />
        </Grid>
        <Grid item xs={3.5}>
          <CustomPatternFormat
            required
            minLength={11}
            id="telephone"
            label={Constants.telephone}
            value={telephone}
            setValue={setTelephone}
            format="(##) #####-####"
          />
        </Grid>
        <Grid item xs={3} lg={2.5}>
          <Toggle
            value={secondToggle}
            setValue={setSecondToggle}
            setLicense={setLicenseDocument}
            options={[Constants.cau, Constants.crea]}
          />
        </Grid>
        <Grid item xs={4} lg={3.5}>
          {secondToggle === Constants.cau ? (
            <AlphaNumericTextfield
              required
              maxLength={7}
              id="cau"
              label={Constants.cau}
              value={licenseDocument}
              setValue={setLicenseDocument}
              cauFormat
            />
          ) : (
            <AlphaNumericTextfield
              required
              maxLength={10}
              id="crea"
              label={Constants.crea}
              value={licenseDocument}
              setValue={setLicenseDocument}
            />
          )}
        </Grid>
        <Grid item xs={5} lg={6} />
      </Grid>
      <StyledDivider />
      <Grid container spacing={4}>
        <Grid item xs={3.5}>
          <CustomPatternFormat
            disabled
            required
            minLength={8}
            id="cep"
            label={Constants.cep}
            value={verifiedCep}
            format="#####-###"
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            required
            id="address"
            label="logradouro"
            value={address}
            setValue={setAddress}
          />
        </Grid>
        <Grid item xs={2.5}>
          <CustomTextField
            required
            id="address-number"
            label={Constants.number}
            value={addressNumber}
            setValue={setAddressNumber}
          />
        </Grid>
        <Grid item xs={3.5}>
          <CustomTextField
            id="complement"
            label={Constants.complement}
            value={complement}
            setValue={setComplement}
          />
        </Grid>
        <Grid item xs={3.5}>
          <CustomTextField
            required
            id="district"
            label={Constants.district}
            value={district}
            setValue={setDistrict}
          />
        </Grid>
        <Grid item xs={2}>
          <SelectTextField
            id="uf"
            label="UF"
            value={uf}
            setValue={setUf}
            selectOptions={selectRegistrationUf()}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            required
            id="city"
            label={Constants.city}
            value={city}
            setValue={setCity}
          />
        </Grid>
      </Grid>
      <StyledDivider />
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <StyledTextField
            required
            helperText={
              passwordError &&
              'mínimo de 8 caracteres com letra mínuscula, maiúscula e número'
            }
            id="password"
            type="password"
            label={Constants.password}
            error={passwordError && passwordError}
            value={password}
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autoComplete: 'off',
              },
            }}
            onChange={(e) => {
              setPassword(e.target.value);
              !validatePassword(e.target.value)
                ? setPasswordError(true)
                : setPasswordError(false);
              if (repeatPassword.length > 0) {
                e.target.value !== repeatPassword
                  ? setRepeatPasswordError(true)
                  : setRepeatPasswordError(false);
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <PasswordStrength password={password} />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={4}>
          <StyledTextField
            required
            helperText={repeatPasswordError && 'as senhas não coincidem'}
            id="repeat-password"
            type="password"
            label={Constants.repeatPassword}
            error={repeatPasswordError && repeatPasswordError}
            value={repeatPassword}
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autoComplete: 'off',
              },
            }}
            onChange={(e) => {
              setRepeatPassword(e.target.value);
              e.target.value !== password
                ? setRepeatPasswordError(true)
                : setRepeatPasswordError(false);
            }}
          />
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Checkbox
              required
              inputProps={{
                onInvalid: (event: ChangeEvent<HTMLInputElement>) =>
                  event.target.setCustomValidity(
                    'Por favor, marque esta caixa se deseja prosseguir'
                  ),
                onInput: (event: ChangeEvent<HTMLInputElement>) =>
                  event.target.setCustomValidity(''),
              }}
              defaultChecked
              id="accept-terms"
              onChange={() => setAcceptTerms(!acceptTerms)}
            />
            <Terms>
              {Constants.acceptTerms.split('*').map((part, index) => {
                if (index % 2 === 1) {
                  return (
                    <StyledLink
                      to={`${handleNavigate(part)}`}
                      key={part}
                      target="blank"
                    >
                      {part}
                    </StyledLink>
                  );
                }
                return <span key={part}>{part}</span>;
              })}
            </Terms>
          </Box>
        </Grid>
      </Grid>
      <ButtonBox>
        <StandardButton form="register-user" type="submit" disabled={loading}>
          {loading ? (
            <CircularProgress color="inherit" size={22} />
          ) : (
            Constants.confirm
          )}
        </StandardButton>
      </ButtonBox>
    </InputBox>
  );
}
