const key = 'verified-cep';

export const setVerifiedCep = (cep: string): void => {
  sessionStorage.setItem(key, JSON.stringify(cep));
};

export const getVerifiedCep = (): string | null => {
  const value = sessionStorage.getItem(key);
  if (value) {
    return JSON.parse(value);
  }
  return null;
};

export const removeVerifiedCep = (): void => {
  sessionStorage.removeItem(key);
};
