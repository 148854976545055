/* eslint-disable */
import { Grid } from '@mui/material';
import { useState } from 'react';

import { IconCloseMS } from '../../../constants/icons';
import { useTour } from '../../../hooks/useTour';
import {
  CloseButton,
  Container,
  PageName,
  StyledButton,
  StyledModal,
  SubText,
  Title,
} from '../../Modal/styles';

export function GuidedTour({ driverObj }: any): JSX.Element {
  const [isOpen, setIsOpen] = useState(true);

  const { setTourOn, setToBeContinued, setProgressOpen, setTutorialStep } =
    useTour();

  const handleClose = (): void => {
    setToBeContinued(false);
    setIsOpen(false);
  };

  const handleStartDrive = (): void => {
    // setToBeContinued(true);
    handleClose();
    setTimeout(() => {
      driverObj.drive();
    }, 300);
    setTourOn(false);
  };

  return (
    <StyledModal open={isOpen} onClose={handleClose}>
      <Container>
        <CloseButton onClick={handleClose}>{IconCloseMS}</CloseButton>
        <Grid container direction="column" alignItems="center" marginBottom={4}>
          <Grid item alignItems="center">
            <Title>Vamos dar início ao</Title>
          </Grid>
          <Grid item alignItems="center">
            <PageName>Tour guiado</PageName>
          </Grid>
        </Grid>
        <Grid container direction="column" alignItems="center" marginBottom={4}>
          <Grid item>
            <SubText>
              Aqui vamos mostrar as funções do nosso sistema e você poderá
              escolher o que quer ver, conforme sua prioridade ou preferência.
            </SubText>
          </Grid>
          <Grid item>
            <SubText>
              Você poderá optar por ver o tour completo ou por etapas (tour
              rápido), se não quiser ver tudo de uma vez, não se preocupe, ele
              estará sempre disponível para você.
            </SubText>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          marginBottom={4}
        >
          <StyledButton onClick={handleStartDrive}>começar!</StyledButton>
        </Grid>
      </Container>
    </StyledModal>
  );
}
