/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import { EventClickArg, EventContentArg } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayjs, { ManipulateType } from 'dayjs';
import { useContext, useEffect, useRef, useState } from 'react';

import { workOrderAPI } from '../../../api';
import { StatusCode } from '../../../api/enumerations';
import { CalendarScheduleData } from '../../../api/inspections/types';
import { UserData } from '../../../api/users/types';
import { WorkOrderData } from '../../../api/workOrders/types';
import { EventDialog } from '../../../components/Dialog/EventDialog';
import { Constants } from '../../../constants/agenda';
import { IconBlockMS } from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useGeneral from '../../../hooks/useGeneral';
import BlockAgendaDialog from '../BlockAgenda';
import { IEngineerData } from '../hooks';
import {
  CalendarBox,
  ColorDot,
  EventBlockage,
  EventBox,
  EventDisabledBox,
  LabelBox,
  StyledTooltip,
} from './styles';

export interface IEventData extends CalendarScheduleData {
  color: string;
}
interface ScheduleProps {
  id: string | undefined;
  groupId: string | undefined;
  title: string;
  start: string;
  end: string;
}

interface CalendarProps {
  view: string;
  month: number;
  setMonth: (month: number) => void;
  year: number;
  setYear: (year: number) => void;
  engineersData: IEngineerData[];
  callback: (
    startDate: string,
    endDate: string,
    engIds?: string
  ) => Promise<void>;
  engineersIdQuery: string;
  newDate: string;
  setNewDate: (value: string) => void;
  usersData: UserData[];
  updateEvents: boolean;
  setUpdateEvents: (value: boolean) => void;
}

export function Calendar({
  view,
  month,
  setMonth,
  year,
  setYear,
  engineersData,
  callback,
  engineersIdQuery,
  newDate,
  setNewDate,
  usersData,
  updateEvents,
  setUpdateEvents,
}: CalendarProps): JSX.Element {
  const [selectedEvent, setSelectedEvent] = useState<EventImpl | null>(null);
  const [events, setEvents] = useState<IEventData[]>([]);
  const [mainEvent, setMainEvent] = useState<IEventData>();
  const [filteredEvents, setFilteredEvents] = useState<ScheduleProps[]>([]);
  const [modalData, setModalData] = useState<WorkOrderData>();
  const [openBlockageDialog, setOpenBlockageDialog] = useState(false);

  const calendarRef = useRef<FullCalendar>(null);
  const today = new Date().toISOString().split('T');

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const dialog = useGeneral();
  const { getErrorMessage } = useErrorMessage();

  useEffect(() => {
    const updatedEvents: IEventData[] = [];
    engineersData.forEach((engineer) => {
      if (engineer.events.length > 0) {
        const eventsWithColor = engineer.events.map((event) => ({
          ...event,
          color: engineer.color,
        }));
        updatedEvents.push(...eventsWithColor);
      }
    });
    setEvents(updatedEvents);
  }, [engineersData]);

  useEffect(() => {
    const filteredInspections = events?.map((e) => {
      return {
        id: e.id.toString(),
        type: e.type,
        groupId: e.work_order?.id.toString(),
        title: `${e.user.name}`,
        userId: e.user.id,
        start: e.start_at,
        end: e.finish_at,
        borderColor: e.color,
        textColor: '#000',
        belongsToCompany: e.belongs_to_company,
        eventInfo: e,
      };
    });

    const myCalendar = calendarRef.current?.getApi();
    if (events?.length > 0) {
      myCalendar?.removeAllEvents();
      myCalendar?.addEventSource(filteredInspections);
      myCalendar?.gotoDate(newDate);
      setFilteredEvents(filteredInspections);
    } else {
      myCalendar?.removeAllEvents();
      myCalendar?.gotoDate(newDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  const renderEventContent = (eventInfo: EventContentArg): JSX.Element => {
    const eventStart = dayjs(eventInfo.event.start);
    const eventEnd = dayjs(eventInfo.event.end);

    let startAt = '';
    let finishAt = '';

    const isSameDay = eventStart.isSame(eventEnd, 'day');
    if (isSameDay) {
      startAt = eventStart.format('HH:mm');
      finishAt = eventEnd.format('HH:mm');
    } else {
      startAt = eventStart.format('DD/MM: HH:mm');
      finishAt = eventEnd.format('DD/MM: HH:mm');
    }

    return eventInfo.event._def.extendedProps.type === 'calendar-blockage' ? (
      <StyledTooltip
        title={`${startAt} - ${finishAt} ${eventInfo.event.title} `}
        placement="bottom"
      >
        <EventBlockage>
          <LabelBox color={eventInfo.event.borderColor}>
            {IconBlockMS}
            <p>
              {startAt} {isSameDay ? '-' : <br />} {finishAt}
            </p>
          </LabelBox>
          <p>{eventInfo.event.title}</p>
        </EventBlockage>
      </StyledTooltip>
    ) : eventInfo.event._def.extendedProps.belongsToCompany ? (
      <EventBox>
        <LabelBox color={eventInfo.event.borderColor}>
          <ColorDot color={eventInfo.event.borderColor} />
          <p>
            {startAt} - {finishAt}
          </p>
        </LabelBox>
        <p>{eventInfo.event.title}</p>
      </EventBox>
    ) : (
      <StyledTooltip
        title="Agendamento não pertence a esta empresa"
        placement="bottom"
      >
        <EventDisabledBox>
          <LabelBox color={eventInfo.event.borderColor}>
            <ColorDot color={eventInfo.event.borderColor} />
            <p>
              {startAt} - {finishAt}
            </p>
          </LabelBox>
          <p>{eventInfo.event.title}</p>
        </EventDisabledBox>
      </StyledTooltip>
    );
  };

  const handleModalData = async (id: string): Promise<void> => {
    const intId = Number(id);

    try {
      const response = await workOrderAPI.getWorkOrder(intId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data) {
        setModalData(response.data);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      dialog.handleClose();
    }
  };

  const handleEventClick = (clickInfo: EventClickArg): void => {
    if (clickInfo.event._def.extendedProps.type === 'calendar-blockage') {
      setMainEvent(clickInfo.event._def.extendedProps.eventInfo);
      setOpenBlockageDialog(true);
      return;
    }

    if (clickInfo.event.groupId !== undefined) {
      if (clickInfo.event._def.extendedProps.belongsToCompany) {
        handleModalData(clickInfo.event.groupId);
        setSelectedEvent(clickInfo.event);
        dialog.handleOpen();
      }
    }
  };

  useEffect(() => {
    const myCalendar = calendarRef?.current?.getApi();
    myCalendar?.gotoDate(newDate);
  }, [newDate]);

  useEffect(() => {
    calendarRef.current?.getApi().changeView(view);
  }, [view]);

  const handleTodayButton = (): void => {
    const currentDate = dayjs(today[0]);
    const getMonth = currentDate.month();
    const getYear = currentDate.year();
    setMonth(getMonth);
    setYear(getYear);
    setNewDate(today[0]);

    if (view === 'timeGridWeek' && getMonth === month && getYear === year) {
      const startDate = currentDate.startOf('week').format('YYYY-MM-DD');
      const endDate = currentDate.endOf('week').format('YYYY-MM-DD');
      callback(startDate, endDate, engineersIdQuery);
    }
  };

  const handlePrevNextButtons = (button: 'prev' | 'next'): void => {
    const timeUnits: {
      [key: string]: string;
    } = {
      timeGridDay: 'day',
      timeGridWeek: 'week',
      dayGridMonth: 'month',
    };
    if (!view || !timeUnits[view]) return;

    const newDateModifier = button === 'prev' ? -1 : 1;
    const modifiedDate = dayjs(newDate).add(
      newDateModifier,
      timeUnits[view] as ManipulateType
    );
    const day = modifiedDate.format('YYYY-MM-DD');
    setNewDate(day);

    const getMonth = modifiedDate.month();
    const getYear = modifiedDate.year();

    if (getMonth !== month || getYear !== year) {
      setMonth(getMonth);
      setYear(getYear);
    }

    if (view === 'timeGridWeek' && getMonth === month && getYear === year) {
      const startDate = modifiedDate.startOf('week').format('YYYY-MM-DD');
      const endDate = modifiedDate.endOf('week').format('YYYY-MM-DD');
      setNewDate(startDate);
      callback(startDate, endDate, engineersIdQuery);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <CalendarBox view={view} id="schedules">
        <FullCalendar
          scrollTime="08:00:00"
          scrollTimeReset={false}
          ref={calendarRef}
          allDaySlot={false}
          displayEventTime={false}
          plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
          selectMirror
          initialView="timeGridWeek"
          locale="pt-br"
          eventClick={handleEventClick}
          customButtons={{
            prev: {
              text: '',
              click: () => handlePrevNextButtons('prev'),
            },
            next: {
              text: '',
              click: () => handlePrevNextButtons('next'),
            },
            today: {
              text: 'hoje',
              hint: `${Constants.todayButtonHint}`,
              click: () => handleTodayButton(),
            },
          }}
          headerToolbar={{
            left: 'prev',
            center: 'title',
            right: 'today, next',
          }}
          titleFormat={{
            month: 'long',
          }}
          views={{
            dayGridMonth: {
              dayHeaderFormat: { weekday: 'long' },
            },
            timeGridWeek: {
              dayHeaderFormat: {
                weekday: 'long',
                day: '2-digit',
                month: '2-digit',
                omitCommas: true,
              },
            },
            timeGridDay: {
              dayHeaderFormat: {
                weekday: 'long',
                day: '2-digit',
                month: '2-digit',
              },
            },
          }}
          slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
          }}
          eventContent={renderEventContent}
          initialEvents={filteredEvents}
          buttonText={{ today: 'hoje' }}
          firstDay={0}
          dayMaxEvents={1}
          moreLinkClassNames="more-events"
          moreLinkContent={(args) => {
            if (args.num > 1) {
              return `+ ${args.num} eventos`;
            }
            return `+ ${args.num} evento`;
          }}
        />
      </CalendarBox>
      {selectedEvent && (
        <EventDialog
          selectedEvent={selectedEvent}
          modalData={modalData}
          open={dialog.open}
          handleClose={dialog.handleClose}
        />
      )}
      {openBlockageDialog && mainEvent && (
        <BlockAgendaDialog
          usersData={usersData}
          blockage={mainEvent}
          open={openBlockageDialog}
          setOpen={setOpenBlockageDialog}
          updateEvents={updateEvents}
          setUpdateEvents={setUpdateEvents}
        />
      )}
    </div>
  );
}
