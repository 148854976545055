/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useContext, useEffect, useState } from 'react';

import { workOrderAPI } from '../../../api';
import { EvaluationType, Status, StatusCode } from '../../../api/enumerations';
import { RoomData } from '../../../api/rooms/types';
import {
  QuestionFormData,
  QuestionResponseData,
  WorkOrderData,
} from '../../../api/workOrders/types';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage, validateFiles } from '../../../helpers';
import { useAttachment } from '../../../hooks/useAttachmentFiles';
import { useChangeStatus } from '../../../hooks/useChangeStatus';
import useGeneral from '../../../hooks/useGeneral';
import { usePropertyData } from '../../../hooks/usePropertyData';
import { useRooms } from '../../../hooks/useRooms';
import useSearchCep from '../../../hooks/useSearchCep';

type LatLngLiteral = google.maps.LatLngLiteral;

interface ICompleteSections {
  clientData: boolean;
  propertyAddress: boolean;
  propertyData: boolean;
  propertyImages: boolean;
  propertyCharacteristic: boolean;
  form: boolean;
}

interface InspectionHook {
  handleProgress: (questions: QuestionFormData[]) => void;
  handleSubmit: (e: React.FormEvent) => Promise<void>;
  verifyCep: (cep: string) => Promise<void>;
  handleFileUpload: (
    e: ChangeEvent<HTMLInputElement>,
    value: string
  ) => Promise<void>;
  address: string;
  setAddress: (value: string) => void;
  addressNumber: string;
  setAddressNumber: (value: string) => void;
  age: number;
  setAge: (value: number) => void;
  buildingStandard: number;
  setBuildingStandard: (value: number) => void;
  builtArea: number;
  setBuiltArea: (value: number) => void;
  cep: string;
  setCep: (value: string) => void;
  city: string;
  setCity: (value: string) => void;
  client: string;
  setClient: (value: string) => void;
  complement: string;
  setComplement: (value: string) => void;
  concept: number;
  setConcept: (value: number) => void;
  conservation: number;
  setConservation: (value: number) => void;
  district: string;
  setDistrict: (value: string) => void;
  goal: number;
  setGoal: (value: number) => void;
  judicialDistrict: string;
  setJudicialDistrict: (value: string) => void;
  parkingLots: number;
  setParkingLots: (value: number) => void;
  propertyFunction: number;
  setPropertyFunction: (value: number) => void;
  propertyType: number;
  setPropertyType: (value: number) => void;
  propertyUse: number;
  setPropertyUse: (value: number) => void;
  registerNumber: string;
  setRegisterNumber: (value: string) => void;
  registrationUf: number;
  setRegistrationUf: (value: number) => void;
  propertyRooms: number;
  setPropertyRooms: (value: number) => void;
  rooms: RoomData[];
  solicitor: string;
  setSolicitor: (value: string) => void;
  suites: number;
  setSuites: (value: number) => void;
  toilets: number;
  setToilets: (value: number) => void;
  totalArea: number;
  setTotalArea: (value: number) => void;
  uf: string;
  setUf: (value: string) => void;
  zone: number;
  setZone: (value: number) => void;
  propertyData: WorkOrderData | undefined;
  registerFileName: string;
  iptuFileName: string;
  searchMap: boolean;
  setSearchMap: (value: boolean) => void;
  setIsAddressEdited: (value: boolean) => void;
  pinPlace: LatLngLiteral;
  setPinPlace: (value: LatLngLiteral) => void;
  questionForm: QuestionResponseData | undefined;
  progressValue: number;
  inspectionId: number;
  enableEditing: boolean;
  setEnableEditing: (value: boolean) => void;
  downloadIptu: string;
  downloadRegister: string;
  submitLoading: boolean;
  handleCheckLocation(): Promise<void>;
  checkLocationDate: string | undefined;
  handleApprove: () => void;
  loadingApprove: boolean;
  loadingPage: boolean;
  getDataCallback: () => Promise<void>;
  handleUpdateForm: () => void;
  completedSections: ICompleteSections;
}

const useInspection = (): InspectionHook => {
  const [inspectionId, setInspectionId] = useState(0);
  const [questionForm, setQuestionForm] = useState<
    QuestionResponseData | undefined
  >();
  const [progressValue, setProgressValue] = useState(0);

  // Client data
  const [goal, setGoal] = useState(0);
  const [solicitor, setSolicitor] = useState('');
  const [propertyUse, setPropertyUse] = useState(0);
  const [propertyFunction, setPropertyFunction] = useState(0);
  const [client, setClient] = useState('');
  const [enableEditing, setEnableEditing] = useState(false);

  // Property data
  const [registerFileName, setRegisterFileName] = useState('');
  const [iptuFileName, setIptuFileName] = useState('');
  const [registerNumber, setRegisterNumber] = useState('');
  const [propertyType, setPropertyType] = useState(0);
  const [concept, setConcept] = useState(0);
  const [zone, setZone] = useState(0);
  const [judicialDistrict, setJudicialDistrict] = useState('');
  const [registrationUf, setRegistrationUf] = useState(0);
  const [downloadIptu, setDownloadIptu] = useState('');
  const [downloadRegister, setDownloadRegister] = useState('');

  // Property address
  const [address, setAddress] = useState('');
  const [addressNumber, setAddressNumber] = useState('');
  const [cep, setCep] = useState('');
  const [city, setCity] = useState('');
  const [complement, setComplement] = useState('');
  const [district, setDistrict] = useState('');
  const [uf, setUf] = useState('');
  const [searchMap, setSearchMap] = useState(true);
  const [pinPlace, setPinPlace] = useState<LatLngLiteral>({
    lat: -23.56162,
    lng: -46.65591,
  });

  // Property informations
  const [age, setAge] = useState(0);
  const [buildingStandard, setBuildingStandard] = useState(0);
  const [builtArea, setBuiltArea] = useState(0);
  const [conservation, setConservation] = useState(0);
  const [parkingLots, setParkingLots] = useState(0);
  const [propertyRooms, setPropertyRooms] = useState(0);
  const [suites, setSuites] = useState(0);
  const [toilets, setToilets] = useState(0);
  const [totalArea, setTotalArea] = useState(0);

  const [isAddressEdited, setIsAddressEdited] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [checkLocationDate, setCheckLocationDate] = useState<
    string | undefined
  >(undefined);
  const [updatedQuestionForm, setUpdatedQuestionForm] = useState(false);
  const [completedSections, setCompletedSections] = useState<ICompleteSections>(
    {
      clientData: false,
      propertyAddress: false,
      propertyData: false,
      propertyImages: false,
      propertyCharacteristic: false,
      form: false,
    }
  );

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { iptuLink, setIptuLink, registerLink, setRegisterLink } =
    useAttachment();
  const { propertyData, getDataCallback } = usePropertyData({
    status: Status.INSPECTION,
  });
  const { handleRooms, rooms } = useRooms();
  const { osId } = useGeneral();
  const { handleSearchCep } = useSearchCep();
  const { handleStatus, loadingApprove, setLoadingApprove } = useChangeStatus();

  const handleProgress = (questions: QuestionFormData[]): void => {
    if (questions) {
      const totalQuestions = questions.length;

      const answeredOptions = questions
        .filter((data) => data.answer)
        .map((data) => data.answer);

      const progress = (answeredOptions.length * 100) / totalQuestions;

      setProgressValue(progress);
    }
  };

  const getInspectionData = async (): Promise<void> => {
    if (propertyData) {
      if (propertyData.inspection) {
        setInspectionId(propertyData?.inspection.id);
        setQuestionForm(propertyData.inspection.form_response);
        handleProgress(propertyData.inspection.form_response.json);
        handleRooms(propertyData.inspection.id);
        setUpdatedQuestionForm(true);
      }
      setGoal(propertyData.report_goal);
      setSolicitor(propertyData.requester_name);
      setPropertyFunction(propertyData.report_function);
      setPropertyUse(propertyData.use);
      setClient(propertyData.client_name);

      setRegisterNumber(propertyData.registration_number);
      setPropertyType(propertyData.real_estate_kind);
      setConcept(propertyData.communities_kind);
      setZone(propertyData.zone);
      setJudicialDistrict(propertyData.judicial_district);
      setRegistrationUf(propertyData.uf);
      setDownloadIptu(propertyData.iptu_document || '');
      setDownloadRegister(propertyData.real_estate_registry || '');

      setAddress(propertyData.street);
      setAddressNumber(propertyData.number);
      setCep(propertyData.zip_code);
      setCity(propertyData.city);
      setComplement(propertyData.address_complement);
      setDistrict(propertyData.district);
      setUf(propertyData.state);
      if (propertyData.latitude && propertyData.longitude) {
        setPinPlace({
          lat: Number(propertyData.latitude),
          lng: Number(propertyData.longitude),
        });
      }

      setAge(propertyData.age);
      setBuildingStandard(propertyData.constructive_standard);
      setBuiltArea(propertyData.constructed_area);
      setConservation(propertyData.preservation_state);
      setParkingLots(propertyData.parking_spaces);
      setPropertyRooms(propertyData.bedrooms);
      setSuites(propertyData.suites);
      setToilets(propertyData.bathrooms);
      setTotalArea(propertyData.total_area);
      setCheckLocationDate(undefined);

      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getInspectionData();
  }, [propertyData]);

  useEffect(() => {
    if (propertyData) {
      getDataCallback();
    }
  }, [iptuLink, registerLink]);

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    if (!cep) {
      setSnackbarMessage('Adicione a localização do imóvel');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    if (isAddressEdited) {
      setSnackbarMessage('Pesquise a localização no mapa antes de salvar.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    if (buildingStandard === 0 || conservation === 0) {
      setSnackbarMessage('Os dados de seleção são obrigatórios');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    if (
      concept === 0 ||
      goal === 0 ||
      propertyFunction === 0 ||
      propertyType === 0 ||
      propertyUse === 0 ||
      registrationUf === 0
    ) {
      setSnackbarMessage('Os dados de seleção são obrigatórios');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const formData = new FormData();
    formData.append('street', address);
    formData.append('number', addressNumber);
    formData.append('age', age.toString());
    formData.append('constructive_standard', buildingStandard.toString());
    formData.append('constructed_area', builtArea.toString());
    formData.append('zip_code', cep);
    formData.append('city', city);
    formData.append('client_name', client);
    formData.append('address_complement', complement);
    formData.append('communities_kind', concept.toString());
    formData.append('preservation_state', conservation.toString());
    formData.append('district', district);
    formData.append('report_goal', goal.toString());
    formData.append('judicial_district', judicialDistrict);
    formData.append('parking_spaces', parkingLots.toString());
    formData.append('report_function', propertyFunction.toString());
    formData.append('real_estate_kind', propertyType.toString());
    formData.append('use', propertyUse.toString());
    formData.append('registration_number', registerNumber);
    formData.append('requester_name', solicitor);
    formData.append('uf', registrationUf.toString());
    formData.append('bedrooms', propertyRooms.toString());
    formData.append('suites', suites.toString());
    formData.append('bathrooms', toilets.toString());
    formData.append('total_area', totalArea.toString());
    formData.append('state', uf);
    formData.append('zone', zone.toString());
    formData.append('latitude', pinPlace.lat.toString());
    formData.append('longitude', pinPlace.lng.toString());

    try {
      setSubmitLoading(true);
      const response = await workOrderAPI.editWorkOrder(osId, formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Dados alterados com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      setSubmitLoading(false);
      setEnableEditing(false);
      getDataCallback();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setSubmitLoading(false);
    }
  };

  const handleCheckLocation = async (): Promise<void> => {
    const updateCoordinate = true;
    const formData = new FormData();
    formData.append('latitude', pinPlace.lat.toString());
    formData.append('longitude', pinPlace.lng.toString());

    try {
      setSubmitLoading(true);
      const response = await workOrderAPI.editWorkOrder(
        osId,
        formData,
        updateCoordinate
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Localização confirmada com sucesso!');
      setCheckLocationDate(new Date().toISOString());
      setErrorMessage(false);
      setOpenSnackbar(true);
      setSubmitLoading(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setSubmitLoading(false);
    }
  };

  const verifyCep = async (cep: string): Promise<void> => {
    const addressData = await handleSearchCep(cep);
    if (addressData) {
      setUf(addressData.uf);
      setCity(addressData.city);
      setDistrict(addressData.district);
      setAddress(addressData.address);
    }
    setIsAddressEdited(true);
  };

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>,
    fileType: string
  ): Promise<void> => {
    if (!e.target.files?.item(0) || !validateFiles(e.target.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem ou pdf');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const file = e.target.files[0];

    const formData = new FormData();
    if (fileType === 'register') {
      formData.append('real_estate_registry', file);
    }
    if (fileType === 'iptu') {
      formData.append('iptu_document', file);
    }

    try {
      const response = await workOrderAPI.editWorkOrder(osId, formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Dados alterados com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      if (fileType === 'register') {
        setRegisterLink(file.name);
        setRegisterFileName(file.name);
      }
      if (fileType === 'iptu') {
        setIptuLink(file.name);
        setIptuFileName(file.name);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const checkRequiredQuestions = (): boolean => {
    const requiredQuestions =
      questionForm?.json?.filter((question) => question.required === true) ||
      [];
    if (requiredQuestions && requiredQuestions.length > 0) {
      return requiredQuestions.some(
        (question) => !question.answer || question.answer === null
      );
    }
    return false;
  };

  const handleApprove = (): void => {
    setLoadingApprove(true);

    const pendingRequiredQuestions = checkRequiredQuestions();
    if (pendingRequiredQuestions) {
      setSnackbarMessage(
        'Responda todas as perguntas obrigatórias antes de prosseguir.'
      );
      setOpenSnackbar(true);
      setErrorMessage(true);
      setLoadingApprove(false);
      return;
    }

    handleStatus(osId, propertyData?.reference_number);
  };

  const handleUpdateForm = (): void =>
    setUpdatedQuestionForm(!updatedQuestionForm);

  useEffect(() => {
    if (checkLocationDate) {
      setCompletedSections((prev) => ({ ...prev, propertyAddress: true }));
    } else {
      setCompletedSections((prev) => ({ ...prev, propertyAddress: false }));
    }
  }, [checkLocationDate]);

  useEffect(() => {
    const isFactor =
      propertyData?.evaluation_type === EvaluationType.AUTOFACTORS ||
      propertyData?.evaluation_type === EvaluationType.AUTOINFERENCES;

    if (isFactor && propertyData.facade_image) {
      setCompletedSections((prev) => ({ ...prev, propertyImages: true }));
    } else if (!isFactor && rooms.length > 0) {
      setCompletedSections((prev) => ({ ...prev, propertyImages: true }));
    } else {
      setCompletedSections((prev) => ({ ...prev, propertyImages: false }));
    }
  }, [propertyData, rooms]);

  useEffect(() => {
    const pendingRequiredQuestions = checkRequiredQuestions();
    if (!pendingRequiredQuestions) {
      setCompletedSections((prev) => ({ ...prev, form: true }));
    } else {
      setCompletedSections((prev) => ({ ...prev, form: false }));
    }
  }, [updatedQuestionForm]);

  useEffect(() => {
    if (
      !registerNumber ||
      !propertyType ||
      !concept ||
      !zone ||
      !judicialDistrict ||
      !registrationUf
    ) {
      setCompletedSections((prev) => ({ ...prev, propertyData: false }));
    } else {
      setCompletedSections((prev) => ({ ...prev, propertyData: true }));
    }
  }, [
    registerNumber,
    propertyType,
    concept,
    zone,
    judicialDistrict,
    registrationUf,
  ]);

  useEffect(() => {
    if (
      client &&
      solicitor &&
      goal &&
      propertyData?.evaluation_type &&
      propertyData.client_kind &&
      propertyFunction &&
      propertyUse
    ) {
      setCompletedSections((prev) => ({ ...prev, clientData: true }));
    } else {
      setCompletedSections((prev) => ({ ...prev, clientData: false }));
    }
  }, [goal, solicitor, propertyFunction, propertyUse, client]);

  useEffect(() => {
    if (
      totalArea &&
      builtArea &&
      propertyRooms &&
      suites &&
      toilets &&
      parkingLots &&
      age &&
      conservation &&
      buildingStandard
    ) {
      setCompletedSections((prev) => ({
        ...prev,
        propertyCharacteristic: true,
      }));
    } else {
      setCompletedSections((prev) => ({
        ...prev,
        propertyCharacteristic: false,
      }));
    }
  }, [
    totalArea,
    builtArea,
    propertyRooms,
    suites,
    toilets,
    parkingLots,
    age,
    conservation,
    buildingStandard,
  ]);

  return {
    handleProgress,
    handleSubmit,
    verifyCep,
    handleFileUpload,
    address,
    setAddress,
    addressNumber,
    setAddressNumber,
    age,
    setAge,
    buildingStandard,
    setBuildingStandard,
    builtArea,
    setBuiltArea,
    cep,
    setCep,
    city,
    setCity,
    client,
    setClient,
    complement,
    setComplement,
    concept,
    setConcept,
    conservation,
    setConservation,
    district,
    setDistrict,
    goal,
    setGoal,
    judicialDistrict,
    setJudicialDistrict,
    parkingLots,
    setParkingLots,
    propertyFunction,
    setPropertyFunction,
    propertyType,
    setPropertyType,
    propertyUse,
    setPropertyUse,
    registerNumber,
    setRegisterNumber,
    registrationUf,
    setRegistrationUf,
    propertyRooms,
    setPropertyRooms,
    rooms,
    solicitor,
    setSolicitor,
    suites,
    setSuites,
    toilets,
    setToilets,
    totalArea,
    setTotalArea,
    uf,
    setUf,
    zone,
    setZone,
    propertyData,
    registerFileName,
    iptuFileName,
    searchMap,
    setSearchMap,
    setIsAddressEdited,
    pinPlace,
    setPinPlace,
    questionForm,
    progressValue,
    inspectionId,
    enableEditing,
    setEnableEditing,
    downloadIptu,
    downloadRegister,
    submitLoading,
    handleCheckLocation,
    checkLocationDate,
    handleApprove,
    loadingApprove,
    loadingPage,
    getDataCallback,
    handleUpdateForm,
    completedSections,
  };
};

export default useInspection;
