/* eslint-disable react-hooks/exhaustive-deps */
import { FormEvent, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { passwordAPI } from '../../../api';
import { StatusCode } from '../../../api/enumerations';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage } from '../../../helpers';
import { getLocalStorageToken } from '../../../helpers/localStorage';
import useGeneral from '../../../hooks/useGeneral';

interface RecoverPasswordHook {
  handleSendEmail: (e: React.FormEvent) => Promise<void>;
  handleRefreshToken: (e: React.FormEvent) => Promise<void>;
  handleSendToken: (e: React.FormEvent) => Promise<void>;
  handleClose: () => void;
  email: string;
  setEmail: (value: string) => void;
  emailError: boolean;
  setEmailError: (value: boolean) => void;
  hasToken: boolean;
  emailToken: string;
  setEmailToken: (value: string) => void;
  disableNewToken: boolean;
  tempToken: string;
  seconds: number;
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
}

const useRecoverPassword = (): RecoverPasswordHook => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [hasToken, setHasToken] = useState(false);
  const [emailToken, setEmailToken] = useState('');
  const [disableNewToken, setDisableNewToken] = useState(true);
  const [tempToken, setTempToken] = useState('');
  const [seconds, setSeconds] = useState(63);
  const [openModal, setOpenModal] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { navigateHome } = useGeneral();
  const navigate = useNavigate();

  const token = getLocalStorageToken();

  useEffect(() => {
    if (token !== null && token !== 'undefined') {
      navigateHome();
    }
    if (seconds === 0) {
      setDisableNewToken(false);
    }
    if (seconds < 61 && seconds > 0) {
      setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
  }, [token, seconds]);

  const handleSendEmail = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    const trimEmail = email.trim();

    try {
      const response = await passwordAPI.recoverPasswordEmail(trimEmail);

      if (response.detail.status_code === StatusCode.NOT_FOUND) {
        setSnackbarMessage('Um código foi enviado para seu email.');
        setErrorMessage(false);
        setOpenSnackbar(true);
        setHasToken(true);
        setSeconds(60);
        return;
      }

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Um código foi enviado para seu email.');
      setErrorMessage(false);
      setOpenSnackbar(true);
      setHasToken(true);
      setSeconds(60);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      navigate('/login');
    }
  };

  const handleRefreshToken = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    const trimEmail = email.trim();

    try {
      const response = await passwordAPI.recoverPasswordEmail(trimEmail);

      if (response.detail.status_code === StatusCode.NOT_FOUND) {
        setSnackbarMessage('Um código foi enviado para seu email.');
        setErrorMessage(false);
        setOpenSnackbar(true);
        setDisableNewToken(true);
        setSeconds(60);
        return;
      }

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Um código foi enviado para seu email.');
      setErrorMessage(false);
      setOpenSnackbar(true);
      setDisableNewToken(true);
      setSeconds(60);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      navigate('/login');
    }
  };

  const handleSendToken = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    const trimEmail = email.trim();
    const trimToken = emailToken.replace(/\s/g, '');

    try {
      const response = await passwordAPI.firstAccessToken(trimEmail, trimToken);

      if (response.detail.status_code === StatusCode.NOT_FOUND) {
        throw new Error('Código inválido. Verifique e tente novamente');
      }

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data?.token) {
        setTempToken(response.data.token);
        setOpenModal(true);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      navigate('/login');
    }
  };

  const handleClose = (): void => {
    navigate('/login');
  };

  return {
    handleSendEmail,
    handleRefreshToken,
    handleSendToken,
    handleClose,
    email,
    setEmail,
    emailError,
    setEmailError,
    hasToken,
    emailToken,
    setEmailToken,
    disableNewToken,
    tempToken,
    seconds,
    openModal,
    setOpenModal,
  };
};

export default useRecoverPassword;
