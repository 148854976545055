import { useContext } from 'react';

import { workOrderAPI } from '../api';
import { StatusCode } from '../api/enumerations';
import { GlobalContext } from '../context/global';
import useErrorMessage from './useErrorMessage';
import useGeneral from './useGeneral';

interface UsePeptHook {
  handleSendToPept: (
    osId: number,
    reference: number | undefined,
    obs: string
  ) => Promise<void>;
}

export function usePept(): UsePeptHook {
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();
  const { navigateHome } = useGeneral();

  const handleSendToPept = async (
    osId: number,
    reference: number | undefined,
    obs: string
  ): Promise<void> => {
    try {
      const response = await workOrderAPI.sendToPept(osId, obs);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage(`OS ${reference} enviada para pendência técnica!`);
      setErrorMessage(false);
      setOpenSnackbar(true);
      navigateHome();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  };

  return {
    handleSendToPept,
  };
}
