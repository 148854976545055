import { Box, styled } from '@mui/material';

export const StyledContainer = styled(Box)(() => ({}));

export const BoxShadowContainer = styled(Box)(() => ({
  top: 85,
  left: 345,
  width: 1202,
  height: 1030,
  background: '#f5f5f5 0% 0% no-repeat paddind-box',
  boxShadow: '0px 3px 6px #00000029',
  opacity: 1,

  padding: '16px 72px',

  '& img': {
    width: '1064px',
    height: '508px',
  },

  '& h1': {
    fontSize: '28px',
    fontFamily: 'Roboto Condensed',
  },

  '& div': {
    marginTop: 24,
  },
}));
