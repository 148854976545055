import { ChangeEvent, DragEvent, useContext, useState } from 'react';

import { GlobalContext } from '../context/global';
import { validateFiles } from '../helpers';

interface UploadFileHook {
  dragActive: boolean;
  handleDrag(e: DragEvent<HTMLDivElement>): void;
  handleDrop(e: DragEvent<HTMLDivElement>): undefined | FileList;
  handleFileUpload(e: ChangeEvent<HTMLInputElement>): undefined | FileList;
}

export default function useUploadFile(): UploadFileHook {
  const [dragActive, setDragActive] = useState(false);
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const handleDrag = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>): undefined | FileList => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    let filesData;
    if (!e.dataTransfer.files || !validateFiles(e.dataTransfer.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return filesData;
    }

    const { files } = e.dataTransfer;
    filesData = files;
    return filesData;
  };

  const handleFileUpload = (
    e: ChangeEvent<HTMLInputElement>
  ): undefined | FileList => {
    let filesData;
    if (!e.target.files?.item(0) || !validateFiles(e.target.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return filesData;
    }
    const { files } = e.target;
    filesData = files;
    return filesData;
  };

  return {
    dragActive,
    handleDrag,
    handleDrop,
    handleFileUpload,
  };
}
