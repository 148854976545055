export const Constants = {
  address: 'logradouro:',
  addressNumber: 'número:',
  addressComplement: 'complemento:',
  addressCep: 'CEP:',
  addressDistrict: 'bairro:',
  addressCity: 'cidade:',
  addressUF: 'estado:',
  lat: 'Latitude: ',
  lng: 'Longitude:',
};
