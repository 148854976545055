/* eslint-disable react-hooks/exhaustive-deps */
import { Table, TableBody, TableHead } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import {
  convertClientKind,
  convertEvaluationType,
  convertReportFunction,
  convertReportGoal,
} from '../../../api/enumerations';
import { WorkOrderData } from '../../../api/workOrders/types';
import { convertDate } from '../../../helpers';
import { Cell, Row, StyledTableCell, StyledTableContainer } from './styles';

interface TableArchivedProps {
  tableData: WorkOrderData[] | undefined;
}

export function TableArchived({ tableData }: TableArchivedProps): JSX.Element {
  const navigate = useNavigate();

  const handleNavigate = (id: number): void => {
    navigate(`/home/property/${id}/archived`);
  };
  return (
    <StyledTableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <Row>
            <Cell align="center" sx={{ borderTopLeftRadius: 8 }}>
              número OS
            </Cell>
            <Cell align="center">cliente</Cell>
            <Cell align="center">tipo</Cell>
            <Cell align="center">localização</Cell>
            <Cell align="center">tipo de laudo</Cell>
            <Cell align="center">finalidade</Cell>
            <Cell align="center">objetivo</Cell>
            <Cell align="center" sx={{ borderTopRightRadius: 8 }}>
              data de criação
            </Cell>
          </Row>
        </TableHead>
        <TableBody id="table">
          {tableData?.map((archived) => (
            <Row key={archived.id} onClick={() => handleNavigate(archived.id)}>
              <StyledTableCell align="center">
                {archived.reference_number}
              </StyledTableCell>
              <StyledTableCell align="center">
                {archived.client_name}
              </StyledTableCell>
              <StyledTableCell align="center">
                {convertClientKind(archived.client_kind)}
              </StyledTableCell>
              <StyledTableCell align="center">
                {archived.street} {archived.number && `, n ${archived.number}`}
                {archived.address_complement &&
                  `, ${archived.address_complement}`}
              </StyledTableCell>
              <StyledTableCell align="center">
                {convertEvaluationType(archived.evaluation_type)}
              </StyledTableCell>
              <StyledTableCell align="center">
                {convertReportFunction(archived.report_function)}
              </StyledTableCell>
              <StyledTableCell align="center">
                {convertReportGoal(archived.report_goal)}
              </StyledTableCell>
              <StyledTableCell align="center">
                {convertDate(archived.created_at)}
              </StyledTableCell>
            </Row>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}
