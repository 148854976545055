import { Box, Button, ButtonBase, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const InputBox = styled(Box)(() => ({
  width: '75%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
}));

export const StyledTypography = styled(Typography)(() => ({
  textAlign: 'center',
  color: '#707070',
}));

export const StyledPatternFormat = {
  '& .MuiFormHelperText-root': {
    color: '#000',
    textAlign: 'center',
    padding: '10px 0',
  },
  '& .MuiInputBase-input': {
    color: '#000',
    fontSize: '40px',
    textAlign: 'center',
    maxWidth: '320px',
    letterSpacing: '8px',
  },
  '& .MuiInputBase-root.MuiInput-root:before': {
    borderBottom: '1px solid #CC34FF',
  },
  '& .MuiInputBase-root.MuiInput-root:after': {
    borderBottom: '1px solid #CC34FF',
  },
  '& .MuiInputBase-root.MuiInput-root:hover:before': {
    borderBottom: '1px solid #CC34FF',
  },
  '& .MuiInputBase-root.MuiInput-root:hover:after': {
    borderBottom: '1px solid #CC34FF',
  },
  'input:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0px 1000px #F2F2F2 inset',
  },
};

export const TimerTypography = styled(Typography)(({ theme }) => ({
  fontSize: '15px',
  color: theme.palette.common.black,
  padding: '10px',
  textAlign: 'center',
}));

export const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  '&:hover': {
    color: theme.palette.primary.dark,
  },
  '&.Mui-disabled': {
    color: theme.palette.secondary.light,
  },
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
  borderRadius: '16px',
  backgroundColor: '#613354',
  fontWeight: '700',
  color: '#D6D4D0',
  textTransform: 'uppercase',
  margin: 'clamp(22px, 4.7vh ,42px) 0',
  boxShadow: '0px 3px 15px #00000029',
  '&:hover': {
    backgroundColor: '#9B7359',
  },
  [theme.breakpoints.down('md')]: {
    width: '280px',
  },
  [theme.breakpoints.up('md')]: {
    width: '320px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '500px',
  },
}));
