import {
  handleUrl,
  myHeadersWithToken,
  myHeadersWithTokenContentType,
} from '../global';
import {
  BasicResponseAPI,
  CompanyObject,
  DetailProps,
  ResetPasswordData,
  UpdateCompanyAddress,
} from './types';

export const getRegistrationDataInformation = (): Promise<CompanyObject> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/companies/me`, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const updateCompanyAddress = (
  data: UpdateCompanyAddress
): Promise<BasicResponseAPI> => {
  const requestOptions = {
    method: 'PATCH',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/companies/me/address`, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteAccount = ({
  userId,
}: {
  userId?: number;
}): Promise<BasicResponseAPI> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/users/${userId}`, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const resetPasswordProto = (
  data: ResetPasswordData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PATCH',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/proto-mobile/password-reset`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
