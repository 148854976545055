/* eslint-disable */
import { CircularProgress, Modal } from '@mui/material';

interface LoadingProps {
  open: boolean;
  onClose: () => void;
}

export function Loading({ open, onClose }: LoadingProps): JSX.Element {
  return (
    <Modal open={open} onClose={onClose} sx={modalStyle}>
      <div style={boxStyle}>
        <CircularProgress size={150} color="primary" />
      </div>
    </Modal>
  );
}

const modalStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const boxStyle = {
  background: 'white',
  height: '300px',
  width: '300px',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
