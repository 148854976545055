/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { driver } from 'driver.js';
import { useCallback, useContext, useEffect, useState } from 'react';

import { pathologyAPI } from '../../../../api';
import { StatusCode } from '../../../../api/enumerations';
import {
  AllPathologiesData,
  PathologiesData,
} from '../../../../api/pathologies/types';
import { PathologiesFormControl } from '../../../../components/CustomInput';
import { SubmitBox } from '../../../../components/UI/Box';
import { CloseIcon, FilledButton } from '../../../../components/UI/Button';
import { DialogTitle } from '../../../../components/UI/Typography';
import {
  IconAssignmentMS,
  IconCloseMS,
  IconDesignServicesMS,
  IconDownloadMS,
  IconPhotoLibraryMS,
} from '../../../../constants/icons';
import {
  Constants,
  DefaultPathologyOptions,
} from '../../../../constants/imageDetails';
import { GlobalContext } from '../../../../context/global';
import { getErrorMessage } from '../../../../helpers';
import { driverConfig } from '../../../../helpers/driver/config';
import { inspectionPathologiesSteps } from '../../../../helpers/driver/steps';
import useGeneral from '../../../../hooks/useGeneral';
import { useTour } from '../../../../hooks/useTour';
import DeletePictureDialog from '../../DeletePictureDialog';
import {
  Dialog,
  DownloadButton,
  FlexEndBox,
  ImageCard,
  PictureBox,
  SectionSubtitle,
} from './styles';

interface KindsData {
  kind_id: number;
}

interface PathologiesOptionsData {
  kind: number;
  name: string;
  checked: boolean;
}

interface PathologiesProps {
  isOpen: boolean;
  setOpenModal(): void;
  setCloseModal(): void;
  pathologyId: number;
  isPictureNew: boolean;
  nextModalCallback: (value: number) => void;
  pictureId: number;
  setPathologies: (pathologies: AllPathologiesData[]) => void;
  edit: boolean;
}
export default function Pathologies({
  isOpen,
  setOpenModal,
  setCloseModal,
  pathologyId,
  isPictureNew,
  nextModalCallback,
  pictureId,
  setPathologies,
  edit,
}: PathologiesProps): JSX.Element {
  const [pathologyData, setPathologyData] = useState<PathologiesData>();
  const [pathologyKinds, setPathologyKinds] = useState<KindsData[]>([]);
  const [pathologieOptions, setPathologieOptions] = useState<
    PathologiesOptionsData[]
  >(DefaultPathologyOptions);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { handleDownloadFile } = useGeneral();

  const {
    setTourCompletion,
    tourCompletion,
    setTourSelection,
    setToBeContinued,
  } = useTour();

  const getDataCallback = useCallback(async () => {
    try {
      const response = await pathologyAPI.getPathology(pathologyId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setPathologyData(response.data);

      if (response.data.kinds) {
        const kindsList = response.data.kinds;

        const updatedOptions = pathologieOptions.map((option) => {
          const mOption = option;
          mOption.checked = kindsList.some(
            (remoteKind) => remoteKind.kind_id === option.kind
          );
          return mOption;
        });

        setPathologieOptions(updatedOptions);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setOpenModal();
    }
  }, []);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  const updatePathologies = async (): Promise<void> => {
    try {
      const response = await pathologyAPI.getAllPathologies(pictureId, 1, 40);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Erro ao carregar as patologias.');
      }

      setPathologies(response.data);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleClose = (): void => {
    updatePathologies();
    setCloseModal();
  };

  const handleSubmitPathologiesKinds = async (): Promise<void> => {
    if (pathologyKinds.length < 1) {
      setSnackbarMessage('Selecione uma patologia!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    const formData = new FormData();
    pathologyKinds.map((kind) =>
      formData.append('kinds', kind.kind_id.toString())
    );

    if (pathologyData) {
      try {
        const response = await pathologyAPI.updatePathology(
          pathologyData.id,
          formData
        );

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setSnackbarMessage('Patologias atualizadas com sucesso!');
        setErrorMessage(false);
        setOpenSnackbar(true);
        handleClose();
        if (isPictureNew && pathologyData.next_id) {
          nextModalCallback(pathologyData.next_id);
        }
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
        handleClose();
      }
    }
  };

  const handleDeletePathology = async (pathologyId: number): Promise<void> => {
    try {
      const response = await pathologyAPI.deletePathology(pathologyId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Foto e patologias deletadas!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      handleClose();
      if (isPictureNew && pathologyData?.next_id) {
        nextModalCallback(pathologyData.next_id);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleCheckboxChange = (checked: boolean, value: number): void => {
    if (checked) {
      const kindId = pathologieOptions.find((e) => {
        return e.kind === value;
      });

      if (kindId) {
        const newData = [
          ...pathologyKinds,
          {
            kind_id: value,
          },
        ];
        setPathologyKinds(newData);
      }
    }
  };

  const pathologiesDriverObj = driver({
    ...driverConfig,
    steps: inspectionPathologiesSteps,
    onNextClick: () => {
      if (pathologiesDriverObj.getActiveIndex() === 2) {
        handleClose();
      }
      if (pathologiesDriverObj.isLastStep()) {
        setTourCompletion((prev) => {
          const newTourCompletion = { ...prev };
          const inspectionIndex = newTourCompletion.inspection.findIndex(
            (item) => item.stage === 'inspectionPathologies'
          );
          if (inspectionIndex !== -1) {
            const updatedInspectionIndex = {
              ...newTourCompletion.inspection[inspectionIndex],
            };
            updatedInspectionIndex.complete = true;
            newTourCompletion.inspection[inspectionIndex] =
              updatedInspectionIndex;
          }
          return newTourCompletion;
        });
      }
      pathologiesDriverObj.moveNext();
    },
    onCloseClick: () => {
      setTourSelection(false);
      setToBeContinued(false);
      pathologiesDriverObj?.destroy();
    },
    onDestroyStarted: () => {
      setTourSelection(false);
      setToBeContinued(false);
      pathologiesDriverObj?.destroy();
    },
  });

  useEffect(() => {
    if (
      !tourCompletion.inspection[4].complete &&
      !tourCompletion.skipTour.inspection &&
      isOpen
    ) {
      setTimeout(() => {
        pathologiesDriverObj.drive();
      }, 200);
    }
  }, [tourCompletion, isOpen]);

  return (
    <Dialog open={isOpen}>
      <CloseIcon onClick={handleClose}>{IconCloseMS}</CloseIcon>
      <DialogTitle>
        {IconDesignServicesMS}
        {Constants.pathologies}
      </DialogTitle>
      <SectionSubtitle>
        {IconPhotoLibraryMS}
        {Constants.roomPathologies}
      </SectionSubtitle>
      <div id="pathologies-container">
        {pathologyData && (
          <FlexEndBox>
            <PictureBox>
              <ImageCard
                image={pathologyData.file}
                title={pathologyData.name || ''}
              />
            </PictureBox>
            <DownloadButton
              onClick={() =>
                handleDownloadFile(
                  pathologyData.file,
                  pathologyData.name || 'patologia'
                )
              }
            >
              {IconDownloadMS}
              {Constants.download}
            </DownloadButton>
          </FlexEndBox>
        )}
        <SectionSubtitle>
          {IconAssignmentMS}
          {Constants.photoPathologies}
        </SectionSubtitle>
        <Grid container spacing={1} sx={{ paddingBottom: !edit ? '60px' : '' }}>
          {pathologieOptions.map((data) => {
            return (
              <Grid item xs={3} key={data.kind}>
                <PathologiesFormControl
                  label={data.name}
                  value={data.kind}
                  isChecked={data.checked}
                  onChangeCallback={handleCheckboxChange}
                  disabled={!edit}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
      {edit && (
        <SubmitBox id="submit-box" className="submit-box">
          {pathologyData && (
            <DeletePictureDialog
              outlinedButton
              modalCallback={handleDeletePathology}
              pictureId={pathologyData.id}
              text={Constants.thisPathology}
            />
          )}
          <FilledButton
            id="add-pathology"
            onClick={handleSubmitPathologiesKinds}
          >
            {Constants.addPathologies}
          </FilledButton>
        </SubmitBox>
      )}
    </Dialog>
  );
}
