import { ButtonBase, Divider, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)(() => ({
  padding: '32px 0',
}));

export const InfoTypography = styled(Typography)(() => ({
  paddingBottom: '4px',
  fontSize: '18px',
}));

export const InfoValueTypography = styled(Typography)(() => ({
  paddingBottom: '4px',
  paddingLeft: '4px',
  fontSize: '18px',
  fontWeight: 'bold',
  textTransform: 'capitalize',
}));

export const TrialTitle = styled(Typography)(() => ({
  color: '#adadad',
  fontSize: '21px',
  fontWeight: '600',
  textTransform: 'uppercase',
  margin: '8px 0',
}));

export const TrialInfo = styled(Typography)(() => ({
  paddingBottom: '4px',
  fontSize: '18px',
  color: '#adadad',
}));

export const TrialInfoValue = styled(Typography)(() => ({
  paddingBottom: '4px',
  paddingLeft: '4px',
  fontSize: '18px',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  color: '#adadad',
}));

export const StyledButton = styled(ButtonBase)(({ theme }) => ({
  fontSize: '18px',
  color: theme.palette.primary.main,
  '&:hover': {
    fontWeight: '600',
    borderBottom: '1px solid #B038FA',
  },
}));

export const StyledDivider = styled(Divider)(() => ({
  marginBottom: '40px',
}));
