import { Box, Button, Dialog, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '16px',
    backgroundColor: '#FAFCFF',
    padding: '32px 40px',
    minWidth: '900px',
    width: '100%',
    height: 'auto',
    [theme.breakpoints.up('lg')]: {
      minWidth: '1200px',
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: '1500px',
    },
  },
  '& .MuiDialog-paperScrollPaper': {
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.dark,
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '&::-webkit-scrollbar-track': {
      margin: '60px 0',
    },
  },
}));

export const EditButton = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: '#FAFCFF',
  width: 200,
  color: theme.palette.primary.dark,
  border: `2px solid ${theme.palette.primary.dark}`,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '3rem',
}));

export const InputText = styled(TextField)(() => ({
  width: '100%',
  '& .MuiInputBase-root': {
    borderRadius: 16,
  },
}));
