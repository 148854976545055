import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TitleBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '20px',
  alignItems: 'center',
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: '28px',
  fontWeight: '600',
  textTransform: 'uppercase',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  '& span': { fontSize: '26px', color: theme.palette.primary.main },
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
  gap: 10,
}));

export const OSNumber = styled(Typography)(({ theme }) => ({
  minWidth: '210px',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  backgroundColor: theme.palette.primary.dark,
  borderRadius: '10px',
  padding: '4px 16px',
  color: theme.palette.common.white,
}));

export const CreatedStyle = styled(Typography)(({ theme }) => ({
  minWidth: '210px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '10px',
  border: `2px solid ${theme.palette.primary.dark}`,
  borderRadius: '10px',
  padding: '4px 16px',
  color: theme.palette.primary.dark,
  fontWeight: '600',
}));
