export const Constants = {
  archived: 'arquivado',
  propertyData: 'Dados do imóvel',
  propertyAddress: 'Localização do imóvel',
  propertyDetails: 'Características do imóvel',
  scheduleInfo: 'Informações sobre agendamento',
  propertyPhotos: 'Imagens do imóvel',
  noPhotos: 'esta OS não possui imagens',
  form: 'Formulário',
  noSample: 'esta OS não possui amostra',
  sample: 'Amostra',
  report: 'Relatório',
  noReport: 'Relatório não pode ser criado',
  reason: 'motivo:',
  exportSample: 'exportar lista de elementos e formulário',
  downloadSamplePdf: 'download amostra PDF',
  downloadReport: 'download do laudo',
};
