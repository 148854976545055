/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { DialogTitle, Grid } from '@mui/material';
import { useCallback, useState } from 'react';

import { StepTour } from '../../../api/enumerations';
import logoProto from '../../../assets/images/proto-logo.png';
import { useTour } from '../../../hooks/useTour';
import {
  StyledBox,
  StyledButtonContained,
  StyledButtonText,
  StyledDivLogo,
  StyledModal,
  StyledTypographyGrid,
  Title,
} from './styles';

export function WelcomeTour(): JSX.Element {
  const {
    setToBeContinued,
    setTutorialStep,
    setTourCompletion,
    tourCompletion,
  } = useTour();

  const [open, setOpen] = useState(true);

  const handleClose = useCallback((): void => setOpen(false), [setOpen]);

  const handleStartDrive = (): void => {
    setToBeContinued(true);
    setTutorialStep(StepTour.GUIDEDTOUR);

    handleClose();
  };

  const handleSkipTour = (): void => {
    setTourCompletion({
      ...tourCompletion,
      skipTour: {
        onBoarding: true,
        pipeline: true,
        pept: true,
        entrance: true,
        schedule: true,
        inspection: true,
        sampleCreate: true,
        calculation: true,
        report: true,
        revision: true,
        archived: true,
        configuration: true,
        form: true,
        agenda: true,
        errorReport: true,
      },
    });
    handleClose();
  };

  return (
    <StyledModal open={open} onClose={handleClose}>
      <StyledBox>
        <DialogTitle>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Title>Bem vindo (a) ao</Title>
            </Grid>
            <Grid item>
              <StyledDivLogo>
                <img src={logoProto} alt="logotipo do proto" />
              </StyledDivLogo>
            </Grid>
          </Grid>
        </DialogTitle>
        <Grid container direction="column" alignItems="center" marginBottom={4}>
          <Grid item width={540}>
            <StyledTypographyGrid>
              Esta é uma ferramenta completa de apoio para profissionais de
              avaliação e peritos.{' '}
            </StyledTypographyGrid>
          </Grid>
          <Grid item width={540}>
            <StyledTypographyGrid>
              Ele auxilia desde o profissional autônomo especializado em busca
              de renda extra até os grandes escritórios.
            </StyledTypographyGrid>
          </Grid>
        </Grid>
        <Grid container direction="column" alignItems="center" gap={2}>
          <StyledButtonContained onClick={handleStartDrive}>
            INICIAR TOUR RÁPIDO
          </StyledButtonContained>
          <StyledButtonText onClick={() => handleSkipTour()}>
            Pular Tour
          </StyledButtonText>
        </Grid>
      </StyledBox>
    </StyledModal>
  );
}
