/* eslint-disable react/require-default-props */
import { Container, StyledButton } from './styles';

interface ToggleProps {
  value: string;
  setValue(value: string): void;
  setLicense?: (value: string) => void;
  options: string[];
}

export default function Toggle({
  value,
  setValue,
  setLicense,
  options,
}: ToggleProps): JSX.Element {
  return (
    <Container>
      {options.map((option) => (
        <StyledButton
          key={option}
          clicked={value === option ? 'true' : 'false'}
          onClick={() => {
            setValue(option);
            if (setLicense) setLicense('');
          }}
        >
          {option}
        </StyledButton>
      ))}
    </Container>
  );
}
