import { Box, InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FilterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 380,
  height: 480,
  '@media (max-height: 820px)': {
    height: 360,
  },
  [theme.breakpoints.down('xl')]: {
    width: 260,
  },
}));

interface ColorDotProps {
  color: string;
}

export const ColorDot = styled(Box)<ColorDotProps>(({ color }) => ({
  height: 18,
  width: 18,
  borderRadius: 50,
  background: color,
}));

export const ColorDotBox = styled(Box)(() => ({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
  whiteSpace: 'nowrap',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: 380,
  borderRadius: '32px',
  border: `1px solid ${theme.palette.primary.main}`,
  padding: '4px 18px',
  marginBottom: '0.5rem',
  '& .MuiInputBase-input': {
    padding: '10px 0 10px',
  },
  [theme.breakpoints.down('xl')]: {
    width: 260,
  },
}));

export const EngineersContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'auto',
  maxWidth: 380,
  overflowX: 'hidden',
  padding: '10px',
  borderRadius: 16,
  background: theme.palette.common.white,
  zIndex: '300',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  '&::-webkit-scrollbar-track': {
    margin: '24px 0',
  },
}));
