/* eslint-disable react/require-default-props */
import {
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import { useCallback, useState } from 'react';

import SampleMarker from '../../../../../assets/mapMarkers/element.svg';
import MarkerSelected from '../../../../../assets/mapMarkers/selected.svg';
import UnusedElement from '../../../../../assets/mapMarkers/unused.svg';

interface IOption {
  isUtilized: boolean;
  position: google.maps.LatLngLiteral;
  id: number;
}

interface CustomMarkerProps {
  markerData: IOption;
  markerIndex: number;
  isSelectedMarker: boolean;
  isUnusedMarker: boolean;
  setPage: (value: number) => void;
  deactivateFilters?: () => void;
}

export default function CustomMarker({
  markerData,
  markerIndex,
  setPage,
  isSelectedMarker,
  isUnusedMarker,
  deactivateFilters,
}: CustomMarkerProps): JSX.Element {
  const [infoWindowShown, setInfoWindowShown] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();

  const getIconUrl = (isSelected: boolean, isUnused: boolean): string => {
    if (isSelected) {
      return MarkerSelected;
    }
    if (isUnused) {
      return markerData.isUtilized ? SampleMarker : UnusedElement;
    }
    return SampleMarker;
  };

  const handleClose = useCallback(() => setInfoWindowShown(false), []);

  return (
    <>
      <AdvancedMarker
        position={markerData.position}
        ref={markerRef}
        zIndex={100}
        onClick={() => {
          setInfoWindowShown(true);
          if (deactivateFilters) deactivateFilters();
          setPage(markerIndex);
        }}
        style={{
          width: '24px',
          height: '30px',
          clipPath:
            'polygon(25% 0%, 75% 0%, 99% 5%, 100% 50%, 75% 80%, 50% 100%, 25% 80%, 0% 50%, 1% 10%)',
        }}
      >
        <img
          onMouseOver={() => setInfoWindowShown(true)}
          onFocus={() => setInfoWindowShown(true)}
          onBlur={() => setInfoWindowShown(false)}
          onMouseOut={() => setInfoWindowShown(false)}
          src={getIconUrl(isSelectedMarker, isUnusedMarker)}
          width={24}
          height={30}
          alt="marcador"
        />
      </AdvancedMarker>
      {infoWindowShown && (
        <InfoWindow anchor={marker} onClose={handleClose}>
          <div
            style={{
              color: '#B038FA',
              font: 'bold 18px Roboto',
              margin: '4px 6px',
            }}
          >
            {markerIndex}
          </div>
        </InfoWindow>
      )}
    </>
  );
}
