import { FilledButton, OutlinedButton } from '../../../../components/UI/Button';
import { StyledDialog } from '../../../../components/UI/Dialog';
import { IconDeleteMS } from '../../../../constants/icons';
import { Constants } from '../../../../constants/inspection';
import useGeneral from '../../../../hooks/useGeneral';
import {
  AlertTypography,
  ButtonBox,
  DialogContainer,
  StyledMenuItem,
  TextTypography,
} from './styles';

interface DeleteRoomDialogProps {
  roomId: number;
  modalCallback: (id: number) => void;
}

export default function DeleteRoomDialog({
  roomId,
  modalCallback,
}: DeleteRoomDialogProps): JSX.Element {
  const dialog = useGeneral();

  const handleConfirm = (): void => {
    modalCallback(roomId);
    dialog.handleClose();
  };

  return (
    <>
      <StyledMenuItem disableTouchRipple onClick={dialog.handleOpen}>
        {IconDeleteMS}
        {Constants.delete}
      </StyledMenuItem>
      <StyledDialog
        open={dialog.open}
        onClose={dialog.handleClose}
        aria-labelledby="delete room"
      >
        <DialogContainer>
          <TextTypography>{Constants.deleteRoomText}</TextTypography>
          <ButtonBox>
            <OutlinedButton width="sm" onClick={dialog.handleClose}>
              {Constants.no}
            </OutlinedButton>
            <FilledButton width="sm" onClick={handleConfirm}>
              {Constants.yes}
            </FilledButton>
          </ButtonBox>
          <AlertTypography>{Constants.deleteRoomAlert}</AlertTypography>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
