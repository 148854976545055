import { Box } from '@mui/material';
import { useContext } from 'react';

import logo from '../../assets/images/proto-logo.png';
import { GlobalContext } from '../../context/global';
import Snackbar from '../Snackbar';
import { ContainerGrid, LoginGrid, LoginImage, Logo } from './styles';

interface LoginLayoutProps {
  children: JSX.Element;
}

export default function LoginLayout({
  children,
}: LoginLayoutProps): JSX.Element {
  const { openSnackbar } = useContext(GlobalContext);

  return (
    <Box>
      <ContainerGrid>
        <LoginGrid>
          <Logo>
            <img src={logo} alt="Logo do Proto" />
          </Logo>
          {children}
        </LoginGrid>
        <LoginImage />
      </ContainerGrid>
      {openSnackbar && <Snackbar />}
    </Box>
  );
}
