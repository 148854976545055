import { IconButton, ToggleButton } from '@mui/material';
import 'driver.js/dist/driver.css';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';

import { PipelineSearch } from '../../components/PipelineSearch';
import { StandardButton } from '../../components/UI/Button';
import { TitleTypography } from '../../components/UI/Typography';
import { Constants } from '../../constants/home';
import {
  IconAddMS,
  IconCheckCircleMS,
  IconCloseMS,
  IconDashboardMS,
  IconViewWeekMS,
} from '../../constants/icons';
import { GlobalContext } from '../../context/global';
import { useStoragedJwt } from '../../hooks/useDecodedJwt';
import { useWorkOrder } from '../../hooks/useWorkOrder';
import '../../styles/driverjs.css';
import ListView from './ListView';
import PipelineView from './PipelineView';
import {
  FlexBox,
  GridContainer,
  ScrollTypography,
  StyledToast,
  StyledToggleButtonGroup,
  TitleBox,
  ToastTypography,
} from './styles';

export default function Home(): JSX.Element {
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const { homeView, setHomeView } = useContext(GlobalContext);
  const navigate = useNavigate();
  const decoded = useStoragedJwt();
  const { lastMessage } = useWebSocket(
    `${process.env.REACT_APP_API_WEBSOCKET_URL}/ws/${decoded?.user.id}`
  );
  const {
    loading,
    searchField,
    setSearchField,
    setAllowSearch,
    workOrders,
    handleTopWorkOrders,
  } = useWorkOrder();

  useEffect(() => {
    if (lastMessage !== null) {
      const messageData = JSON.parse(lastMessage.data);
      setToastMessage(
        `OS ${messageData.payload.reference_number} finalizou o cálculo.`
      );
      setOpenToast(true);
      handleTopWorkOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  const handleNavigate = (): void => {
    navigate('/home/property/new');
  };

  const handleView = (
    event: React.MouseEvent<HTMLElement>,
    newView: string
  ): void => {
    if (newView !== null) setHomeView(newView);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ): void => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      {IconCloseMS}
    </IconButton>
  );

  return (
    <GridContainer>
      <TitleBox>
        <TitleTypography>{Constants.title}</TitleTypography>
        <FlexBox>
          <StandardButton onClick={handleNavigate} id="new-os-button">
            {IconAddMS}
            {Constants.buttonAdd}
          </StandardButton>
          <PipelineSearch setSearchField={setSearchField} />
        </FlexBox>
      </TitleBox>
      <StyledToggleButtonGroup
        value={homeView}
        exclusive
        onChange={handleView}
        aria-label="visualização"
      >
        <ToggleButton value="pipeline" aria-label="pipeline" className="pipe">
          {IconViewWeekMS}
        </ToggleButton>
        <ToggleButton value="lista" aria-label="lista" className="lista">
          {IconDashboardMS}
        </ToggleButton>
      </StyledToggleButtonGroup>
      {homeView === 'lista' ? (
        <ListView search={searchField} setAllowSearch={setAllowSearch} />
      ) : (
        <PipelineView
          workOrders={workOrders}
          pipeLoading={loading}
          isSearching={searchField.length > 0}
          setAllowSearch={setAllowSearch}
        />
      )}
      <ScrollTypography>-</ScrollTypography>
      <StyledToast
        open={openToast}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleClose}
        action={action}
        message={
          <ToastTypography>
            {IconCheckCircleMS}
            {toastMessage}
          </ToastTypography>
        }
      />
    </GridContainer>
  );
}
