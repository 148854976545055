import { Box, CircularProgress } from '@mui/material';

import { BoxContainer, StyledBackdrop } from './styles';

interface BackdropLoadingProps {
  open: boolean;
}

export default function BackdropLoading({
  open,
}: BackdropLoadingProps): JSX.Element {
  return (
    <Box>
      <StyledBackdrop open={open}>
        <BoxContainer>
          <CircularProgress size="5rem" />
        </BoxContainer>
      </StyledBackdrop>
    </Box>
  );
}
