import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContainer = styled(Box)(() => ({
  width: '600px',
  minHeight: '200px',
  padding: '40px 40px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}));

export const ErrorMessage = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: '16px',
  color: theme.palette.common.danger,
}));

export const TextTypography = styled(Typography)(() => ({
  textAlign: 'center',
  fontSize: '22px',
  fontWeight: '600',
  color: '#595959',
}));

export const AlertTypography = styled(Typography)(() => ({
  color: '#595959',
  textAlign: 'center',
}));

export const ButtonBox = styled(Box)(() => ({
  margin: '20px 0',
  display: 'flex',
  gap: '40px',
  justifyContent: 'center',
}));

export const CancelOSButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: '#FAFCFF',
  fontWeight: 600,
  fontSize: 15,
  lineHeight: '20px',
  border: `2px solid ${theme.palette.common.danger}`,
  borderRadius: 10,
  color: theme.palette.common.danger,
  display: 'flex',
  gap: 8,
  '&:hover': {
    backgroundColor: theme.palette.common.danger,
  },
  '&:active': {
    backgroundColor: theme.palette.common.danger,
  },
}));
