import { useEffect, useState } from 'react';

import {
  FirstAccessStages,
  convertFirstAccess,
} from '../../../api/enumerations';
import FirstAccessAuthentication from '../Authentication';
import FirstAccessEmail from '../Email';
import FirstAccessIdentity from '../Identity';
import FirstAccessRegister from '../Register';
import FirstAccessSuccess from '../Success';

export interface FirstAccessComponent {
  stage: FirstAccessStages;
  title?: string;
  component: JSX.Element;
}

interface FirstAccessHook {
  setStageNumber: (value: number) => void;
  stageNumber: number;
  component: FirstAccessComponent | undefined;
}

const useFirstAccess = (): FirstAccessHook => {
  const [stageNumber, setStageNumber] = useState(FirstAccessStages.EMAIL);
  const [component, setComponent] = useState<FirstAccessComponent>();
  const [email, setEmail] = useState<string>('');

  const components = [
    {
      stage: FirstAccessStages.EMAIL,
      title: convertFirstAccess(FirstAccessStages.EMAIL),
      component: (
        <FirstAccessEmail
          handleNextStage={setStageNumber}
          setEmailId={setEmail}
        />
      ),
    },
    {
      stage: FirstAccessStages.AUTHENTICATION,
      title: convertFirstAccess(FirstAccessStages.AUTHENTICATION),
      component: (
        <FirstAccessAuthentication
          handleNextStage={setStageNumber}
          email={email}
        />
      ),
    },
    {
      stage: FirstAccessStages.REGISTER,
      title: convertFirstAccess(FirstAccessStages.REGISTER),
      component: (
        <FirstAccessRegister handleNextStage={setStageNumber} email={email} />
      ),
    },
    {
      stage: FirstAccessStages.IDENTITY,
      title: convertFirstAccess(FirstAccessStages.IDENTITY),
      component: (
        <FirstAccessIdentity handleNextStage={setStageNumber} email={email} />
      ),
    },
    {
      stage: FirstAccessStages.SUCCESS,
      title: convertFirstAccess(FirstAccessStages.SUCCESS),
      component: <FirstAccessSuccess />,
    },
  ];

  useEffect(() => {
    const element = components.find(
      (component) => component.stage === stageNumber
    );
    setComponent(element);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageNumber]);

  return {
    stageNumber,
    component,
    setStageNumber,
  };
};

export default useFirstAccess;
