import { Box, CardMedia, CardMediaProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { UploadBox } from '../../UI/Box';
import { StyledDialog } from '../../UI/Dialog';

export const Dialog = styled(StyledDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    minHeight: '600px',
    padding: '40px 60px 0px',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '800px',
      minWidth: '700px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1100px',
      minWidth: '1000px',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '1300px',
      minWidth: '1200px',
    },
  },
}));

export const Upload = styled(UploadBox)(() => ({
  width: '300px',
  minHeight: '220px',
  gap: '8px',
  margin: '20px auto',
}));

export const InsideBox = styled(Box)(() => ({
  cursor: 'pointer',
  textAlign: 'center',
}));

export const UploadIcon = styled(Typography)(({ theme }) => ({
  '& span': { fontSize: '72px', color: theme.palette.primary.main },
}));

export const UploadTitle = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '14px',
}));

export const UploadSubtitle = styled(Typography)(() => ({
  fontSize: '14px',
}));

export const CropperContainer = styled(Box)(() => ({
  position: 'relative',
  width: '90%',
  minHeight: '460px',
  margin: '0 auto 10px',
}));

export const AvatarBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '24px',
  margin: '20px 0',
  [theme.breakpoints.up('lg')]: {
    margin: '40px 0',
  },
}));

interface StyledCarkMediaProps extends CardMediaProps {
  selected?: boolean;
}

export const StyledCardMedia = styled(CardMedia, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<StyledCarkMediaProps>(({ selected, theme }) => ({
  backgroundSize: 'contain',
  height: '140px',
  width: '140px',
  cursor: 'pointer',
  margin: '0 auto',
  '&:hover': {
    filter: 'brightness(70%)',
  },
  ...(selected && {
    boxShadow: '8px 8px 10px #e038fa',
  }),
  [theme.breakpoints.down('lg')]: {
    height: '90px',
    width: '90px',
  },
}));

export const ButtonBox = styled(Box)(() => ({
  margin: '20px 0 40px',
  display: 'flex',
  justifyContent: 'center',
  gap: '64px',
}));
