import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

export const Text = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 600,
  marginBottom: '6px',
}));

interface StrenghLineProps {
  strengthlevel: number;
}

export const StrenghLine = styled(Box)<StrenghLineProps>(
  ({ theme, strengthlevel }) => {
    const widthPercentage =
      strengthlevel === 0 ? '0' : `${(strengthlevel / 4) * 100}%`;
    return {
      height: '100%',
      width: widthPercentage,
      backgroundColor: theme.palette.primary.main,
      transition: 'width 0.3s',
    };
  }
);

export const StrenghContainer = styled(Box)(({ theme }) => ({
  border: '1px solid #ADADAD',
  height: '11px',
  backgroundColor: theme.palette.common.white,
}));
