/* eslint-disable react/require-default-props */
import { ChangeEvent, DragEvent } from 'react';

import { IconAddPhotoAlternateMS } from '../../../../../constants/icons';
import { Constants } from '../../../../../constants/sampleCreation';
import useUploadFile from '../../../../../hooks/useUploadFile';
import {
  FacadeContainer,
  InsideBox,
  PhotosContainer,
  StyledCardMedia,
  StyledText,
  UploadBox,
  UploadIcon,
  UploadSubtitle,
  UploadText,
} from './styles';

interface UploadPicturesProps {
  facadePicture?: File;
  setFacadePicture(picture: File): void;
  pictureFiles: File[];
  setPictureFiles(picture: File[]): void;
}

export default function UploadPictures({
  facadePicture,
  setFacadePicture,
  pictureFiles,
  setPictureFiles,
}: UploadPicturesProps): JSX.Element {
  const { dragActive, handleDrag, handleDrop, handleFileUpload } =
    useUploadFile();

  const dropFile = (e: DragEvent<HTMLDivElement>): void => {
    const files = handleDrop(e);
    if (files) {
      if (!facadePicture) {
        setFacadePicture(files[0]);
      } else {
        setPictureFiles([...pictureFiles, ...Array.from(files)]);
      }
    }
  };

  const uploadFile = (e: ChangeEvent<HTMLInputElement>): void => {
    const files = handleFileUpload(e);
    if (files) {
      if (!facadePicture) {
        setFacadePicture(files[0]);
      } else {
        setPictureFiles([...pictureFiles, ...Array.from(files)]);
      }
    }
  };

  return (
    <PhotosContainer>
      {!facadePicture ? (
        <FacadeContainer className="insert-photos">
          <UploadBox
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={dropFile}
          >
            <label htmlFor="uploadFacadePhotos">
              <input
                accept="image/*"
                multiple
                id="uploadFacadePhotos"
                type="file"
                style={{ display: 'none' }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => uploadFile(e)}
              />
              {!dragActive ? (
                <InsideBox>
                  <UploadIcon>{IconAddPhotoAlternateMS}</UploadIcon>
                  <UploadText>{Constants.uploadText}</UploadText>
                  <UploadSubtitle>{Constants.fileType}</UploadSubtitle>
                </InsideBox>
              ) : (
                <UploadText>{Constants.dragText}</UploadText>
              )}
            </label>
          </UploadBox>
          <StyledText>{Constants.facade}</StyledText>
        </FacadeContainer>
      ) : (
        <UploadBox
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={dropFile}
        >
          <label htmlFor="uploadSamplePhotos">
            <input
              accept="image/*"
              multiple
              id="uploadSamplePhotos"
              type="file"
              style={{ display: 'none' }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => uploadFile(e)}
            />
            {!dragActive ? (
              <InsideBox>
                <UploadIcon>{IconAddPhotoAlternateMS}</UploadIcon>
                <UploadText>{Constants.uploadText}</UploadText>
                <UploadSubtitle>{Constants.fileType}</UploadSubtitle>
              </InsideBox>
            ) : (
              <UploadText>{Constants.dragText}</UploadText>
            )}
          </label>
        </UploadBox>
      )}
      {facadePicture && (
        <StyledCardMedia
          image={URL.createObjectURL(facadePicture)}
          title={facadePicture.name}
        />
      )}
      {pictureFiles.map((file) => (
        <StyledCardMedia
          key={file.name}
          image={URL.createObjectURL(file)}
          title={file.name}
        />
      ))}
    </PhotosContainer>
  );
}
