/* eslint-disable react/require-default-props */

import { IconAssignmentMS, IconInventoryMS } from '../../../constants/icons';
import { convertDate } from '../../../helpers';
import {
  CreatedStyle,
  FlexBox,
  OSNumber,
  TitleBox,
  TitleTypography,
} from './styles';

interface TitleProps {
  osNumber?: number;
  icon?: JSX.Element;
  title: string;
  newOs?: boolean;
  createdAt?: string;
}

export function Title({
  osNumber,
  icon = IconAssignmentMS,
  title,
  newOs,
  createdAt,
}: TitleProps): JSX.Element {
  const isWorkOrderPage = osNumber || newOs || createdAt;
  return (
    <TitleBox>
      <TitleTypography>
        {icon}
        {title}
      </TitleTypography>
      {isWorkOrderPage && (
        <FlexBox>
          {createdAt && (
            <CreatedStyle>criação {convertDate(createdAt)}</CreatedStyle>
          )}
          {!newOs && !!osNumber && (
            <OSNumber>
              {IconInventoryMS}
              número OS {osNumber}
            </OSNumber>
          )}
        </FlexBox>
      )}
    </TitleBox>
  );
}
