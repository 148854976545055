import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FlexSpaceBetweenBox } from '../../../../../components/UI/Box';
import { Constants } from '../../../../../constants/plans';
import { GlobalContext } from '../../../../../context/global';
import { DiscountTypography, RoundButton, TotalTypography } from '../styles';
import { BoxContainer } from './styles';

interface StripePaymentProps {
  clientSecret: string;
  discount: number;
  total: number;
}

export default function StripePayment({
  clientSecret,
  discount,
  total,
}: StripePaymentProps): JSX.Element {
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    if (elements == null || stripe === null) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(true);
      setSnackbarMessage(submitError.message || 'Algo deu errado.');
      setOpenSnackbar(true);
      return;
    }

    setDisableSubmit(true);

    const response = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: 'https://app.protoai.com.br/configuration',
      },
      redirect: 'if_required',
    });

    if (response.error) {
      setErrorMessage(true);
      setSnackbarMessage(response.error.message || 'Algo deu errado.');
      setOpenSnackbar(true);
      navigate('/configuration');
    } else {
      setErrorMessage(false);
      setSnackbarMessage('Assinatura realizada com sucesso!');
      setOpenSnackbar(true);
      navigate('/configuration');
    }
  };

  return (
    <>
      <BoxContainer component="form" id="payment" onSubmit={handleSubmit}>
        <PaymentElement />
      </BoxContainer>
      <FlexSpaceBetweenBox p="0 16px">
        <DiscountTypography>{Constants.discount}</DiscountTypography>
        <DiscountTypography>
          R$ {discount.toLocaleString().replace(',', '.')}
        </DiscountTypography>
      </FlexSpaceBetweenBox>
      <FlexSpaceBetweenBox px={2}>
        <TotalTypography>{Constants.total}</TotalTypography>
        <TotalTypography>
          R$ {total.toLocaleString().replace(',', '.')}
        </TotalTypography>
      </FlexSpaceBetweenBox>
      <RoundButton
        form="payment"
        type="submit"
        disabled={!stripe || !elements || disableSubmit}
      >
        {Constants.pay}
      </RoundButton>
    </>
  );
}
