/* eslint-disable max-len */
import { InitialModalTour } from '../../Modal/InitialModalTour';

export function ScheduleTour(): JSX.Element {
  return (
    <InitialModalTour
      title="Vamos dar início ao"
      pageName="Agendamento"
      subtext1="Nesta tela faremos o agendamento da OS para algum vistoriador cadastrado em sua empresa. Aqui também será possível visualizar a agenda do vistoriador, para poder analisar melhor data e horário."
      stepTourName="schedule"
    />
  );
}
