import { TableHead, Table, TableBody } from '@mui/material';
import { useState } from 'react';

import { convertProfileUsers } from '../../../../api/enumerations';
import { UserData } from '../../../../api/users/types';
import { Constants } from '../../../../constants/users';
import useGeneral from '../../../../hooks/useGeneral';
import UserDialog from '../UserDialog';
import { Cell, StyledTableCell, Row, StyledTableContainer } from './styles';

interface TableProps {
  updateTable(): void;
  tableData: UserData[];
  userRole: number | undefined;
}

export default function TableUser({
  tableData,
  updateTable,
  userRole,
}: TableProps): JSX.Element {
  const [selectedUser, setSelectedUser] = useState<UserData>();

  const dialog = useGeneral();

  const handleClickOpen = (user: UserData): void => {
    dialog.handleOpen();
    setSelectedUser(user);
  };

  return (
    <StyledTableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <Row>
            <Cell align="center" sx={{ borderTopLeftRadius: 8 }}>
              {Constants.status}
            </Cell>
            <Cell align="center">{Constants.name}</Cell>
            <Cell align="center">{Constants.role}</Cell>
            <Cell align="center" sx={{ borderTopRightRadius: 8 }}>
              {Constants.email}
            </Cell>
          </Row>
        </TableHead>
        <TableBody className="table-body">
          {tableData?.map((user) => (
            <Row key={user.id} onClick={() => handleClickOpen(user)}>
              <StyledTableCell
                align="center"
                sx={{ color: user.is_active ? '#B038FA' : '#8B8C8E' }}
              >
                {user.is_active ? 'ativo' : 'inativo'}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{ color: user.is_active ? '#595959' : '#8b8c8e99' }}
              >
                {user.name}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{ color: user.is_active ? '#595959' : '#8b8c8e99' }}
              >
                {convertProfileUsers(user.role)}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{ color: user.is_active ? '#595959' : '#8b8c8e99' }}
              >
                {user.email}
              </StyledTableCell>
            </Row>
          ))}
        </TableBody>
      </Table>
      {dialog.open && selectedUser && (
        <UserDialog
          open={dialog.open}
          userData={selectedUser}
          handleClose={dialog.handleClose}
          updateTable={updateTable}
          userRole={userRole}
        />
      )}
    </StyledTableContainer>
  );
}
