import {
  Box,
  Button,
  ButtonBase,
  CardMedia,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { UploadBox } from '../../../components/UI/Box';

export const InputBox = styled(Box)(() => ({
  width: '75%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
}));

export const StyledTypography = styled(Typography)(() => ({
  textAlign: 'center',
  color: '#707070',
  margin: '0 -30px',
}));

export const MultiFormControl = styled(FormControlLabel)(({ theme }) => ({
  margin: '8px 0',
  width: '80px',
  '& .MuiButtonBase-root': {
    padding: '4px',
  },
  '&:hover': {
    '& .MuiButtonBase-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiTypography-root': {
      fontWeight: '600',
      fontSize: '18px',
    },
  },
  '& .MuiTypography-root': {
    fontSize: '18px',
    '&.Mui-disabled': {
      color: '#595959',
    },
  },
}));

export const Upload = styled(UploadBox)(({ theme }) => ({
  width: '70%',
  height: '230px',
  gap: '8px',
  cursor: 'pointer',
  [theme.breakpoints.down('xl')]: {
    height: '190px',
  },
}));

export const InsideBox = styled(Box)(() => ({
  cursor: 'pointer',
  textAlign: 'center',
}));

export const UploadIcon = styled(Typography)(({ theme }) => ({
  '& span': { fontSize: '88px', color: theme.palette.primary.main },
}));

export const UploadText = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '14px',
}));

export const UploadSubtitle = styled(Typography)(() => ({
  fontSize: '14px',
}));

export const FileBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  width: '70%',
  height: '230px',
  borderRadius: '20px',
  backgroundSize: 'cover',
  [theme.breakpoints.down('xl')]: {
    height: '190px',
  },
}));

export const FlexReverseBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row-reverse',
}));

export const DeleteButton = styled(ButtonBase)(() => ({
  margin: '-22px 44px 0 0',
  zIndex: '10',
  backgroundColor: '#F2F2F2',
  borderRadius: '50%',
  padding: '4px',
  color: '#914FB2',
  width: '40px',
  height: '40px',
  border: '2px solid #914FB2',
  '& span': {
    fontWeight: '600',
  },
  '&:hover': {
    border: '2px solid #520679',
    color: '#520679',
  },
}));

export const FilledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.secondary.main,
  textTransform: 'uppercase',
  borderRadius: '16px',
  marginTop: '20px',
  '&:hover': {
    backgroundColor: '#9B7359',
    filter: 'brightness(85%)',
  },
}));
