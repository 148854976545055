import { Box, CardMedia, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxContainer = styled(Box)(() => ({
  '.customMap': {
    height: 'clamp(660px, 37.8vw, 725px)',
    width: '100%',
  },
  '.customMap > div:first-of-type': {
    border: '1px solid #D7D8DB',
    borderRadius: '10px',
  },
  '.yNHHyP-marker-view': {
    '&:focus-visible': {
      outline: 'none',
    },
  },
}));

export const LegendBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  gap: '36px',
  marginTop: '4px',
}));

export const Item = styled(Box)(() => ({
  display: 'flex',
  gap: '6px',
}));

export const StyledText = styled(Typography)(() => ({
  fontSize: '16px',
}));

export const Marker = styled(CardMedia)(() => ({
  width: '19px',
}));
