import { Checkbox, FormControlLabel, InputAdornment } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { Constants } from '../../../constants/agenda';
import { IconSearchMS } from '../../../constants/icons';
import { IEngineerData } from '../hooks';
import {
  ColorDot,
  ColorDotBox,
  EngineersContainer,
  FilterContainer,
  StyledInputBase,
} from './styles';

interface FilterEngineersProps {
  usersData: IEngineerData[];
  handleCheckboxChange: (engineerId: number) => void;
  selectedEngineers: number[];
  searchEngineerTerm: string;
  setSearchEngineerTerm: (term: string) => void;
}

export default function FilterEngineers({
  usersData,
  handleCheckboxChange,
  selectedEngineers,
  searchEngineerTerm,
  setSearchEngineerTerm,
}: FilterEngineersProps): JSX.Element {
  const [users, setUsers] = useState<IEngineerData[]>(usersData);
  const [showList, setShowList] = useState<boolean>(false);
  const filterContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!usersData) return;
    if (searchEngineerTerm.length > 0) {
      const searchedEngineers = usersData.filter((engineer) => {
        if (engineer.name) {
          const engineerNameLowerCase = engineer.name.toLowerCase();
          const searchEngineerTermLowerCase = searchEngineerTerm.toLowerCase();
          return engineerNameLowerCase.includes(searchEngineerTermLowerCase);
        }
        return null;
      });
      setUsers(searchedEngineers);
    } else {
      setUsers(usersData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersData, searchEngineerTerm]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        filterContainerRef.current &&
        !filterContainerRef.current.contains(event.target as Node)
      ) {
        setShowList(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [filterContainerRef]);

  return (
    <FilterContainer id="engineers" ref={filterContainerRef}>
      <StyledInputBase
        placeholder={Constants.searchEngineer}
        inputProps={{ 'aria-label': 'pesquisar', maxLength: 11 }}
        type="search"
        startAdornment={
          <InputAdornment position="start" sx={{ color: '#B038FA' }}>
            {IconSearchMS}
          </InputAdornment>
        }
        onChange={(e) => {
          if (e.target.value.length > 2) {
            setSearchEngineerTerm(e.target.value);
          }
          if (e.target.value.length === 0) {
            setSearchEngineerTerm('');
            setUsers(usersData);
          }
        }}
        onFocus={() => setShowList(true)}
      />
      {showList && (
        <EngineersContainer>
          {users.map((engineer) => (
            <FormControlLabel
              key={engineer.id}
              control={
                <Checkbox
                  checked={selectedEngineers.includes(engineer.id)}
                  onChange={() => handleCheckboxChange(engineer.id)}
                />
              }
              label={
                <ColorDotBox>
                  <ColorDot color={engineer.color} />
                  {engineer.name}
                </ColorDotBox>
              }
            />
          ))}
        </EngineersContainer>
      )}
    </FilterContainer>
  );
}
