export const Constants = {
  accessInfo: 'Informações de acesso',
  plan: 'Plano ',
  startPlanText: 'Sua assinatura teve início em **.',
  paymentPlanText:
    ' O pagamento é ** e automático, a próxima cobrança será feita no dia ***.',
  planIncludes: 'Este plano inclui:',
  users: ' usuários (máximo)',
  reports: ' laudos (mês)',
  samples: ' amostras (mês)',
  space: ' de espaço (fotos)',
  spaceSubtitle: 'Aproximadamente ** de fotos',
  access: 'Acesso a todos os módulos',
  accessSubtitle: 'Gestão, Vistoria, Amostrador, Cálculo e Relatório',
  coupon: 'Cupom promocional',
  couponText:
    'Caso tenha um cupom para estender o período da avaliação gratuita, adicione abaixo:',
  insertCoupon: 'inserir cupom',
  apply: 'aplicar',
  choosePlan: 'Escolha um plano abaixo e faça sua assinatura:',
  yearly: 'anual',
  monthly: 'mensal',
  limitless: 'Ilimitado',
  planButton: 'quero este plano',
  rs: 'R$',
  valueMonth: '/mês',
  subscribePlan: 'Contratar plano',
  couponApplied: 'Cupom ** aplicado',
  payCard: 'Pagamento com Cartão',
  discount: 'desconto:',
  total: 'Total:',
  subscribe: 'assinar plano',
  pay: 'pagar',
  popular: 'Mais escolhido!',
};
