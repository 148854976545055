import { Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import {
  EmailIcon,
  IconVisibility,
  PasswordIcon,
} from '../../components/InputIcon';
import LoginLayout from '../../components/LoginLayout';
import { Constants } from '../../constants/login';
import { handleNavigate } from '../../helpers';
import useLogin from './hooks';
import {
  AccessButtonBase,
  FlexAroundBox,
  InputBox,
  InputTitle,
  LinkStyled,
  StyledButtonBase,
  StyledLink,
  StyledText,
  StyledTextField,
  SubmitButton,
} from './styles';

export default function Login(): JSX.Element {
  const {
    handleSubmit,
    email,
    setEmail,
    password,
    setPassword,
    showPassword,
    setShowPassword,
    submitLoading,
  } = useLogin();

  const navigate = useNavigate();

  return (
    <LoginLayout>
      <InputBox component="form" id="login" onSubmit={handleSubmit}>
        <InputTitle>{Constants.title}</InputTitle>
        <StyledTextField
          required
          id="email"
          variant="standard"
          InputProps={EmailIcon}
          placeholder={Constants.emailPlaceholder}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <StyledTextField
          required
          type={showPassword ? 'text' : 'password'}
          variant="standard"
          InputProps={{
            startAdornment: <PasswordIcon />,
            endAdornment: (
              <IconVisibility
                showPassword={showPassword}
                setShowPassword={setShowPassword}
              />
            ),
          }}
          id="password"
          placeholder={Constants.passwordPlaceholder}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <LinkStyled>
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label={Constants.keepConnected}
          />
          <StyledButtonBase
            disableTouchRipple
            onClick={() => navigate('/recover-password')}
          >
            {Constants.forgotPassword}
          </StyledButtonBase>
        </LinkStyled>
        <StyledText sx={{ fontSize: '14px' }}>
          {Constants.acceptTerms.split('*').map((part, index) => {
            if (index % 2 === 1) {
              return (
                <StyledLink
                  to={`${handleNavigate(part)}`}
                  key={part}
                  target="blank"
                >
                  {part}
                </StyledLink>
              );
            }
            return <span key={part}>{part}</span>;
          })}
        </StyledText>
        <SubmitButton form="login" type="submit" disabled={submitLoading}>
          {submitLoading ? <CircularProgress size={22} /> : Constants.submit}
        </SubmitButton>
        <FlexAroundBox>
          <AccessButtonBase
            disableTouchRipple
            onClick={() => navigate('/create-account')}
          >
            {Constants.newAccount}
          </AccessButtonBase>
          <AccessButtonBase
            disableTouchRipple
            onClick={() => navigate('/first-access')}
          >
            {Constants.firstAccess}
          </AccessButtonBase>
        </FlexAroundBox>
      </InputBox>
    </LoginLayout>
  );
}
