import protoSuccess from '../../../assets/avatars/Avatar-9.png';
import { Constants } from '../../../constants/firstAccess';
import { InputBox, StyledCardMedia, StyledTypography } from './styles';

export default function FirstAccessSuccess(): JSX.Element {
  return (
    <InputBox>
      <StyledCardMedia image={protoSuccess} title="sucesso" />
      <StyledTypography>{Constants.docSuccess}</StyledTypography>
      <StyledTypography fontSize={18}>{Constants.redirect}</StyledTypography>
    </InputBox>
  );
}
