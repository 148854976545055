import { Menu } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { pictureAPI, roomAPI } from '../../../../api';
import { RoomData } from '../../../../api/rooms/types';
import standard from '../../../../assets/images/roomImages/standard.png';
import { IconMoreVertMS } from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import { getErrorMessage } from '../../../../helpers';
import { roomsOptions } from '../../../../helpers/roomImages';
import DeleteRoomDialog from '../DeleteRoomDialog';
import EditRoomDialog from '../EditRoomDialog';
import {
  FlexReverseBox,
  RelativeBox,
  StyledCardMedia,
  StyledCardText,
  StyledIconButton,
} from './styles';

export interface RoomCardProps {
  room: RoomData;
  rooms: RoomData[];
  setRooms: (rooms: RoomData[]) => void;
  edit: boolean;
}

export function RoomCard({
  room,
  rooms,
  setRooms,
  edit,
}: RoomCardProps): JSX.Element {
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorMenu);
  const openMenu = (e: React.MouseEvent<HTMLButtonElement>): void =>
    setAnchorMenu(e.currentTarget);
  const closeMenu = (): void => setAnchorMenu(null);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const navigate = useNavigate();
  const { id, inspection } = useParams();
  const osId = parseInt(id as string, 10);
  const inspectionId = parseInt(inspection as string, 10);

  const navigateToRoom = async (roomId: number): Promise<void> => {
    try {
      const response = await pictureAPI.getAllPictures(roomId, 1, 10);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.total_records === 0) {
        if (!edit) {
          throw new Error('Cômodo não possui fotos.');
        }
        navigate(
          `/home/property/${osId}/inspection/${inspectionId}/room/${roomId}/add-photos`
        );
      } else {
        navigate(
          `/home/property/${osId}/inspection/${inspectionId}/room/${roomId}/gallery`
        );
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleRoomImage = (roomId: number): string => {
    const hasImage = roomsOptions.data.find((item) => item.id === roomId);

    if (hasImage) {
      return hasImage.image;
    }
    return standard;
  };

  const handleDeleteRoom = async (roomId: number): Promise<void> => {
    try {
      const response = await roomAPI.deleteRoom(roomId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Cômodo deletado!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      const filterRooms = rooms.filter((el) => el.id !== roomId);
      setRooms(filterRooms);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <RelativeBox key={room.id} className="galery-container">
      <FlexReverseBox>
        <StyledIconButton
          id="actions-button"
          onClick={openMenu}
          sx={{ display: edit ? 'inline-flex' : 'none' }}
        >
          {IconMoreVertMS}
        </StyledIconButton>
        <Menu anchorEl={anchorMenu} open={isMenuOpen} onClose={closeMenu}>
          <EditRoomDialog room={room} rooms={rooms} setRooms={setRooms} />
          <DeleteRoomDialog roomId={room.id} modalCallback={handleDeleteRoom} />
        </Menu>
      </FlexReverseBox>
      <StyledCardMedia
        image={handleRoomImage(room.room_type_id)}
        title={room.name}
        onClick={() => navigateToRoom(room.id)}
      />
      <StyledCardText onClick={() => navigateToRoom(room.id)}>
        {room.name}
      </StyledCardText>
    </RelativeBox>
  );
}
