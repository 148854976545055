import { Box, Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { UploadBox } from '../../../components/UI/Box';

export const Upload = styled(UploadBox)(() => ({
  width: '100%',
  minHeight: '400px',
  gap: '10px',
  marginTop: 20,
}));

export const UploadIcon = styled(Typography)(({ theme }) => ({
  '& span': { fontSize: '172px', color: theme.palette.primary.main },
}));

export const UploadText = styled(Typography)(() => ({
  fontWeight: '600',
}));

export const UploadSubtitle = styled(Typography)(() => ({
  fontSize: '14px',
  marginBottom: '20px',
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: '20px 0',
  '&.MuiDivider-root::before': {
    borderTop: `4px solid ${theme.palette.primary.dark}`,
  },
  '&.MuiDivider-root::after': {
    borderTop: `4px solid ${theme.palette.primary.dark}`,
  },
  '& span': {
    fontSize: '18px',
    fontWeight: '600',
    padding: '0 14px',
  },
}));

export const TipsBox = styled(Box)(() => ({
  backgroundColor: '#FAFCFF',
  borderRadius: '8px',
  boxShadow: '3px 3px 6px #8B8C8E',
  textAlign: 'center',
  padding: '20px',
  minHeight: '180px',
}));

export const TipsTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '700',
  color: theme.palette.primary.main,
  paddingBottom: '10px',
}));

export const TipsText = styled(Typography)(() => ({
  fontSize: '14px',
  color: '#595959',
}));
