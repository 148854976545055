/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  LineController,
  BarController,
  ChartData,
} from 'chart.js';
import React, { useEffect, useRef } from 'react';
import { Chart } from 'react-chartjs-2';

import { ChartBox, ChartContainer, ChartTitle } from '../styles';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  LineController,
  BarController
);

interface AxisProps {
  x: number;
  y: number;
}

interface HistogramProps {
  start: number;
  end: number;
  value: number;
}

interface LineBarProps {
  chart: {
    chart_title: string;
    x_axis_title: string;
    y_axis_title: string;
    line_data: {
      title: string;
      color: string;
      data: AxisProps[];
    };
    histogram_data: HistogramProps[];
  };
  chartCallback?: (img: HTMLCanvasElement, title: string) => Promise<void>;
}

const LineBar = React.memo(({ chart, chartCallback }: LineBarProps) => {
  const chartRef = useRef<
    ChartJS<
      'line' | 'bar',
      | number[]
      | {
          x: number;
          y: number;
          _custom: {
            start: number;
            end: number;
          };
        }[]
    >
  >(null);

  const handleSaveImage = (): void => {
    if (chartRef?.current) {
      const { canvas } = chartRef.current;

      if (chartCallback) {
        chartCallback(canvas, chart.chart_title);
      }
    }
  };

  useEffect(() => {
    if (chartRef !== null) handleSaveImage();
  }, [chartRef]);

  const options = {
    animation: {
      duration: 0,
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        title: {
          display: true,
          text: chart.y_axis_title,
          font: {
            size: 16,
          },
        },
      },
      x: {
        id: 'x',
        title: {
          display: true,
          text: chart.x_axis_title,
          font: {
            size: 16,
          },
        },
        type: 'linear' as const,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const data: ChartData<
    'line' | 'bar',
    | number[]
    | {
        x: number;
        y: number;
        _custom: {
          start: number;
          end: number;
        };
      }[]
  > = {
    labels: chart.line_data.data.map((e) => e.x),
    datasets: [
      {
        type: 'line' as const,
        data: chart.line_data.data.map((e) => e.y),
        backgroundColor: chart.line_data.color,
        borderColor: chart.line_data.color,
        pointRadius: 0,
        tension: 0.4,
      },
      {
        type: 'bar' as const,
        data: chart.histogram_data.map((e) => ({
          x: e.start,
          y: e.value,
          _custom: {
            start: e.start,
            end: e.end,
          },
        })),
        backgroundColor: '#967cbc',
        barPercentage: 1,
        categoryPercentage: 1,
      },
    ],
  };

  return (
    <ChartContainer>
      <ChartTitle>{chart.chart_title}</ChartTitle>
      <ChartBox>
        <Chart ref={chartRef} options={options} type="bar" data={data} />
      </ChartBox>
    </ChartContainer>
  );
});

export default LineBar;
