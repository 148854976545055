import { Box, Table, TableBody, TableHead } from '@mui/material';

import { PropertyType } from '../../../../api/enumerations';
import { SampleElementData } from '../../../../api/samplesLists/types';
import { IconDeleteMS } from '../../../../constants/icons';
import { Constants } from '../../../../constants/samples';
import { presentTableHead } from './presenter';
import { Cell, Row, StyledTableCell, StyledTableContainer } from './styles';

interface ElementTableProps {
  tableData: SampleElementData[] | undefined;
  handleDeleteElement: (elementId: number) => Promise<void>;
  propertyType: PropertyType | undefined;
}

export function ElementTable({
  tableData,
  handleDeleteElement,
  propertyType,
}: ElementTableProps): JSX.Element {
  return (
    <Box textAlign="center">
      {tableData && tableData?.length > 0 ? (
        <StyledTableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <Row className="table">
                <Cell align="center" small="true">
                  {Constants.item}
                </Cell>
                <Cell align="center" small="true">
                  {Constants.actions}
                </Cell>
                {presentTableHead(propertyType).map((cell) => (
                  <Cell
                    align="center"
                    key={cell.id}
                    small={cell.small ? 'true' : 'false'}
                  >
                    {cell.label}
                  </Cell>
                ))}
              </Row>
            </TableHead>
            <TableBody>
              {tableData.map((element, index) => (
                <Row key={element.id}>
                  <StyledTableCell align="center">{index + 1}</StyledTableCell>
                  <StyledTableCell
                    align="center"
                    button="true"
                    onClick={() => handleDeleteElement(element.id)}
                  >
                    {IconDeleteMS}
                  </StyledTableCell>
                  {presentTableHead(propertyType, element).map((cell) => (
                    <StyledTableCell key={cell.id} align="center">
                      {cell.value}
                    </StyledTableCell>
                  ))}
                </Row>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      ) : (
        Constants.noElements
      )}
    </Box>
  );
}
