import { Config } from 'driver.js';

export const driverConfig: Config = {
  popoverClass: 'driverjs-theme',
  showButtons: ['next', 'previous', 'close'],
  nextBtnText: 'próximo',
  prevBtnText: 'voltar',
  doneBtnText: 'concluir',
  stagePadding: 10,
  showProgress: true,
  progressText: '({{current}}/{{total}})',
  allowClose: true,
};
