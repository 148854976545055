import {
  TableCell,
  TableRow,
  TableContainer,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTableContainer = styled(TableContainer)(() => ({
  width: '100%',
  maxHeight: '800px',
}));

export const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: '600',
    fontSize: '16px',
    color: '#F2F2F2',
    backgroundColor: theme.palette.primary.dark,
  },
  '&:first-of-type': {
    borderRightTopRadius: 16,
  },
}));

export const StyledTableCell = styled(TableCell)(() => ({
  color: '#1A1A1A',
  padding: '12px',
  fontSize: '14px',
  maxWidth: '200px',
}));

export const DescriptionTableCell = styled(TableCell)(({ theme }) => ({
  padding: '12px',
  color: '#CE273B',
  fontSize: '15px',
  textAlign: 'left',
  maxWidth: '280px',
  [theme.breakpoints.up('xl')]: {
    maxWidth: '300px',
  },
}));

export const Row = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  border: '1px solid #1A1A1A',
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    '& > td': {
      color: '#FAFCFF',
    },
  },
}));
