import { Button, Grid, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTextField = styled(TextField)(() => ({
  width: '100px',
  '& .MuiInputBase-root': { borderRadius: '16px' },
  '& .MuiInputBase-input': { padding: '14px' },
}));

export const StyledGrid = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const SliderGrid = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const DeleteButton = styled(Button)(() => ({
  width: 200,
  height: 48,
  background: '#E81710 0% 0% no-repeat padding-box',
  boxShadow: '0px 3px 6px #00000029',
  borderRadius: 10,
  opacity: 1,
  display: 'flex',
  gap: 10,
  '&:hover': {
    backgroundColor: '#D0140E',
  },
}));
