import { Link } from 'react-router-dom';

import { Constants, links } from '../../../constants/createAccount';
import { IconCheckMS } from '../../../constants/icons';
import { Title } from '../styles';
import {
  InputBox,
  LinkBox,
  LinkText,
  StyledTypography,
  Text,
  TextBox,
} from './styles';

export default function IdentityVerification(): JSX.Element {
  return (
    <>
      <Title>{Constants.ready}</Title>
      <InputBox>
        <StyledTypography>
          {Constants.registerFinished}
          {IconCheckMS}
        </StyledTypography>
        <TextBox>
          {Constants.accessGranted.map((line) => (
            <Text key={line}>{line}</Text>
          ))}
        </TextBox>
        <LinkBox>
          {links.map((link) => (
            <LinkText key={link.id}>
              <link.icon sx={{ color: '#520679', fontSize: 36 }} />
              <Link to={link.link} target="_blank">
                {link.title}
              </Link>
            </LinkText>
          ))}
        </LinkBox>
      </InputBox>
    </>
  );
}
