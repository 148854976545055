export const Constants = {
  title: 'Usuário',
  buttonAdd: 'adicionar usuário',
  buttonEdit: 'confirmar alterações',
  panel: 'Painel de usuários',
  status: 'situação',
  active: 'ativo',
  name: 'nome',
  role: 'função',
  email: 'e-mail',
  telephone: 'telefone',
  newUser: 'nome do usuário',
  editUser: 'editar dados do usuário',
  rolePlaceholder: 'selecione a função',
  protoUser: 'usuário do ProtoApp',
};
