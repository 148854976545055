import {
  IconCheckCircleMS,
  IconRunningWithErrorsMS,
} from '../../../../constants/icons';
import { AlertButton, CheckedButton } from './styles';

interface DashboardIconProps {
  page: number;
  setPage: (value: number) => void;
  sampleId: number;
  alert: boolean;
}

export function DashboardIcon({
  page,
  setPage,
  sampleId,
  alert,
}: DashboardIconProps): JSX.Element {
  return alert ? (
    <AlertButton onClick={() => setPage(page)}>
      {sampleId}
      {IconRunningWithErrorsMS}
    </AlertButton>
  ) : (
    <CheckedButton onClick={() => setPage(page)}>
      {sampleId}
      {IconCheckCircleMS}
    </CheckedButton>
  );
}
