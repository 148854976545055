import {
  ButtonBase,
  Pagination,
  Table,
  TableBody,
  TableHead,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import {
  convertPropertyType,
  convertRegistrationUf,
} from '../../../api/enumerations';
import { SampleList } from '../../../api/samplesLists/types';
import ConfirmationDialog from '../../../components/Dialog/ConfirmationDialog';
import { IconDeleteMS } from '../../../constants/icons';
import { Constants } from '../../../constants/samples';
import { convertDate } from '../../../helpers';
import {
  Cell,
  PagesContainer,
  Row,
  StyledTableCell,
  StyledTableContainer,
} from './styles';

interface SampleTableProps {
  tableData: SampleList[];
  page: number;
  setPage: (value: number) => void;
  totalPages: number;
  handleDeleteSample: (sampleId: number) => Promise<void>;
}

export function SampleTable({
  tableData,
  page,
  setPage,
  totalPages,
  handleDeleteSample,
}: SampleTableProps): JSX.Element {
  const navigate = useNavigate();

  const handleChangePage = (pageNumber: number): void => {
    setPage(pageNumber);
  };
  return (
    <>
      <StyledTableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <Row className="table">
              <Cell align="center">{Constants.sampleName}</Cell>
              <Cell align="center">{Constants.city}</Cell>
              <Cell align="center">{Constants.uf}</Cell>
              <Cell align="center">{Constants.realState}</Cell>
              <Cell align="center">{Constants.createdAt}</Cell>
              <Cell align="center">{Constants.actions}</Cell>
            </Row>
          </TableHead>
          <TableBody>
            {tableData?.map((sample) => (
              <Row
                key={sample.id}
                onClick={() => navigate(`/samples/${sample.id}`)}
              >
                <StyledTableCell align="center">
                  {sample.region_name}
                </StyledTableCell>
                <StyledTableCell align="center">{sample.city}</StyledTableCell>
                <StyledTableCell align="center">
                  {convertRegistrationUf(sample.uf)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {convertPropertyType(sample.real_estate_kind)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {convertDate(sample.created_at)}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ color: '#B038FA' }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <ConfirmationDialog
                    id="delete-sample"
                    button={<ButtonBase>{IconDeleteMS}</ButtonBase>}
                    text="excluir esta amostra e os elementos salvos"
                    modalCallback={() => {
                      handleDeleteSample(sample.id);
                    }}
                  />
                </StyledTableCell>
              </Row>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <PagesContainer>
        <Pagination
          page={page}
          count={totalPages}
          onChange={(_, page) => handleChangePage(page)}
        />
      </PagesContainer>
    </>
  );
}
