/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';

import { PicturesData } from '../../../../api/sample/types';
import {
  IconArrowBackMS,
  IconArrowForwardMS,
  IconCloseMS,
} from '../../../../constants/icons';
import useGeneral from '../../../../hooks/useGeneral';
import {
  CloseButton,
  ContainerModal,
  MainBox,
  MainCardMedia,
  MoreImages,
  PicturesContainer,
  ScrollContainer,
  SliderButton,
  StyledBox,
  StyledCardMedia,
  ThumbCardMedia,
} from './styles';

interface AllPicturesSliderProps {
  file: PicturesData;
  images: PicturesData[];
  getMorePictures(): Promise<void>;
  loading: boolean;
  isUpdated: boolean;
}

export default function AllPicturesSlider({
  file,
  images,
  getMorePictures,
  loading,
  isUpdated,
}: AllPicturesSliderProps): JSX.Element {
  const [current, setCurrent] = useState(0);

  const dialog = useGeneral();

  const { length } = images;

  const nextImage = (): void => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevImage = (): void => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  useEffect(() => {
    if (dialog.open) {
      const index = images.findIndex((image) => image.file === file.file);
      if (index !== -1) {
        setCurrent(index);
      } else {
        setCurrent(0);
      }
    }
  }, [dialog.open]);

  return (
    <>
      <StyledCardMedia
        image={file.file}
        title={file.file}
        onClick={dialog.handleOpen}
      />
      <ContainerModal
        open={dialog.open}
        onClose={dialog.handleClose}
        aria-labelledby="picture dialog"
      >
        <StyledBox>
          <MainBox>
            <CloseButton onClick={dialog.handleClose}>
              {IconCloseMS}
            </CloseButton>
            <SliderButton disableTouchRipple onClick={prevImage}>
              {IconArrowBackMS}
            </SliderButton>
            {images.map(
              (slide, index) =>
                index === current && (
                  <MainCardMedia
                    key={slide.id ? slide.id : slide.caption}
                    image={slide.file}
                    component="img"
                  />
                )
            )}
            <SliderButton disableTouchRipple onClick={nextImage}>
              {IconArrowForwardMS}
            </SliderButton>
          </MainBox>
          <PicturesContainer>
            <ScrollContainer>
              {images.map((slide, index) => (
                <ThumbCardMedia
                  key={slide.id ? slide.id : slide.caption}
                  image={slide.file}
                  component="img"
                  onClick={() => setCurrent(index)}
                />
              ))}
              {!isUpdated && (
                <MoreImages
                  disabled={loading}
                  onClick={() => {
                    getMorePictures();
                    setCurrent(0);
                  }}
                >
                  {loading ? <CircularProgress size={32} /> : 'Mais imagens'}
                </MoreImages>
              )}
            </ScrollContainer>
          </PicturesContainer>
        </StyledBox>
      </ContainerModal>
    </>
  );
}
