/* eslint-disable react/require-default-props */
import { EventImpl } from '@fullcalendar/core/internal';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  convertClientKind,
  convertEvaluationType,
  convertReportFunction,
  convertReportGoal,
  convertStatusUrl,
} from '../../../api/enumerations';
import { WorkOrderData } from '../../../api/workOrders/types';
import { IconCloseMS } from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import useGeneral from '../../../hooks/useGeneral';
import { CloseIcon, RoundedButton } from '../../UI/Button';
import { StyledDialog } from '../../UI/Dialog';
import {
  CloseButtonBox,
  DialogContainer,
  OsButton,
  SpaceAroundBox,
  StyledTypography,
  Title,
  spanStyle,
} from './styles';

export function EventDialog({
  selectedEvent,
  modalData,
  deleteSchedule,
  open,
  handleClose,
}: {
  selectedEvent: EventImpl;
  modalData: WorkOrderData | undefined;
  deleteSchedule?: (id: string) => Promise<void>;
  open: boolean;
  handleClose: () => void;
}): JSX.Element {
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const dialog = useGeneral();
  const navigate = useNavigate();

  const navigateToOs = (): void => {
    if (!modalData) {
      setSnackbarMessage('Não foi possível identificar essa OS.');
      setErrorMessage(true);
      setOpenSnackbar(true);
    } else {
      const status = convertStatusUrl(modalData.status);
      handleClose();
      navigate(`/home/property/${modalData?.id}/${status}`);
      window.location.reload();
    }
  };
  return (
    <StyledDialog open={open} onClose={handleClose}>
      <CloseButtonBox>
        <CloseIcon onClick={handleClose}>{IconCloseMS}</CloseIcon>
      </CloseButtonBox>
      <DialogContainer>
        <Title>
          {modalData && `Número da OS: ${modalData.reference_number}`}
        </Title>
        <StyledTypography>
          cliente:{' '}
          <span style={spanStyle}>{modalData && modalData.client_name}</span>
        </StyledTypography>
        <StyledTypography>
          tipo do cliente:{' '}
          <span style={spanStyle}>
            {modalData && convertClientKind(modalData.client_kind)}
          </span>
        </StyledTypography>
        <StyledTypography>
          localização:{' '}
          <span style={spanStyle}>{modalData && modalData.street}</span>
        </StyledTypography>
        <StyledTypography>
          tipo de laudo:{' '}
          <span style={spanStyle}>
            {modalData && convertEvaluationType(modalData.evaluation_type)}
          </span>
        </StyledTypography>
        <StyledTypography>
          objetivo:{' '}
          <span style={spanStyle}>
            {modalData && convertReportGoal(modalData.report_goal)}
          </span>
        </StyledTypography>
        <StyledTypography>
          finalidade:{' '}
          <span style={spanStyle}>
            {modalData && convertReportFunction(modalData.report_function)}
          </span>
        </StyledTypography>
        {modalData && modalData.id !== dialog.osId && (
          <OsButton onClick={() => navigateToOs()}>
            ir para OS {modalData?.reference_number}
          </OsButton>
        )}
        {deleteSchedule && (
          <SpaceAroundBox>
            <RoundedButton
              model="filled"
              width="md"
              onClick={() => {
                deleteSchedule(selectedEvent.id);
                dialog.handleClose();
              }}
            >
              excluir agendamento
            </RoundedButton>
          </SpaceAroundBox>
        )}
      </DialogContainer>
    </StyledDialog>
  );
}
