/* eslint-disable max-len */
export const Constants = {
  forms: 'Formulários',
  newForm: 'novo formulário',
  saved: 'Salvos',
  lastModified: 'última modificação',
  createForm: 'Criar Formulário',
  editForm: 'Editar Formulário',
  importQuestions: 'importar perguntas',
  editAnswers: 'permitir edição de respostas',
  showProgress: 'mostrar barra de progresso',
  download: 'baixar',
  questionBank: 'Banco de Perguntas',
  newQuestion: 'nova pergunta',
  editQuestion: 'editar pergunta',
  selectedQuestions: 'Perguntas Selecionadas',
  selectAll: 'selecionar tudo',
  add: 'adicionar',
  remove: 'remover',
  view: 'visualizar',
  cancel: 'cancelar',
  save: 'salvar',
  edit: 'editar',
  delete: 'excluir',
  required: 'obrigatória',
  addOption: 'adicionar opção',
  jumpToText:
    'Esta pergunta não pode ser deletada enquanto estiver relacionada com a pergunta ',
  deleteForm: 'excluir este formulário?',
  no: 'não',
  yes: 'sim',
  deleteAlert: 'Atenção! Esta ação não poderá ser desfeita.',
  formModels: 'modelos de formulários',
  toolTipText:
    'Aqui você pode escolher um modelo de formulário já criado para utilizar e se necessário, poderá alterá-lo conforme suas necessidades.',
};
