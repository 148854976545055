import { FormControl, Radio, RadioGroup, TextField } from '@mui/material';
import { useContext, useState } from 'react';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';

import { userAPI } from '../../../api';
import { FirstAccessStages, StatusCode } from '../../../api/enumerations';
import { IconVisibility, PasswordIcon } from '../../../components/InputIcon';
import { Constants } from '../../../constants/firstAccess';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage, validatePassword } from '../../../helpers';
import {
  ButtonBox,
  FilledButton,
  InputBox,
  MultiFormControl,
  OutlinedButton,
  StyledPatternFormat,
  StyledTextField,
  StyledTypography,
} from './styles';

interface RegisterProps {
  handleNextStage: (stage: FirstAccessStages) => void;
  email: string;
}

export default function FirstAccessRegister({
  handleNextStage,
  email,
}: RegisterProps): JSX.Element {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [counselIdType, setCounselIdType] = useState<string>('CREA');
  const [counselId, setCounselId] = useState<string>('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [confirmation, setConfirmation] = useState('');
  const [confirmationError, setConfirmationError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const navigate = useNavigate();
  const navigateLogin = (): void => {
    navigate('/login');
  };

  const handleSubmitPassword = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    const data = {
      email,
      name,
      phone_number: phone,
      engineer_document: counselId,
      engineer_document_type: counselIdType,
      password,
      password_confirmation: confirmation,
    };

    try {
      const response = await userAPI.sendUserData(data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Cadastro realizado com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      handleNextStage(FirstAccessStages.IDENTITY);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  };

  const handleCounsel = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCounselIdType((event.target as HTMLInputElement).value);
  };

  return (
    <InputBox component="form" id="register" onSubmit={handleSubmitPassword}>
      <StyledTypography>{Constants.registerText}</StyledTypography>
      <StyledTextField
        required
        id="name"
        variant="standard"
        placeholder={Constants.name}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <PatternFormat
        required
        id="telephone"
        format="(##) #####-####"
        variant="standard"
        color="secondary"
        mask="&#8212;"
        customInput={TextField}
        placeholder={Constants.phone}
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        sx={StyledPatternFormat}
      />
      <ButtonBox alignItems="flex-start">
        <FormControl sx={{ minWidth: '160px' }}>
          <RadioGroup row value={counselIdType} onChange={handleCounsel}>
            <MultiFormControl
              value={Constants.crea}
              label={Constants.crea}
              control={<Radio color="primary" />}
            />
            <MultiFormControl
              value={Constants.cau}
              label={Constants.cau}
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </FormControl>
        <NumericFormat
          required
          id="counsel"
          variant="standard"
          color="secondary"
          customInput={TextField}
          value={counselId}
          onChange={(e) => setCounselId(e.target.value)}
          sx={StyledPatternFormat}
        />
      </ButtonBox>
      <StyledTextField
        required
        type={showPassword ? 'text' : 'password'}
        variant="standard"
        helperText={password && Constants.passwordHelp}
        InputProps={{
          startAdornment: <PasswordIcon color="#8B8C8E" />,
          endAdornment: (
            <IconVisibility
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              color="#8B8C8E"
            />
          ),
        }}
        id="password"
        placeholder={Constants.password}
        error={passwordError}
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
          !validatePassword(e.target.value)
            ? setPasswordError(true)
            : setPasswordError(false);
        }}
      />
      <StyledTextField
        required
        type={showConfirmation ? 'text' : 'password'}
        variant="standard"
        helperText={confirmation && Constants.confirmHelp}
        InputProps={{
          startAdornment: <PasswordIcon color="#8B8C8E" />,
          endAdornment: (
            <IconVisibility
              showPassword={showConfirmation}
              setShowPassword={setShowConfirmation}
              color="#8B8C8E"
            />
          ),
        }}
        id="confirmation"
        placeholder={Constants.confirmPassword}
        error={confirmationError}
        value={confirmation}
        onChange={(e) => {
          setConfirmation(e.target.value);
          e.target.value !== password
            ? setConfirmationError(true)
            : setConfirmationError(false);
        }}
      />
      <ButtonBox>
        <OutlinedButton onClick={navigateLogin}>
          {Constants.cancel}
        </OutlinedButton>
        <FilledButton
          form="register"
          type="submit"
          disabled={passwordError || confirmationError}
        >
          {Constants.continue}
        </FilledButton>
      </ButtonBox>
    </InputBox>
  );
}
