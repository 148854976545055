import { Snackbar as MuiSnackbar } from '@mui/material';
import { useContext } from 'react';

import { IconCheckCircleMS, IconErrorMS } from '../../constants/icons';
import { GlobalContext } from '../../context/global';
import { ErrorTypography, SuccessTypography } from './styles';

export default function Snackbar(): JSX.Element {
  const { openSnackbar, setOpenSnackbar, errorMessage, snackbarMessage } =
    useContext(GlobalContext);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ): void => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const sbErrorMessage = (
    <ErrorTypography>
      {IconErrorMS}
      {snackbarMessage}
    </ErrorTypography>
  );

  const successMessage = (
    <SuccessTypography>
      {IconCheckCircleMS}
      {snackbarMessage}
    </SuccessTypography>
  );

  return (
    <MuiSnackbar
      open={openSnackbar}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={handleClose}
      message={errorMessage ? sbErrorMessage : successMessage}
      sx={{ '.MuiSnackbarContent-root': { minWidth: 'fit-content' } }}
    />
  );
}
