/* eslint-disable react/require-default-props */
import { Box, FormControlLabel, TextField } from '@mui/material';
import { useEffect } from 'react';
import { NumericFormat } from 'react-number-format';

import { ProfileUsers } from '../../../../api/enumerations';
import { UserData } from '../../../../api/users/types';
import {
  CustomPatternFormat,
  CustomTextField,
  SelectTextField,
} from '../../../../components/CustomInput';
import {
  CloseIcon,
  FilledButton,
  StandardButton,
} from '../../../../components/UI/Button';
import { StyledDialog } from '../../../../components/UI/Dialog';
import { DialogTitle } from '../../../../components/UI/Typography';
import {
  IconAddMS,
  IconCloseMS,
  IconPersonAddMS,
} from '../../../../constants/icons';
import { selectProfileUser } from '../../../../constants/selectOptions';
import { Constants } from '../../../../constants/users';
import useGeneral from '../../../../hooks/useGeneral';
import useUsers from '../hooks';
import {
  ButtonBox,
  DialogContainer,
  FormStyled,
  StyledCheckbox,
  StyledPatternFormat,
} from './styles';

export interface UserDialogProps {
  open?: boolean;
  userData?: UserData;
  userRole?: number;
  handleClose?: () => void;
  updateTable(): void;
}

export default function UserDialog({
  open,
  userData,
  handleClose,
  userRole,
  updateTable,
}: UserDialogProps): JSX.Element {
  const dialog = useGeneral();
  const {
    handleNewUser,
    handleEdit,
    name,
    setName,
    email,
    setEmail,
    selectedRole,
    setSelectedRole,
    active,
    setActive,
    counselId,
    setCounselId,
    counselIdType,
    setCounselIdType,
    personalId,
    setPersonalId,
    personalIdType,
    setPersonalIdType,
    telephone,
    setTelephone,
  } = useUsers();

  useEffect(() => {
    if (userData) {
      setEmail(userData.email);
      setName(userData.name);
      setSelectedRole(userData.role);
      setActive(userData.is_active);
      setCounselId(userData.engineer_document || '');
      setCounselIdType(userData.engineer_document_type || 'CREA/CAU');
      setPersonalId(userData.personal_document || '');
      setPersonalIdType(userData.personal_document_type || 'RG/CNH');
      setTelephone(userData.phone_number || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const resetInputs = (): void => {
    setName('');
    setEmail('');
    setSelectedRole(0);
  };

  const handleSubmitAdd = (e: React.FormEvent): void => {
    handleNewUser(e).then(() => updateTable());
    resetInputs();
    dialog.handleClose();
  };

  const handleSubmitEdit = (e: React.FormEvent): void => {
    if (userData) {
      handleEdit(e, userData).then(() => updateTable());
      if (handleClose) handleClose();
    }
  };

  return (
    <div id="new-user-btn">
      {!userData && (
        <StandardButton
          onClick={dialog.handleOpen}
          sx={{ marginRight: '20px' }}
        >
          {IconAddMS}
          {Constants.buttonAdd}
        </StandardButton>
      )}
      <StyledDialog
        open={open || dialog.open}
        onClose={handleClose || dialog.handleClose}
        aria-labelledby="upload logo"
      >
        <DialogContainer>
          <CloseIcon onClick={handleClose || dialog.handleClose}>
            {IconCloseMS}
          </CloseIcon>
          <DialogTitle>
            {IconPersonAddMS}
            {Constants.buttonAdd}
          </DialogTitle>
          <FormStyled
            component="form"
            id="login"
            onSubmit={userData ? handleSubmitEdit : handleSubmitAdd}
          >
            {userData && (
              <>
                <FormControlLabel
                  control={
                    <StyledCheckbox
                      checked={active}
                      onChange={() => setActive(!active)}
                    />
                  }
                  label={Constants.active}
                />
                <Box display="flex" gap="2rem">
                  <NumericFormat
                    required
                    disabled={!userData.first_access}
                    id="counsel-id"
                    color="secondary"
                    customInput={TextField}
                    label={counselIdType}
                    value={counselId}
                    onChange={(e) => setCounselId(e.target.value)}
                    sx={StyledPatternFormat}
                  />
                  <NumericFormat
                    required
                    disabled={!userData.first_access}
                    id="personal-id"
                    color="secondary"
                    customInput={TextField}
                    label={personalIdType}
                    value={personalId}
                    onChange={(e) => setPersonalId(e.target.value)}
                    sx={StyledPatternFormat}
                  />
                </Box>
              </>
            )}
            <CustomTextField
              required
              id="user-name"
              label={Constants.name}
              value={name}
              setValue={setName}
            />
            <CustomTextField
              required
              id="user-email"
              label={Constants.email}
              value={email}
              setValue={setEmail}
            />
            <Box display="flex" gap="2rem">
              <SelectTextField
                id="user-role"
                label={Constants.role}
                required
                disable={userRole !== ProfileUsers.ADMIN}
                value={selectedRole}
                setValue={setSelectedRole}
                selectOptions={selectProfileUser()}
              />
              {userData && (
                <CustomPatternFormat
                  disabled={!userData.first_access}
                  required
                  minLength={11}
                  id="telephone"
                  label={Constants.telephone}
                  value={telephone}
                  setValue={setTelephone}
                  format="(##) #####-####"
                />
              )}
            </Box>
            <ButtonBox>
              <FilledButton form="login" type="submit">
                {userData ? Constants.buttonEdit : Constants.buttonAdd}
              </FilledButton>
            </ButtonBox>
          </FormStyled>
        </DialogContainer>
      </StyledDialog>
    </div>
  );
}
