/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, IconButton } from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { companyAPI } from '../../../api';
import { RegisterStage, StatusCode } from '../../../api/enumerations';
import { Constants } from '../../../constants/createAccount';
import { IconCheckCircleMS, IconCloseMS } from '../../../constants/icons';
import { RegistrationIdentificationContext } from '../../../context/registrationIdentification';
import { validateEmail } from '../../../helpers';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useSnackbar from '../../../hooks/useSnackbar';
import {
  EmailInput,
  InputBox,
  StyledText,
  StyledToast,
  SubmitButton,
  ToastTypography,
} from './styles';

interface IdentificationProps {
  handleNextStage: (stage: RegisterStage) => void;
}

export function Identification({
  handleNextStage,
}: IdentificationProps): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [openToast, setOpenToast] = useState(false);

  const { setIdentificationEmail, setPhoneNumber, toastMessage } = useContext(
    RegistrationIdentificationContext
  );
  const { getErrorMessage } = useErrorMessage();
  const { handleSnackbar } = useSnackbar();

  useEffect(() => {
    if (toastMessage) {
      setOpenToast(true);
    }
  }, [toastMessage]);

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setLoading(true);

      if (validateEmail(email)) {
        try {
          const { data, detail } = await companyAPI.registrationStatus(email);

          if (detail.status_code !== StatusCode.OK && detail.description) {
            throw new Error(detail.description);
          }

          if (data) {
            if (data.status === 1) {
              setIdentificationEmail(email);
              handleNextStage(RegisterStage.REGISTER_FORM);
            }
            if (data.status === 2) {
              setIdentificationEmail(email);
              setPhoneNumber(data.phone_number);
              handleNextStage(RegisterStage.AUTHENTICATION);
            }
            if (data.status === 3) {
              setIdentificationEmail(email);
              setPhoneNumber(data.phone_number);
              handleNextStage(RegisterStage.DOCUMENTS_VERIFICATION);
            }
            setLoading(false);
          }
        } catch (error) {
          handleSnackbar(getErrorMessage(error), true);
          setLoading(false);
        }
      } else {
        handleSnackbar('Email inválido', true);
        setLoading(false);
      }
    },
    [email]
  );

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ): void => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      {IconCloseMS}
    </IconButton>
  );

  return (
    <InputBox component="form" id="identification">
      <StyledText>{Constants.informEmail}</StyledText>
      <EmailInput
        label="e-mail principal"
        type="email"
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setEmail(e.target.value)
        }
      />
      <SubmitButton
        form="identification"
        type="submit"
        onClick={handleSubmit}
        disabled={loading}
      >
        {loading ? (
          <CircularProgress color="inherit" size={22} />
        ) : (
          Constants.continue
        )}
      </SubmitButton>
      <StyledToast
        open={openToast}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleClose}
        action={action}
        message={
          <ToastTypography>
            {IconCheckCircleMS}
            {toastMessage}
          </ToastTypography>
        }
      />
    </InputBox>
  );
}
