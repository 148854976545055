import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ButtonContainer = styled(Box)(() => ({
  margin: '20px 0',
  display: 'flex',
  justifyContent: 'space-around',
  gap: '40px',
}));

export const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '20px',
}));

export const ButtonTitle = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: '600',
  padding: '10px',
}));
