import {
  Box,
  Button,
  ButtonBase,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

export const InputBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '70%',
}));

export const InputTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: '700',
  marginBottom: '4vw',
  color: theme.palette.secondary.main,
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  fontSize: '18px',
  width: '75%',
  marginBottom: '40px',
  '& .MuiInputBase-input': {
    color: theme.palette.common.black,
    fontSize: '18px',
  },
  '& .MuiInputBase-root.MuiInput-root:before': {
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
  '& .MuiInputBase-root.MuiInput-root:after': {
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
  '& .MuiInputBase-root.MuiInput-root.Mui-error:before': {
    borderBottom: '1px solid #d32f2f',
  },
  '& .MuiInputBase-root.MuiInput-root.Mui-error:after': {
    borderBottom: '1px solid #d32f2f',
  },
  '& .MuiInputBase-root.MuiInput-root:hover:before': {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  '& .MuiInputBase-root.MuiInput-root:hover:after': {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  'input:hover::-webkit-input-placeholder': { color: 'transparent' },
  'input:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0px 1000px #F2F2F2 inset',
    paddingLeft: '10px',
  },
}));

export const LinkStyled = styled(Grid)(() => ({
  display: 'flex',
  width: '75%',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '-25px',
  '& span': {
    fontSize: '14px',
    color: '#707070',
  },
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
  borderRadius: '16px',
  backgroundColor: theme.palette.secondary.main,
  fontWeight: '700',
  textTransform: 'uppercase',
  margin: 'clamp(42px, 6.7vh ,62px) 0',
  boxShadow: '0px 3px 15px #00000029',
  '&:hover': {
    backgroundColor: '#9B7359',
  },
  [theme.breakpoints.down('md')]: {
    width: '280px',
  },
  [theme.breakpoints.up('md')]: {
    width: '320px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '500px',
  },
  '@media (max-height: 875px)': {
    margin: 'clamp(22px, 4.7vh ,42px) 0',
  },
}));

export const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  textDecoration: 'underline',
  fontSize: '14px',
  color: theme.palette.secondary.main,
  fontWeight: '600',
  marginLeft: '0.3rem',
}));

export const FlexAroundBox = styled(Box)(() => ({
  width: '75%',
  display: 'flex',
  justifyContent: 'space-around',
}));

export const AccessButtonBase = styled(ButtonBase)(({ theme }) => ({
  fontSize: '14px',
  color: theme.palette.secondary.main,
  fontWeight: '600',
  '@media (min-height: 800px)': {
    flex: 1,
  },
}));

export const StyledText = styled(Typography)(() => ({
  color: '#707070',
  marginTop: '50px',
  width: '90%',
  textAlign: 'center',
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  marginRight: '0.3rem',
  textDecoration: 'underline',
  fontSize: '14px',
  color: theme.palette.secondary.main,
  fontWeight: '600',
  marginLeft: '0.3rem',
}));
