import { Box, CardMedia, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PageWrapper = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const ImageBox = styled(Box)(() => ({
  height: 463,
  width: 579,
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const LogoBackground = styled(CardMedia)(() => ({
  width: '90%',
  height: '90%',
  position: 'absolute',
  zIndex: 0,
}));

export const ProtoRobot = styled(CardMedia)(() => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 1,
}));

export const FaceLeft = styled(CardMedia)(() => ({
  width: 80,
  height: 80,
  position: 'absolute',
  left: -65,
  bottom: 50,
}));

export const FaceRight = styled(CardMedia)(() => ({
  width: 100,
  height: 100,
  position: 'absolute',
  right: 30,
  bottom: 150,
}));

export const TextBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: -30,
}));

export const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 80,
  fontWeight: 600,
  span: {
    fontWeight: 400,
  },
  lineHeight: 1,
}));

export const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: 40,
  lineHeight: 1,
}));
