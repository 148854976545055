/* eslint-disable react/require-default-props */
import { Box, Grid } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import { Constants } from '../../../../../constants/plans';
import { PlanInfo } from '../../presenter';
import {
  BoldTypography,
  CardContainer,
  DescriptionBox,
  LimitTypography,
  LineThroughTypography,
  ListBox,
  PlanList,
  PopularStamp,
  RoundButton,
  TitleTypography,
  TotalTypography,
  ValueTypography,
} from './styles';

interface PlanCardProps {
  details: PlanInfo;
  frequency: string;
  horizontal?: boolean;
  popular?: boolean;
}

export default function PlanCard({
  details,
  frequency,
  horizontal,
  popular,
}: PlanCardProps): JSX.Element {
  const navigate = useNavigate();

  const navigateSubscribe = (id: number): void => {
    navigate(`${id}/subscribe/${frequency}`);
  };

  return horizontal ? (
    <Grid key={details.id} item xs={12} xl={3}>
      <CardContainer
        sx={{
          height: { xs: '340px', xl: '780px' },
        }}
      >
        <TitleTypography className="plan-title">
          {details.title}
        </TitleTypography>
        <Box
          sx={{
            padding: '20px 20px 36px 20px',
            height: { xs: '270px', xl: '710px' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ height: 54, display: { xs: 'none', xl: 'block' } }} />
          <Box>
            <LimitTypography sx={{ padding: { xs: '16px 0', xl: '32px 0' } }}>
              {Constants.limitless}
            </LimitTypography>
            <BoldTypography>{details.description}</BoldTypography>
          </Box>
          <RoundButton sx={{ width: { xs: '300px', xl: '100%' } }}>
            <Link
              style={{ textDecoration: 'none', color: '#F5F5F4' }}
              to="https://wa.me/+5511919167696"
              target="_blank"
            >
              {Constants.planButton}
            </Link>
          </RoundButton>
        </Box>
      </CardContainer>
    </Grid>
  ) : (
    <Grid key={details.id} item xs={4} xl={3}>
      <CardContainer>
        <TitleTypography className="plan-title">
          {details.title}
          {popular && <PopularStamp>{Constants.popular}</PopularStamp>}
        </TitleTypography>
        <Box sx={{ padding: '20px' }}>
          <DescriptionBox>
            <BoldTypography>{details.description}</BoldTypography>
            <Box>
              {frequency === 'yearly' ? (
                <Box>
                  <LineThroughTypography>
                    {Constants.rs} {details.value}
                  </LineThroughTypography>
                  <ValueTypography>
                    <span style={{ fontSize: '14px' }}>{Constants.rs}</span>
                    {details.valueYear}
                    <span style={{ fontSize: '14px' }}>
                      {Constants.valueMonth}
                    </span>
                  </ValueTypography>
                  <TotalTypography>{details.total}</TotalTypography>
                </Box>
              ) : (
                <>
                  <ValueTypography>
                    <span style={{ fontSize: '14px' }}>{Constants.rs}</span>
                    {details.value}
                    <span style={{ fontSize: '14px' }}>
                      {Constants.valueMonth}
                    </span>
                  </ValueTypography>
                  <Box height={40} />
                </>
              )}
            </Box>
          </DescriptionBox>
          <ListBox>
            <PlanList>
              <span>{details.users}</span>
            </PlanList>
            <PlanList>
              <span>{details.reports}</span>
            </PlanList>
            <PlanList>
              <span>{details.samples}</span>
            </PlanList>
            <PlanList>
              <span>{details.space}</span>
            </PlanList>
            <PlanList>
              <span>{details.photos}</span>
            </PlanList>
          </ListBox>
          <RoundButton onClick={() => navigateSubscribe(details.id)}>
            {Constants.planButton}
          </RoundButton>
        </Box>
      </CardContainer>
    </Grid>
  );
}

PlanCard.defaultProps = {
  horizontal: false,
};
