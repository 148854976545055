import { handleUrl, myHeadersWithTokenContentType } from '../global';
import {
  CouponData,
  DetailProps,
  DiscountCouponProps,
  GetAvailablePlansProps,
  GetInvoicesProps,
  GetPlansProps,
} from './types';

const getPlans = (): Promise<GetPlansProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/plans/me`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const getAvailablePlans = (): Promise<GetAvailablePlansProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/plans?only_available=true`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const getInvoices = (page: number, size: number): Promise<GetInvoicesProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/invoices?page=${page}&size=${size}&order_by=due_date%23desc`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const redeemCoupon = (data: CouponData): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/coupons/redemption`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const checkCoupon = (
  coupon: string,
  price: string
): Promise<DiscountCouponProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/coupons/${coupon}?stripe_price_id=${price}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export default {
  getPlans,
  getAvailablePlans,
  getInvoices,
  redeemCoupon,
  checkCoupon,
};
