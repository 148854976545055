import {
  TableCell,
  TableRow,
  TableContainer,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTableContainer = styled(TableContainer)(() => ({
  width: '100%',
  padding: '32px 0',
}));

export const Cell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: '600',
    fontSize: '18px',
    backgroundColor: '#DCC7E6',
    borderRight: '1px solid #C087DC',
    '&:last-child': {
      borderRight: '0',
    },
    borderBottom: `1px solid #DCC7E6`,
  },
}));

export const StyledTableCell = styled(TableCell)(() => ({
  color: ' #2E383F',
  padding: '12px',
  fontSize: '18px',
  borderBottom: `1px solid #DCC7E6`,
  '&:first-of-type': {
    borderLeft: `1px solid #DCC7E6`,
  },
  '&:last-child': {
    borderRight: `1px solid #DCC7E6`,
    color: '#CC34FF',
    '& span': {
      fontWeight: 'bold',
    },
  },
}));

export const Row = styled(TableRow)(() => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#C087DC',
    '& span': {
      color: '#FAFCFF',
    },
  },
}));
