/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, LinearProgress, TableContainer } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { errorReportAPI } from '../../api';
import { StatusCode, StepTour } from '../../api/enumerations';
import { ErrorReportData } from '../../api/errorReport/types';
import { PipelineSearch } from '../../components/PipelineSearch';
import { TitleBox } from '../../components/UI/Box';
import { TitleTypography } from '../../components/UI/Typography';
import { Constants } from '../../constants/errorReport';
import { IconWarningMS } from '../../constants/icons';
import { GlobalContext } from '../../context/global';
import useErrorMessage from '../../hooks/useErrorMessage';
import { useTour } from '../../hooks/useTour';
import { ReportTable } from './ReportTable';
import { StyledToggleButton, StyledToggleButtonGroup } from './styles';

export function ErrorReport(): JSX.Element {
  const [reports, setReports] = useState<ErrorReportData[]>();
  const [filteredReports, setFilteredReports] = useState<ErrorReportData[]>();
  const [filteredSearchReports, setFilteredSearchReports] =
    useState<ErrorReportData[]>();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [type, setType] = useState(0);
  const [searchField, setSearchField] = useState('');
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const rowsPerPage = 8;

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage, company } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();
  const { tourCompletion, setTutorialStep, setTourOn } = useTour();

  const getDataCallback = useCallback(async () => {
    setLoading(true);
    setStatus('');
    setType(0);
    const currentPage = page + 1;

    try {
      const response = await errorReportAPI.getAllErrorReports(
        currentPage,
        rowsPerPage
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente');
      }

      if (response.detail.total_records) {
        setTotalRecords(response.detail.total_records);
      }

      setReports(response.data);
      setFilteredReports(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [page, rowsPerPage, setErrorMessage, setOpenSnackbar, setSnackbarMessage]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  useEffect(() => {
    setPage(0);
    setReports([]);
    setFilteredReports([]);
    getDataCallback();
  }, [company]);

  useEffect(() => {
    if (
      !tourCompletion.errorReport.complete &&
      !tourCompletion.skipTour.errorReport
    ) {
      setTutorialStep(StepTour.ERRORREPORTSTART);
      setTourOn(true);
    }
  }, [tourCompletion]);

  useEffect(() => {
    if (reports) {
      if (!status && !type) {
        setFilteredReports(reports);
        return;
      }
      const currentStatus = status === 'finalizados';
      const filteredErrors: ErrorReportData[] = reports.filter((data) => {
        if (!status) {
          return data.report_source === type;
        }
        if (!type) {
          return data.finished === currentStatus;
        }
        return data.report_source === type && data.finished === currentStatus;
      });
      setFilteredReports(filteredErrors);
    }
  }, [status, type]);

  useEffect(() => {
    if (filteredReports) {
      if (!searchField) {
        setFilteredSearchReports(filteredReports);
        return;
      }
      const filteredErrors: ErrorReportData[] = filteredReports.filter(
        (data) => {
          return (
            (data.requester_name &&
              data.requester_name
                .toLowerCase()
                .includes(searchField.toLowerCase())) ||
            data.reference_number.toString().includes(searchField)
          );
        }
      );
      setFilteredSearchReports(filteredErrors);
    }
  }, [searchField, filteredReports]);

  const handleStatus = (
    event: React.MouseEvent<HTMLElement>,
    newStatus: string
  ): void => {
    setStatus(newStatus);
  };

  const handleType = (
    event: React.MouseEvent<HTMLElement>,
    newType: number
  ): void => {
    setType(newType);
  };

  return (
    <>
      <TitleBox>
        <TitleTypography>
          {IconWarningMS}
          {Constants.reportedErrors}
        </TitleTypography>
      </TitleBox>
      <TableContainer sx={{ mx: '20px', width: 'auto' }}>
        {loading ? (
          <LinearProgress />
        ) : reports && reports.length > 0 ? (
          <>
            <StyledToggleButtonGroup
              value={status}
              exclusive
              onChange={handleStatus}
              aria-label="status"
            >
              <StyledToggleButton value="abertos" aria-label="abertos">
                abertos
              </StyledToggleButton>
              <StyledToggleButton value="finalizados" aria-label="finalizados">
                finalizados
              </StyledToggleButton>
            </StyledToggleButtonGroup>
            <StyledToggleButtonGroup
              value={type}
              exclusive
              onChange={handleType}
              aria-label="tipo"
            >
              <StyledToggleButton value={1} aria-label="elemento">
                elemento
              </StyledToggleButton>
              <StyledToggleButton value={2} aria-label="relatorio">
                relatório
              </StyledToggleButton>
            </StyledToggleButtonGroup>
            <Box sx={{ float: 'right' }}>
              <PipelineSearch setSearchField={setSearchField} />
            </Box>
            <ReportTable
              tableData={filteredSearchReports || reports}
              page={page}
              setPage={setPage}
              totalRecords={totalRecords}
              rowsPerPage={rowsPerPage}
            />
          </>
        ) : (
          Constants.noreports
        )}
      </TableContainer>
    </>
  );
}
