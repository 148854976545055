/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react';

import { sampleAPI } from '../../../../../api';
import {
  PropertyType,
  StatusCode,
  convertRegistrationUf,
} from '../../../../../api/enumerations';
import { ElementData } from '../../../../../api/sample/types';
import { WorkOrderData } from '../../../../../api/workOrders/types';
import {
  CustomFormControl,
  CustomPatternFormat,
  CustomTextField,
  NumericTextField,
  PreffixNumericTextField,
} from '../../../../../components/CustomInput';
import StandardMap from '../../../../../components/Maps/StandardMap';
import {
  BoldTypography,
  CoordBox,
  CoordGrid,
} from '../../../../../components/Sections/Sample/AddElement/styles';
import {
  CloseIcon,
  FilledButton,
  OutlinedButton,
  RoundedButton,
} from '../../../../../components/UI/Button';
import { DialogTitle } from '../../../../../components/UI/Typography';
import { IconCloseMS, IconEditMS } from '../../../../../constants/icons';
import { GlobalContext } from '../../../../../context/global';
import { formatPhone } from '../../../../../helpers';
import useErrorMessage from '../../../../../hooks/useErrorMessage';
import useGeneral from '../../../../../hooks/useGeneral';
import useSearchCep from '../../../../../hooks/useSearchCep';
import { Constants } from '../constants';
import { ButtonBox, StyledDialog, InputText } from './styles';

interface EditSampleProps {
  elementData: ElementData;
  propertyData: WorkOrderData;
  updateSample: () => void;
  handleLastEditedPage: () => void;
  deactivateFilters?: () => void;
  goToLastPage: boolean;
  setPage: (value: number) => void;
}

const initialPinPlace = {
  lat: -23.56162,
  lng: -46.65591,
};

interface PinPlaceProps {
  lat: number;
  lng: number;
}

export function EditElement({
  elementData,
  propertyData,
  updateSample,
  handleLastEditedPage,
  deactivateFilters,
  setPage,
  goToLastPage = false,
}: EditSampleProps): JSX.Element {
  const [address, setAddress] = useState('');
  const [addressNumber, setAddressNumber] = useState('');
  const [addressComplement, setAddressComplement] = useState('');
  const [cep, setCep] = useState('');
  const [city, setCity] = useState('');
  const [district, setDistrict] = useState('');
  const [uf, setUf] = useState('');
  const [totalArea, setTotalArea] = useState(0);
  const [landArea, setLandArea] = useState(0);
  const [parkingLots, setParkingLots] = useState(0);
  const [sellingPrice, setSellingPrice] = useState(0);
  const [rooms, setRooms] = useState(0);
  const [communityValue, setCommunityValue] = useState(0);
  const [advertiser, setAdvertiser] = useState('');
  const [advertiserContact, setAdvertiserContact] = useState('');
  const [propertyLink, setPropertyLink] = useState('');
  const [pinPlace, setPinPlace] = useState<PinPlaceProps>(initialPinPlace);
  const [searchMap, setSearchMap] = useState(false);
  const [checked, setChecked] = useState(false);
  const [acceptedAt, setAcceptedAt] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAddressEdited, setIsAddressEdited] = useState(false);
  const { description } = elementData;

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();
  const { handleSearchCep } = useSearchCep();
  const dialog = useGeneral();

  const housePropertyType =
    propertyData.real_estate_kind === PropertyType.HOUSE;

  useEffect(() => {
    setCep(description.zipcode || '');
    setAddress(description.street);
    setAddressNumber(description.number);
    setAddressComplement(description.address_complement || '');
    setCity(description.city);
    setDistrict(description.district);
    setTotalArea(description.area);
    setParkingLots(description.parking_spaces);
    setRooms(description.bedrooms);
    setSellingPrice(description.sell_price);
    setCommunityValue(description.condominium_fee);
    setAdvertiser(description.advertiser?.name || '');
    setAdvertiserContact(description.advertiser?.phone || '');
    setPropertyLink(description.link || '');
    setPinPlace({ lat: description.latitude, lng: description.longitude });
    setUf(convertRegistrationUf(propertyData.uf));
    setLandArea(description.land_area ? description.land_area : 0);
    setChecked(false);
  }, [elementData]);

  useEffect(() => {
    if (checked) {
      const dateTime = new Date().toLocaleString('pt-br');
      setAcceptedAt(dateTime);
    }
  }, [checked]);

  const verifyCep = async (cep: string): Promise<void> => {
    const addressData = await handleSearchCep(cep);
    if (addressData) {
      setCep(addressData.cep);
      setCity(addressData.city);
      setDistrict(addressData.district);
      setAddress(addressData.address);
      setUf(addressData.uf);
    }
    setIsAddressEdited(true);
  };

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    if (isAddressEdited) {
      setSnackbarMessage('Pesquise a localização no mapa antes de salvar.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    if (!checked) {
      setSnackbarMessage('Confirme a responsabilidade pelos dados declarados.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      setLoading(false);
      return;
    }

    if (sellingPrice === 0 || totalArea === 0 || rooms === 0) {
      setSnackbarMessage('Confirme o preenchimento dos itens obrigatórios.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    if (housePropertyType && landArea === 0) {
      setSnackbarMessage('Confirme o preenchimento dos itens obrigatórios.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    description.zipcode = cep;
    description.street = address;
    description.number = addressNumber;
    description.address_complement = addressComplement;
    description.city = city;
    description.district = district;
    description.uf = uf;
    description.area = totalArea;
    description.parking_spaces = parkingLots;
    description.bedrooms = rooms;
    description.sell_price = sellingPrice;
    description.condominium_fee = communityValue;
    description.link = propertyLink;
    description.latitude = pinPlace.lat;
    description.longitude = pinPlace.lng;
    description.level = 1;
    description.accepted = checked;
    description.accepted_at = acceptedAt;
    description.advertiser = {
      name: advertiser,
      phone: advertiserContact,
    };

    if (housePropertyType) {
      description.land_area = landArea;
    }

    try {
      setLoading(true);
      const response = await sampleAPI.editElement(elementData.id, {
        description: JSON.stringify(elementData?.description),
      });

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (deactivateFilters && goToLastPage) {
        deactivateFilters();
        setPage(1);
      } else {
        handleLastEditedPage();
      }
      setSnackbarMessage('Dados alterados com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      setLoading(false);
      dialog.handleClose();
      updateSample();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  return (
    <>
      <RoundedButton model="dark" onClick={dialog.handleOpen}>
        {IconEditMS}
        {description.level === 2 ? Constants.editConfirm : Constants.edit}
      </RoundedButton>
      <StyledDialog
        open={dialog.open}
        onClose={dialog.handleClose}
        aria-labelledby="edit element dialog"
      >
        <CloseIcon onClick={dialog.handleClose}>{IconCloseMS}</CloseIcon>
        <DialogTitle>
          {IconEditMS}
          {Constants.edit}
        </DialogTitle>
        <Grid
          container
          spacing={2}
          component="form"
          id="edit-element"
          onSubmit={handleSubmit}
        >
          <Grid item xs={12} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <CustomPatternFormat
                  required
                  id="cep"
                  label="CEP"
                  value={cep}
                  setValue={setCep}
                  format="#####-###"
                  helper={verifyCep}
                />
              </Grid>
              <Grid item xs={8}>
                <CustomTextField
                  required
                  id="address"
                  label="logradouro"
                  value={address}
                  setValue={setAddress}
                  onChange={setIsAddressEdited}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  required
                  id="address-number"
                  label="número"
                  value={addressNumber}
                  setValue={setAddressNumber}
                  onChange={setIsAddressEdited}
                />
              </Grid>
              <Grid item xs={8}>
                <CustomTextField
                  id="address-complement"
                  label="complemento"
                  value={addressComplement}
                  setValue={setAddressComplement}
                  onChange={setIsAddressEdited}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  required
                  id="city"
                  label="cidade"
                  value={city}
                  setValue={setCity}
                  onChange={setIsAddressEdited}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  required
                  id="district"
                  label="bairro"
                  value={district}
                  setValue={setDistrict}
                  onChange={setIsAddressEdited}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  required
                  id="uf"
                  label="estado"
                  value={uf}
                  setValue={setUf}
                  onChange={setIsAddressEdited}
                />
              </Grid>
              <Grid item xs={4}>
                <NumericTextField
                  id="total-area"
                  label={
                    propertyData.real_estate_kind === PropertyType.APARTMENT
                      ? Constants.privateArea
                      : Constants.constructedArea
                  }
                  suffix=" m²"
                  decimalSeparator=","
                  decimalScale={2}
                  maxLength={18}
                  value={totalArea}
                  setValue={setTotalArea}
                />
              </Grid>
              {housePropertyType && (
                <Grid item xs={4}>
                  <NumericTextField
                    required
                    id="land-area"
                    label={Constants.landArea}
                    suffix=" m²"
                    decimalSeparator=","
                    decimalScale={2}
                    maxLength={18}
                    value={landArea}
                    setValue={setLandArea}
                  />
                </Grid>
              )}
              <Grid item xs={4}>
                <NumericTextField
                  id="parking-lots"
                  label="vagas de garagem"
                  suffix=" vagas"
                  maxLength={9}
                  value={parkingLots}
                  setValue={setParkingLots}
                />
              </Grid>
              <Grid item xs={4}>
                <NumericTextField
                  id="rooms"
                  label="quartos*"
                  suffix=" quartos"
                  maxLength={12}
                  value={rooms}
                  setValue={setRooms}
                />
              </Grid>
              <Grid item xs={housePropertyType ? 4 : 6}>
                <PreffixNumericTextField
                  id="selling-price"
                  label="preço de venda"
                  prefix="R$ "
                  decimalSeparator=","
                  decimalScale={2}
                  maxLength={18}
                  value={sellingPrice}
                  setValue={setSellingPrice}
                />
              </Grid>
              <Grid item xs={housePropertyType ? 4 : 6}>
                <PreffixNumericTextField
                  id="community-value"
                  label="valor do condomínio"
                  prefix="R$ "
                  decimalSeparator=","
                  decimalScale={2}
                  maxLength={18}
                  value={communityValue}
                  setValue={setCommunityValue}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  id="advertiser"
                  label="anunciante"
                  value={advertiser}
                  setValue={setAdvertiser}
                />
              </Grid>
              <Grid item xs={6}>
                <InputText
                  id="advertiser-contact"
                  label="contato anunciante"
                  value={advertiserContact}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAdvertiserContact(formatPhone(e.target.value))
                  }
                  inputProps={{ maxLength: 15 }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  id="property-link"
                  label="link do imóvel"
                  value={propertyLink}
                  setValue={setPropertyLink}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <StandardMap
              searchMap={searchMap}
              address={`${address} ${addressNumber} ${district} ${city}`}
              pinPlace={pinPlace}
              setPinPlace={setPinPlace}
            />
            <CoordGrid item xs={12}>
              <CoordBox>
                <BoldTypography>{Constants.lat}</BoldTypography>
                <Typography>{pinPlace.lat}</Typography>
              </CoordBox>
              <CoordBox>
                <BoldTypography>{Constants.lng}</BoldTypography>
                <Typography>{pinPlace.lng}</Typography>
              </CoordBox>
            </CoordGrid>
            <RoundedButton
              onClick={() => {
                setSearchMap(!searchMap);
                setIsAddressEdited(false);
              }}
            >
              {Constants.searchMap}
            </RoundedButton>
          </Grid>
          <Box mt={2} ml={2}>
            <CustomFormControl
              required
              label={Constants.editElementCheckbox}
              isChecked={checked}
              setIsChecked={setChecked}
            />
          </Box>
        </Grid>
        <ButtonBox>
          <OutlinedButton onClick={dialog.handleClose}>
            {Constants.cancel}
          </OutlinedButton>
          <FilledButton form="edit-element" type="submit" disabled={loading}>
            {loading ? <CircularProgress size={22} /> : Constants.save}
          </FilledButton>
        </ButtonBox>
      </StyledDialog>
    </>
  );
}
