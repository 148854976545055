export const Constants = {
  title: 'Área de acesso',
  emailPlaceholder: 'digite seu e-mail de acesso',
  passwordPlaceholder: 'digite sua senha',
  submit: 'entrar',
  forgotPassword: 'esqueci minha senha',
  keepConnected: 'manter-me conectado',
  newAccount: 'Criar nova conta',
  firstAccess: 'Acesso convidado',
  acceptTerms:
    'Ao continuar, declaro que estou ciente dos *Termos de uso* e da *Política de privacidade.*',
};
