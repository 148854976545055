/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RegisterStage, convertRegisterStage } from '../../api/enumerations';
import logo from '../../assets/images/proto-logo.png';
import Snackbar from '../../components/Snackbar';
import { GlobalContext } from '../../context/global';
import { getVerifiedCep } from '../../helpers/cepVerified';
import Authentication from './Authentication';
import Documents from './Documents';
import { Identification } from './Identification';
import IdentityVerification from './IdentityVerification';
import RegisterForm from './RegisterForm';
import { Container, Logo, Stage, Title } from './styles';

interface IComponent {
  stage: RegisterStage;
  title?: string;
  component: JSX.Element;
}

export default function Register(): JSX.Element {
  const [stageNumber, setStageNumber] = useState(RegisterStage.IDENTIFICATION);
  const [component, setComponent] = useState<IComponent>();
  const [verifiedCep, setVerifiedCep] = useState('');

  const { openSnackbar } = useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { zE } = window as any;
    // function from Zendesk shows messenger on load
    zE('messenger', 'show');
    return () => zE('messenger', 'hide');
  }, []);

  useEffect(() => {
    const cep = getVerifiedCep();
    if (!cep || cep.length === 0) {
      navigate('/create-account');
    } else {
      setVerifiedCep(cep);
    }
  }, []);

  const navigateToLogin = (): void => navigate('/login');

  const handleNextStage = (stage: RegisterStage): void => {
    setStageNumber(stage);
  };

  const components = [
    {
      stage: RegisterStage.IDENTIFICATION,
      title: convertRegisterStage(RegisterStage.IDENTIFICATION),
      component: <Identification handleNextStage={handleNextStage} />,
    },
    {
      stage: RegisterStage.REGISTER_FORM,
      title: convertRegisterStage(RegisterStage.REGISTER_FORM),
      component: (
        <RegisterForm
          handleNextStage={handleNextStage}
          verifiedCep={verifiedCep}
        />
      ),
    },
    {
      stage: RegisterStage.AUTHENTICATION,
      title: convertRegisterStage(RegisterStage.AUTHENTICATION),
      component: <Authentication handleNextStage={handleNextStage} />,
    },
    {
      stage: RegisterStage.DOCUMENTS_VERIFICATION,
      title: convertRegisterStage(RegisterStage.DOCUMENTS_VERIFICATION),
      component: <Documents handleNextStage={handleNextStage} />,
    },
    {
      stage: RegisterStage.IDENTITY_VERIFICATION,
      component: <IdentityVerification />,
    },
  ];

  useEffect(() => {
    const element = components.find(
      (component) => component.stage === stageNumber
    );
    setComponent(element);
  }, [stageNumber]);

  return (
    <Container>
      <Logo onClick={navigateToLogin}>
        <img src={logo} alt="Logo do Proto" />
      </Logo>
      <>
        <Stage>{`Etapa ${stageNumber} de 5`}</Stage>
        {component?.title && <Title>{component?.title}</Title>}
        {component?.component}
      </>
      {openSnackbar && <Snackbar />}
    </Container>
  );
}
