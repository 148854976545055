import { Box, CardMedia, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FacadeImage = styled(CardMedia)(() => ({
  width: 250,
  height: 200,
  borderRadius: 8,
  objectFit: 'cover',
  marginLeft: 32,
}));

export const CoordGrid = styled(Grid)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
}));

export const BoldTypography = styled(Typography)(() => ({
  fontWeight: '600',
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
}));
