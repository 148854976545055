import { Box, TableContainer } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LoadingBox = styled(Box)(() => ({
  width: '100%',
  marginTop: 16,
}));

export const TableBox = styled(TableContainer)(() => ({
  padding: '0 20px',
}));

export const FilterBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'end',
  marginBottom: 32,
  gap: '1rem',
  alignItems: 'center',
  '& span': { fontSize: '30px' },
}));
