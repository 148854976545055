import { IconEditNoteMS } from '../../../constants/icons';
import { EditButton } from './styles';

interface EditWorkOrderProps {
  edit: boolean;
  setEdit: (value: boolean) => void;
}

export function EditWorkOrder({
  edit,
  setEdit,
}: EditWorkOrderProps): JSX.Element {
  return (
    <EditButton disableTouchRipple onClick={() => setEdit(!edit)}>
      {IconEditNoteMS}
      {edit ? 'cancelar edição' : 'editar OS'}
    </EditButton>
  );
}
