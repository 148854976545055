/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState, useMemo } from 'react';

import { CompanyData } from '../api/login/types';

interface GlobalProviderProps {
  openSnackbar: boolean;
  setOpenSnackbar: (isSnackbarOpen: boolean) => void;
  errorMessage: boolean;
  setErrorMessage: (errorMessage: boolean) => void;
  snackbarMessage: string;
  setSnackbarMessage: (message: string) => void;
  inspectionPath: string;
  setInspectionPath: (path: string) => void;
  company: CompanyData | undefined;
  setCompany: (value: CompanyData) => void;
  updateLogo: boolean;
  setUpdateLogo: (value: boolean) => void;
  updateAvatar: boolean;
  setUpdateAvatar: (value: boolean) => void;
  updatePlan: boolean;
  setUpdatePlan: (value: boolean) => void;
  isMenuOpen: boolean;
  setIsMenuOpen: (value: boolean) => void;
  homeView: string;
  setHomeView: (value: string) => void;
}

export const GlobalContext = createContext({} as GlobalProviderProps);

type MyComponentProps = React.PropsWithChildren;

export function GlobalProvider({ children }: MyComponentProps): JSX.Element {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [inspectionPath, setInspectionPath] = useState('');
  const [company, setCompany] = useState<CompanyData | undefined>();
  const [updateLogo, setUpdateLogo] = useState(false);
  const [updateAvatar, setUpdateAvatar] = useState(false);
  const [updatePlan, setUpdatePlan] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [homeView, setHomeView] = useState('pipeline');

  const contextValue = useMemo(
    () => ({
      openSnackbar,
      setOpenSnackbar,
      errorMessage,
      setErrorMessage,
      snackbarMessage,
      setSnackbarMessage,
      inspectionPath,
      setInspectionPath,
      company,
      setCompany,
      updateLogo,
      setUpdateLogo,
      updateAvatar,
      setUpdateAvatar,
      updatePlan,
      setUpdatePlan,
      isMenuOpen,
      setIsMenuOpen,
      homeView,
      setHomeView,
    }),
    [
      openSnackbar,
      setOpenSnackbar,
      errorMessage,
      setErrorMessage,
      snackbarMessage,
      setSnackbarMessage,
      inspectionPath,
      setInspectionPath,
      company,
      setCompany,
      updateLogo,
      setUpdateLogo,
      updateAvatar,
      setUpdateAvatar,
      updatePlan,
      setUpdatePlan,
      isMenuOpen,
      setIsMenuOpen,
      homeView,
      setHomeView,
    ]
  );

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
}
