export const Constants = {
  level: 'Nível ',
  advertiser: 'nome anunciante',
  advertiserContact: 'contato anunciante',
  privateArea: 'área privativa',
  landArea: 'área do terreno',
  constructedArea: 'área construída:',
  squareMeters: ' m²',
  parkingLots: 'vagas de garagem',
  propertyType: 'tipo de imóvel',
  cep: 'CEP',
  city: 'cidade',
  district: 'bairro',
  address: 'logradouro',
  number: 'número',
  sellingPrice: 'preço de venda',
  real: 'R$ ',
  rentingPrice: 'preço do aluguel',
  condominiumValue: 'valor do condomínio',
  propertyLink: 'link do imóvel',
  showMap: 'Mostrar mapa',
  estimateConservation: 'calcular estado de conservação',
  approve: 'aprovar',
  desapprove: 'desaprovar',
  deleteElement: 'excluir elemento',
  deleteText: 'excluir este elemento',
  pictures: 'Fotos',
  edit: 'editar',
  editConfirm: 'editar / confirmar',
  cancel: 'cancelar',
  save: 'salvar',
  reportError: 'reportar erro',
  reason: 'motivo',
  send: 'enviar',
  update: 'atualizar',
  lat: 'latitude:',
  lng: 'longitude:',
  searchMap: 'pesquisar no mapa',
  reportErrorReason: 'Escreva aqui o motivo do erro reportado',
  propertyDescription: 'Descrição do imóvel:',
  movePin: 'Mova o pin e atualize caso a localização esteja incorreta',
  editElementCheckbox:
    'Estou ciente que sou responsável pelos dados inseridos e os declaro como verdadeiros',
  downloadPdf: 'download site pdf',
};
