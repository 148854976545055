export const Constants = {
  downloadText:
    'Você pode realizar o download dos seguintes documentos anexados:',
  propertyRegister: 'Matrícula do imóvel',
  download: 'download',
  iptu: 'Cartela do IPTU',
  registerNumber: 'nº da matrícula:',
  propertyType: 'tipo do imóvel:',
  concept: 'conceito do espaço:',
  zone: 'ofício/zona:',
  judicialDistrict: 'comarca:',
  registrationUf: 'UF:',
};
