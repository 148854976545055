/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { companyAPI, plansAPI, userAPI } from '../../api';
import { StatusCode } from '../../api/enumerations';
import { CompanyData } from '../../api/login/types';
import { PlansData } from '../../api/plans/types';
import { UserData } from '../../api/users/types';
import protoLogo from '../../assets/images/proto-logo.png';
import { GlobalContext } from '../../context/global';
import { getErrorMessage } from '../../helpers';
import { updateTourDataFormat } from '../../helpers/driver/utils';
import {
  getLocalStorageCompany,
  removeLocalStorageCompany,
  removeLocalStorageToken,
  setLocalStorageCompany,
} from '../../helpers/localStorage';
import { useStoragedJwt } from '../../hooks/useDecodedJwt';
import { useTour } from '../../hooks/useTour';
import BackdropLoading from '../BackdropLoading';
import CompaniesDialog from '../Dialog/CompaniesDialog';
import { TermsOfUseDialog } from '../Dialog/TermsOfUseDialog';
import Menu from '../Menu';
import { ProgressBox } from '../ProgressBox';
import Snackbar from '../Snackbar';
import { TrialRemainingDays } from '../TrialRemainingDays';
import { PageWrapper } from '../UI/Grid';
import { Container, OutletContainer } from './styles';

export default function Layout(): JSX.Element {
  const [user, setUser] = useState<UserData>();
  const [urlImage, setUrlImage] = useState('');
  const [applyBackground, setApplyBackground] = useState(false);
  const [plan, setPlan] = useState<PlansData>();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalAcceptTerms, setOpenModalAcceptTerms] = useState(false);
  const [accepted, setAccepted] = useState(false);

  const {
    openSnackbar,
    setOpenSnackbar,
    setErrorMessage,
    setSnackbarMessage,
    company,
    setCompany,
    updateLogo,
    updateAvatar,
    updatePlan,
    isMenuOpen,
  } = useContext(GlobalContext);
  const decoded = useStoragedJwt();
  const navigate = useNavigate();
  const storedCompany = getLocalStorageCompany();
  const { isProgressOpen, setTourCompletion, setTourOn } = useTour();
  const location = useLocation();
  const configurationPath = location.pathname.includes('configuration');

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { zE } = window as any;
    // function from Zendesk shows messenger on load
    zE('messenger', 'show');
    return () => zE('messenger', 'hide');
  }, []);

  const getSelfPlan = useCallback(async () => {
    try {
      const response = await plansAPI.getPlans();

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setPlan(response.data);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePlan]);

  const getCompanyLogo = useCallback(async () => {
    try {
      const response = await companyAPI.getSelfCompany();

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente');
      }

      if (response.data.logo) {
        setUrlImage(response.data.logo);
      } else {
        setUrlImage(protoLogo);
        setApplyBackground(true);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, updateLogo]);

  useEffect(() => {
    if (company !== null && user) {
      if (company?.is_responsible_user) {
        getSelfPlan();
      }
      getCompanyLogo();
    }
  }, [company, updateLogo, updatePlan]);

  const getDataCallback = useCallback(async (selectedCompany: CompanyData) => {
    try {
      if (decoded === null) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      const response = await userAPI.GetUser(decoded.user.id);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data?.guide_tour) {
        const newTourCompletion = updateTourDataFormat(
          response.data.guide_tour
        );
        setTourCompletion(newTourCompletion);
        if (
          !response.data.guide_tour.skipTour &&
          !response.data.guide_tour.onBoarding.complete
        ) {
          setTourOn(true);
        }
      } else {
        setTourOn(true);
      }

      if (selectedCompany.is_responsible_user) {
        getSelfPlan();
      }

      setUser(response.data);
      setLoading(false);
      getCompanyLogo();
    } catch (error) {
      setLoading(false);
      removeLocalStorageToken();
      removeLocalStorageCompany();
      navigate('/login');
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, []);

  useEffect(() => {
    if (storedCompany) {
      setCompany(storedCompany);
      getDataCallback(storedCompany);
    } else if (decoded !== null) {
      if (!decoded.user.accepted_terms_of_use) {
        setOpenModalAcceptTerms(true);
      }
      if (
        (decoded.user.companies.length > 1 && accepted) ||
        (decoded.user.companies.length > 1 &&
          decoded.user.accepted_terms_of_use)
      ) {
        setOpenModal(true);
      }
      if (
        (decoded.user.companies.length === 1 && accepted) ||
        (decoded.user.companies.length === 1 &&
          decoded.user.accepted_terms_of_use)
      ) {
        setLocalStorageCompany(decoded.user.companies[0]);
        setCompany(decoded.user.companies[0]);
        getDataCallback(decoded.user.companies[0]);
      }
    }
  }, [accepted, openModalAcceptTerms]);

  useEffect(() => {
    if (storedCompany) {
      getDataCallback(storedCompany);
    }
  }, [updateAvatar]);

  return (
    <Container>
      {!!user && company && urlImage.length > 0 ? (
        <>
          <Menu userData={user} logo={urlImage} bg={applyBackground} />
          {isProgressOpen && <ProgressBox />}
          <OutletContainer>
            {plan?.name === 'Gratuito' &&
              plan?.remaining_days !== null &&
              !configurationPath && (
                <TrialRemainingDays
                  remainingDays={plan.remaining_days}
                  pipeline
                />
              )}
            <PageWrapper open={isMenuOpen}>
              <Outlet />
            </PageWrapper>
          </OutletContainer>
        </>
      ) : (
        <BackdropLoading open={loading} />
      )}
      {decoded !== null &&
        (!accepted && !decoded.user.accepted_terms_of_use ? (
          <TermsOfUseDialog
            open={openModalAcceptTerms}
            setOpen={setOpenModalAcceptTerms}
            userData={decoded.user}
            setAccepted={setAccepted}
          />
        ) : (
          <CompaniesDialog
            open={openModal}
            setOpen={setOpenModal}
            setLoading={setLoading}
            userData={decoded.user}
            modalCallback={getDataCallback}
          />
        ))}
      {openSnackbar && <Snackbar />}
    </Container>
  );
}
