import { Checkbox, FormControl, Grid, Radio, RadioGroup } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

import { QuestionType } from '../../../../../api/enumerations';
import { QuestionData } from '../../../../../api/forms/types';
import { Constants } from '../../../../../constants/inspection';
import {
  CheckboxFormControl,
  FlexBox,
  FlexCenterGrid,
  ListMenuItem,
  ListTextField,
  MultiFormControl,
  NumberTypography,
  PaperQuestion,
  ShortAnswerTextField,
  SingleSelectTypo,
  StyledDatePicker,
  TitleTypography,
} from './styles';

interface QuestionProps {
  question: QuestionData;
  questionNum: number;
}

export function Question({
  question,
  questionNum,
}: QuestionProps): JSX.Element {
  const [answer, setAnswer] = useState<string | number>('');
  const [checkboxValues, setCheckboxValues] = useState<boolean[]>([]);
  const [date, setDate] = useState<Dayjs | null>(null);

  useEffect(() => {
    setAnswer('');
    setCheckboxValues([]);
    setDate(null);
  }, [question]);

  return (
    <PaperQuestion>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FlexBox>
            <NumberTypography>{questionNum}. </NumberTypography>
            <TitleTypography>{question.title}</TitleTypography>
          </FlexBox>
        </Grid>
        <FlexCenterGrid item xs={12} sx={{ gap: '10px' }}>
          {question.required && (
            <SingleSelectTypo>{Constants.required}</SingleSelectTypo>
          )}
        </FlexCenterGrid>
        {question.type === QuestionType.SHORT_ANSWER && (
          <FlexCenterGrid item xs={12}>
            <ShortAnswerTextField
              id="short-answer"
              multiline
              rows={3}
              label="resposta curta"
              color="secondary"
              value={answer}
              onChange={(e) => {
                setAnswer(e.target.value);
              }}
            />
          </FlexCenterGrid>
        )}
        {question.type === QuestionType.SINGLE_SELECTION && (
          <Grid item xs={12}>
            <FormControl>
              <RadioGroup
                value={answer}
                onChange={(e) => {
                  setAnswer(Number(e.target.value));
                }}
              >
                {question.choices.map((opt) => (
                  <MultiFormControl
                    key={opt.id}
                    value={opt.id}
                    label={opt.title}
                    control={<Radio color="primary" />}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        )}
        {question.type === QuestionType.MULTIPLE_CHOICE && (
          <>
            {question.choices.map((opt, i) => (
              <Grid item xs={12} key={opt.id}>
                <CheckboxFormControl
                  label={opt.title}
                  control={
                    <Checkbox
                      checked={checkboxValues[i] || false}
                      color="primary"
                      value={opt.id}
                      onChange={(e) => {
                        const tempChecked = [...checkboxValues];
                        tempChecked[i] = e.target.checked;
                        setCheckboxValues(tempChecked);
                      }}
                    />
                  }
                />
              </Grid>
            ))}
          </>
        )}
        {question.type === QuestionType.LIST && (
          <ListTextField
            id="question-list"
            select
            label="selecione"
            color="secondary"
            value={answer}
            onChange={(e) => {
              setAnswer(Number(e.target.value));
            }}
          >
            {question.choices.map((option) => (
              <ListMenuItem key={option.id} value={option.id}>
                {option.title}
              </ListMenuItem>
            ))}
          </ListTextField>
        )}
        {question.type === QuestionType.DATE && (
          <FlexCenterGrid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="data"
                format="DD-MM-YYYY"
                sx={StyledDatePicker}
                value={date}
                onChange={(e) => {
                  setDate(e);
                  const dateEvent = e as Dayjs;
                  setAnswer(dayjs(dateEvent).format('YYYY-MM-DD'));
                }}
              />
            </LocalizationProvider>
          </FlexCenterGrid>
        )}
        {question.type === QuestionType.NUMERIC && (
          <FlexCenterGrid item xs={12}>
            <NumericFormat
              customInput={ShortAnswerTextField}
              id="numeric"
              label="resposta numérica"
              color="secondary"
              allowNegative={false}
              allowLeadingZeros
              decimalScale={0}
              value={answer}
              onChange={(e) => {
                setAnswer(e.target.value);
              }}
            />
          </FlexCenterGrid>
        )}
      </Grid>
    </PaperQuestion>
  );
}
