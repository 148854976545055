import {
  TableCell,
  TableRow,
  TableContainer,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  width: '100%',
  maxHeight: '500px',
  borderRadius: 8,
  paddingBottom: 8,
  '&::-webkit-scrollbar': {
    width: '10px',
    height: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

interface ICell {
  small?: 'true' | 'false';
}

export const Cell = styled(TableCell)<ICell>(({ small, theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: '600',
    fontSize: '16px',
    color: theme.palette.primary.dark,
    backgroundColor: '#DCC7E6',
    minWidth: small === 'true' ? '70px' : '150px',
    whiteSpace: 'nowrap',
  },
}));

interface IStyledTableCell {
  button?: 'true';
}

export const StyledTableCell = styled(TableCell)<IStyledTableCell>(
  ({ button, theme }) => ({
    color: button === 'true' ? theme.palette.primary.main : '#1A1A1A',
    padding: '12px',
    fontSize: '14px',
    lineBreak: 'normal',
    cursor: button === 'true' ? 'pointer' : 'default',
    whiteSpace: 'nowrap',
    overflow: 'auto',
    maxWidth: '250px',

    '&::-webkit-scrollbar': {
      height: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.dark,
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  })
);

export const Row = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  border: '1px solid #1A1A1A',
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
