import { Box, BoxProps, Button, Paper, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

export const PaperQuestions = styled(Paper)(({ theme }) => ({
  maxHeight: '330px',
  overflowY: 'auto',
  backgroundColor: theme.palette.common.white,
  border: '1px solid #D7D8DB',
  borderRadius: '16px',
  marginBottom: '20px',
  '&::-webkit-scrollbar': {
    width: '12px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.7),
    },
  },
  '&::-webkit-scrollbar-track': {
    margin: '6px 0',
  },
}));

interface QuestionsBoxProps extends BoxProps {
  open: boolean;
}

export const QuestionsBox = styled(Box)<QuestionsBoxProps>(({ open }) => ({
  cursor: 'pointer',
  backgroundColor: '#FFFFFF',
  ...(open && {
    backgroundColor: '#b36fc866',
  }),
}));

export const SelectTypography = styled(Typography)(() => ({
  margin: '40px',
  fontSize: '21px',
  color: '#8B8C8E',
}));

export const NavigationBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  margin: '12px 12px 0',
  gap: '10px',
}));

export const OutlinedButton = styled(Button)(({ theme }) => ({
  minWidth: '32px',
  width: '48px',
  height: '42px',
  display: 'flex',
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '16px',
  color: theme.palette.primary.main,
  backgroundColor: '#FAFCFF',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
  '&:disabled': {
    color: theme.palette.primary.main,
  },
}));
