/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from '@mui/material';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { workOrderAPI } from '../../../api';
import { StatusCode, convertStatusUrl } from '../../../api/enumerations';
import { WorkOrderData } from '../../../api/workOrders/types';
import { GlobalContext } from '../../../context/global';
import useErrorMessage from '../../../hooks/useErrorMessage';
import { Card } from './Card';
import { StyledSkeleton } from './styles';

interface ListViewProps {
  search: string;
  setAllowSearch: (value: boolean) => void;
}

export default function ListView({
  search,
  setAllowSearch,
}: ListViewProps): JSX.Element {
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [workOrders, setWorkOrders] = useState<WorkOrderData[]>([]);
  const [searching, setSearching] = useState(false);
  const [update, setUpdate] = useState(false);
  const workOrdersPerPage = 12;

  const observer = useRef<IntersectionObserver | null>(null);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();

  const getSearchedData = useCallback(async (): Promise<void> => {
    setSearching(true);

    try {
      const response = await workOrderAPI.getListWorkOrders(1, 40, search);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data) {
        setWorkOrders(response.data);
        setSearching(false);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setSearching(false);
    }
  }, [search]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (search.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        getSearchedData();
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
    setWorkOrders([]);
    setPage(1);
    setLastCalledPage(0);
  }, [search]);

  const getDataCallback = useCallback(async (): Promise<void> => {
    if (page === lastCalledPage) return;
    setLoadingMore(true);

    try {
      const response = await workOrderAPI.getListWorkOrders(
        page,
        workOrdersPerPage,
        ''
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.detail.total_pages && page === 1) {
        setTotalPages(response.detail.total_pages);
      }

      if (response.data) {
        if (page === 1) {
          setWorkOrders(response.data);
        } else {
          const updatedData = [...workOrders, ...response.data];
          setWorkOrders(updatedData);
        }

        setLastCalledPage(page);
        setLoadingMore(false);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoadingMore(false);
    }
  }, [page]);

  useEffect(() => {
    if (search.length === 0) {
      getDataCallback();
    }
  }, [getDataCallback, page, update]);

  useEffect(() => {
    setAllowSearch(false);
  }, [setAllowSearch]);

  const lastElementRef = (node: HTMLAnchorElement): void => {
    if (searching || loadingMore || search.length > 0) return;

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && page < totalPages) {
        setPage(page + 1);
      }
    });

    if (node) observer.current.observe(node);
  };

  return (
    <Grid
      container
      spacing={{ xs: '32px', xl: '40px' }}
      sx={{ padding: '20px' }}
    >
      {!searching &&
        workOrders.length > 0 &&
        workOrders.map((data) => (
          <Grid item key={data.id} xs={4} xl={3}>
            <Link
              to={`/home/property/${data?.id}/${convertStatusUrl(data.status)}`}
              style={{ textDecoration: 'none' }}
            >
              <Card
                cardData={data}
                setLastPage={setLastCalledPage}
                setPage={setPage}
                update={update}
                setUpdate={setUpdate}
              />
            </Link>
          </Grid>
        ))}
      <Box ref={lastElementRef} width="100%" />
      {loadingMore || searching ? (
        [1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
          <Grid item key={item} xs={4} xl={3}>
            <StyledSkeleton variant="rectangular" animation="pulse" />
          </Grid>
        ))
      ) : (
        <Box />
      )}
    </Grid>
  );
}
