import { Checkbox, FormControl, Grid, Radio, RadioGroup } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

import { QuestionType } from '../../../../api/enumerations';
import { QuestionFormData } from '../../../../api/workOrders/types';
import { Constants } from '../../../../constants/inspection';
import {
  CheckboxFormControl,
  FlexBox,
  FlexCenterGrid,
  ListMenuItem,
  ListTextField,
  MultiFormControl,
  NumberTypography,
  PaperQuestion,
  ShortAnswerTextField,
  SingleSelectType,
  StyledDatePicker,
  SubmitButton,
  TitleTypography,
} from './styles';

interface QuestionProps {
  question: QuestionFormData;
  questionNum: number;
  setAnswer: (value: string | number) => void;
  answer: string | number;
  setCheckboxAnswer: (value: number[]) => void;
  checkboxAnswer: number[];
  setSaveAnswer: (value: boolean) => void;
  handleAnswer: () => Promise<void>;
}

export function Question({
  question,
  questionNum,
  setAnswer,
  answer,
  checkboxAnswer,
  setCheckboxAnswer,
  setSaveAnswer,
  handleAnswer,
}: QuestionProps): JSX.Element {
  const [checkboxValues, setCheckboxValues] = useState<boolean[]>([]);
  const [date, setDate] = useState<Dayjs | null>(null);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {
    setSelectAll(false);
    if (!question.answer) {
      setAnswer('');
      setCheckboxValues([]);
      setCheckboxAnswer([]);
      return;
    }
    if (Array.isArray(question.answer)) {
      setCheckboxAnswer(question.answer);
      const questionOptions = question.choices.map((data) => {
        const questionAnswer = question.answer as number[];
        const hasAnswer = questionAnswer.find((e) => e === data.id);
        return !!hasAnswer;
      });
      setCheckboxValues(questionOptions);

      if (question.answer.length === questionOptions.length) {
        setSelectAll(true);
      }
    } else {
      setAnswer(question.answer);
      if (question.type === QuestionType.DATE) {
        const formatedDate = dayjs(question.answer);
        setDate(formatedDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const handleKeyPress = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter') {
      handleAnswer();
    }
  };

  return (
    <PaperQuestion>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FlexBox>
            <NumberTypography>{questionNum}. </NumberTypography>
            <TitleTypography>{question.title}</TitleTypography>
          </FlexBox>
        </Grid>
        <FlexCenterGrid item xs={12} sx={{ gap: '10px' }}>
          {question.required && (
            <SingleSelectType>{Constants.required}</SingleSelectType>
          )}
        </FlexCenterGrid>
        {question.type === QuestionType.SHORT_ANSWER && (
          <FlexCenterGrid item xs={12}>
            <ShortAnswerTextField
              id="short-answer"
              multiline
              rows={3}
              label="resposta curta"
              color="secondary"
              value={answer}
              onChange={(e) => {
                setSaveAnswer(true);
                setAnswer(e.target.value);
              }}
            />
          </FlexCenterGrid>
        )}
        {question.type === QuestionType.SINGLE_SELECTION && (
          <Grid item xs={12}>
            <FormControl onKeyDown={handleKeyPress}>
              <RadioGroup
                value={answer}
                onChange={(e) => {
                  setSaveAnswer(true);
                  setAnswer(Number(e.target.value));
                }}
              >
                {question.choices.map((opt) => (
                  <MultiFormControl
                    key={opt.id}
                    value={opt.id}
                    label={opt.title}
                    control={<Radio color="primary" />}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        )}
        {question.type === QuestionType.MULTIPLE_CHOICE && (
          <>
            <Grid item xs={12}>
              <CheckboxFormControl
                label="Selecionar Todas"
                onKeyDown={handleKeyPress}
                control={
                  <Checkbox
                    checked={selectAll}
                    value={selectAll}
                    color="primary"
                    onChange={(e) => {
                      setSelectAll(e.target.checked);
                      if (e.target.checked) {
                        // check all options except with title Sem destaque
                        const questionOptions = question.choices.map((q) => {
                          if (q.title === 'Sem destaque') {
                            return false;
                          }
                          return true;
                        });
                        const questionAnswer = question.choices
                          .map((d, i) => {
                            if (d.title !== 'Sem destaque') {
                              return i + 1;
                            }
                            return 0;
                          })
                          .filter((n) => n !== 0);
                        setSaveAnswer(true);
                        setCheckboxValues(questionOptions);
                        setCheckboxAnswer(questionAnswer);
                      } else {
                        setSaveAnswer(true);
                        setCheckboxValues([]);
                        setCheckboxAnswer([]);
                      }
                    }}
                  />
                }
              />
            </Grid>
            {question.choices.map((opt, i) => (
              <Grid item xs={12} key={opt.id}>
                <CheckboxFormControl
                  label={opt.title}
                  onKeyDown={handleKeyPress}
                  control={
                    <Checkbox
                      checked={checkboxValues[i] || false}
                      value={opt.id}
                      color="primary"
                      onChange={(e) => {
                        const newData = checkboxAnswer.filter((element) => {
                          return element !== Number(e.target.value);
                        });
                        if (e.target.checked) {
                          checkboxValues[i] = true;
                          if (newData) {
                            setSaveAnswer(true);
                            setCheckboxAnswer([
                              ...newData,
                              Number(e.target.value),
                            ]);
                          } else {
                            setSaveAnswer(true);
                            setCheckboxAnswer([
                              ...checkboxAnswer,
                              Number(e.target.value),
                            ]);
                          }
                        } else {
                          setSaveAnswer(true);
                          checkboxValues[i] = false;
                          setCheckboxAnswer(newData);
                          setSelectAll(false);
                        }
                      }}
                    />
                  }
                />
              </Grid>
            ))}
          </>
        )}
        {question.type === QuestionType.LIST && (
          <ListTextField
            id="question-list"
            select
            label="selecione"
            color="secondary"
            value={answer}
            onChange={(e) => {
              setSaveAnswer(true);
              setAnswer(Number(e.target.value));
            }}
          >
            {question.choices.map((option) => (
              <ListMenuItem key={option.id} value={option.id}>
                {option.title}
              </ListMenuItem>
            ))}
          </ListTextField>
        )}
        {question.type === QuestionType.DATE && (
          <FlexCenterGrid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="data"
                format="DD-MM-YYYY"
                sx={StyledDatePicker}
                value={date}
                onChange={(e) => {
                  setSaveAnswer(true);
                  setDate(e);
                  const dateEvent = e as Dayjs;
                  setAnswer(dayjs(dateEvent).format('YYYY-MM-DD'));
                }}
              />
            </LocalizationProvider>
          </FlexCenterGrid>
        )}
        {question.type === QuestionType.NUMERIC && (
          <FlexCenterGrid item xs={12}>
            <NumericFormat
              customInput={ShortAnswerTextField}
              id="numeric"
              label="resposta numérica"
              color="secondary"
              allowNegative={false}
              allowLeadingZeros
              decimalScale={0}
              value={answer}
              onChange={(e) => {
                setSaveAnswer(true);
                setAnswer(e.target.value);
              }}
            />
          </FlexCenterGrid>
        )}
        <Grid item xs={12}>
          <SubmitButton onClick={handleAnswer}>{Constants.ok}</SubmitButton>
        </Grid>
      </Grid>
    </PaperQuestion>
  );
}
