import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ChartContainer = styled(Box)(() => ({
  padding: '20px',
}));

export const ChartTitle = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: '600',
  color: theme.palette.primary.dark,
  textAlign: 'center',
  marginBottom: '10px',
}));

export const FlexBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '0 20px',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.down('lg')]: {
    flexWrap: 'wrap',
  },
}));

export const SingleLabelBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  padding: '0 20px',
}));

export const LabelBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  minWidth: '120px',
  justifyContent: 'flex-end',
}));

export const LabelTypography = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '14px',
  color: '#717171',
}));

export const LabelLine = styled(Box)(() => ({
  minWidth: '18px',
  width: '20px',
  height: '1px',
  borderBottom: '5px solid',
  margin: '8px',
}));

export const ChartBox = styled(Box)(() => ({
  position: 'relative',
  minHeight: '360px',
}));
