import { Box, CardMedia, IconButton, Typography, styled } from '@mui/material';

import { UploadBox } from '../../../components/UI/Box';

export const DialogContainer = styled(Box)(() => ({
  width: 800,
  height: 580,
  padding: '40px',
}));

export const Upload = styled(UploadBox)(() => ({
  height: 308,
  flex: 1,
}));

export const InsideBox = styled(Box)(() => ({
  cursor: 'pointer',
  textAlign: 'center',
}));

export const UploadIcon = styled(Typography)(({ theme }) => ({
  '& span': { fontSize: '72px', color: theme.palette.primary.main },
}));

export const UploadTitle = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '14px',
}));

export const UploadSubtitle = styled(Typography)(() => ({
  fontSize: '14px',
}));

export const UploadAndPreviewContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 50,
}));

export const ImagePreview = styled(CardMedia)(() => ({
  width: 250,
  height: 250,
  marginTop: 16,
  borderRadius: 30,
  objectFit: 'cover',
  position: 'relative',
}));

export const DeleteCircleButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: -8,
  top: -8,
  backgroundColor: theme.palette.primary.main,
  padding: '4px',
  color: theme.palette.common.white,
  '&:hover': {
    padding: '5px',
    backgroundColor: theme.palette.primary.main,
  },
}));
