import {
  Table,
  TableCell,
  TableCellProps,
  TableRow,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    marginTop: '0 !important',
  },
}));

export const StyledTable = styled(Table)(() => ({
  borderCollapse: 'collapse',
}));

export const BoldCell = styled(TableCell)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.dark}`,
  padding: '8px',
  fontSize: '16px',
  fontWeight: '600',
  color: theme.palette.primary.dark,
}));

export const RowHeader = styled(TableRow)(() => ({
  backgroundColor: '#DCC7E6',
}));

export const RowCaption = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#914FB2',
  border: `1px solid ${theme.palette.primary.dark}`,
}));

export const CellCaption = styled(TableCell)(() => ({
  paddingLeft: '40px',
  fontSize: '21px',
  fontWeight: '600',
  color: '#ECF0F6',
  textAlign: 'center',
}));

interface CellHeaderProps extends TableCellProps {
  minwidth?: string;
}

export const CellHeader = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'bleft' && prop !== 'bright',
})<CellHeaderProps>(({ minwidth, theme }) => ({
  border: `1px solid ${theme.palette.primary.dark}`,
  padding: '8px',
  fontSize: '16px',
  fontWeight: '600',
  color: theme.palette.primary.dark,
  minWidth: minwidth,
}));

export const CellContent = styled(TableCell)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.dark}`,
  padding: '8px',
  fontSize: '16px',
  color: theme.palette.primary.dark,
}));
