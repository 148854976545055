import { Box, Dialog, styled } from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 16,
    backgroundColor: theme.palette.common.white,
  },
}));

export const DialogContainer = styled(Box)(() => ({
  minWidth: 600,
  minHeight: 200,
  padding: 40,
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
}));

export const ButtonBox = styled(Box)(() => ({
  margin: '20px 0',
  display: 'flex',
  gap: '40px',
  justifyContent: 'center',
}));
