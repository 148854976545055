/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
import { Box, CircularProgress, Grid } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { sampleAPI } from '../../../../api';
import {
  PropertyType,
  StatusCode,
  convertPropertyType,
} from '../../../../api/enumerations';
import { ElementData, PicturesData } from '../../../../api/sample/types';
import { WorkOrderData } from '../../../../api/workOrders/types';
import {
  NumericTextField,
  SelectTextField,
} from '../../../../components/CustomInput';
import ConfirmationDialog from '../../../../components/Dialog/ConfirmationDialog';
import { ReportErrorDialog } from '../../../../components/Dialog/ReportErrorDialog';
import AllPicturesSlider from '../../../../components/Sections/Sample/AllPicturesSlider';
import { RoundedButton } from '../../../../components/UI/Button';
import {
  IconDescriptionMS,
  IconExpandMoreMS,
  IconKeyboardArrowRightMS,
  IconPhotoLibraryMS,
} from '../../../../constants/icons';
import {
  selectConservation,
  selectStandard,
  selectStandard400K,
} from '../../../../constants/selectOptions';
import { GlobalContext } from '../../../../context/global';
import { formatFloatNumber, getErrorMessage } from '../../../../helpers';
import useGeneral from '../../../../hooks/useGeneral';
import { CheckErrorDialog } from './CheckErrorDialog';
import { Constants } from './constants';
import { EditElement } from './EditElement';
import {
  DescriptionTypography,
  ElementButton,
  ErrorButton,
  FlexBox,
  IdLabel,
  InputTitle,
  LabelBox,
  LevelLabel,
  SampleContainer,
  SectionBox,
  SectionTitle,
  StyledIcon,
  StyledInput,
  StyledProgress,
} from './styles';

interface SampleCardProps {
  sampleData: ElementData[];
  elementData: ElementData;
  propertyData: WorkOrderData;
  updateSample: () => void;
  sampleId?: number;
  disapprovedElements: number[];
  setDisapprovedElements: (value: number[]) => void;
  handleLastEditedPage: () => void;
  goToLastPage: boolean;
  page: number;
  setPage: (value: number) => void;
  deactivateFilters?: () => void;
}

export function ElementCard({
  sampleData,
  elementData,
  propertyData,
  updateSample,
  sampleId,
  disapprovedElements,
  setDisapprovedElements,
  handleLastEditedPage,
  goToLastPage = false,
  page,
  setPage,
  deactivateFilters,
}: SampleCardProps): JSX.Element {
  const [age, setAge] = useState<number>(0);
  const [buildingStandard, setBuildingStandard] = useState(0);
  const [conservation, setConservation] = useState(0);
  const [landArea, setLandArea] = useState(0);
  const [disableInfo, setDisableInfo] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [estimatedConservation, setEstimatedConservation] = useState(false);
  const [initialPictures, setInitialPictures] = useState<PicturesData[]>([]);
  const [allPictures, setAllPictures] = useState<PicturesData[]>([]);

  const [showDescription, setShowDescription] = useState(false);
  const [openCheckError, setOpenCheckError] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [loadingApproveElement, setLoadingApproveElement] = useState(false);
  const [loadingErrorReport, setLoadingErrorReport] = useState(false);
  const [loadingMorePictures, setLoadingMorePictures] = useState(false);
  const [updatedPictures, setUpdatedPictures] = useState(false);
  const [loadingDeleteElement, setLoadingDeleteElement] = useState(false);
  const { description } = elementData;
  const housePropertyType =
    propertyData.real_estate_kind === PropertyType.HOUSE;

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { handleDownloadFile } = useGeneral();

  const handleConservationEstimate = (): void => {
    setEstimatedConservation(true);

    if (age < 2) {
      setConservation(1);
    }
    if (age >= 2 && age < 5) {
      setConservation(2);
    }
    if (age >= 5 && age < 15) {
      setConservation(3);
    }
    if (age >= 15 && age < 25) {
      setConservation(4);
    }
    if (age >= 25 && age < 35) {
      setConservation(5);
    }
    if (age >= 35 && age < 45) {
      setConservation(6);
    }
    if (age >= 45) {
      setConservation(7);
    }
  };

  const handleApproveElement = async (): Promise<void> => {
    if (!estimatedConservation) {
      setSnackbarMessage('Calcule o estado de conservação!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    if (!buildingStandard) {
      setSnackbarMessage('Selecione o padrão construtivo!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    if (housePropertyType && landArea === 0) {
      setSnackbarMessage('Forneça o valor da área do terreno!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    setLoadingApproveElement(true);

    if (housePropertyType) {
      description.land_area = landArea;
    }

    if (!description.level) {
      description.level = 1;
    }

    const approveData = {
      age,
      constructive_standard: buildingStandard,
      preservation_state: conservation,
      description: JSON.stringify(elementData?.description),
    };

    try {
      const response = await sampleAPI.approveElement(
        elementData.id,
        approveData
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setLoadingApproveElement(false);
      setIsApproved(true);
      setSnackbarMessage('Dados alterados com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      if (sampleData) {
        const index = sampleData.findIndex((element) => {
          return element.id === elementData.id;
        });

        if (index !== -1) {
          sampleData[index] = {
            ...sampleData[index],
            age,
            approved: true,
            constructive_standard: buildingStandard,
            preservation_state: conservation,
          };
        }
      }
      if (disapprovedElements) {
        if (
          goToLastPage &&
          deactivateFilters &&
          elementData.description.level === 1
        ) {
          deactivateFilters();
          setPage(1);
        }

        const removeId = disapprovedElements.filter((element) => {
          return element !== sampleId;
        });
        setDisapprovedElements(removeId);
      }
      setDisableInfo(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoadingApproveElement(false);
    }
  };

  const getDataCallback = useCallback(async () => {
    setAge(elementData.age ? elementData.age : 0);
    setLandArea(description.land_area ? description.land_area : 0);
    if (elementData?.constructive_standard) {
      setBuildingStandard(elementData.constructive_standard);
    }
    if (elementData?.preservation_state) {
      setConservation(elementData.preservation_state);
    }
    if (elementData?.approved) {
      setDisableInfo(true);
      setIsApproved(true);
    }
  }, [elementData, setAge]);

  const getElementPhotos = useCallback(async () => {
    try {
      const response = await sampleAPI.getElementPictures(elementData.id, 1, 6);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Não foi possível carregar as fotos.');
      }
      setInitialPictures(response.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }, [page]);

  useEffect(() => {
    setIsApproved(false);
    setDisableInfo(false);
    getDataCallback();
  }, [getDataCallback, elementData]);

  useEffect(() => {
    setAllPictures([]);
    setUpdatedPictures(false);
    getElementPhotos();
  }, [getElementPhotos, page]);

  useEffect(() => {
    setEstimatedConservation(false);
  }, [age]);

  const handleUpdateSample = (): void => {
    if (goToLastPage) {
      setPage(page - 1);
    }
    updateSample();
  };

  const handleDeleteElement = useCallback(async () => {
    setLoadingDeleteElement(true);
    try {
      const response = await sampleAPI.deleteElement(elementData.id);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Elemento excluído com sucesso!');
      setOpenSnackbar(true);
      setErrorMessage(false);
      setLoadingDeleteElement(false);
      handleUpdateSample();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
      setLoadingDeleteElement(false);
    }
  }, [setSnackbarMessage, setOpenSnackbar, setErrorMessage, elementData]);

  const handleOriginalElement = useCallback(async (update: boolean) => {
    setLoadingErrorReport(true);
    try {
      const response = await sampleAPI.getOriginalElement(
        elementData.id,
        update
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data?.updated_by_human) {
        setOpenCheckError(true);
      } else {
        setOpenErrorDialog(true);
      }
      setLoadingErrorReport(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
      setLoadingErrorReport(false);
    }
  }, []);

  const handleDisapproveSample = async (): Promise<void> => {
    setDisableInfo(false);
    setIsApproved(false);
  };

  const getMorePictures = async (): Promise<void> => {
    setLoadingMorePictures(true);

    try {
      const response = await sampleAPI.getAllElementPictures(elementData.id);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Não foi possível carregar as fotos.');
      }
      setAllPictures(response.data);
      setLoadingMorePictures(false);
      setUpdatedPictures(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      setLoadingMorePictures(false);
    }
  };

  return (
    <SampleContainer id="element-info">
      <LabelBox>
        <IdLabel>{sampleId}</IdLabel>
        <LevelLabel>
          {Constants.level}
          {elementData?.description?.level || 1}
        </LevelLabel>
      </LabelBox>
      <Grid container spacing={{ xs: 2, xl: 4 }}>
        <Grid item xs={8} lg={9}>
          <InputTitle>{Constants.address}</InputTitle>
          <StyledInput sx={{ width: '100%' }}>
            {elementData?.description?.street}
          </StyledInput>
        </Grid>
        <Grid item xs={4} lg={3}>
          <InputTitle>{Constants.number}</InputTitle>
          <StyledInput>{elementData?.description?.number}</StyledInput>
        </Grid>
        <Grid item xs={4} lg={3}>
          <InputTitle>{Constants.cep}</InputTitle>
          <StyledInput>{elementData?.description?.zipcode}</StyledInput>
        </Grid>
        <Grid item xs={4} lg={3}>
          <InputTitle>{Constants.city}</InputTitle>
          <StyledInput>{elementData?.description?.city}</StyledInput>
        </Grid>
        <Grid item xs={4} lg={3}>
          <InputTitle>{Constants.district}</InputTitle>
          <StyledInput>{elementData?.description?.district}</StyledInput>
        </Grid>
        <Grid item xs={4} lg={3}>
          <InputTitle>{Constants.propertyType}</InputTitle>
          <StyledInput>
            {propertyData.real_estate_kind &&
              convertPropertyType(propertyData.real_estate_kind)}
          </StyledInput>
        </Grid>
        <Grid item xs={4} lg={3}>
          <InputTitle>
            {propertyData.real_estate_kind === PropertyType.APARTMENT
              ? Constants.privateArea
              : Constants.constructedArea}
          </InputTitle>
          <StyledInput>
            {elementData?.description?.area}
            {Constants.squareMeters}
          </StyledInput>
        </Grid>
        <Grid item xs={4} lg={3}>
          <InputTitle>{Constants.parkingLots}</InputTitle>
          <StyledInput>{elementData?.description?.parking_spaces}</StyledInput>
        </Grid>
        <Grid item xs={4} lg={3}>
          <InputTitle>{Constants.sellingPrice}</InputTitle>
          <StyledInput>
            {Constants.real}
            {elementData?.description?.sell_price &&
              formatFloatNumber(elementData?.description?.sell_price)}
          </StyledInput>
        </Grid>
        <Grid item xs={4} lg={3}>
          <InputTitle
            error={
              !elementData.description?.advertiser?.name ? 'true' : undefined
            }
          >
            {Constants.advertiser}
          </InputTitle>
          <StyledInput
            error={
              !elementData.description?.advertiser?.name ? 'true' : undefined
            }
          >
            {elementData.description?.advertiser?.name || ''}
          </StyledInput>
        </Grid>
        <Grid item xs={4} lg={3}>
          <InputTitle
            error={
              !elementData.description?.advertiser?.phone ? 'true' : undefined
            }
          >
            {Constants.advertiserContact}
          </InputTitle>
          <StyledInput
            error={
              !elementData.description?.advertiser?.phone ? 'true' : undefined
            }
          >
            {elementData.description?.advertiser?.phone || ''}
          </StyledInput>
        </Grid>
        <Grid item xs={4} lg={3} sx={{ display: 'flex', alignItems: 'end' }}>
          <ElementButton
            disabled={!elementData.description?.link}
            onClick={() =>
              window.open(elementData?.description?.link, '_blank')
            }
          >
            {Constants.propertyLink}
          </ElementButton>
        </Grid>
        <Grid item xs={4} lg={3} sx={{ display: 'flex', alignItems: 'end' }}>
          <ElementButton
            disabled={!elementData.description?.pdf}
            onClick={() =>
              elementData.description?.pdf &&
              handleDownloadFile(elementData.description?.pdf, 'pdf', true)
            }
          >
            {Constants.downloadPdf}
          </ElementButton>
        </Grid>
      </Grid>
      {elementData?.description?.description && (
        <SectionBox>
          <FlexBox>
            <SectionTitle
              onClick={() => {
                setShowDescription(!showDescription);
              }}
            >
              {IconDescriptionMS}
              {Constants.propertyDescription}
            </SectionTitle>
            <StyledIcon
              onClick={() => {
                setShowDescription(!showDescription);
              }}
            >
              {showDescription ? IconExpandMoreMS : IconKeyboardArrowRightMS}
            </StyledIcon>
          </FlexBox>
          {showDescription && (
            <DescriptionTypography>
              {elementData?.description?.description}
            </DescriptionTypography>
          )}
        </SectionBox>
      )}
      <SectionBox sx={{ mt: '40px' }}>
        <Grid container spacing={{ xs: 2, xl: 4 }}>
          {housePropertyType && (
            <Grid item xs={2}>
              <NumericTextField
                disable={disableInfo}
                id="land-area"
                label={Constants.landArea}
                suffix=" m²"
                decimalSeparator=","
                decimalScale={2}
                maxLength={18}
                value={landArea}
                setValue={setLandArea}
                error={!description.land_area}
              />
            </Grid>
          )}
          <Grid
            item
            md={housePropertyType ? 2 : 3}
            lg={housePropertyType ? 2 : 3.5}
          >
            <NumericTextField
              disable={disableInfo}
              id="property-age-estimate"
              label="idade do imóvel"
              suffix=" anos"
              maxLength={9}
              value={age}
              setValue={setAge}
              error={elementData?.age === null}
            />
          </Grid>
          <Grid
            item
            md={housePropertyType ? 4 : 5}
            lg={housePropertyType ? 4 : 4.5}
          >
            <RoundedButton
              disabled={disableInfo}
              onClick={handleConservationEstimate}
            >
              {Constants.estimateConservation}
            </RoundedButton>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={3}>
            <ErrorButton
              id="error-report"
              onClick={() => handleOriginalElement(false)}
            >
              {loadingErrorReport ? (
                <StyledProgress size={22} />
              ) : (
                Constants.reportError
              )}
            </ErrorButton>
            <CheckErrorDialog
              sampleId={elementData?.id}
              open={openCheckError}
              handleClose={() => setOpenCheckError(false)}
              updateSample={handleUpdateSample}
            />
            <ReportErrorDialog
              element
              sampleId={elementData?.id}
              open={openErrorDialog}
              setOpen={setOpenErrorDialog}
              updateSample={handleUpdateSample}
            />
          </Grid>
          <Grid item xs={3.5}>
            <SelectTextField
              disable={disableInfo}
              id="conservation-age-estimate"
              label="estado de conservação do imóvel"
              value={conservation}
              setValue={setConservation}
              selectOptions={selectConservation()}
            />
          </Grid>
          <Grid item xs={2.5}>
            <SelectTextField
              disable={disableInfo}
              id="standard-age-estimate"
              label="padrão construtivo"
              value={buildingStandard}
              setValue={setBuildingStandard}
              selectOptions={
                propertyData.sample_sell_price_above_400k
                  ? selectStandard400K()
                  : selectStandard()
              }
            />
          </Grid>
          <Grid item xs={2}>
            {isApproved ? (
              <RoundedButton onClick={handleDisapproveSample}>
                {Constants.desapprove}
              </RoundedButton>
            ) : (
              <RoundedButton
                onClick={handleApproveElement}
                disabled={loadingApproveElement}
              >
                {loadingApproveElement ? (
                  <CircularProgress size="24px" />
                ) : (
                  Constants.approve
                )}
              </RoundedButton>
            )}
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={3}>
            <ConfirmationDialog
              id="delete-element"
              text={Constants.deleteText}
              button={
                <RoundedButton model="error" disabled={loadingDeleteElement}>
                  {loadingDeleteElement ? (
                    <CircularProgress size="24px" color="error" />
                  ) : (
                    Constants.deleteElement
                  )}
                </RoundedButton>
              }
              model="error"
              modalCallback={handleDeleteElement}
            />
          </Grid>
        </Grid>
      </SectionBox>
      <SectionBox id="element-images">
        <SectionTitle>
          {IconPhotoLibraryMS}
          {Constants.pictures}
        </SectionTitle>
        <Grid container spacing={1} sx={{ mt: '20px' }}>
          {initialPictures.map((pic) => (
            <Grid item xs={2} key={pic.id}>
              <AllPicturesSlider
                file={pic}
                images={allPictures.length ? allPictures : initialPictures}
                getMorePictures={getMorePictures}
                loading={loadingMorePictures}
                isUpdated={updatedPictures}
              />
            </Grid>
          ))}
        </Grid>
      </SectionBox>
      <SectionBox display="flex" justifyContent="end">
        <Box width={240}>
          <EditElement
            elementData={elementData}
            propertyData={propertyData}
            updateSample={updateSample}
            handleLastEditedPage={handleLastEditedPage}
            deactivateFilters={deactivateFilters}
            setPage={setPage}
            goToLastPage={goToLastPage && isApproved}
          />
        </Box>
      </SectionBox>
    </SampleContainer>
  );
}
