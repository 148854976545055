/* eslint-disable max-len */
import { Driver } from 'driver.js';

import { InitialModalTour } from '../../Modal/InitialModalTour';

interface InspectionTourProps {
  driverObj: Driver | null;
}

export function PeptTour({ driverObj }: InspectionTourProps): JSX.Element {
  return (
    <InitialModalTour
      title="Vamos dar início a"
      pageName="Pendência técnica"
      subtext1="Nesta tela você poderá ver o motivo pelo qual a Ordem de Serviço foi enviada para Pendência Técnica e poderá anexar arquivos, quando necessário. Quando a Pendência Técnica for resolvida, você poderá voltar a Ordem de Serviço ao fluxo de atendimento."
      driverObj={driverObj}
      stepTourName="pept"
    />
  );
}
