import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { ProfileUsers } from '../api/enumerations';
import Layout from '../components/Layout';
import { GlobalContext } from '../context/global';
import {
  removeLocalStorageCompany,
  removeLocalStorageToken,
} from '../helpers/localStorage';
import { useStoragedJwt } from '../hooks/useDecodedJwt';

interface PrivateRouteProps {
  element: React.ReactElement;
  allowedTypes?: number[];
}

export default function PrivateRoute({
  element,
  allowedTypes = [
    ProfileUsers.ARCHITECT,
    ProfileUsers.ENGINEER,
    ProfileUsers.ADMIN,
  ],
}: PrivateRouteProps): JSX.Element {
  const decoded = useStoragedJwt();
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage, company } =
    useContext(GlobalContext);
  const hasPermission = (userType: number, allowedTypes: number[]): boolean => {
    return allowedTypes.includes(userType);
  };
  try {
    if (decoded === null || decoded.expired) {
      removeLocalStorageToken();
      removeLocalStorageCompany();
      return <Navigate to="/login" />;
    }
    if (company && hasPermission(company.role, allowedTypes)) {
      return element;
    }
    if (company && !hasPermission(company.role, allowedTypes)) {
      setSnackbarMessage('Usuário não autorizado');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return <Navigate to="/home" />;
    }
    return <Layout />;
  } catch {
    return <Navigate to="/login" />;
  }
}
