import {
  CardContainer,
  FlexBox,
  NumberTypography,
  TitleTypography,
} from './styles';

interface QuestionItemProps {
  title: string;
  questionNum: number;
}

export function QuestionItem({
  title,
  questionNum,
}: QuestionItemProps): JSX.Element {
  return (
    <CardContainer>
      <FlexBox>
        <NumberTypography>{questionNum}. </NumberTypography>
        <TitleTypography>{title}</TitleTypography>
      </FlexBox>
    </CardContainer>
  );
}
