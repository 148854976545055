import { Box, Grid } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { companyAPI, plansAPI } from '../../../api';
import { StatusCode } from '../../../api/enumerations';
import { InvoicesData, PlansData } from '../../../api/plans/types';
import ConfirmationDialog from '../../../components/Dialog/ConfirmationDialog';
import { TrialRemainingDays } from '../../../components/TrialRemainingDays';
import { OutlinedButton } from '../../../components/UI/Button';
import { Constants } from '../../../constants/configuration';
import { GlobalContext } from '../../../context/global';
import { formatDate, getErrorMessage } from '../../../helpers';
import useGeneral from '../../../hooks/useGeneral';
import { PageTitle } from '../styles';
import { InvoicesTable } from './InvoicesTable';
import {
  GridContainer,
  InfoTypography,
  InfoValueTypography,
  StyledButton,
  StyledDivider,
  TrialInfo,
  TrialInfoValue,
  TrialTitle,
} from './styles';

export default function Plans(): JSX.Element {
  const [plan, setPlan] = useState<PlansData>();
  const [trial, setTrial] = useState<boolean>(true);
  const [invoices, setInvoices] = useState<InvoicesData[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const rowsPerPage = 8;

  const navigate = useNavigate();

  const { navigateHome } = useGeneral();

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const getDataCallback = useCallback(async () => {
    try {
      const response = await plansAPI.getPlans();

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setPlan(response.data);

      if (response.data.current_price !== null) {
        setTrial(false);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [setErrorMessage, setOpenSnackbar, setSnackbarMessage]);

  const getPlansCallback = useCallback(async () => {
    try {
      const response = await plansAPI.getInvoices(page, rowsPerPage);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.detail.total_pages) {
        setTotalPages(response.detail.total_pages);
      }

      setInvoices(response.data);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [page, setErrorMessage, setOpenSnackbar, setSnackbarMessage]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  useEffect(() => {
    getPlansCallback();
  }, [getPlansCallback]);

  const handleChangeCard = async (): Promise<void> => {
    try {
      const response = await companyAPI.changeCard();

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      const linkSource = response.data.url;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.click();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleCancelCompanyPlan = async (): Promise<void> => {
    try {
      const response = await companyAPI.deleteCompanyPlan();

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Seu plano foi cancelado!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      navigateHome();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <>
      {plan?.name === 'Gratuito' && plan?.remaining_days !== null && (
        <TrialRemainingDays remainingDays={plan.remaining_days} />
      )}
      <Box>
        <PageTitle>{Constants.plan}</PageTitle>
        <GridContainer container spacing={4}>
          <Grid item xs={2}>
            <InfoTypography>{Constants.description}</InfoTypography>
            <InfoValueTypography>
              {plan?.name ? plan.name : '-'}
            </InfoValueTypography>
          </Grid>
          <Grid item xs={2}>
            <InfoTypography>{Constants.price}</InfoTypography>
            <InfoValueTypography>
              {plan?.current_price
                ? `R$ ${plan.current_price.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                  })}`
                : '-'}
            </InfoValueTypography>
          </Grid>
          <Grid item xs={2}>
            <InfoTypography>{Constants.frequency}</InfoTypography>
            <InfoValueTypography>
              {plan?.payment_frequency ? plan.payment_frequency : '-'}
            </InfoValueTypography>
          </Grid>
          <Grid item xs={2}>
            <InfoTypography>{Constants.renewall}</InfoTypography>
            <InfoValueTypography>
              {plan?.renewal ? plan.renewal : '-'}
            </InfoValueTypography>
          </Grid>
          <Grid item xs={2}>
            <InfoTypography>{Constants.status}</InfoTypography>
            <InfoValueTypography>
              {plan?.status ? plan.status : '-'}
            </InfoValueTypography>
          </Grid>
          <Grid item xs={2} display="flex" alignItems="center">
            <StyledButton onClick={() => navigate('plans')}>
              {trial ? Constants.signPlan : Constants.changePlan}
            </StyledButton>
          </Grid>
        </GridContainer>
        <StyledDivider />
        {trial ? (
          <>
            <TrialTitle>{Constants.billing}</TrialTitle>
            <GridContainer container spacing={4}>
              <Grid item xs={2.5}>
                <TrialInfo>{Constants.beggining}</TrialInfo>
                <TrialInfoValue>-</TrialInfoValue>
              </Grid>
              <Grid item xs={2.5}>
                <TrialInfo>{Constants.lastPayment}</TrialInfo>
                <TrialInfoValue>-</TrialInfoValue>
              </Grid>
              <Grid item xs={2.5}>
                <TrialInfo>{Constants.nextPayment}</TrialInfo>
                <TrialInfoValue>-</TrialInfoValue>
              </Grid>
              <Grid item xs={2.5}>
                <TrialInfo>{Constants.paymentMethod}</TrialInfo>
                <TrialInfoValue>-</TrialInfoValue>
              </Grid>
            </GridContainer>
          </>
        ) : (
          <>
            <PageTitle>{Constants.billing}</PageTitle>
            <GridContainer container spacing={4}>
              <Grid item xs={2.5}>
                <InfoTypography>{Constants.beggining}</InfoTypography>
                <InfoValueTypography>
                  {plan?.subscription_started_at
                    ? formatDate(plan.subscription_started_at)
                    : '-'}
                </InfoValueTypography>
              </Grid>
              <Grid item xs={2.5}>
                <InfoTypography>{Constants.lastPayment}</InfoTypography>
                <InfoValueTypography>
                  {plan?.charging_details?.last_payment
                    ? formatDate(plan.charging_details.last_payment)
                    : '-'}
                </InfoValueTypography>
              </Grid>
              <Grid item xs={2.5}>
                <InfoTypography>{Constants.nextPayment}</InfoTypography>
                <InfoValueTypography>
                  {plan?.charging_details?.next_payment
                    ? formatDate(plan.charging_details.next_payment)
                    : '-'}
                </InfoValueTypography>
              </Grid>
              <Grid item xs={2.5}>
                <InfoTypography>{Constants.paymentMethod}</InfoTypography>
                <InfoValueTypography>
                  {plan?.charging_details?.card_info
                    ? plan.charging_details.card_info
                    : '-'}
                </InfoValueTypography>
              </Grid>
              <Grid item xs={2} display="flex" alignItems="center">
                <StyledButton onClick={handleChangeCard}>
                  {Constants.changeCard}
                </StyledButton>
              </Grid>
            </GridContainer>
          </>
        )}
        <StyledDivider />
        {invoices.length > 0 && (
          <>
            <PageTitle>{Constants.paymentHistory}</PageTitle>
            <InvoicesTable
              tableData={invoices}
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
            <StyledDivider />
          </>
        )}
        <ConfirmationDialog
          text={Constants.cancelPlanText}
          button={<OutlinedButton>{Constants.cancelPlan}</OutlinedButton>}
          modalCallback={handleCancelCompanyPlan}
        />
      </Box>
    </>
  );
}
