/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress } from '@mui/material';
import { driver } from 'driver.js';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { inspectionAPI, roomAPI, workOrderAPI } from '../../../api';
import { Status, StepTour } from '../../../api/enumerations';
import { RoomData } from '../../../api/rooms/types';
import ConfirmationDialog from '../../../components/Dialog/ConfirmationDialog';
import { BoxContainer, FlexBoxGap } from '../../../components/UI/Box';
import {
  BackButton,
  CancelOSButton,
  StandardButton,
} from '../../../components/UI/Button';
import { GridContainer } from '../../../components/UI/Grid';
import { SectionTitle } from '../../../components/UI/Typography';
import {
  IconArrowCircleLeftMS,
  IconAssignmentMS,
  IconDeleteMS,
  IconDoorFrontMS,
  IconDownloadMS,
} from '../../../constants/icons';
import { Constants } from '../../../constants/inspection';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage } from '../../../helpers';
import { driverConfig } from '../../../helpers/driver/config';
import { inspectionRoomSteps } from '../../../helpers/driver/steps';
import useGeneral from '../../../hooks/useGeneral';
import { useTour } from '../../../hooks/useTour';
import { RoomCard } from './RoomCard';
import {
  EditButton,
  FlexCenterBox,
  GalleryContainer,
  TitleBox,
  TitleTypography,
} from './styles';

export default function Rooms(): JSX.Element {
  const [edit, setEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [rooms, setRooms] = useState<RoomData[]>([]);
  const pageSize = 12;

  const {
    setOpenSnackbar,
    setErrorMessage,
    setSnackbarMessage,
    inspectionPath,
  } = useContext(GlobalContext);

  const {
    tourCompletion,
    setTourCompletion,
    setTutorialStep,
    setToBeContinued,
    setTourSelection,
  } = useTour();

  const { handleDownloadFile } = useGeneral();

  const navigate = useNavigate();
  const { id, inspection } = useParams();
  const osId = parseInt(id as string, 10);
  const inspectionId = parseInt(inspection as string, 10);

  const navigateBack = (): void => {
    if (inspectionPath) {
      navigate(inspectionPath);
    } else {
      navigate('/home');
    }
  };

  const navigateSelectRoom = (): void => {
    navigate(`/home/property/${osId}/inspection/${inspectionId}/rooms/select`);
  };

  const getDataCallback = useCallback(async () => {
    if (page === lastCalledPage) {
      return;
    }

    if (page === 1) {
      try {
        const response = await workOrderAPI.getWorkOrder(osId);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        if (
          response.data.status === Status.ENTRANCE ||
          response.data.status === Status.PEPT ||
          response.data.status === Status.SCHEDULE
        ) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        if (response.data.status === Status.INSPECTION) {
          setEdit(true);
        }
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
        navigate('/home');
      }
    }

    try {
      const response = await roomAPI.getAllRooms(inspectionId, page, pageSize);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data) {
        setRooms([...rooms, ...response.data]);
        setLastCalledPage(page);
      }

      if (response.detail.total_pages && response.detail.total_pages > page) {
        setPage(page + 1);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      navigateBack();
    }
  }, [page]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback, page]);

  const handleDownloadPictures = async (): Promise<void> => {
    setDownloading(true);
    try {
      const response = await inspectionAPI.getInspectionPictures(inspectionId);

      if (response.ok) {
        const contentDisposition = response.headers
          .get('Content-Disposition')
          ?.split('filename=')[1];
        const filename = contentDisposition || 'file.zip';
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          handleDownloadFile(url, filename);
        });
        setDownloading(false);
      } else {
        throw new Error('Não foi possível fazer o download, tente novamente.');
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setDownloading(false);
    }
  };

  const handleDeletePictures = async (): Promise<void> => {
    try {
      const response = await inspectionAPI.deleteInspectionPictures(
        inspectionId
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Todas as imagens foram deletas!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      navigateBack();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const newInspection = tourCompletion.inspection;
  const roomsDriverObj = driver({
    ...driverConfig,
    showProgress: false,
    steps: inspectionRoomSteps,
    onPrevClick: () => {
      const activeIndex = roomsDriverObj.getActiveIndex();
      if (activeIndex === 2) {
        navigate(-1);
        setTimeout(() => {
          roomsDriverObj.moveTo(1);
        }, 400);
      }
      if (activeIndex === 3) {
        navigate(`${window.location.pathname}/select`);
        setTimeout(() => {
          roomsDriverObj.moveTo(2);
        }, 200);
      }
      roomsDriverObj.movePrevious();
    },
    onNextClick: () => {
      const activeIndex = roomsDriverObj.getActiveIndex();
      if (!window.location.pathname.includes('/select') && activeIndex === 1) {
        navigate(`${window.location.pathname}/select`);
        setTimeout(() => {
          roomsDriverObj.moveTo(2);
        }, 200);
      }
      if (activeIndex === 2 && rooms.length) {
        setToBeContinued(true);
        navigate(-1);
        setTimeout(() => {
          roomsDriverObj.moveTo(3);
        }, 725);
      }
      if (activeIndex === 2 && !rooms.length) {
        navigate(-1);
        roomsDriverObj.destroy();
        newInspection[1].step = activeIndex;
        setTourCompletion(() => ({
          ...tourCompletion,
          inspection: newInspection,
        }));
      }
      if (roomsDriverObj.isLastStep()) {
        newInspection[1].complete = true;
        newInspection[1].step = inspectionRoomSteps.length;
        setTourCompletion(() => ({
          ...tourCompletion,
          inspection: newInspection,
        }));
        setToBeContinued(false);
      }
      roomsDriverObj.moveNext();
    },
    onCloseClick: () => {
      setTourSelection(false);
      setToBeContinued(false);
      roomsDriverObj.destroy();
    },
    onDestroyStarted: () => {
      setTourSelection(false);
      setToBeContinued(false);
      roomsDriverObj.destroy();
    },
  });

  useEffect(() => {
    setTutorialStep(StepTour.INSPECTIONSTART);

    if (
      !tourCompletion.inspection[1].complete &&
      !tourCompletion.skipTour.inspection
    ) {
      if (rooms.length) {
        roomsDriverObj.moveTo(3);
      } else if (tourCompletion.inspection[1].step === 0) {
        roomsDriverObj.drive();
      }
    }
  }, [tourCompletion, rooms]);

  return (
    <GridContainer>
      <BackButton onClick={navigateBack}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer id="rooms-container">
        <TitleBox>
          <TitleTypography>
            {IconAssignmentMS}
            {Constants.inspection}
          </TitleTypography>
          <FlexBoxGap>
            <EditButton onClick={handleDownloadPictures} disabled={downloading}>
              {downloading ? <CircularProgress size={22} /> : IconDownloadMS}
              {Constants.downloadImages}
            </EditButton>
            {rooms.length > 0 && edit && (
              <Box width={282}>
                <ConfirmationDialog
                  button={
                    <CancelOSButton>
                      {IconDeleteMS}
                      {Constants.deleteImages}
                    </CancelOSButton>
                  }
                  model="error"
                  text={Constants.deleteImagesText}
                  modalCallback={handleDeletePictures}
                />
              </Box>
            )}
          </FlexBoxGap>
        </TitleBox>
        <SectionTitle>
          {IconDoorFrontMS}
          {Constants.rooms}
        </SectionTitle>
        <GalleryContainer>
          {rooms.map((room) => (
            <RoomCard
              key={room.id}
              room={room}
              rooms={rooms}
              setRooms={setRooms}
              edit={edit}
            />
          ))}
        </GalleryContainer>
      </BoxContainer>
      <FlexCenterBox>
        {edit && (
          <StandardButton id="new-room-button" onClick={navigateSelectRoom}>
            {Constants.addRoom}
          </StandardButton>
        )}
      </FlexCenterBox>
    </GridContainer>
  );
}
