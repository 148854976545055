import { Box, Button, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const InputText = styled(TextField)(() => ({
  width: '100%',
  '& .MuiInputBase-root': {
    borderRadius: 16,
  },
}));

export const CoordContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-around',
  padding: '8px',
  border: '1px solid #D7D8DB',
  borderRadius: '10px',
  margin: '16px 0 26px',
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
}));

export const BoldTypography = styled(Typography)(() => ({
  fontWeight: '600',
}));

export const MapButton = styled(Button)(({ theme }) => ({
  borderRadius: '10px',
  width: '100%',
  border: `2px solid ${theme.palette.primary.dark}`,
  color: theme.palette.primary.dark,
  backgroundColor: '#FAFCFF',
  fontWeight: '600',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  '&:disabled': {
    color: theme.palette.primary.dark,
  },
}));

export const CepStyle = {
  '& .MuiInputBase-root': {
    borderRadius: '16px',
  },
};
