import { Box, ButtonBase, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CloseButtonBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
}));

export const DialogContainer = styled(Box)(() => ({
  minWidth: '600px',
  minHeight: '200px',
  padding: '40px 40px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

export const Title = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '22px',
  marginBottom: '20px',
}));

export const StyledTypography = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: '600',
  color: '#1A1A1A',
  textAlign: 'start',
}));

export const OsButton = styled(ButtonBase)(({ theme }) => ({
  width: 'fit-content',
  justifyContent: 'flex-start',
  fontSize: '18px',
  fontWeight: '600',
  color: theme.palette.primary.main,
  margin: '-4px',
  padding: '6px',
  borderRadius: '4px',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const SpaceAroundBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-around',
  marginTop: '20px',
}));

export const spanStyle = {
  fontWeight: '400',
};
