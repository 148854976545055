import { Box, Button, ButtonBase, CardMedia, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { UploadBox } from '../../../components/UI/Box';
import { SectionTitle } from '../../../components/UI/Typography';

export const SectionContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const SectionBox = styled(Box)(() => ({
  margin: '0',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  width: '800px',
}));

export const FlexEndBox = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
}));

export const PhotoContainer = styled(Box)(() => ({
  borderRadius: '30px',
}));

export const ImageCard = styled(CardMedia)(({ theme }) => ({
  backgroundColor: '#bcbcbc66',
  width: '768px',
  height: '576px',
  borderRadius: '15px',
  alignSelf: 'center',
  backgroundSize: 'contain',
  objectFit: 'contain',
  margin: 'auto',
  [theme.breakpoints.down('xl')]: {
    width: '640px',
    height: '480px',
  },
}));

export const DownloadButton = styled(ButtonBase)(({ theme }) => ({
  float: 'right',
  height: '42px',
  width: '280px',
  border: '1px solid #737475',
  borderRadius: '16px',
  display: 'flex',
  gap: '10px',
  '& span': {
    color: theme.palette.primary.main,
  },
  '&:hover': {
    backgroundColor: '#EDEFF2',
    borderColor: theme.palette.primary.main,
  },
}));

export const SectionSubtitle = styled(SectionTitle)(() => ({
  marginTop: '10px',
  fontSize: '18px',
}));

export const GalleryContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '32px',
}));

export const Upload = styled(UploadBox)(() => ({
  width: '240px',
  minHeight: '220px',
  gap: '8px',
  cursor: 'pointer',
}));

export const InsideBox = styled(Box)(() => ({
  cursor: 'pointer',
  textAlign: 'center',
}));

export const UploadIcon = styled(Typography)(({ theme }) => ({
  '& span': { fontSize: '72px', color: theme.palette.primary.main },
}));

export const UploadText = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '14px',
}));

export const UploadSubtitle = styled(Typography)(() => ({
  fontSize: '14px',
}));

export const FlexReverseBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row-reverse',
}));

export const StyledCardMedia = styled(CardMedia)(() => ({
  backgroundColor: '#bcbcbc66',
  objectFit: 'contain',
  backgroundSize: 'contain',
  width: '240px',
  height: '220px',
  borderRadius: '30px',
  cursor: 'pointer',
}));

export const PathologieButton = styled(Button)(() => ({
  border: '1px solid #00B39B',
  backgroundColor: '#FAFCFF',
  fontWeight: '700',
  color: '#00B39B',
  '&:hover': {
    backgroundColor: '#00B39B',
  },
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
  width: '300px',
  backgroundColor: theme.palette.primary.dark,
}));

export const PicBackButton = styled(ButtonBase)(({ theme }) => ({
  position: 'absolute',
  margin: '288px 0 0 -90px',
  borderRadius: '50%',
  padding: '10px 14px 10px 6px',
  color: theme.palette.primary.main,
  '&.Mui-disabled': {
    color: '#D7D8DB',
  },
  '&:hover': {
    backgroundColor: '#dfc1e9d1',
  },
  '& span': {
    fontSize: '48px',
  },
  [theme.breakpoints.down('xl')]: {
    margin: '240px 0 0 -90px',
  },
}));

export const PicForwardButton = styled(ButtonBase)(({ theme }) => ({
  float: 'right',
  position: 'absolute',
  margin: '288px -90px 0 0',
  borderRadius: '50%',
  padding: '10px 6px 10px 14px',
  color: theme.palette.primary.main,
  '&.Mui-disabled': {
    color: '#D7D8DB',
  },
  '&:hover': {
    backgroundColor: '#dfc1e9d1',
  },
  '& span': {
    fontSize: '48px',
  },
  [theme.breakpoints.down('xl')]: {
    margin: '240px -90px 0 0',
  },
}));
