/* eslint-disable react/require-default-props */
import { useLocation, useNavigate } from 'react-router-dom';

import {
  IconArrowBackStandardMS,
  IconArrowForwardStandardMS,
  IconHourglassTopMS,
} from '../../constants/icons';
import { PipelineBox, StyledBox, StyledTypography, TextBox } from './styles';

interface TrialRemainingDaysProps {
  remainingDays: number;
  pipeline?: boolean;
}

export function TrialRemainingDays({
  remainingDays,
  pipeline,
}: TrialRemainingDaysProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const plansPathname = location.pathname.includes('plans');

  const navigateToPlans = (): void => {
    if (plansPathname) {
      navigate('/configuration');
    } else {
      navigate('/configuration/plans');
    }
  };
  return pipeline ? (
    <PipelineBox onClick={navigateToPlans}>
      {IconHourglassTopMS}
      <StyledTypography sx={{ fontSize: 16 }}>
        você tem <b>{remainingDays} dias</b> de acesso gratuito{' '}
      </StyledTypography>
    </PipelineBox>
  ) : (
    <StyledBox onClick={navigateToPlans}>
      {IconHourglassTopMS}
      <TextBox>
        {plansPathname && IconArrowBackStandardMS}
        <StyledTypography>
          você tem {remainingDays} dias de acesso gratuito{' '}
        </StyledTypography>
        {!plansPathname && IconArrowForwardStandardMS}
      </TextBox>
    </StyledBox>
  );
}
