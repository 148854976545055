/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { samplesListAPI } from '../../../../api';
import { StatusCode } from '../../../../api/enumerations';
import {
  SampleElementData,
  SampleList,
} from '../../../../api/samplesLists/types';
import { GlobalContext } from '../../../../context/global';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import useSnackbar from '../../../../hooks/useSnackbar';

interface SamplesHook {
  getSampleElements: () => Promise<void>;
  handleDeleteElement: (elementId: number) => Promise<void>;
  sampleData: SampleList | undefined;
  elementsData: SampleElementData[] | undefined;
  sampleId: number;
  loading: boolean;
}

const useSample = (): SamplesHook => {
  const [loading, setLoading] = useState(true);
  const [sampleData, setSampleData] = useState<SampleList>();
  const [elementsData, setElementsData] = useState<SampleElementData[]>([]);

  const { id } = useParams();
  const sampleId = parseInt(id as string, 10);
  const { handleSnackbar } = useSnackbar();
  const { getErrorMessage } = useErrorMessage();
  const { company } = useContext(GlobalContext);
  const navigate = useNavigate();

  const getSampleElements = useCallback(async () => {
    setLoading(true);
    try {
      const response = await samplesListAPI.getSampleElements(sampleId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setElementsData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleSnackbar(getErrorMessage(error), true);
    }
  }, [sampleId]);

  const getSampleData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await samplesListAPI.getSampleList(sampleId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSampleData(response.data);
      getSampleElements();
    } catch (error) {
      setLoading(false);
      handleSnackbar(getErrorMessage(error), true);
      navigate('/samples');
    }
  }, [company, sampleId]);

  useEffect(() => {
    getSampleData();
  }, [company, sampleId]);

  const handleDeleteElement = async (elementId: number): Promise<void> => {
    setLoading(true);
    try {
      const response = await samplesListAPI.deleteSampleElement(
        sampleId,
        elementId
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      getSampleElements();
    } catch (error) {
      setLoading(false);
      handleSnackbar(getErrorMessage(error), true);
    }
  };

  return {
    getSampleElements,
    handleDeleteElement,
    sampleData,
    elementsData,
    sampleId,
    loading,
  };
};

export default useSample;
