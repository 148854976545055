/* eslint-disable react/require-default-props */
import { Grid, Typography } from '@mui/material';

import { WorkOrderData } from '../../../api/workOrders/types';
import { Constants } from './constants';
import {
  BoldTypography,
  CoordGrid,
  InputTitle,
  SmallStyledInput,
  StyledInput,
} from './styles';

interface PropertyAddressProps {
  propertyData?: WorkOrderData;
}

export function PropertyAddress({
  propertyData,
}: PropertyAddressProps): JSX.Element {
  return (
    <Grid container spacing={4}>
      <Grid item xs={4}>
        <InputTitle>{Constants.address}</InputTitle>
        <StyledInput>{propertyData?.street}</StyledInput>
      </Grid>
      <Grid item xs={2}>
        <InputTitle>{Constants.addressNumber}</InputTitle>
        <SmallStyledInput>{propertyData?.number}</SmallStyledInput>
      </Grid>
      <Grid item xs={5}>
        <InputTitle>{Constants.addressComplement}</InputTitle>
        <StyledInput>{propertyData?.address_complement}</StyledInput>
      </Grid>
      <Grid item xs={3}>
        <InputTitle>{Constants.addressCep}</InputTitle>
        <StyledInput>{propertyData?.zip_code}</StyledInput>
      </Grid>
      <Grid item xs={3}>
        <InputTitle>{Constants.addressDistrict}</InputTitle>
        <StyledInput>{propertyData?.district}</StyledInput>
      </Grid>
      <Grid item xs={3}>
        <InputTitle>{Constants.addressCity}</InputTitle>
        <StyledInput>{propertyData?.city}</StyledInput>
      </Grid>
      <Grid item xs={3}>
        <InputTitle>{Constants.addressUF}</InputTitle>
        <StyledInput>{propertyData?.state}</StyledInput>
      </Grid>
      <CoordGrid item xs={3}>
        <BoldTypography>{Constants.lat}</BoldTypography>
        <Typography>{propertyData?.latitude}</Typography>
      </CoordGrid>
      <CoordGrid item xs={3}>
        <BoldTypography>{Constants.lng}</BoldTypography>
        <Typography>{propertyData?.longitude}</Typography>
      </CoordGrid>
    </Grid>
  );
}
