export const Constants = {
  recoverPassword: 'Recuperar senha',
  emailPlaceholder: 'digite seu e-mail cadastrado',
  submit: 'enviar',
  check: 'verificar',
  cancel: 'cancelar',
  timer: 'você poderá solicitar um novo código em ',
  seconds: ' segundos:',
  newToken: 'solicitar novo código',
  newPassword: 'Altere sua senha',
  passwordText: 'Digite uma senha para concluir o login',
  save: 'salvar',
};
