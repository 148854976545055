import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 20px',
  alignItems: 'center',
  gap: 20,
}));

export const HeaderContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

export const FilterBox = styled(Box)(() => ({
  display: 'flex',
  width: '65%',
  gap: '8px',
  alignItems: ' center',
}));
