import { CompanyData } from '../api/login/types';

const tokenKey = 'proto-token';
const companyKey = 'company';

export const setLocalStorageToken = (token: string): void => {
  localStorage.setItem(tokenKey, token);
};

export const getLocalStorageToken = (): string | null => {
  const value = localStorage.getItem(tokenKey);
  return value;
};

export const removeLocalStorageToken = (): void => {
  localStorage.removeItem(tokenKey);
};

export const setLocalStorageCompany = (company: CompanyData): void => {
  localStorage.setItem(companyKey, JSON.stringify(company));
};

export const getLocalStorageCompany = (): CompanyData | null => {
  const value = localStorage.getItem(companyKey);
  if (value) {
    return JSON.parse(value);
  }
  return null;
};

export const removeLocalStorageCompany = (): void => {
  localStorage.removeItem(companyKey);
};
