/* eslint-disable no-lonely-if */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { samplesListAPI } from '../../../api';
import {
  PropertyType,
  RegistrationUf,
  StatusCode,
} from '../../../api/enumerations';
import { CityData, SampleList } from '../../../api/samplesLists/types';
import {
  SelectProps,
  selectRegistrationUf,
} from '../../../constants/selectOptions';
import { GlobalContext } from '../../../context/global';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useSnackbar from '../../../hooks/useSnackbar';

interface SamplesListHook {
  handleDeleteSample: (sampleId: number) => Promise<void>;
  searchField: string;
  setSearchField: (value: string) => void;
  setPage: (value: number) => void;
  page: number;
  totalPages: number;
  samplesData: SampleList[] | undefined;
  setUf: (value: number) => void;
  uf: number;
  city: string;
  setCity: (value: string) => void;
  realEstateKind: number;
  setRealEstateKind: (value: number) => void;
  ufList: SelectProps[];
  cityList: SelectProps[];
  loading: boolean;
}

const useSamplesList = (): SamplesListHook => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [samplesData, setSamplesData] = useState<SampleList[]>();
  const [sampleCities, setSampleCities] = useState<CityData[]>();
  const [cityList, setCityList] = useState<SelectProps[]>([]);
  const [city, setCity] = useState('');
  const [ufList, setUfList] = useState<SelectProps[]>([
    { label: 'selecione uma opção', value: 0 },
  ]);
  const [uf, setUf] = useState(RegistrationUf.SELECT);
  const [realEstateKind, setRealEstateKind] = useState(PropertyType.SELECT);
  const [searchField, setSearchField] = useState('');
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const { handleSnackbar } = useSnackbar();
  const { getErrorMessage } = useErrorMessage();
  const { company } = useContext(GlobalContext);

  const prevUfRef = useRef(0);

  const rowsPerPage = 10;

  const filterRegistrationUF = (data: CityData[]): SelectProps[] => {
    const ufs = Array.from(new Set(data.map((city) => city.uf)));
    const registrationUfList = selectRegistrationUf();
    const filteredUfList = registrationUfList.filter((item) =>
      ufs.includes(item.value)
    );
    return [{ label: 'selecione uma opção', value: 0 }, ...filteredUfList];
  };

  const getCitiesList = useCallback(async () => {
    setLoading(true);

    try {
      const response = await samplesListAPI.getCitiesList();

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data) {
        setSampleCities(response.data);
        const ufs = filterRegistrationUF(response.data);
        setUfList(ufs);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      handleSnackbar(getErrorMessage(error), true);
    }
  }, []);

  const getSamplesListData = useCallback(async () => {
    setLoading(true);

    const params = {
      city: city !== '' && uf !== 0 ? city : undefined,
      page,
      realEstateKind: realEstateKind !== 0 ? realEstateKind : undefined,
      size: rowsPerPage,
      uf: uf !== 0 ? uf : undefined,
      searchTerm: searchField.length > 0 ? searchField : undefined,
    };

    try {
      const response = await samplesListAPI.getSamplesLists(params);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data) {
        setSamplesData(response.data);
        setTotalPages(response.detail.total_pages || 1);
        if (sampleCities) {
          setLoading(false);
        } else {
          getCitiesList();
        }
      }
    } catch (error) {
      setLoading(false);
      handleSnackbar(getErrorMessage(error), true);
    }
  }, [company, uf, city, realEstateKind, page, searchField]);

  useEffect(() => {
    getSamplesListData();
  }, [company, realEstateKind, page]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      if (!isFirstLoad) {
        if (searchField.length > 0) {
          getSamplesListData();
        } else {
          getSamplesListData();
        }
      } else {
        setIsFirstLoad(false);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchField]);

  useEffect(() => {
    if (
      !sampleCities ||
      (uf === RegistrationUf.SELECT &&
        prevUfRef.current === 0 &&
        city.length === 0)
    ) {
      return;
    }

    if (uf === RegistrationUf.SELECT && prevUfRef.current !== 0) {
      setCity('');
      setCityList([]);
      getSamplesListData();
      prevUfRef.current = uf;
      return;
    }

    if (
      uf !== RegistrationUf.SELECT &&
      uf !== prevUfRef.current &&
      city.length !== 0
    ) {
      setCity('');
      const filteredCities = sampleCities.filter((city) => city.uf === uf);
      const cityOptions = filteredCities.map((city) => ({
        label: city.name,
        value: city.id,
      }));

      setCityList([...cityOptions]);
      return;
    }
    const filteredCities = sampleCities.filter((city) => city.uf === uf);
    const cityOptions = filteredCities.map((city) => ({
      label: city.name,
      value: city.id,
    }));

    setCityList([...cityOptions]);

    getSamplesListData();

    prevUfRef.current = uf;
  }, [uf, sampleCities, city]);

  const handleDeleteSample = async (sampleId: number): Promise<void> => {
    try {
      const response = await samplesListAPI.deleteSampleList(sampleId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      getSamplesListData();
    } catch (error) {
      setLoading(false);
      handleSnackbar(getErrorMessage(error), true);
    }
  };

  return {
    handleDeleteSample,
    searchField,
    setSearchField,
    setPage,
    page,
    totalPages,
    samplesData,
    setUf,
    uf,
    city,
    setCity,
    realEstateKind,
    setRealEstateKind,
    ufList,
    cityList,
    loading,
  };
};

export default useSamplesList;
