import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { passwordAPI } from '../../../api';
import { StatusCode } from '../../../api/enumerations';
import { IconVisibility, PasswordIcon } from '../../../components/InputIcon';
import { Constants } from '../../../constants/firstAccess';
import { GlobalContext } from '../../../context/global';
import { validatePassword } from '../../../helpers';
import useErrorMessage from '../../../hooks/useErrorMessage';
import {
  ButtonBox,
  FilledButton,
  OutlinedButton,
  SectionSubtitle,
  SectionTitle,
  StyledDialog,
  StyledTextField,
} from './styles';

interface ChangePasswordProps {
  isOpen: boolean;
  setOpenModal: (value: boolean) => void;
  tempToken: string;
}

export default function ChangePassword({
  isOpen,
  setOpenModal,
  tempToken,
}: ChangePasswordProps): JSX.Element {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [confirmation, setConfirmation] = useState('');
  const [confirmationError, setConfirmationError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();

  const navigate = useNavigate();

  const handleClose = (): void => {
    setOpenModal(false);
  };

  const handleSubmitPassword = async (): Promise<void> => {
    const formData = new FormData();
    formData.append('password', password);

    try {
      const response = await passwordAPI.resetPassword(tempToken, formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setOpenModal(false);
      setSnackbarMessage('Senha salva com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      navigate('/login');
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      navigate('/login');
    }
  };

  return (
    <StyledDialog open={isOpen}>
      <SectionTitle>{Constants.newPassword}</SectionTitle>
      <SectionSubtitle>{Constants.passwordText}</SectionSubtitle>
      <StyledTextField
        required
        type={showPassword ? 'text' : 'password'}
        variant="standard"
        helperText={
          password &&
          'mínimo de 8 caracteres com letra mínuscula, maiúscula e número'
        }
        InputProps={{
          startAdornment: <PasswordIcon color="#8B8C8E" />,
          endAdornment: (
            <IconVisibility
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              color="#8B8C8E"
            />
          ),
        }}
        id="password"
        placeholder="digite sua senha"
        error={passwordError}
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
          !validatePassword(e.target.value)
            ? setPasswordError(true)
            : setPasswordError(false);
        }}
      />
      <StyledTextField
        required
        type={showConfirmation ? 'text' : 'password'}
        variant="standard"
        helperText={confirmation && 'as senhas devem ser iguais'}
        InputProps={{
          startAdornment: <PasswordIcon color="#8B8C8E" />,
          endAdornment: (
            <IconVisibility
              showPassword={showConfirmation}
              setShowPassword={setShowConfirmation}
              color="#8B8C8E"
            />
          ),
        }}
        id="confirmation"
        placeholder="confirme sua senha"
        error={confirmationError}
        value={confirmation}
        onChange={(e) => {
          setConfirmation(e.target.value);
          e.target.value !== password
            ? setConfirmationError(true)
            : setConfirmationError(false);
        }}
      />
      <ButtonBox>
        <OutlinedButton onClick={handleClose}>
          {Constants.cancel}
        </OutlinedButton>
        <FilledButton
          onClick={handleSubmitPassword}
          disabled={passwordError || confirmationError}
        >
          {Constants.save}
        </FilledButton>
      </ButtonBox>
    </StyledDialog>
  );
}
