export const Constants = {
  dataEntrance: 'Entrada de Dados',
  dataEdition: 'Edição de Dados',
  cancelOs: 'cancelar OS',
  cancelOsText: 'cancelar esta OS',
  reportData: 'Dados do relatório',
  propertyData: 'Dados do imóvel',
  uploadText: 'Faça o upload ou download dos seguintes documentos:',
  propertyRegister: 'Matrícula do imóvel',
  upload: 'upload',
  iptu: 'Cartela do IPTU',
  downloadText:
    'Você pode realizar o download dos seguintes documentos anexados:',
  download: 'download',
  propertyAddress: 'Localização do imóvel',
  searchMap: 'pesquisar no mapa',
  lat: 'Latitude: ',
  lng: 'Longitude:',
  propertyDetails: 'Características do imóvel',
  submit: 'criar nova OS',
  confirmEdit: 'confirmar alterações',
  approve: 'aprovar',
  pept: 'pendência técnica',
  changeStatus:
    'gravar os dados atuais e alterar o status da OS ** de Entrada para Agendamento',
};
