import { handleUrl } from '../global';
import { LoginProps } from './types';

const login = (email: string, password: string): Promise<LoginProps> => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

  const urlencoded = new URLSearchParams();
  urlencoded.append('username', email);
  urlencoded.append('password', password);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/login`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      return JSON.parse(result);
    })
    .catch((error) => new Error(error));
};

export default {
  login,
};
