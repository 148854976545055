import { Box, ButtonBase, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PipelineBox = styled(ButtonBase)(({ theme }) => ({
  minWidth: 1100,
  display: 'flex',
  gap: 2,
  position: 'absolute',
  top: 0,
  right: 0,
  left: 260,
  backgroundColor: '#D814C9',
  padding: '4px 12px',
  textAlign: 'center',
  cursor: 'pointer',
  span: {
    color: theme.palette.common.white,
    fontSize: 21,
  },
  [theme.breakpoints.down('xl')]: {
    left: 210,
  },
  zIndex: 1,
}));

export const StyledBox = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  position: 'absolute',
  right: ' 0 !important',
  top: 40,
  backgroundColor: '#D814C9',
  borderRadius: '20px 0px 0px 20px',
  padding: '4px 12px',
  textAlign: 'center',
  span: {
    color: theme.palette.common.white,
    fontSize: 32,
  },
}));

export const TextBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  fontSize: 14,
  color: theme.palette.common.white,
  span: {
    fontSize: 16,
    fontWeight: 600,
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.common.white,
}));
