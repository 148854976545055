import { useState } from 'react';

import standard from '../../../../assets/images/roomImages/standard.png';
import { CustomTextField } from '../../../../components/CustomInput';
import { CloseIcon, FilledButton } from '../../../../components/UI/Button';
import { StyledDialog } from '../../../../components/UI/Dialog';
import { DialogTitle } from '../../../../components/UI/Typography';
import {
  IconAddMS,
  IconCloseMS,
  IconDoorFrontMS,
} from '../../../../constants/icons';
import { Constants } from '../../../../constants/inspection';
import useGeneral from '../../../../hooks/useGeneral';
import {
  AddRoomBox,
  AddRoomButton,
  RelativeBox,
  StyledCardMedia,
  StyledCardText,
} from '../styles';
import { DialogContainer, ButtonBox } from './styles';

export function AddNewRoom(): JSX.Element {
  const [roomName, setRoomName] = useState('');
  const dialog = useGeneral();

  const handleNewRoom = (): void => {
    dialog.handleClose();
  };

  return (
    <>
      <RelativeBox onClick={dialog.handleOpen}>
        <StyledCardMedia image={standard} title="outros" />
        <StyledCardText>outros</StyledCardText>
        <AddRoomBox>
          <AddRoomButton>{IconAddMS}</AddRoomButton>
        </AddRoomBox>
      </RelativeBox>
      <StyledDialog
        open={dialog.open}
        onClose={dialog.handleClose}
        aria-labelledby="new room dialog"
      >
        <DialogContainer>
          <CloseIcon onClick={dialog.handleClose}>{IconCloseMS}</CloseIcon>
          <DialogTitle>
            {IconDoorFrontMS}
            {Constants.newRoom}
          </DialogTitle>
          <CustomTextField
            id="room-name"
            label="nome do cômodo"
            value={roomName}
            setValue={setRoomName}
          />
          <ButtonBox>
            <FilledButton onClick={handleNewRoom}>{Constants.add}</FilledButton>
          </ButtonBox>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
