import { Box, Button, ButtonBase, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const InputBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '75%',
  minHeight: 450,
}));

export const InputTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: '700',
  marginBottom: '4vw',
  color: theme.palette.secondary.main,
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  fontSize: '18px',
  width: '100%',
  marginBottom: '40px',
  '& .MuiInputBase-input': {
    color: theme.palette.common.black,
    fontSize: '18px',
  },
  '& .MuiInputBase-root.MuiInput-root:before': {
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
  '& .MuiInputBase-root.MuiInput-root:after': {
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
  '& .MuiInputBase-root.MuiInput-root.Mui-error:before': {
    borderBottom: '1px solid #d32f2f',
  },
  '& .MuiInputBase-root.MuiInput-root.Mui-error:after': {
    borderBottom: '1px solid #d32f2f',
  },
  '& .MuiInputBase-root.MuiInput-root:hover:before': {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  '& .MuiInputBase-root.MuiInput-root:hover:after': {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  'input:hover::-webkit-input-placeholder': { color: 'transparent' },
  'input:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0px 1000px #F2F2F2 inset',
    paddingLeft: '10px',
  },
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
  borderRadius: '16px',
  backgroundColor: '#613354',
  fontWeight: '700',
  color: '#D6D4D0',
  textTransform: 'uppercase',
  margin: 'clamp(42px, 6.7vh ,62px) 0',
  boxShadow: '0px 3px 15px #00000029',
  '&:hover': {
    backgroundColor: '#9B7359',
  },
  [theme.breakpoints.down('md')]: {
    width: '280px',
  },
  [theme.breakpoints.up('md')]: {
    width: '320px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '500px',
  },
}));

export const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  color: theme.palette.primary.main,
  '&:hover': {
    color: theme.palette.primary.dark,
    '& :nth-of-type(2)': {
      textDecorationColor: theme.palette.primary.dark,
    },
  },
  '&.Mui-disabled': {
    color: theme.palette.secondary.light,
    '& :nth-of-type(2)': {
      textDecorationColor: theme.palette.secondary.light,
    },
  },
  '& :nth-of-type(2)': {
    fontSize: '16px',
    textDecoration: 'underline',
    textDecorationColor: theme.palette.primary.main,
  },
  '& span': {
    fontSize: '21px',
  },
}));

export const TimerTypography = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  color: theme.palette.common.black,
  padding: '20px',
  textAlign: 'center',
}));

export const StyledPatternFormat = {
  marginBottom: '40px',
  '& .MuiFormHelperText-root': {
    color: '#000',
    textAlign: 'center',
    padding: '10px 0',
  },
  '& .MuiInputBase-input': {
    color: '#000',
    fontSize: '40px',
    textAlign: 'center',
    maxWidth: '320px',
    letterSpacing: '8px',
  },
  '& .MuiInputBase-root.MuiInput-root:before': {
    borderBottom: '1px solid #CC34FF',
  },
  '& .MuiInputBase-root.MuiInput-root:after': {
    borderBottom: '1px solid #CC34FF',
  },
  '& .MuiInputBase-root.MuiInput-root:hover:before': {
    borderBottom: '1px solid #CC34FF',
  },
  '& .MuiInputBase-root.MuiInput-root:hover:after': {
    borderBottom: '1px solid #CC34FF',
  },
  'input:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0px 1000px #004D43 inset',
    paddingLeft: '10px',
    WebkitTextFillColor: '#000',
  },
};

export const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 20,
  width: '100%',
}));

export const OutlinedButton = styled(Button)(({ theme }) => ({
  maxWidth: '220px',
  border: `1.5px solid ${theme.palette.secondary.main}`,
  color: theme.palette.secondary.main,
  fontWeight: '600',
  backgroundColor: 'transparent',
  textTransform: 'uppercase',
  borderRadius: '16px',
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export const FilledButton = styled(Button)(({ theme }) => ({
  maxWidth: '220px',
  backgroundColor: theme.palette.secondary.main,
  textTransform: 'uppercase',
  borderRadius: '16px',
  '&:hover': {
    backgroundColor: '#9B7359',
    filter: 'brightness(85%)',
  },
}));
