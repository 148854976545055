import { Box, CardMedia, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PhotosContainer = styled(Box)(() => ({
  margin: '20px 4px',
  display: 'flex',
  gap: '20px',
  flexWrap: 'wrap',
}));

export const FacadeContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '0.5rem',
}));

export const UploadBox = styled(Box)(() => ({
  width: '260px',
  minHeight: '220px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  // eslint-disable-next-line max-len
  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23595959FF' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  borderRadius: '30px',
}));

export const InsideBox = styled(Box)(() => ({
  cursor: 'pointer',
  textAlign: 'center',
}));

export const UploadIcon = styled(Typography)(({ theme }) => ({
  '& span': { fontSize: '92px', color: theme.palette.primary.main },
}));

export const UploadText = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '14px',
}));

export const UploadSubtitle = styled(Typography)(() => ({
  fontSize: '14px',
}));

export const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: '600',
  color: theme.palette.primary.main,
}));

export const StyledCardMedia = styled(CardMedia)(() => ({
  width: '260px',
  borderRadius: '30px',
  margin: '0 20px',
  height: 220,
}));
