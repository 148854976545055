import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: 'auto',
  paddingBottom: '5rem',
}));

export const Logo = styled(Box)(() => ({
  marginBottom: '40px',
  backgroundColor: '#CC35FF',
  padding: '0.5rem',
  img: {
    height: 77,
    width: 88,
  },
}));

export const Stage = styled(Typography)(({ theme }) => ({
  fontSize: 28,
  marginBottom: 50,
  color: theme.palette.primary.main,
}));

export const Title = styled(Typography)(() => ({
  fontSize: 37,
  marginBottom: 30,
  fontWeight: 600,
}));
