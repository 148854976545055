/* eslint-disable */
import { DriveStep } from 'driver.js';

const onBoardingSteps: DriveStep[] = [
  {
    element: '#lateral-menu',
    popover: {
      title: '',
      description:
        'No menu lateral você poderá navegar entre a tela inicial, a página com as ordens de serviços mais antigas, usuários cadastrados, formulários, configurações, agenda e erros reportados.',
      side: 'right',
      align: 'center',
    },
  },
  {
    element: '#tour-button',
    popover: {
      description:
        'Aqui você terá acesso às opções de telas do tour rápido sempre que desejar.',
      side: 'right',
      align: 'center',
    },
  },
  {
    element: '#modal-progress',
    popover: {
      description:
        'Acesse o tour rápido a hora que quiser e complete o tour ou reveja algum tópico sempre que desejar para relembrar alguma função.',
      side: 'right',
      align: 'center',
    },
  },
];

const pipelineSteps: DriveStep[] = [
  {
    element: '#new-os-button',
    popover: {
      description: 'Neste botão você poderá adicionar novas ordens de serviço.',
      side: 'top',
      align: 'start',
    },
  },
  {
    element: '#entrance',
    popover: {
      description:
        'Após criada a <strong>ordem de serviço</strong>, ela aparecerá nesta coluna de entrada, e ao acessá-la, os dados deverão ser confirmados para que a <strong>ordem de serviço</strong> siga o fluxo de atendimento.',
      side: 'right',
      align: 'center',
    },
  },
  {
    element: '#pept',
    popover: {
      description:
        'A coluna de pendência técnica servirá para enviar as Ordens de Serviço que possuem pendências, como falta de matrícula do imóvel ou algum outro documento.',
      side: 'right',
      align: 'center',
    },
  },
  {
    element: '#schedule',
    popover: {
      description:
        'Na coluna de agendamento, ficarão as Ordens de Serviço que estão pendentes o agendamento da vistoria.',
      side: 'left',
      align: 'center',
    },
  },
  {
    element: '#inspection',
    popover: {
      description:
        'Na coluna de vistoria, estarão as Ordens de Serviço que estão pendentes de conclusão da vistoria.',
      side: 'left',
      align: 'center',
    },
  },
  {
    element: '#sample-creation',
    popover: {
      description:
        'Na coluna de criação de amostra ficarão as Ordens de Serviço que estarão pendentes na criação da amostra ou análise/aprovação dos elementos que compõe a amostra.',
      side: 'left',
      align: 'center',
    },
  },
  {
    element: '#calculation',
    popover: {
      description:
        'Na coluna de processamento de cálculo, ficarão as Ordens de Serviço que foram enviadas para o cálculo automático, porém ainda não terminaram o processo ou não encontraram um modelo válido.',
      side: 'left',
      align: 'center',
    },
  },
  {
    element: '#report',
    popover: {
      description:
        'Na coluna de Elaboração de Laudo, ficarão as Ordens de Serviço que tiveram o cálculo finalizado, possuindo o resultado. Além disso ela serve para analisar o resultado do cálculo antes da emissão do relatório em pdf.',
      side: 'left',
      align: 'center',
    },
  },
  {
    element: '#revision',
    popover: {
      description:
        'As Ordens de Serviço que estarão em Revisão e Aprovação estão aguardando a análise do relatório em pdf que foi criado para poder finalizar e armazenar a Ordem de Serviço como Concluída.',
      side: 'left',
      align: 'center',
    },
  },
];

const entranceSteps: DriveStep[] = [
  {
    element: '#report-section',
    popover: {
      description:
        'Para criar uma nova ordem de serviço, você deve preencher os dados do relatório e do imóvel',
      side: 'top',
      align: 'start',
    },
  },
  {
    element: '#property-section',
    popover: {
      description: 'Aqui você poderá inserir os dados referente ao imóvel',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#buttons-container',
    popover: {
      description:
        'Aqui você poderá inserir os anexos referente a entrada de dados.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#attachment-button',
    popover: {
      description:
        'Aqui você encontrará os anexos referentes a ordem de serviço que foram inseridos durante todas as etapas.',
    },
  },
  {
    element: '#location-of-the-property',
    popover: {
      description:
        'Para inserir a localização do imóvel você deverá inserir os dados e depois clicar em "pesquisar mapa". Feito isso, ele atualizará o mapa com a respectiva localidade.',
      side: 'top',
      align: 'start',
    },
  },
  {
    element: '#pin',
    popover: {
      description:
        'Você deverá verificar se o Pin mostrado no mapa corresponde com a localidade real do imóvel, caso contrário, você poderá arrastar o pin para o local correto.',
      side: 'left',
      align: 'center',
    },
  },
  {
    element: '#characteristic-of-the-property',
    popover: {
      description: 'Aqui, você deve preencher as características do imóvel.',
      side: 'top',
      align: 'start',
    },
  },
  {
    element: '#pept-button',
    popover: {
      description:
        'Caso haja alguma pendência, como uma falta de documento, por exemplo, você poderá enviar a OS para "Pendência Técnica", até que ela seja resolvida e possa voltar para o fluxo de atendimento.',
      side: 'top',
      align: 'start',
    },
  },
  {
    element: '#new-os-button',
    popover: {
      description:
        'Finalizando a inserção de dados, para concluir a criação da ordem de serviço, você deverá clicar no botão "criar nova OS".',
      side: 'top',
      align: 'start',
    },
  },
];

const peptSteps: DriveStep[] = [
  {
    element: '#reason',
    popover: {
      description:
        'Aqui será exibido o motivo pelo qual a Ordem de Serviço foi enviada para pendência técnica.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#approve-btn',
    popover: {
      description:
        'Após resolver o motivo da pendência, você poderá clicar neste botão para que a OS volte para o status que ela estava antes de ser enviada para pendência técnica.',
      side: 'left',
      align: 'center',
    },
  },
];

const scheduleSteps: DriveStep[] = [
  {
    element: '#scheduling-information',
    popover: {
      description:
        'Neste espaço, você deverá inserir os dados necessários para criar um agendamento, assim como o responsável por acompanhar o vistoriador.',
    },
  },
  {
    element: '#create-schedule-btn',
    popover: {
      description:
        'Logo após inserir as informações, clicar no botão para criar agendamento.',
      side: 'right',
      align: 'center',
    },
  },
  {
    element: '#schedule-calendar',
    popover: {
      description:
        'Ao selecionar o vistoriador, a agenda irá carregar com as vistorias marcadas e os períodos que o vistoriador estará disponível.',
      side: 'left',
      align: 'center',
    },
  },
  {
    element: '#approve-btn',
    popover: {
      description:
        'Após agendado, para avançar a OS para vistoria, basta clicar em "Aprovar".',
      side: 'top',
      align: 'start',
    },
  },
];

const inspectionSteps: DriveStep[] = [
  {
    element: '.location-confirm',
    popover: {
      description:
        'Caso você esteja preenchendo a vistoria pelo computador, você precisará confirmar se o pin está localizado exatamente onde está o imóvel. Se não estiver, você poderá movimentá-lo. Quando o pin estiver no local correto, basta selecionar "Confirmar Localização".',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#rooms-container',
    popover: {
      description:
        'Neste setor será possível inserir gerenciar os cômodos e caso já possua fotos, poderemos visualizá-las.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#form-container',
    popover: {
      description:
        'Aqui você poderá responder o formulário com as informações do imóvel avaliando coletadas durante a vistoria.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#approve-btn',
    popover: {
      description:
        'Terminando de responder o formulário, você poderá clicar neste botão.',
      side: 'top',
      align: 'center',
    },
  },
];

const inspectionRoomSteps: DriveStep[] = [
  {
    element: '#rooms-container',
    popover: {
      description:
        'Nesta tela, poderemos criar os cômodos referentes ao imóvel para que sejam inseridas as respectivas fotos.',
      side: 'bottom',
      align: 'center',
    },
  },
  {
    element: '#new-room-button',
    popover: {
      description: 'Para adicionar um novo cômodo, basta clicar nesse botão.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#room',
    popover: {
      description:
        'Clicando no cômodo desejado, ele será adicionado automaticamente na tela anterior.',
      side: 'right',
      align: 'center',
      showProgress: false,
    },
  },
  {
    element: '#actions-button',
    popover: {
      description: 'Aqui, poderemos editar um cômodo criado ou excluí-lo.',
      side: 'right',
      align: 'center',
    },
  },
  {
    element: '.galery-container',
    popover: {
      description: 'No cômodo criado, basta clicar nele para inserir as fotos',
      side: 'top',
      align: 'start',
    },
  },
];

const inspectionAddPhotosSteps: DriveStep[] = [
  {
    element: '#upload-box',
    popover: {
      description:
        'Para inserir uma imagem, basta clicar no botão de selecionar foto ou arrastar e soltar o arquivo que o upload iniciará.',
      side: 'bottom',
      align: 'center',
    },
  },
];

const inspectionImageDetailsSteps: DriveStep[] = [
  {
    element: '#observation-desc',
    popover: {
      description:
        'Assim que terminar o upload da foto, neste campo você poderá inserir algum comentário sobre a imagem.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#pathologies',
    popover: {
      description:
        'Neste campo, você poderá inserir imagens de patologias relacionada à imagem que foi inserida.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#delete',
    popover: {
      description:
        'Caso seja necessário apagar a foto do cômodo, basta clicar neste botão. Apenas lembre-se que caso apague a foto do cômodo, as patologias relacionadas a ela também serão apagadas.',
      side: 'top',
      align: 'start',
    },
  },
  {
    element: '#confirm-btn',
    popover: {
      description:
        'Ao clicar nesse botão, você finalizará a inserção da foto e suas patologias.',
      side: 'top',
      align: 'center',
    },
  },
];

const inspectionPathologiesSteps: DriveStep[] = [
  {
    element: '#pathologies-container',
    popover: {
      description:
        'Após o upload da patologia, devemos selecionar o(s) tipo(s) de patologia(s) que aparece(m) na imagem.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#submit-box',
    popover: {
      description:
        'Caso queria deletar a imagem de patologia, basta clicar neste botão.',
      side: 'top',
      align: 'start',
    },
  },
  {
    element: '.submit-box',
    popover: {
      description:
        'Após inserir as informações sobre a patologia, basta clicar neste botão para adicioná-la.',
      side: 'top',
      align: 'end',
    },
  },
  {
    element: '#pathologies',
    popover: {
      description:
        'As patologias ficarão registradas nesse campo quando finalizar sua adição.',
      side: 'left',
      align: 'center',
    },
  },
];

const gallerySteps: DriveStep[] = [
  {
    element: '#box-container',
    popover: {
      description:
        'Aqui temos a galeria das fotos de um cômodo, e caso haja alguma patologia referente a alguma dessas fotos, é possível saber olhando na numeração inserida em cada foto.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#circle-btn',
    popover: {
      description:
        'Clicando neste botão você também poderá excluir a imagem e suas patologias relacionadas.',
      side: 'right',
      align: 'center',
    },
  },
  {
    element: '#total',
    popover: {
      description:
        'Neste campo é exibido a quantidade de patologias relacionadas a essa foto.',
      side: 'right',
      align: 'center',
    },
  },
  {
    element: '#back-btn',
    popover: {
      description: 'Para voltar a tela dos cômodos, basta clicar neste botão.',
      side: 'right',
      align: 'center',
    },
  },
];

const sampleCreationSteps: DriveStep[] = [
  {
    element: '.conservation-state',
    popover: {
      description:
        'Nesses campos você deverá confirmar a idade estimada (ou estimar, caso não haja essa informação), depois calcular o estado de conservação e confirmar o padrão construtivo do elemento.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#search-sample',
    popover: {
      description:
        'Para buscar os elementos para compor a amostra, basta clicar nesse botão que o sistema irá efetuar a busca.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#supply-offer',
    popover: {
      description:
        'Neste campo você poderá escolher o percentual do fator oferta (valor descontado do preço anunciado do imóvel).',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#delete-sample',
    popover: {
      description:
        'Caso você queira buscar uma nova amostra, você poderá excluir a amostra e fazer uma nova busca.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#sample-length',
    popover: {
      description:
        'Neste campo, mostramos a quantidade de elementos que a busca trouxe.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#map-location',
    popover: {
      description:
        'Aqui é exibido um mapa com o imóvel avaliando e os elementos encontrados ao seu redor. Os elementos podem ser filtrados pelo Pin no mapa para mostrar suas informações.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#dashboard',
    popover: {
      description:
        'Este espaço abaixo funciona como um dashboard para gerenciar os elementos, aqui é possível filtrar os elementos incompletos ou navegar pelos elementos de uma forma mais fácil. Você também poderá mudar o tipo de visualização para o que achar mais confortável para sua análise.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#element-info',
    popover: {
      description:
        'Essa parte da tela trás as informações de um elemento, aqui você pode editar os dados caso necessário e conseguirá efetuar a análise com as informações trazidas. Além disso, caso necessário você poderá excluir um determinado item da amostra.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#error-report',
    popover: {
      description:
        'Caso haja algum erro no elemento que não seja passível de correção, você pode clicar neste botão para reportar o erro. Ele sairá de sua amostra e o elemento irá para uma análise mais profunda para correção.',
      side: 'left',
      align: 'center',
    },
  },
  {
    element: '#delete-element',
    popover: {
      description:
        'Caso haja algum problema com o elemento, você pode excluí-lo clicando neste botão.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#element-images',
    popover: {
      description:
        'Aqui você poderá visualizar as imagens que foram inseridas no anúncio, elas poderão te ajudar na estimativa de idade, padrão construtivo, estado de conservação e eventualmente confirmar o endereço do imóvel utilizando a foto de fachada, quando disponível.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#navigation-button',
    popover: {
      description:
        'Você poderá utilizar esse botões para navegar entre os elementos quando a visualização estiver paginada (mostrando apenas 1 elemento de cada vez).',
      side: 'top',
      align: 'center',
    },
  },
  // {
  //   element: '#numbers-of-pagination',
  //   popover: {
  //     description:
  //       'Aqui você poderá navegar entre os elementos também, caso esteja na visualização paginada.',
  //     side: 'top',
  //     align: 'center',
  //   },
  // },
  {
    element: '#add-element',
    popover: {
      description:
        'Aqui você poderá inserir elementos manualmente, caso não haja elementos encontrados automaticamente ou se os elementos encontrados não forem suficientes para concluir o cálculo.',
      side: 'top',
      align: 'center',
    },
  },
];

const sampleAddNewElement: DriveStep[] = [
  {
    element: '.ad-data',
    popover: {
      description:
        'Aqui você poderá incluir os dados do anúncio para criar um elemento.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '.grid-map',
    popover: {
      description:
        'Após inserir o endereço, você deverá clicar no botão "Buscar no Mapa" para encontrar a localização do imóvel no mapa.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '.insert-photos',
    popover: {
      description:
        'Aqui você poderá inserir as fotos do elemento. Caso seja um relatório de fatores, você poderá inserir a foto de fachada, pois ela sairá no relatório.',
      side: 'right',
      align: 'start',
    },
  },
  {
    element: '#form-control',
    popover: {
      description:
        'Quando o elemento for inserido manualmente, você deverá confirmar que os dados inseridos são de responsabilidade de quem os inseriu.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '.add-element',
    popover: {
      description:
        'Para gravar os dados e criar o elemento, basta clicar neste botão.',
      side: 'top',
      align: 'end',
    },
  },
];

const reportSteps: DriveStep[] = [
  {
    element: '#automated-calculation',
    popover: {
      description: 'Os dados do cálculo aparecerão nesta aba.',
      side: 'top',
      align: 'start',
    },
  },
  {
    element: '#additional-fields',
    popover: {
      description:
        'Nos campos complementares você deverá adicionar ou alterar algumas informações que serão exibidas no laudo final.',
      side: 'top',
      align: 'start',
    },
  },
  {
    element: '#inspection-images',
    popover: {
      description:
        'Aqui você poderá selecionar algumas fotos da vistoria para compor o relatório, caso seja necessário.',
      side: 'top',
      align: 'start',
    },
  },
  {
    element: '#back-to-sample',
    popover: {
      description:
        'Caso seja necessário retornar a Ordem de Serviço para o status de "Criação de Amostra", você poderá clicar neste botão, efetuar as alterações necessárias e enviar a OS para cálculo novamente.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#pept-button',
    popover: {
      description:
        'Caso exista alguma pendência técnica que impeça o andamento da ordem de serviço, você poderá clicar neste botão para enviá-la para o status de pendência.',
      side: 'top',
      align: 'start',
    },
  },
  {
    element: '#approve-btn',
    popover: {
      description:
        'Quando o cálculo for validado pelo vistoriador, ele poderá clica em aprovar, para enviar a OS para o status seguinte.',
      side: 'top',
      align: 'start',
    },
  },
];

const revisionSteps: DriveStep[] = [
  {
    element: '#pdf-viewer',
    popover: {
      description:
        'Nessa parte será exibido o arquivo final que deverá ser validado.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#xlsx-button',
    popover: {
      description:
        'Aqui você poderá efetuar o download de um arquivo que contem o memorial do cálculo, o formulário respondido e os detalhes dos elementos utilizados no cálculo.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#report-pdf-button',
    popover: {
      description:
        'Aqui você poderá efetuar o download do arquivo do relatório.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#reject-button',
    popover: {
      description:
        'Caso haja algum problema com a criação do documento ou do arquivo com as informações do cálculo, basta clicar neste botão e inserir uma descrição do problema encontrado.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#approve-btn',
    popover: {
      description:
        'Clicando aqui, a Ordem de Serviço é finalizada com o status de "Concluída". Você ainda terá acesso às informações e arquivos disponíveis na OS clicando no menu lateral na opção "Arquivados"',
      side: 'top',
      align: 'center',
    },
  },
];

const archivedSteps: DriveStep[] = [
  {
    element: '#filter-box',
    popover: {
      description:
        'Aqui você poderá filtrar por período ou utilizar palavras para buscar alguma <strong>Ordem de Serviço</strong> específica.',
      side: 'left',
      align: 'center',
    },
  },
  {
    element: '#table',
    popover: {
      description:
        'Esta tabela trará todas as <strong>Ordens de Serviço</strong> arquivadas, basta clicar em alguma para que a <strong>Ordem de Serviço</strong> seja aberta.',
      side: 'top',
      align: 'center',
    },
  },
];

const formSteps: DriveStep[] = [
  {
    element: '#new-form-btn',
    popover: {
      description: 'Para criar um novo formulário, basta clicar aqui.',
      side: 'bottom',
      align: 'center',
    },
  },
  {
    element: '.saved-forms',
    popover: {
      description:
        'Aqui você poderá selecionar algum modelo de formulário já criado para utilizar como base de um formulário específico seu. Se necessário, você poderá editá-lo.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '.form-model',
    popover: {
      description:
        'Aqui você poderá selecionar algum modelo de formulário já criado para utilizar como base de um formulário específico seu. Se necessário, você poderá editá-lo.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '.form-info',
    popover: {
      description:
        'Aqui você irá preencher as informações referentes ao formulário que está sendo criado e nos campos de tipo de cliente e tipo do imóvel, você irá preencher para definir qual formulário aparecerá na vistoria conforme sua configuração.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#question-bank',
    popover: {
      description:
        'O banco de perguntas é uma sessão onde as perguntas são criadas, porém ainda não aparecerão no formulário para serem respondidas. Serve para ajudar na organização.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#new-question',
    popover: {
      description: 'Para inserir uma nova pergunta, basta clicar aqui.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#btn-container',
    popover: {
      description:
        'Esses botões servem para você selecionar as perguntas que estarão no formulário. Com eles você pode adicionar ou remover perguntas conforme necessário.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#selected-questions',
    popover: {
      description:
        'Aqui nós teremos as perguntas que aparecerão em vistoria. Caso necessário, você poderá ordená-las ou ainda voltar as perguntas para o banco de perguntas. Obs.: algumas perguntas são obrigatórias e não poderão ser retiradas e nem ordenadas dessa seleção.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#preview-btn',
    popover: {
      description:
        'Clicando nesse botão, você poderá ver o formulário completo que você preparou, sendo o mesmo que aparecerá quando estiver em vistoria.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#cancel-save-btn-container',
    popover: {
      description: 'Aqui você pode cancelar ou salvar as alterações feitas.',
      side: 'top',
      align: 'center',
    },
  },
];

const errorReportSteps: DriveStep[] = [
  {
    element: '.table',
    popover: {
      description:
        'Nessa tela, você terá um resumo das informações da OS e poderá clicar para acessar as informações completas.',
      side: 'top',
      align: 'center',
    },
  },
];

const agendaSteps: DriveStep[] = [
  {
    element: '#filter',
    popover: {
      description:
        'Nesses campos você poderá filtrar uma busca mais específica.',
      side: 'bottom',
      align: 'center',
    },
  },
  {
    element: '#engineers',
    popover: {
      description:
        'Aqui você poderá selecionar vistoriadores para exibir seus agendamentos.',
      side: 'right',
      align: 'center',
    },
  },
  {
    element: '#schedules',
    popover: {
      description:
        'Aqui serão exibidos os agendamentos das Ordens de Serviço conforme os filtros efetuados.',
      side: 'top',
      align: 'center',
    },
  },
];

export {
  agendaSteps,
  archivedSteps,
  entranceSteps,
  errorReportSteps,
  formSteps,
  gallerySteps,
  inspectionAddPhotosSteps,
  inspectionImageDetailsSteps,
  inspectionPathologiesSteps,
  inspectionRoomSteps,
  inspectionSteps,
  onBoardingSteps,
  peptSteps,
  pipelineSteps,
  reportSteps,
  revisionSteps,
  sampleAddNewElement,
  sampleCreationSteps,
  scheduleSteps,
};
