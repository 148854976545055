/* eslint-disable max-len */
import { InitialModalTour } from '../../Modal/InitialModalTour';

export function RevisionTour(): JSX.Element {
  return (
    <InitialModalTour
      title="Vamos dar início à"
      pageName="Revisão e aprovação"
      subtext1="Nesta tela, você poderá fazer uma revisão final do relatório criado e a partir disso poderá ter o arquivo final do relatório."
      stepTourName="revision"
    />
  );
}
