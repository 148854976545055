/* eslint-disable react-hooks/exhaustive-deps */
import { Box, LinearProgress, Pagination } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { workOrderAPI } from '../../api';
import { StatusCode, StepTour } from '../../api/enumerations';
import { WorkOrderData } from '../../api/workOrders/types';
import { SelectTextField } from '../../components/CustomInput';
import SearchBox from '../../components/SearchBox';
import { PagesContainer, TitleBox } from '../../components/UI/Box';
import { TitleTypography } from '../../components/UI/Typography';
import { Constants } from '../../constants/archived';
import { GlobalContext } from '../../context/global';
import useErrorMessage from '../../hooks/useErrorMessage';
import { useTour } from '../../hooks/useTour';
import { FilterBox, LoadingBox, TableBox } from './styles';
import { TableArchived } from './Table';

export default function Archived(): JSX.Element {
  const [period, setPeriod] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<WorkOrderData[]>([]);
  const [allArchivedOs, setAllArchivedOS] = useState<WorkOrderData[]>([]);

  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);

  const workOrderPerPage = 10;
  const options = [
    { value: 0, label: 'Período' },
    { value: 1, label: 'últimos 15 dias' },
    { value: 2, label: 'últimos 30 dias' },
    { value: 3, label: 'últimos 90 dias' },
    { value: 4, label: 'últimos 120 dias' },
  ];

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage, company } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();

  const { tourCompletion, setTutorialStep, setTourOn } = useTour();

  const getDataCallback = useCallback(async () => {
    setLoading(true);
    try {
      const response = await workOrderAPI.getArchivedWorkOrders(
        page,
        workOrderPerPage
      );
      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente');
      }
      if (response.data) {
        setTotalPages(response.data.total_pages || 0);
        setAllArchivedOS(response.data.items || []);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (loading) {
      getDataCallback();
    }
  }, [getDataCallback, loading]);

  const handleNextPage = (pageNumber: number): void => {
    setPage(pageNumber);
    setLoading(true);
  };

  useEffect(() => {
    handleNextPage(1);
  }, [company]);

  useEffect(() => {
    if (
      !tourCompletion.archived.complete &&
      !tourCompletion.skipTour.archived
    ) {
      setTutorialStep(StepTour.ARCHIVEDSTART);
      setTourOn(true);
    }
  }, []);

  return (
    <>
      <TitleBox>
        <TitleTypography>{Constants.archived}</TitleTypography>
      </TitleBox>
      <TableBox>
        <FilterBox id="filter-box">
          <Box style={{ width: '200px' }}>
            <SelectTextField
              id="period-select"
              label=""
              value={period}
              selectOptions={options}
              setValue={setPeriod}
            />
          </Box>
          <SearchBox<WorkOrderData>
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            initialDatas={allArchivedOs}
            setResults={setSearchResults}
          />
        </FilterBox>
        {loading ? (
          <LoadingBox>
            <LinearProgress />
          </LoadingBox>
        ) : (
          <>
            <TableArchived
              tableData={searchResults.length ? searchResults : allArchivedOs}
            />
            <PagesContainer>
              <Pagination
                page={page}
                count={
                  searchResults.length !== 0
                    ? Math.ceil(Number(searchResults.length) / 10)
                    : totalPages
                }
                onChange={(_, page) => handleNextPage(page)}
              />
            </PagesContainer>
          </>
        )}
      </TableBox>
    </>
  );
}
