/* eslint-disable react/require-default-props */
import { DeleteCircleButton } from '../../../components/UI/Button';
import { StyledDialog } from '../../../components/UI/Dialog';
import { IconCloseMS } from '../../../constants/icons';
import useGeneral from '../../../hooks/useGeneral';
import {
  AlertTypography,
  ButtonBox,
  DeleteOutlineButton,
  DialogContainer,
  FilledButton,
  OutlinedButton,
  TextTypography,
} from './styles';

interface DelelePictureProps {
  id?: string;
  modalCallback: (id: number) => void;
  outlinedButton: boolean;
  pictureId: number;
  text: string;
}

export default function DeletePictureDialog({
  id,
  modalCallback,
  outlinedButton,
  pictureId,
  text,
}: DelelePictureProps): JSX.Element {
  const dialog = useGeneral();

  const handleConfirm = (): void => {
    modalCallback(pictureId);
    dialog.handleClose();
  };

  return (
    <>
      {outlinedButton ? (
        <DeleteOutlineButton
          id={id}
          disableTouchRipple
          onClick={dialog.handleOpen}
        >
          deletar foto
        </DeleteOutlineButton>
      ) : (
        <DeleteCircleButton
          id="circle-btn"
          disableTouchRipple
          onClick={dialog.handleOpen}
        >
          {IconCloseMS}
        </DeleteCircleButton>
      )}
      <StyledDialog
        open={dialog.open}
        onClose={dialog.handleClose}
        aria-labelledby="delete picture"
      >
        <DialogContainer>
          <TextTypography>Você gostaria de deletar {text}?</TextTypography>
          <ButtonBox>
            <OutlinedButton onClick={dialog.handleClose}>Não</OutlinedButton>
            <FilledButton onClick={handleConfirm}>Sim</FilledButton>
          </ButtonBox>
          <AlertTypography>
            Atenção! Esta ação não poderá ser desfeita.
          </AlertTypography>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
