import faceLeft from '../../assets/images/errorPage/left_face.svg';
import logoBg from '../../assets/images/errorPage/logo_background.svg';
import protoRobot from '../../assets/images/errorPage/PROTO_robot.svg';
import faceRight from '../../assets/images/errorPage/right_face.svg';
import { StandardButton } from '../../components/UI/Button';
import useGeneral from '../../hooks/useGeneral';
import {
  ErrorText,
  FaceLeft,
  FaceRight,
  ImageBox,
  LogoBackground,
  PageWrapper,
  ProtoRobot,
  StyledText,
  TextBox,
} from './styles';

export function ErrorPage(): JSX.Element {
  const { navigateHome } = useGeneral();
  return (
    <PageWrapper>
      <ImageBox>
        <LogoBackground image={logoBg} />
        <ProtoRobot image={protoRobot} />
        <FaceLeft image={faceLeft} />
        <FaceRight image={faceRight} />
      </ImageBox>
      <TextBox>
        <ErrorText>
          ERROR <span>404</span>
        </ErrorText>
        <StyledText>página não encontrada.</StyledText>
      </TextBox>
      <StandardButton sx={{ marginTop: '20px' }} onClick={navigateHome}>
        Início
      </StandardButton>
    </PageWrapper>
  );
}
