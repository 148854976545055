import {
  Box,
  Button,
  ButtonBase,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const FilledButton = styled(Button)(() => ({
  borderRadius: '16px',
  width: '100%',
}));

export const IconButtonBase = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  padding: '2px 4px',
  borderRadius: '8px',
  alignItems: 'center',
  fontSize: '16px',
  '& span': { color: theme.palette.primary.main },
  '&:hover': {
    '& span': {
      fontSize: '26px',
    },
    fontWeight: '600',
  },
}));

export const GridQuestions = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

export const TitleQuestions = styled(Typography)(() => ({
  fontSize: '21px',
  fontWeight: '600',
}));

export const PaperQuestions = styled(Paper)(({ theme }) => ({
  height: '560px',
  overflowY: 'auto',
  backgroundColor: theme.palette.common.white,
  border: '1px solid #0000003b',
  borderRadius: '16px',
  padding: '8px',
  marginBottom: '20px',
  '&::-webkit-scrollbar': {
    width: '12px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  '&::-webkit-scrollbar-track': {
    margin: '6px 0',
  },
  [theme.breakpoints.up('xl')]: {
    height: '660px',
  },
}));

export const GridButton = styled(Grid)(() => ({
  display: 'flex',
  gap: '20px',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const OutlinedButton = styled(Button)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: '10px',
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '16px',
  color: theme.palette.primary.main,
  fontWeight: '600',
  backgroundColor: '#FAFCFF',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
  '&:disabled': {
    color: theme.palette.primary.main,
  },
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
  gap: '40px',
}));

export const FilledDarkButton = styled(Button)(({ theme }) => ({
  borderRadius: '16px',
  width: '100%',
  backgroundColor: theme.palette.primary.dark,
  color: '#FAFCFF',
  '&:hover': {
    filter: 'brightness(85%)',
  },
}));

export const OutlinedDarkButton = styled(Button)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: '10px',
  border: `2px solid ${theme.palette.primary.dark}`,
  borderRadius: '16px',
  color: theme.palette.primary.dark,
  fontWeight: '600',
  backgroundColor: theme.palette.common.white,
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    filter: 'brightness(85%)',
  },
  '&:disabled': {
    color: theme.palette.primary.dark,
  },
}));

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    textAlign: 'justify',
    fontSize: 12,
    padding: 10,
    fontWeight: 'normal',
    border: '1px solid #dadde9',
    boxShadow: '0px 3px 6px #00000029',
  },
}));
