import { Box, Slider } from '@mui/material';
import { ChangeEvent, useCallback, useContext, useState } from 'react';
import Cropper from 'react-easy-crop';

import { userAPI } from '../../../api';
import avatar1 from '../../../assets/avatars/Avatar-1.png';
import avatar2 from '../../../assets/avatars/Avatar-2.png';
import avatar3 from '../../../assets/avatars/Avatar-3.png';
import avatar4 from '../../../assets/avatars/Avatar-4.png';
import avatar5 from '../../../assets/avatars/Avatar-8.png';
import {
  IconAccountCircleMS,
  IconAddPhotoAlternateMS,
  IconCloseMS,
} from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage, validateImg } from '../../../helpers';
import { useStoragedJwt } from '../../../hooks/useDecodedJwt';
import { CloseIcon, FilledButton, OutlinedButton } from '../../UI/Button';
import { DialogTitle, SectionTitle } from '../../UI/Typography';
import { Constants } from '../constants';
import {
  AvatarBox,
  ButtonBox,
  CropperContainer,
  Dialog,
  InsideBox,
  StyledCardMedia,
  Upload,
  UploadIcon,
  UploadSubtitle,
  UploadTitle,
} from './styles';

interface AvatarMenuProps {
  open: boolean;
  handleClose: () => void;
}

type area = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export function AvatarMenu({
  open,
  handleClose,
}: AvatarMenuProps): JSX.Element {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [uploadedFile, setUploadedFile] = useState<string>();
  const [avatar, setAvatar] = useState<string>();
  const [croppedArea, setCroppedArea] = useState<area>();

  const avatarOptions = [avatar1, avatar2, avatar3, avatar4, avatar5];

  const decoded = useStoragedJwt();
  const {
    setOpenSnackbar,
    setErrorMessage,
    setSnackbarMessage,
    updateAvatar,
    setUpdateAvatar,
  } = useContext(GlobalContext);

  const resetValues = (): void => {
    setAvatar('');
    setUploadedFile('');
    handleClose();
  };

  const uploadFile = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.files?.item(0) || !validateImg(e.target.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    setAvatar('');

    const reader = new FileReader();
    if (!reader) return;

    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setUploadedFile(reader.result?.toString());
    };
  };

  const onCropComplete = useCallback(
    (croppedArea: area, croppedAreaPixels: area): void => {
      setCroppedArea(croppedAreaPixels);
    },
    []
  );

  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.src = url;
    });

  const getCroppedImg = async (
    imageSrc: string,
    pixelCrop: area
  ): Promise<File | null> => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    if (!ctx) return null;

    // // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        if (file) {
          resolve(new File([file], 'avatar', { type: 'image/png' }));
        }
      }, 'image/png');
    });
  };

  const handleSubmit = async (): Promise<void> => {
    if (!decoded) {
      resetValues();
      setSnackbarMessage('Algo deu errado, tente novamente.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const formData = new FormData();

    if (avatar) {
      const response = await fetch(avatar);
      const blob = await response.blob();
      const file = new File([blob], 'avatar', { type: 'image/png' });

      formData.append('avatar', file);
    } else {
      if (!uploadedFile || !croppedArea) return;
      const croppedImage = await getCroppedImg(uploadedFile, croppedArea);

      if (croppedImage) {
        formData.append('avatar', croppedImage);
      }
    }

    try {
      const response = await userAPI.changeUserImages(
        decoded.user.id,
        formData
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente');
      }

      setSnackbarMessage('Foto alterada com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      resetValues();
      setUpdateAvatar(!updateAvatar);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <Dialog open={open} onClose={() => resetValues()}>
      <CloseIcon onClick={() => resetValues()}>{IconCloseMS}</CloseIcon>
      <DialogTitle>
        {IconAccountCircleMS}
        {Constants.changeAvatar}
      </DialogTitle>
      {uploadedFile ? (
        <Box textAlign="center">
          <CropperContainer>
            <Cropper
              image={uploadedFile}
              crop={crop}
              aspect={4 / 4}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              zoom={zoom}
              onZoomChange={setZoom}
            />
          </CropperContainer>
          <Slider
            aria-label="Volume"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            onChange={(e: Event, newValue: number | number[]) => {
              setZoom(newValue as number);
            }}
            sx={{ width: '70%' }}
          />
        </Box>
      ) : (
        <>
          <Upload>
            <label htmlFor="uploadAvatar">
              <input
                accept="image/*"
                id="uploadAvatar"
                type="file"
                style={{ display: 'none' }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => uploadFile(e)}
              />
              <InsideBox>
                <UploadIcon>{IconAddPhotoAlternateMS}</UploadIcon>
                <UploadTitle>{Constants.selectImg}</UploadTitle>
                <UploadSubtitle>{Constants.imgType}</UploadSubtitle>
              </InsideBox>
            </label>
          </Upload>
          <SectionTitle>{Constants.chooseAvatar}</SectionTitle>
          <AvatarBox>
            {avatarOptions.map((img) => (
              <StyledCardMedia
                key={img}
                onClick={() => setAvatar(img)}
                image={img}
                title=""
                selected={img === avatar}
              />
            ))}
          </AvatarBox>
        </>
      )}
      <ButtonBox>
        <OutlinedButton
          onClick={() => {
            if (uploadedFile) {
              setUploadedFile('');
            } else {
              resetValues();
            }
          }}
        >
          {uploadedFile ? Constants.back : Constants.cancel}
        </OutlinedButton>
        <FilledButton onClick={handleSubmit}>{Constants.save}</FilledButton>
      </ButtonBox>
    </Dialog>
  );
}
