/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';

import { userAPI } from '../../../../api';
import { ProfileUsers, StatusCode } from '../../../../api/enumerations';
import { EditUserProps, UserData } from '../../../../api/users/types';
import { validateEmail, validateUserName } from '../../../../helpers';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import useSnackbar from '../../../../hooks/useSnackbar';

interface UsersHook {
  searchTerm: string;
  setSearchTerm(value: string): void;
  searchedUsers: UserData[];
  setSearchedUsers(value: UserData[]): void;
  totalPages: number | undefined;
  page: number;
  setPage(value: number): void;
  name: string;
  setName(value: string): void;
  email: string;
  setEmail(value: string): void;
  selectedRole: number;
  setSelectedRole(value: number): void;
  active: boolean;
  setActive(value: boolean): void;
  counselIdType: string;
  setCounselIdType(value: string): void;
  counselId: string;
  setCounselId(value: string): void;
  personalIdType: string;
  setPersonalIdType(value: string): void;
  personalId: string;
  setPersonalId(value: string): void;
  telephone: string;
  setTelephone(value: string): void;
  loading: boolean;
  setLoading(value: boolean): void;
  users: UserData[];
  setUsers: (value: UserData[]) => void;
  handleNextPage(pageNumber: number): void;
  handleEdit(e: React.FormEvent, userData: UserData): Promise<void>;
  handleNewUser(e: React.FormEvent): Promise<void>;
}

export default function useUsers(): UsersHook {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedUsers, setSearchedUsers] = useState<UserData[]>([]);
  const [users, setUsers] = useState<UserData[]>([]);

  const [totalPages, setTotalPages] = useState<number | undefined>(0);
  const [page, setPage] = useState(1);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [selectedRole, setSelectedRole] = useState<ProfileUsers>(0);
  const [active, setActive] = useState(false);

  const [counselIdType, setCounselIdType] = useState<string>('CREA/CAU');
  const [counselId, setCounselId] = useState<string>('');
  const [personalIdType, setPersonalIdType] = useState<string>('RG/CNH');
  const [personalId, setPersonalId] = useState<string>('');
  const [telephone, setTelephone] = useState('');

  const { getErrorMessage } = useErrorMessage();
  const { handleSnackbar } = useSnackbar();

  const usersPerPage = 10;

  const getDataCallback = useCallback(async () => {
    try {
      const response = await userAPI.GetAllUsers(page, usersPerPage);
      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }
      setTotalPages(response.detail.total_pages);
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleSnackbar(getErrorMessage(error), true);
    }
  }, [loading]);

  useEffect(() => {
    if (loading) {
      getDataCallback();
    }
  }, [loading]);

  const handleNextPage = (pageNumber: number): void => {
    setPage(pageNumber);
    setLoading(true);
  };

  const handleEdit = async (
    e: React.FormEvent,
    userData: UserData
  ): Promise<void> => {
    e.preventDefault();

    const editUserData: EditUserProps = {
      engineer_document: counselId,
      name,
      is_active: active,
      personal_document: personalId,
      phone_number: telephone,
      role: selectedRole,
    };

    if (userData.email !== email) editUserData.email = email;

    try {
      const response = await userAPI.EditUser(userData.id, editUserData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }
      handleSnackbar('Usuário alterado com sucesso!', false);
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    }
  };

  const handleNewUser = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    const trimEmail = email.trim();

    if (!validateUserName(name)) {
      handleSnackbar('Nome inválido', true);
      return;
    }
    if (!validateEmail(trimEmail)) {
      handleSnackbar('E-mail inválido', true);
      return;
    }
    if (selectedRole === ProfileUsers.SELECT) {
      handleSnackbar('Selecione uma função', true);
      return;
    }

    const userData = {
      email: trimEmail,
      role: selectedRole,
      name,
    };
    try {
      const response = await userAPI.CreateUser(userData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.detail.status_code === StatusCode.OK) {
        handleSnackbar('Usuário adicionado com sucesso!', false);
        getDataCallback();
      }
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    }
  };

  return {
    searchTerm,
    setSearchTerm,
    searchedUsers,
    setSearchedUsers,
    totalPages,
    page,
    setPage,
    name,
    setName,
    email,
    setEmail,
    selectedRole,
    setSelectedRole,
    active,
    setActive,
    counselIdType,
    setCounselIdType,
    personalId,
    setPersonalId,
    personalIdType,
    setPersonalIdType,
    counselId,
    setCounselId,
    telephone,
    setTelephone,
    loading,
    setLoading,
    users,
    setUsers,
    handleNextPage,
    handleEdit,
    handleNewUser,
  };
}
