import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const InputBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#F5F5F4',
  boxShadow: '0px 1px 2px #61335429',
  width: '70%',
  maxWidth: 1300,
  padding: '60px 60px',
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: 28,
  fontWeight: 500,
  gap: 2,
  '& span': {
    fontSize: 42,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
}));

export const TextBox = styled(Box)(() => ({
  margin: '88px 0px 42px 0px',
  display: 'flex',
  flexDirection: 'column',
  gap: 32,
}));

export const Text = styled(Typography)(() => ({
  fontSize: 24,
}));

export const LinkBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  alignSelf: 'flex-start',
}));

export const LinkText = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  '& a': {
    fontSize: 20,
    textDecoration: 'none',
    color: theme.palette.primary.dark,
    '& span': {
      color: theme.palette.primary.dark,
    },
  },
}));
