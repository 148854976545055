import {
  handleUrl,
  myHeadersWithToken,
  myHeadersWithTokenContentType,
} from '../global';
import { UpdateQuestionData } from '../workOrders/types';
import {
  AllInspectionAgenda,
  DetailProps,
  GetAllEngineerProps,
  GetScheduledEventsProps,
  GetSelectedInspectionPicturesProps,
  ScheduleData,
} from './types';

const getAllEngineers = (search?: string): Promise<GetAllEngineerProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  const query = search ? `?search_string=${search}` : '';
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/users/engineers${query}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const createInspection = (data: ScheduleData): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/inspections`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const getInspections = (
  id: number,
  date?: string
): Promise<GetScheduledEventsProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };

  const query = date ? `?date=${date}` : '';
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/inspections/engineer/${id}${query}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const getSelectedInspectionPictures = (
  id: number
): Promise<GetSelectedInspectionPicturesProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/inspections/${id}/selected-pictures`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const updateInspections = (
  id: number,
  data: ScheduleData | UpdateQuestionData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/inspections/${id}?sending_inspection=false`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const deleteInspection = (id: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/inspections/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getInspectionPictures = (
  id: number,
  selected = false
): Promise<Response> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/inspections/${id}/pictures?only_selected=${selected}`,
    requestOptions
  ).then((response) => response);
};

export const deleteInspectionPictures = (id: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/inspections/${id}/pictures`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getAllInspections = (
  startDate: string,
  endDate: string,
  usersId: string
): Promise<AllInspectionAgenda> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseURL = handleUrl();
  return fetch(
    `${BaseURL}/api/V1/calendar-schedules?start_date=${startDate}&end_date=${endDate}${usersId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const updateSelfie = (id: number, data: FormData): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
    body: data,
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/proto-mobile/inspections/${id}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export default {
  getAllEngineers,
  getInspections,
  createInspection,
  deleteInspection,
  updateInspections,
  getInspectionPictures,
  getSelectedInspectionPictures,
  deleteInspectionPictures,
  getAllInspections,
  updateSelfie,
};
