import { Box, ButtonBase, CardMedia } from '@mui/material';
import { styled } from '@mui/material/styles';

import { StyledDialog } from '../../../../components/UI/Dialog';
import { SectionTitle } from '../../../../components/UI/Typography';

export const Dialog = styled(StyledDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    minHeight: '600px',
    padding: '60px 80px 0px',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '800px',
      minWidth: '700px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1200px',
      minWidth: '1000px',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '1400px',
      minWidth: '1200px',
    },
  },
}));

export const SectionSubtitle = styled(SectionTitle)(() => ({
  fontSize: '18px',
  marginBottom: '20px',
}));

export const FlexEndBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: '10px',
}));

export const PictureBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}));

export const ImageCard = styled(CardMedia)(({ theme }) => ({
  backgroundColor: '#bcbcbc66',
  width: '768px',
  height: '576px',
  borderRadius: '15px',
  alignSelf: 'center',
  backgroundSize: 'contain',
  objectFit: 'contain',
  margin: 'auto',
  marginBottom: 40,
  [theme.breakpoints.down('xl')]: {
    width: '640px',
    height: '480px',
  },
}));

export const DownloadButton = styled(ButtonBase)(({ theme }) => ({
  float: 'right',
  height: '42px',
  width: '280px',
  border: '1px solid #737475',
  borderRadius: '16px',
  display: 'flex',
  gap: '10px',
  '& span': {
    color: theme.palette.primary.main,
  },
  '&:hover': {
    backgroundColor: '#EDEFF2',
    borderColor: theme.palette.primary.main,
  },
}));
