/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { Box, LinearProgress } from '@mui/material';

import { SelectTextField } from '../../components/CustomInput';
import { PipelineSearch } from '../../components/PipelineSearch';
import FilterInput from '../../components/Sections/Sample/Filter';
import { TitleBox } from '../../components/UI/Box';
import { TitleTypography } from '../../components/UI/Typography';
import { Constants } from '../../constants/samples';
import { selectPropertyType } from '../../constants/selectOptions';
import { AddSample } from './AddSample';
import useSamplesList from './hooks';
import { SampleTable } from './SampleTable';
import { HeaderContainer, FilterBox, Container } from './styles';

export function SamplesList(): JSX.Element {
  const {
    handleDeleteSample,
    setSearchField,
    setPage,
    page,
    totalPages,
    samplesData,
    uf,
    setUf,
    city,
    setCity,
    realEstateKind,
    setRealEstateKind,
    ufList,
    cityList,
    loading,
  } = useSamplesList();

  return (
    <>
      <TitleBox>
        <TitleTypography>{Constants.samples}</TitleTypography>
      </TitleBox>
      <Container>
        <HeaderContainer>
          <AddSample />
          <FilterBox>
            <SelectTextField
              id="uf-select"
              label={Constants.uf}
              value={uf}
              setValue={setUf}
              selectOptions={ufList}
            />
            <FilterInput
              label={Constants.city}
              selectOptions={cityList}
              setSelectedOption={setCity}
              selectedOption={city}
              disabled={uf === 0}
            />
            <SelectTextField
              id="real-estate-select"
              label={Constants.realState}
              value={realEstateKind}
              setValue={setRealEstateKind}
              selectOptions={selectPropertyType()}
            />
            <PipelineSearch setSearchField={setSearchField} />
          </FilterBox>
        </HeaderContainer>
        {loading ? (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        ) : samplesData && samplesData.length > 0 ? (
          <SampleTable
            tableData={samplesData}
            page={page}
            setPage={setPage}
            totalPages={totalPages}
            handleDeleteSample={handleDeleteSample}
          />
        ) : (
          Constants.noSamples
        )}
      </Container>
    </>
  );
}
