import { Grid, GridProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)(({ theme }) => ({
  paddingBottom: '20px',
  backgroundColor: theme.palette.primary.light,
}));

interface PageWrapperProps extends GridProps {
  open?: boolean;
}

export const PageWrapper = styled(Grid)<PageWrapperProps>(
  ({ open, theme }) => ({
    position: 'relative',
    ...(open && {
      padding: '60px 90px 30px 280px',
      [theme.breakpoints.down('xl')]: {
        padding: '40px 48px 26px 280px',
      },
    }),
    ...(!open && {
      padding: '60px 90px 30px 190px',
      [theme.breakpoints.down('xl')]: {
        padding: '40px 48px 26px 152px',
      },
    }),
  })
);
