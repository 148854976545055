export const Constants = {
  clientData: 'Dados do cliente',
  clientName: 'nome do cliente:',
  clientType: 'tipo do cliente:',
  evaluationType: 'tipo do cálculo:',
  goal: 'objetivo:',
  function: 'finalidade:',
  propertyUse: 'uso do imóvel:',
  solicitor: 'solicitante:',
};
