export const Constants = {
  pept: 'Pendência Técnica',
  osNumber: 'número OS',
  cancelOs: 'cancelar OS',
  cancelOsText: 'cancelar esta OS',
  reason: 'Motivo',
  clientData: 'Dados do cliente',
  propertyData: 'Dados do imóvel',
  propertyAddress: 'Localização do imóvel',
  propertyDetails: 'Características do imóvel',
  approve: 'tirar de pendência técnica',
  changeStatus: 'tirar esta OS de Pendência Técnica',
};
