import { Typography, styled, Snackbar } from '@mui/material';

export const ToastTypography = styled(Typography)(() => ({
  display: 'flex',
  gap: '4px',
  color: '#FAFCFF',
  '& span': { color: '#FFD700' },
}));

export const StyledToast = styled(Snackbar)(() => ({
  '.MuiSnackbarContent-root': { textAlign: 'center', flexWrap: 'nowrap' },
  '.MuiSnackbarContent-action': {
    justifyContent: 'start',
    alignSelf: 'flex-start',
  },
}));
