import { Box, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CalendarBox = styled(Box)(({ theme }) => ({
  '.fc ': {
    height: '580px',
    margin: '20px 0',
  },
  '.fc .fc-toolbar': {
    padding: '10px',
    borderTopRightRadius: '30px',
    borderTopLeftRadius: '30px',
  },
  '.fc-header-toolbar > .fc-toolbar-chunk:nth-of-type(2)::before': {
    fontSize: '21px',
    content: '"Agenda"',
    fontWeight: '600',
  },
  '.fc-button-group': {
    '.fc-button:not(:first-of-type)': {
      display: 'none',
    },
  },
  '.fc-scroller': {
    overflow: 'auto !important',
  },
  '.fc-scroller::-webkit-scrollbar': {
    width: '8px',
    backgroundColor: '#DDDDDD',
  },
  '.fc-theme-standard .fc-scrollgrid': {
    border: '0px',
  },
  '.fc-scroller::-webkit-scrollbar-thumb ': {
    borderRadius: '12px',
    backgroundColor: theme.palette.primary.dark,
  },
  '.fc-theme-standard th': {
    border: 'none',
  },
  '.fc-toolbar.fc-header-toolbar': {
    backgroundColor: theme.palette.primary.dark,
    color: '#ECF0F6',
    marginBottom: '0',
  },
  '.fc-button-primary': {
    backgroundColor: 'transparent',
    border: 'none',
    transition: 'transform 0.3s ease',
    fontSize: '1.2rem',
    padding: '4px',
    margin: '4px',
    ':focus': {
      boxShadow: 'none !important',
    },
    ':active': {
      borderRadius: '6px !important',
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  },
  '.fc-monthPrev-button': {
    backgroundColor: 'transparent',
    border: 'none',
    transition: 'transform 0.3s ease',
    fontSize: '1.2rem',
    padding: '4px',
    margin: '4px',
    ':hover': {
      backgroundColor: 'transparent',
      border: 'none !important',
      transform: 'scale(1.2)',
    },
  },
  '.fc-monthNext-button': {
    backgroundColor: 'transparent',
    border: 'none',
    transition: 'transform 0.3s ease',
    fontSize: '1.2rem',
    padding: '4px',
    margin: '4px',
    ':hover': {
      backgroundColor: 'transparent',
      border: 'none !important',
      transform: 'scale(1.2)',
    },
  },
  '.fc-prev-button:hover': {
    backgroundColor: 'transparent',
    border: 'none !important',
    transform: 'scale(1.2)',
  },
  '.fc-next-button:hover': {
    backgroundColor: 'transparent',
    border: 'none !important',
    transform: 'scale(1.2)',
  },
  '.fc-col-header': {
    margin: '8px 0',
  },
  '.fc-col-header-cell-cushion': {
    padding: '8px',
    fontSize: '16px',
    fontWeight: '500',
  },
  '.fc-scrollgrid-section-header': {
    backgroundColor: '#380354',
    color: '#D7D8DB',
  },
  '.fc-day-today': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: '10px',
  },
  '.fc-timegrid-body': {
    borderLeft: '1px solid #DDDDDD',
    marginTop: '2px',
  },
  '.fc-timegrid-slot-label-frame': {
    backgroundColor: '#ECF0F6',
  },
  '.fc-timegrid-slot-label-cushion': {
    color: theme.palette.primary.dark,
    fontWeight: '600',
    padding: '8px',
  },
  '.fc-timegrid-col.fc-day-today ': {
    background: '#F6E5FF !important',
    border: 'none !important',
  },
  '.fc-timegrid-event-harness>.fc-timegrid-event': {
    backgroundColor: '#dccde4',
    minHeight: '38px',
    border: 'none',
  },
}));

export const EventBox = styled(Box)(({ theme }) => ({
  border: `2px solid ${theme.palette.primary.dark}`,
  color: theme.palette.primary.dark,
  width: '100%',
  height: '100%',
  borderRadius: '4px',
  padding: '16px 18px',
  fontSize: '16px',
  fontWeight: '600',
  p: {
    margin: '0',
  },
  cursor: 'pointer',
}));

export const EventBlockage = styled(Box)(({ theme }) => ({
  border: `2px solid #76787B`,
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  padding: '8px',
  alignContent: 'flex-start',
  textAlign: 'center',
  backgroundColor: '#e3e4e4',
  width: '100%',
  height: '100%',
  fontSize: '16px',
  fontWeight: '600',
  overflow: 'hidden',
  color: '#76787B',
  p: {
    margin: 0,
  },
  span: {
    fontSize: '20px !important',
    fontWeight: '600',
  },
  [theme.breakpoints.down('xl')]: {
    fontSize: '14px',
  },
}));

export const EventDisabledBox = styled(Box)(() => ({
  border: `2px solid #76787B`,
  width: '100%',
  height: '100%',
  borderRadius: '4px',
  padding: '16px 18px',
  fontSize: '16px',
  fontWeight: '600',
  p: {
    margin: '0',
    color: '#76787B',
  },
}));

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ECF0F6',
    color: '#76787B',
    fontSize: 12,
    fontWeight: '400',
    padding: '2px 6px',
    marginTop: '4px !important',
    marginBottom: '2px !important',
  },
}));
