/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import { Box, CircularProgress, Typography } from '@mui/material';
import { driver, DriveStep } from 'driver.js';
import { useEffect, useState } from 'react';

import { EvaluationType, StepTour } from '../../api/enumerations';
import { AttachFilesDialog } from '../../components/Dialog/AttachFilesDialog';
import ConfirmationDialog from '../../components/Dialog/ConfirmationDialog';
import { PeptDialog } from '../../components/Dialog/PeptDialog';
import { AccordionTitle } from '../../components/Sections/AccordionTitle';
import { ClientData } from '../../components/Sections/ClientData';
import { FormResponse } from '../../components/Sections/FormResponse';
import { WorkOrderProgressBar } from '../../components/Sections/ProgressBar';
import { PropertyAddressWithMaps } from '../../components/Sections/PropertyAddressWithMaps';
import { PropertyData } from '../../components/Sections/PropertyData';
import { PropertyInfo } from '../../components/Sections/PropertyInfo';
import { Rooms } from '../../components/Sections/Rooms';
import { SampleSection } from '../../components/Sections/Sample';
import { ScheduleInfo } from '../../components/Sections/ScheduleInfo';
import { Title } from '../../components/Sections/Title';
import { ToogleAccordion } from '../../components/ToogleAccordion';
import {
  BoxContainer,
  CancelOsBox,
  FlexSpaceBetweenBox,
  LoadingBox,
  SectionBox,
  SubmitBox,
} from '../../components/UI/Box';
import {
  BackButton,
  CancelOSButton,
  FilledButton,
} from '../../components/UI/Button';
import { GridContainer } from '../../components/UI/Grid';
import { Constants } from '../../constants/calculation';
import {
  IconApartmentMS,
  IconArrowCircleLeftMS,
  IconCalendarTodayMS,
  IconCancelMS,
  IconFactCheckMS,
  IconListAltMS,
  IconLocationCityMS,
  IconLocationOnMS,
  IconPhotoLibraryMS,
} from '../../constants/icons';
import { driverConfig } from '../../helpers/driver/config';
import { useAccordion } from '../../hooks/useAccordion';
import { useCancelWorkOrder } from '../../hooks/useCancelWorkOrder';
import { useChangeStatus } from '../../hooks/useChangeStatus';
import useGeneral from '../../hooks/useGeneral';
import { useTour } from '../../hooks/useTour';
import { ForceFactors } from './ForceFactors';
import useCalculation from './hooks';
import { AvmStatusBox, FlexBox, QueuePosition } from './styles';

export function Calculation(): JSX.Element {
  const [tourHasStarted, setTourHasStarted] = useState(false);
  const [stepTour, setStepTour] = useState<DriveStep[]>();

  const { osId, navigateHome } = useGeneral();
  const { handleCancelWorkOrder } = useCancelWorkOrder();
  const { handlePreviousStatus, loadingPreviousStatus } = useChangeStatus();
  const {
    propertyData,
    sampleData,
    rooms,
    avmStatus,
    queuePosition,
    loadingPage,
  } = useCalculation();
  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandFour,
    setExpandFour,
    expandFive,
    setExpandFive,
    expandSix,
    setExpandSix,
    expandSeven,
    setExpandSeven,
    expandAll,
    setExpandAll,
    toogleAccordions,
  } = useAccordion();

  const {
    tourCompletion,
    setTourCompletion,
    setTutorialStep,
    driveIsActive,
    setDriveIsActive,
    isTourOn,
    setTourOn,
    tourSelection,
    setTourSelection,
    setToBeContinued,
  } = useTour();

  const forceFactor =
    propertyData?.evaluation_type === EvaluationType.SIMPFACTORS ||
    propertyData?.evaluation_type === EvaluationType.AUTOFACTORS;

  const getTourSteps = (forceFactor: boolean): DriveStep[] => {
    switch (forceFactor) {
      case true:
        return [
          {
            element: '#status-box',
            popover: {
              description:
                'Caso não encontre algum modelo, a mensagem exibida será essa. Para resolver o problema você deverá voltar a OS para criação de amostra e alterar os elementos para melhorar a amostra criada.',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#back-status',
            popover: {
              description:
                'Para voltar para criação de amostra, basta clicar neste botão.',
              side: 'left',
              align: 'center',
            },
          },
          {
            element: '#recalculate',
            popover: {
              description:
                'Caso você tenha optado por forçar um ou mais fatores e isso ocasionou o cálculo a não encontrar mais modelos disponíveis, você poderá clicar em recalcular para não forçar nenhum fator ou ainda escolher algum outro fator para forçar o cálculo e solicitar o cálculo novamente.',
              side: 'left',
              align: 'end',
            },
          },
          {
            element: '#factors-dialog',
            popover: {
              description:
                'Aqui você poderá selecionar o fator que deseja forçar a utilização no cálculo, caso você queira enviar o cálculo sem fator nenhum, basta confirmar que a OS será enviada para o cálculo. Lembrando que, o fator de áreas não pode ser desligado.',
              side: 'left',
              align: 'center',
            },
          },
        ];
      case false:
        return [
          {
            element: '#status-box',
            popover: {
              description:
                'Neste campo você conseguirá ver em qual status está o cálculo, se está calculando ou não encontrou algum modelo.',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#back-status',
            popover: {
              description:
                'Para voltar para criação de amostra, basta clicar neste botão.',
              side: 'left',
              align: 'center',
            },
          },
        ];
      default:
        return [];
    }
  };

  const driverObj = driver({
    ...driverConfig,
    steps: stepTour,
    onPrevClick: () => {
      if (driverObj.getActiveIndex() === 3 && forceFactor) {
        const btnCancel = document.getElementById('cancel-btn');
        if (btnCancel) btnCancel.click();
      }
      driverObj.movePrevious();
    },
    onNextClick: () => {
      if (driverObj.isLastStep()) {
        setTourCompletion({
          ...tourCompletion,
          calculation: { complete: true },
          lastStepSeen: StepTour.CALCULATIONFINISH,
        });
        setTutorialStep(StepTour.CALCULATIONFINISH);
        setToBeContinued(false);
        setTourSelection(false);
        setDriveIsActive(false);
        setTourOn(true);
      }
      if (driverObj.getActiveIndex() === 2 && forceFactor) {
        const btn = document.getElementById('recalculate');
        if (btn) btn.click();
        setTimeout(() => {
          driverObj.moveTo(3);
        }, 300);
      }
      if (driverObj.getActiveIndex() === 3 && forceFactor) {
        const btnCancel = document.getElementById('cancel-btn');
        if (btnCancel) btnCancel.click();
      }
      driverObj.moveNext();
    },
    onCloseClick: () => {
      setDriveIsActive(false);
      setTourSelection(false);
      setToBeContinued(false);
      driverObj.destroy();
    },
    onDestroyStarted: () => {
      setDriveIsActive(false);
      setTourSelection(false);
      setToBeContinued(false);
      driverObj.destroy();
    },
  });

  useEffect(() => {
    let steps: DriveStep[] = [];
    steps = getTourSteps(forceFactor);

    setStepTour(steps);
  }, [forceFactor]);

  useEffect(() => {
    if (
      !tourCompletion.calculation.complete &&
      !tourCompletion.skipTour.calculation
    ) {
      setTutorialStep(StepTour.CALCULATIONSTART);
      if (!driveIsActive && !loadingPage && !isTourOn && !tourHasStarted) {
        setTourOn(true);
        setTourHasStarted(true);
      } else if (driveIsActive && !isTourOn) {
        driverObj.drive();
      }
    }
    if (tourSelection && driveIsActive && !isTourOn) {
      setTutorialStep(StepTour.CALCULATIONSTART);
      setTimeout(() => {
        driverObj.drive();
      }, 300);
    }
  }, [driveIsActive, loadingPage, isTourOn, tourSelection]);

  useEffect(() => {
    if (
      expandOne &&
      expandTwo &&
      expandThree &&
      expandFour &&
      expandFive &&
      expandSix &&
      expandSeven
    ) {
      setExpandAll(true);
    } else {
      setExpandAll(false);
    }
  }, [
    expandOne,
    expandTwo,
    expandThree,
    expandFour,
    expandFive,
    expandSix,
    expandSeven,
  ]);

  const showSchedulingAccordion =
    propertyData?.evaluation_type === EvaluationType.SIMPFACTORS ||
    propertyData?.evaluation_type === EvaluationType.SIMPINFERENCES;

  return (
    <GridContainer>
      <BackButton onClick={navigateHome}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer>
        <Title
          osNumber={propertyData?.reference_number || 0}
          title={Constants.calculation}
          createdAt={propertyData?.created_at}
        />
        {loadingPage ? (
          <LoadingBox>
            <CircularProgress />
          </LoadingBox>
        ) : (
          <>
            <FlexSpaceBetweenBox>
              <CancelOsBox>
                <ConfirmationDialog
                  text={Constants.cancelOsText}
                  button={
                    <CancelOSButton>
                      {IconCancelMS}
                      {Constants.cancelOs}
                    </CancelOSButton>
                  }
                  model="error"
                  modalCallback={handleCancelWorkOrder}
                />
              </CancelOsBox>
              <FlexBox>
                {queuePosition && (
                  <QueuePosition>
                    {Constants.queuePosition}
                    {queuePosition}
                  </QueuePosition>
                )}
                <AttachFilesDialog propertyData={propertyData} osId={osId} />
              </FlexBox>
            </FlexSpaceBetweenBox>
            {avmStatus && (
              <AvmStatusBox color={avmStatus.color} id="status-box">
                {avmStatus.text}
                {propertyData?.avm_status?.failure_reason &&
                  Constants.avmFailedHint}
              </AvmStatusBox>
            )}
            <WorkOrderProgressBar complete>
              <>
                <ClientData propertyData={propertyData} />
                <ToogleAccordion expand={expandAll} toogle={toogleAccordions} />
              </>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyData}
                  icon={IconApartmentMS}
                  openAccordion={expandOne}
                  setOpenAccordion={setExpandOne}
                />
                {expandOne && <PropertyData propertyData={propertyData} />}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyAddress}
                  icon={IconLocationOnMS}
                  openAccordion={expandTwo}
                  setOpenAccordion={setExpandTwo}
                />
                {expandTwo && (
                  <PropertyAddressWithMaps
                    checkLocation
                    propertyData={propertyData}
                  />
                )}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyDetails}
                  icon={IconLocationCityMS}
                  openAccordion={expandThree}
                  setOpenAccordion={setExpandThree}
                />
                {expandThree && <PropertyInfo propertyData={propertyData} />}
              </SectionBox>
            </WorkOrderProgressBar>
            {showSchedulingAccordion && (
              <WorkOrderProgressBar complete>
                <SectionBox>
                  <AccordionTitle
                    title={Constants.scheduleInfo}
                    icon={IconCalendarTodayMS}
                    openAccordion={expandFour}
                    setOpenAccordion={setExpandFour}
                  />
                  {expandFour && (
                    <ScheduleInfo
                      inspectionData={propertyData.inspection}
                      rooms={rooms}
                    />
                  )}
                </SectionBox>
              </WorkOrderProgressBar>
            )}
            <WorkOrderProgressBar complete>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyPhotos}
                  icon={IconPhotoLibraryMS}
                  openAccordion={expandFive}
                  setOpenAccordion={setExpandFive}
                />
                {expandFive && (
                  <Box>
                    {propertyData?.inspection && rooms.length > 0 ? (
                      <Rooms
                        navigationPath={`${osId}/inspection/${propertyData.inspection.id}/rooms`}
                        rooms={rooms}
                        osId={osId}
                        inspectionId={propertyData.inspection.id}
                      />
                    ) : (
                      <Typography>{Constants.noPhotos}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete>
              <SectionBox>
                <AccordionTitle
                  title={Constants.form}
                  icon={IconListAltMS}
                  openAccordion={expandSix}
                  setOpenAccordion={setExpandSix}
                />
                {expandSix && (
                  <FormResponse
                    questionForm={propertyData?.inspection?.form_response}
                  />
                )}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete lastAccordion>
              <SectionBox>
                <AccordionTitle
                  title={Constants.sample}
                  icon={IconFactCheckMS}
                  openAccordion={expandSeven}
                  setOpenAccordion={setExpandSeven}
                />
                {expandSeven && (
                  <Box>
                    {sampleData && sampleData?.length > 0 ? (
                      <SampleSection
                        sampleData={sampleData}
                        propertyData={propertyData}
                        osId={osId}
                      />
                    ) : (
                      <Typography>{Constants.noSample}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
            </WorkOrderProgressBar>
            <SubmitBox>
              <PeptDialog
                osId={osId}
                referenceNumber={propertyData?.reference_number}
              />
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <ConfirmationDialog
                  id="back-status"
                  text={Constants.previousStatusText.replace(
                    '**',
                    `${propertyData?.reference_number}`
                  )}
                  button={
                    <FilledButton
                      width="md"
                      model="warning"
                      disabled={loadingPreviousStatus}
                    >
                      {loadingPreviousStatus ? (
                        <CircularProgress color="inherit" size={22} />
                      ) : (
                        Constants.previousStatus
                      )}
                    </FilledButton>
                  }
                  modalCallback={() =>
                    handlePreviousStatus(
                      osId,
                      propertyData?.reference_number,
                      Constants.previousStatusName
                    )
                  }
                />
                {forceFactor && (
                  <ForceFactors
                    refNumber={propertyData?.reference_number}
                    factors={propertyData?.force_factors || null}
                  />
                )}
              </div>
            </SubmitBox>
          </>
        )}
      </BoxContainer>
    </GridContainer>
  );
}
