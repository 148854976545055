import { Button } from '@mui/base';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CitiesOptionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  width: '100%',
  overflowX: 'hidden',
  padding: '8px 0',
  borderRadius: '4px',
  background: theme.palette.common.white,
  zIndex: '300',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  '&::-webkit-scrollbar-track': {
    margin: '24px 0',
  },

  position: 'absolute',
  color: theme.palette.primary.dark,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
}));

export const Option = styled(Button)(() => ({
  border: 'none',
  background: 'transparent',
  fontSize: '16px',
  textAlign: 'left',
  cursor: 'pointer',
  padding: '8px 16px',
  '&:hover': {
    background: '#d485eed1',
  },
}));
