/* eslint-disable react/require-default-props */
import {
  IconArrowBackMS,
  IconArrowForwardMS,
} from '../../../../constants/icons';
import {
  SampleBackButton,
  SampleForwardBox,
  SampleForwardButton,
} from './styles';

interface CardPaginationProps {
  sampleLength?: number;
  page: number;
  setPage: (value: number) => void;
  receipt?: boolean;
}

export function CardPagination({
  sampleLength,
  page,
  setPage,
  receipt,
}: CardPaginationProps): JSX.Element {
  return (
    <>
      <SampleBackButton
        receipt={receipt ? 'true' : 'false'}
        disabled={page === 1}
        onClick={() => setPage(page - 1)}
      >
        {IconArrowBackMS}
      </SampleBackButton>
      <SampleForwardBox>
        <SampleForwardButton
          id="navigation-button"
          receipt={receipt ? 'true' : 'false'}
          disabled={page === sampleLength}
          onClick={() => setPage(page + 1)}
        >
          {IconArrowForwardMS}
        </SampleForwardButton>
      </SampleForwardBox>
    </>
  );
}
