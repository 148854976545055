import { handleUrl, myHeadersWithTokenContentType } from '../global';
import {
  DetailProps,
  GetRoomProps,
  GetAllRoomsProps,
  AddRoomData,
  UpdateRoomData,
} from './types';

const addRoom = (data: AddRoomData): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/rooms`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const getAllRooms = (
  id: number,
  page: number,
  size: number
): Promise<GetAllRoomsProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/inspections/${id}/rooms?page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const getRoom = (id: number): Promise<GetRoomProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/rooms/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const updateRoom = (id: number, name: UpdateRoomData): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(name),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/rooms/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const deleteRoom = (id: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/rooms/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export default {
  addRoom,
  getAllRooms,
  getRoom,
  updateRoom,
  deleteRoom,
};
