import { useContext, useState } from 'react';

import { resetPasswordProto } from '../../../../api/settings';
import { IconVisibility, PasswordIcon } from '../../../../components/InputIcon';
import { FilledButton, OutlinedButton } from '../../../../components/UI/Button';
import { DialogTitle } from '../../../../components/UI/Typography';
import { IconEditMS } from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import { validatePassword } from '../../../../helpers';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import {
  ButtonBox,
  DialogContainer,
  StyledDialog,
  StyledTextField,
} from './styles';

interface ResetPasswordProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export function ResetPassword({
  open,
  setOpen,
}: ResetPasswordProps): JSX.Element {
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmationError, setConfirmationError] = useState(false);

  const { setErrorMessage, setOpenSnackbar, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();

  const handleClose = (): void => {
    setOpen(false);
    setCurrentPassword('');
    setPassword('');
    setPasswordConfirmation('');
    setPasswordError(false);
    setConfirmationError(false);
    setShowCurrentPassword(false);
    setShowPassword(false);
    setShowConfirmation(false);
  };

  const handleSubmit = async (): Promise<void> => {
    if (!currentPassword || !password || !passwordConfirmation) {
      setSnackbarMessage('Preencha todos os campos!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    if (password !== passwordConfirmation) {
      setSnackbarMessage('Confirme sua senha.');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    const passwordData = {
      current_password: currentPassword,
      password,
      password_confirmation: passwordConfirmation,
    };
    try {
      const response = await resetPasswordProto(passwordData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Senha atualizada com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      handleClose();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="reset password dialog"
    >
      <DialogContainer>
        <DialogTitle>
          {IconEditMS}
          Redefinir Senha
        </DialogTitle>
        <StyledTextField
          required
          type={showCurrentPassword ? 'text' : 'password'}
          InputProps={{
            startAdornment: <PasswordIcon color="#8B8C8E" />,
            endAdornment: (
              <IconVisibility
                showPassword={showCurrentPassword}
                setShowPassword={setShowCurrentPassword}
                color="#8B8C8E"
              />
            ),
          }}
          id="current-password"
          placeholder="digite sua senha atual"
          value={currentPassword}
          onChange={(e) => {
            setCurrentPassword(e.target.value);
          }}
        />
        <StyledTextField
          required
          type={showPassword ? 'text' : 'password'}
          helperText={
            passwordError &&
            'mínimo de 8 caracteres com letra mínuscula, maiúscula e número'
          }
          InputProps={{
            startAdornment: <PasswordIcon color="#8B8C8E" />,
            endAdornment: (
              <IconVisibility
                showPassword={showPassword}
                setShowPassword={setShowPassword}
                color="#8B8C8E"
              />
            ),
          }}
          id="password"
          placeholder="digite uma nova senha"
          error={passwordError}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            !validatePassword(e.target.value)
              ? setPasswordError(true)
              : setPasswordError(false);
          }}
        />
        <StyledTextField
          required
          type={showConfirmation ? 'text' : 'password'}
          helperText={confirmationError && 'as senhas devem ser iguais'}
          InputProps={{
            startAdornment: <PasswordIcon color="#8B8C8E" />,
            endAdornment: (
              <IconVisibility
                showPassword={showConfirmation}
                setShowPassword={setShowConfirmation}
                color="#8B8C8E"
              />
            ),
          }}
          id="confirmation"
          placeholder="confirme sua senha"
          error={confirmationError}
          value={passwordConfirmation}
          onChange={(e) => {
            setPasswordConfirmation(e.target.value);
            e.target.value !== password
              ? setConfirmationError(true)
              : setConfirmationError(false);
          }}
        />
        <ButtonBox>
          <OutlinedButton width="sm" onClick={handleClose}>
            cancelar
          </OutlinedButton>
          <FilledButton width="sm" onClick={handleSubmit}>
            confirmar
          </FilledButton>
        </ButtonBox>
      </DialogContainer>
    </StyledDialog>
  );
}
