import { Box, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CoordGrid = styled(Grid)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
}));

export const BoldTypography = styled(Typography)(() => ({
  fontWeight: '600',
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiInputBase-root': { borderRadius: '16px' },
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: '#595959 !important',
  },
}));

export const PatternFormatStyle = {
  width: '100%',
  '& .MuiInputBase-root': { borderRadius: '16px' },
  '& .MuiSvgIcon-root': {
    color: '#004D43',
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: '#595959 !important',
  },
};

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
}));
