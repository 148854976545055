import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

export const PaperQuestion = styled(Paper)(({ theme }) => ({
  height: '280px',
  backgroundColor: '#FFFFFF',
  border: '1px solid #D7D8DB',
  padding: '32px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '12px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.7),
    },
  },
  '&::-webkit-scrollbar-track': {
    margin: '6px 0',
  },
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
}));

export const NumberTypography = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: '600',
  color: theme.palette.primary.dark,
}));

export const TitleTypography = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '18px',
  fontWeight: '600',
}));

export const ShortAnswerTextField = styled(TextField)(() => ({
  width: '100%',
  margin: '8px',
  '& .MuiInputBase-root': {
    borderRadius: '16px',
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      '&.Mui-disabled': {
        WebkitTextFillColor: '#595959',
      },
    },
  },
}));

export const FlexCenterGrid = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const SingleSelectTypo = styled(Typography)(() => ({
  marginTop: '-10px',
  fontSize: '14px',
  color: '#8B8C8E',
}));

export const MultiFormControl = styled(FormControlLabel)(({ theme }) => ({
  margin: '8px 0',
  '& .MuiButtonBase-root': {
    padding: '4px',
  },
  '&:hover': {
    '& .MuiButtonBase-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiTypography-root': {
      fontWeight: '600',
      fontSize: '18px',
    },
  },
  '& .MuiTypography-root': {
    fontSize: '18px',
    '&.Mui-disabled': {
      color: '#595959',
    },
  },
}));

export const CheckboxFormControl = styled(FormControlLabel)(({ theme }) => ({
  padding: '8px 12px 0',
  '& .MuiButtonBase-root': {
    padding: '4px',
  },
  '&:hover': {
    '& .MuiButtonBase-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiTypography-root': {
      fontWeight: '600',
    },
  },
  '& .MuiTypography-root': {
    fontSize: '18px',
  },
  '&.Mui-disabled': {
    color: '#595959',
  },
}));

export const ListTextField = styled(TextField)(() => ({
  margin: '40px 20px 16px',
  minWidth: '180px',
  width: 'fit-content',
  '& .MuiInputBase-root': { borderRadius: '16px' },
}));

export const StyledDatePicker = {
  margin: '24px 6px 16px',
  minWidth: '180px',
  '& .MuiOutlinedInput-root': { borderRadius: '16px' },
  '& .MuiInputBase-input.MuiOutlinedInput-input': {
    '&.Mui-disabled': {
      WebkitTextFillColor: '#595959',
    },
  },
};
