/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, Divider, Grid } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { plansAPI } from '../../../../api';
import { StatusCode } from '../../../../api/enumerations';
import { AvailablePlansData, PlansData } from '../../../../api/plans/types';
import { TrialRemainingDays } from '../../../../components/TrialRemainingDays';
import { BoxContainer, SectionBox } from '../../../../components/UI/Box';
import { BackButton } from '../../../../components/UI/Button';
import { GridContainer } from '../../../../components/UI/Grid';
import {
  IconArrowCircleLeftMS,
  IconHourglassTopMS,
} from '../../../../constants/icons';
import { Constants } from '../../../../constants/plans';
import { GlobalContext } from '../../../../context/global';
import { getErrorMessage } from '../../../../helpers';
import { InfinityDetails, PlanDetails } from '../presenter';
import CurrentPlan from './CurrentPlan';
import PlanCard from './PlanCard';
import {
  BoldTypography,
  CouponTextfield,
  PlanContainer,
  StyledButton,
  StyledToggleButton,
  StyledToggleButtonGroup,
  TitleTypography,
} from './styles';

export default function PlansInfo(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [plan, setPlan] = useState<PlansData>();
  const [availablePlans, setAvailablePlans] = useState<AvailablePlansData[]>(
    []
  );
  const [coupon, setCoupon] = useState<string>('');
  const [frequency, setFrequency] = useState<string>('yearly');

  const {
    setOpenSnackbar,
    setErrorMessage,
    setSnackbarMessage,
    company,
    setUpdatePlan,
    updatePlan,
  } = useContext(GlobalContext);

  const navigate = useNavigate();

  const navigateBack = (): void => {
    navigate(`/configuration`);
  };

  useEffect(() => {
    if (company) {
      if (company.is_responsible_user === false) {
        setSnackbarMessage('Usuário não autorizado.');
        setErrorMessage(true);
        setOpenSnackbar(true);
        navigateBack();
      }
    }
  }, [company]);

  const getDataCallback = useCallback(async () => {
    setLoading(true);
    try {
      const response = await plansAPI.getPlans();

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }
      setLoading(false);
      setPlan(response.data);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      navigateBack();
    }

    try {
      const response = await plansAPI.getAvailablePlans();

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setAvailablePlans(response.data);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [setErrorMessage, setOpenSnackbar, setSnackbarMessage]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  const handleFrequency = (
    event: React.MouseEvent<HTMLElement>,
    newStatus: string
  ): void => {
    if (newStatus !== null) {
      setFrequency(newStatus);
    }
  };

  const handleCoupon = async (): Promise<void> => {
    if (coupon.length < 1) {
      setSnackbarMessage('Adicione um cupom.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }
    try {
      const response = await plansAPI.redeemCoupon({ code: coupon });

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Cupom de extensão resgatado com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      setPlan(undefined);
      setAvailablePlans([]);
      getDataCallback();
      setUpdatePlan(!updatePlan);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };
  return (
    <GridContainer sx={{ position: 'relative' }}>
      <BackButton onClick={navigateBack}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer>
        <TitleTypography>
          {IconHourglassTopMS}
          {Constants.accessInfo}
        </TitleTypography>
        {plan?.name === 'Gratuito' && plan?.remaining_days !== null && (
          <TrialRemainingDays remainingDays={plan.remaining_days} />
        )}
        {plan && (
          <>
            <PlanContainer>
              {plan.status === 'Ativo' && <CurrentPlan plan={plan} />}
              <Box>
                <BoldTypography>{Constants.coupon}</BoldTypography>
                <BoldTypography sx={{ fontSize: '18px' }}>
                  {Constants.couponText}
                </BoldTypography>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <CouponTextfield
                      id="coupon"
                      placeholder={Constants.insertCoupon}
                      color="primary"
                      value={coupon}
                      onChange={(e) => setCoupon(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <StyledButton onClick={handleCoupon}>
                      {Constants.apply}
                    </StyledButton>
                  </Grid>
                </Grid>
              </Box>
            </PlanContainer>
            {plan.status === 'Ativo' && <Divider color="#D8AAEF" />}
          </>
        )}
        <SectionBox textAlign="center">
          {loading && <CircularProgress />}
          <BoldTypography sx={{ fontSize: '28px' }}>
            {Constants.choosePlan}
          </BoldTypography>
          <StyledToggleButtonGroup
            value={frequency}
            exclusive
            onChange={handleFrequency}
            aria-label="frequency"
          >
            <StyledToggleButton disableRipple value="yearly" aria-label="anual">
              {Constants.yearly}
            </StyledToggleButton>
            <StyledToggleButton
              disableRipple
              value="monthly"
              aria-label="mensal"
            >
              {Constants.monthly}
            </StyledToggleButton>
          </StyledToggleButtonGroup>
          <Grid container spacing={2}>
            {availablePlans.length > 0 &&
              PlanDetails(availablePlans).map((details) => (
                <PlanCard
                  key={details.id}
                  details={details}
                  frequency={frequency}
                  popular={details.popular}
                />
              ))}
            <PlanCard
              details={InfinityDetails}
              frequency={frequency}
              horizontal
            />
          </Grid>
        </SectionBox>
      </BoxContainer>
    </GridContainer>
  );
}
