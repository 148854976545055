import { Box } from '@mui/material';
import { useCallback, useContext, useEffect } from 'react';

import { userAPI } from '../../../api';
import { FirstAccessStages } from '../../../api/enumerations';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage } from '../../../helpers';
import { InputBox } from './styles';

interface IdentityProps {
  handleNextStage: (value: number) => void;
  email: string;
}

export default function FirstAccessIdentity({
  handleNextStage,
  email,
}: IdentityProps): JSX.Element {
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const handleSubmitIdWall = useCallback(
    async (idwallNumber: IdwallToken) => {
      if (idwallNumber) {
        const data = {
          email,
          sdk_key: idwallNumber?.token,
        };
        try {
          const { detail } = await userAPI.sendIdwallSdk(data);

          if (detail.description) {
            throw new Error(detail.description);
          }

          handleNextStage(FirstAccessStages.SUCCESS);
        } catch (error) {
          setSnackbarMessage(getErrorMessage(error));
          setOpenSnackbar(true);
          setErrorMessage(true);
        }
      }
    },
    [
      email,
      handleNextStage,
      setErrorMessage,
      setOpenSnackbar,
      setSnackbarMessage,
    ]
  );

  type IdwallToken = {
    token: string;
  };

  useEffect(() => {
    window.idwSDKWeb({
      token: process.env.REACT_APP_IDWALL_SDK_WEB_TOKEN,
      onComplete: (token: IdwallToken) => {
        handleSubmitIdWall(token);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      },
    });
  }, [
    handleNextStage,
    handleSubmitIdWall,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
  ]);

  return (
    <InputBox>
      <Box data-idw-sdk-web />
    </InputBox>
  );
}
