import { Box, CardMedia, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const InputBox = styled(Box)(() => ({
  width: '75%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
}));

export const StyledTypography = styled(Typography)(() => ({
  textAlign: 'center',
  color: '#2E383F',
  margin: '0 -30px',
}));

export const StyledCardMedia = styled(CardMedia)(() => ({
  width: '230px',
  height: '230px',
  backgroundSize: 'contain',
}));
