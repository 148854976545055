/* eslint-disable max-len */
import { InitialModalTour } from '../../Modal/InitialModalTour';

export function ReportTour(): JSX.Element {
  return (
    <InitialModalTour
      title="Vamos dar início à"
      pageName="Elaboração de laudo"
      subtext1="Nesta tela você terá acesso ao resultado do cálculo e poderá inserir as informações complementares."
      stepTourName="report"
    />
  );
}
