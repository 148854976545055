/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Box, Divider, Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { userAPI } from '../../../api';
import { RegistrationUf } from '../../../api/enumerations';
import {
  deleteAccount,
  getRegistrationDataInformation,
  updateCompanyAddress,
} from '../../../api/settings';
import { DetailProps } from '../../../api/users/types';
import {
  CustomPatternFormat,
  CustomTextField,
  DisabledTextField,
} from '../../../components/CustomInput';
import ConfirmationDialog from '../../../components/Dialog/ConfirmationDialog';
import { FilledButton, OutlinedButton } from '../../../components/UI/Button';
import { Constants } from '../../../constants/configuration';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage } from '../../../helpers';
import {
  removeLocalStorageCompany,
  removeLocalStorageToken,
} from '../../../helpers/localStorage';
import { useStoragedJwt } from '../../../hooks/useDecodedJwt';
import useGeneral from '../../../hooks/useGeneral';
import useSearchCep from '../../../hooks/useSearchCep';
import { PageTitle } from '../styles';
import { ResetPassword } from './ResetPasswordDialog';
import { GridContainer, StyledButton } from './styles';

export default function RegisterInfo(): JSX.Element {
  const [openDialog, setOpenDialog] = useState(false);
  const [address, setAddress] = useState('');
  const [addressNumber, setAddressNumber] = useState('');
  const [cep, setCep] = useState('');
  const [city, setCity] = useState('');
  const [complement, setComplement] = useState('');
  const [district, setDistrict] = useState('');
  const [uf, setUf] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyDocument, setCompanyDocument] = useState({
    type: '',
    number: '',
  });
  const [responsible, setResponsible] = useState({
    name: '',
    cpf: '',
    email: '',
    phone: '',
    licenseType: '',
  });
  const navigate = useNavigate();

  const decoded = useStoragedJwt();

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { company } = useContext(GlobalContext);

  const { handleSearchCep } = useSearchCep();
  const { handleDownloadXlsx } = useGeneral();

  const verifyCep = async (cep: string): Promise<void> => {
    const addressData = await handleSearchCep(cep);
    if (addressData) {
      setCep(addressData.cep);
      setUf(addressData.uf);
      setCity(addressData.city);
      setDistrict(addressData.district);
      setAddress(addressData.address);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getRegistrationDataInformation();
        setAddress(data.address);
        setAddressNumber(data.address_number);
        setCep(data.zipcode);
        setCity(data.city);
        setComplement(data.address_complement);
        setDistrict(data.address_district);
        setUf(data.uf);
        setCompanyName(data.name);
        setCompanyDocument({
          type: data.document_type ?? '',
          number: data.document ?? '',
        });
        setResponsible({
          name: data.responsible_user.name ?? '',
          cpf: data.responsible_user.document ?? '',
          email: data.responsible_user.email ?? '',
          phone: data.phone_number ?? '',
          licenseType: data.license_type ?? '',
        });
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }
    };
    fetchData();
  }, [setErrorMessage, setOpenSnackbar, setSnackbarMessage, company]);

  const handleGetUserData = async (): Promise<void> => {
    try {
      const response = await userAPI.GetUserData();

      if (response.status === 200) {
        const contentType = response.headers.get('Content-Type');
        if (contentType === 'application/json') {
          setSnackbarMessage(
            'Você receberá um email com os dados em até 15 dias.'
          );
          setErrorMessage(false);
          setOpenSnackbar(true);
        } else {
          handleDownloadXlsx(response);
        }
      } else {
        const errorResponse: Promise<DetailProps> = response
          .text()
          .then((result) => JSON.parse(result));
        const result = await errorResponse;
        if (result.detail.description) {
          throw new Error(result.detail.description);
        }
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <Box>
      <PageTitle>{Constants.dataTitle}</PageTitle>
      <GridContainer container spacing={4}>
        <Grid item xs={4}>
          <DisabledTextField
            id={companyDocument.type}
            label={companyDocument.type.toLocaleUpperCase()}
            value={companyDocument.number}
          />
        </Grid>
        <Grid item xs={8}>
          <DisabledTextField id="company" label="empresa" value={companyName} />
        </Grid>
      </GridContainer>
      <Divider />
      <GridContainer container spacing={4}>
        <Grid item xs={8}>
          <DisabledTextField
            id="responsible"
            label="responsável"
            value={responsible.name}
          />
        </Grid>
        <Grid item xs={4}>
          <DisabledTextField id="cpf" label="CPF" value={responsible.cpf} />
        </Grid>
        <Grid item xs={8}>
          <DisabledTextField
            id="email"
            label="email principal"
            value={responsible.email}
          />
        </Grid>
        <Grid item xs={4}>
          <DisabledTextField
            id="phone"
            label="telefone"
            value={responsible.phone}
          />
        </Grid>
        <Grid item xs={3}>
          <DisabledTextField
            id={responsible.licenseType}
            label={responsible.licenseType.toUpperCase()}
            value={responsible.licenseType}
          />
        </Grid>
      </GridContainer>
      <Divider />
      <GridContainer container spacing={4}>
        <Grid item xs={3}>
          <CustomPatternFormat
            required
            id="cep"
            label="CEP"
            value={cep}
            setValue={setCep}
            format="#####-###"
            helper={verifyCep}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            required
            id="address"
            label="logradouro"
            value={address}
            setValue={setAddress}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            required
            id="address-number"
            label="número"
            value={addressNumber}
            setValue={setAddressNumber}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            id="complement"
            label="complemento"
            value={complement}
            setValue={setComplement}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            required
            id="district"
            label="bairro"
            value={district}
            setValue={setDistrict}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            required
            id="city"
            label="cidade"
            value={city}
            setValue={setCity}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            required
            id="uf"
            label="estado"
            value={uf}
            setValue={setUf}
          />
        </Grid>
      </GridContainer>
      <Divider />
      <GridContainer container spacing={4} textAlign="center">
        <Grid item xs={2.3}>
          <StyledButton onClick={() => setOpenDialog(true)}>
            {Constants.changePassword}
          </StyledButton>
        </Grid>
        <Grid item xs={2.3}>
          <ConfirmationDialog
            text={Constants.deleteAccountText}
            button={<StyledButton>{Constants.deleteAccount}</StyledButton>}
            modalCallback={() => {
              try {
                deleteAccount({ userId: decoded?.user?.id });
                navigate('/login');
                removeLocalStorageToken();
                removeLocalStorageCompany();
              } catch (error) {
                setSnackbarMessage(getErrorMessage(error));
                setErrorMessage(true);
                setOpenSnackbar(true);
              }
            }}
          />
        </Grid>
        <Grid item xs={2.4}>
          <StyledButton onClick={handleGetUserData}>
            {Constants.downloadData}
          </StyledButton>
        </Grid>
        <Grid item xs={2.6}>
          <Link
            to="https://marketing.protoai.com.br/LP/politica-de-privacidade"
            target="_blank"
            style={{ textDecoration: 'none' }}
          >
            <StyledButton>{Constants.privacyPolicy}</StyledButton>
          </Link>
        </Grid>
        <Grid item xs={2.4}>
          <Link
            to="https://marketing.protoai.com.br/LP/termos-de-uso"
            target="_blank"
            style={{ textDecoration: 'none' }}
          >
            <StyledButton>{Constants.termsUse}</StyledButton>
          </Link>
        </Grid>
      </GridContainer>
      <Divider />
      <GridContainer container textAlign="center">
        <Grid item xs={4}>
          <OutlinedButton id="cancel-btn">{Constants.cancel}</OutlinedButton>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <FilledButton
            id="save-btn"
            onClick={() => {
              function getRegistrationUfPosition(uf: string): number {
                const ufArray = Object.keys(RegistrationUf)
                  .filter((key) => Number(key))
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  .map((key) => RegistrationUf[key as any]);

                return ufArray.indexOf(uf) + 1;
              }
              updateCompanyAddress({
                address,
                address_complement: complement,
                address_district: district,
                address_number: addressNumber,
                city,
                uf: getRegistrationUfPosition(uf),
                zipcode: cep,
              });
            }}
          >
            {Constants.save}
          </FilledButton>
        </Grid>
      </GridContainer>
      <ResetPassword open={openDialog} setOpen={setOpenDialog} />
    </Box>
  );
}
