import { createContext, useState, useMemo, ReactNode } from 'react';

interface RegistrationIdentificationProps {
  identificationEmail: string;
  setIdentificationEmail: (value: string) => void;
  phoneNumber: string;
  setPhoneNumber: (value: string) => void;
  toastMessage: string;
  setToastMessage: (message: string) => void;
  reset: () => void;
}

export const RegistrationIdentificationContext = createContext(
  {} as RegistrationIdentificationProps
);

interface RegistrationIdentificationProviderProps {
  children: ReactNode;
}

export function RegistrationIdentification({
  children,
}: RegistrationIdentificationProviderProps): JSX.Element {
  const [identificationEmail, setIdentificationEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [toastMessage, setToastMessage] = useState('');

  const reset = (): void => {
    setIdentificationEmail('');
    setPhoneNumber('');
    setToastMessage('');
  };

  const contextValue = useMemo(
    () => ({
      identificationEmail,
      setIdentificationEmail,
      phoneNumber,
      setPhoneNumber,
      toastMessage,
      setToastMessage,
      reset,
    }),
    [identificationEmail, phoneNumber, toastMessage]
  );

  return (
    <RegistrationIdentificationContext.Provider value={contextValue}>
      {children}
    </RegistrationIdentificationContext.Provider>
  );
}
