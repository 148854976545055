/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Grid, LinearProgress } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { workOrderAPI } from '../../../api';
import {
  ReportSource,
  convertReportGoal,
  convertReportSource,
} from '../../../api/enumerations';
import { getErrorReport } from '../../../api/errorReport';
import {
  ErrorElementData,
  ErrorReportData,
} from '../../../api/errorReport/types';
import { WorkOrderData } from '../../../api/workOrders/types';
import Snackbar from '../../../components/Snackbar';
import { BoxContainer } from '../../../components/UI/Box';
import { BackButton } from '../../../components/UI/Button';
import { GridContainer } from '../../../components/UI/Grid';
import { TitleTypography } from '../../../components/UI/Typography';
import { Constants } from '../../../constants/errorReport';
import {
  IconArrowCircleLeftMS,
  IconInventoryMS,
  IconWarningMS,
} from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import { convertDate } from '../../../helpers';
import useErrorMessage from '../../../hooks/useErrorMessage';
import { Element } from './Element';
import { Revision } from './Revision';
import {
  CreatedStyle,
  DescriptionTypography,
  FlexBox,
  FlexGrid,
  OSNumber,
  StyledTypography,
  TitleBox,
} from './styles';

export function ReportDetails(): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [errorReport, setErrorReport] = useState<ErrorReportData>();
  const [elementSource, setElementSource] = useState(false);
  const [element, setElement] = useState<ErrorElementData>();
  const [propertyData, setPropertyData] = useState<WorkOrderData>();
  const [isUpdated, setIsUpdated] = useState(false);

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();
  const navigate = useNavigate();
  const { id } = useParams();
  const reportId = parseInt(id as string, 10);

  const navigateBack = (): void => {
    navigate('/error-report');
  };

  const getDataCallback = useCallback(async () => {
    let isReport = false;
    let workOrderId = 0;

    try {
      const response = await getErrorReport(reportId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setErrorReport(response.data);
      if (response.data.report_source === ReportSource.ELEMENT) {
        if (!response.data.sample_description) {
          setSnackbarMessage('Não foi possível carregar o elemento.');
          setErrorMessage(true);
          setOpenSnackbar(true);
          navigateBack();
          return;
        }
        setElementSource(true);
        setElement(response.data.sample_description);
        setLoading(false);
        if (response.data.sample_description.updated_by_human) {
          setIsUpdated(true);
        }
      } else {
        isReport = true;
        workOrderId = response.data.work_order_id;
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error, true));
      setErrorMessage(true);
      setOpenSnackbar(true);
      navigateBack();
    }

    if (isReport) {
      try {
        const response = await workOrderAPI.getWorkOrder(workOrderId);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setPropertyData(response.data);
        setLoading(false);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error, true));
        setErrorMessage(true);
        setOpenSnackbar(true);
        navigateBack();
      }
    }
  }, []);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  return (
    <GridContainer>
      <BackButton onClick={navigateBack}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer>
        <TitleBox>
          <TitleTypography>
            {IconWarningMS}
            {Constants.reportedError}
          </TitleTypography>
          <FlexBox>
            <CreatedStyle>
              {Constants.creation}{' '}
              {errorReport?.created_at && convertDate(errorReport.created_at)}
            </CreatedStyle>
            <OSNumber>
              {IconInventoryMS}
              {Constants.osNumber} {errorReport?.reference_number}
            </OSNumber>
          </FlexBox>
        </TitleBox>
        <Grid container spacing={5} sx={{ marginBottom: '40px' }}>
          <FlexGrid item xs={6}>
            <StyledTypography>{Constants.reportedBy}</StyledTypography>
            <StyledTypography fontWeight="bold">
              {errorReport?.user_name}
            </StyledTypography>
          </FlexGrid>
          <FlexGrid item xs={6}>
            <StyledTypography>{Constants.client}</StyledTypography>
            <StyledTypography fontWeight="bold">
              {errorReport?.requester_name}
            </StyledTypography>
          </FlexGrid>
          <FlexGrid item xs={6}>
            <StyledTypography>{Constants.goal}</StyledTypography>
            <StyledTypography fontWeight="bold">
              {errorReport?.report_goal &&
                convertReportGoal(errorReport?.report_goal)}
            </StyledTypography>
          </FlexGrid>
          <FlexGrid item xs={6}>
            <StyledTypography>{Constants.source}</StyledTypography>
            <StyledTypography fontWeight="bold">
              {errorReport?.report_source &&
                convertReportSource(errorReport?.report_source)}
            </StyledTypography>
          </FlexGrid>
          <FlexGrid item xs={12}>
            <StyledTypography>{Constants.errorDescription}</StyledTypography>
            <DescriptionTypography>
              {errorReport?.error_description}
            </DescriptionTypography>
          </FlexGrid>
        </Grid>
        <Divider />
        {loading ? (
          <LinearProgress />
        ) : (
          <Box>
            {isUpdated && (
              <DescriptionTypography mt="20px">
                Este elemento já foi atualizado, verifique se o erro já foi
                corrigido.
              </DescriptionTypography>
            )}
            {elementSource && element && (
              <Element
                elementData={element}
                errorFindings={errorReport?.findings}
              />
            )}
            {!elementSource && propertyData && (
              <Revision
                propertyData={propertyData}
                errorFindings={errorReport?.findings}
              />
            )}
          </Box>
        )}
      </BoxContainer>
      {openSnackbar && <Snackbar />}
    </GridContainer>
  );
}
