import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const InputBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#F5F5F4',
  boxShadow: '0px 1px 2px #61335429',
  padding: '90px clamp(80px, 12vw, 230px) 0',
  width: '70%',
  maxWidth: 1300,
}));
