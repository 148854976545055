import { CircularProgress, Grid } from '@mui/material';
import { useCallback, useContext, useState } from 'react';

import { companyAPI } from '../../../api';
import { CodeValidationProps } from '../../../api/companies/types';
import { RegisterStage } from '../../../api/enumerations';
import { CustomTextField } from '../../../components/CustomInput';
import Snackbar from '../../../components/Snackbar';
import { StandardButton } from '../../../components/UI/Button';
import { Constants } from '../../../constants/createAccount';
import { GlobalContext } from '../../../context/global';
import { RegistrationIdentificationContext } from '../../../context/registrationIdentification';
import { getErrorMessage } from '../../../helpers';
import {
  ButtonBox,
  InputBox,
  Label,
  Resend,
  StyledGrid,
  StyledText,
} from './styles';

interface AuthenticationProps {
  handleNextStage: (stage: RegisterStage) => void;
}

export default function Authentication({
  handleNextStage,
}: AuthenticationProps): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [emailCode, setEmailCode] = useState('');
  const [SMSCode, setSMSCode] = useState('');

  const { openSnackbar, setOpenSnackbar, setSnackbarMessage, setErrorMessage } =
    useContext(GlobalContext);
  const { identificationEmail, phoneNumber } = useContext(
    RegistrationIdentificationContext
  );

  const sendEmailCode = useCallback(async () => {
    const data = {
      email: identificationEmail,
    };
    try {
      const { detail } = await companyAPI.sendVerificationCodeByEmail(data);

      if (detail.description) {
        throw new Error(detail.description);
      }

      setSnackbarMessage('Código enviado para o seu e-mail com sucesso');
      setErrorMessage(false);
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [
    identificationEmail,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
  ]);

  const sendSMSCode = useCallback(async () => {
    const data = {
      phone_number: phoneNumber,
    };
    try {
      const { detail } = await companyAPI.sendVerificationCodeBySMS(data);

      if (detail.description) {
        throw new Error(detail.description);
      }

      setSnackbarMessage('Código enviado para o seu telefone com sucesso');
      setErrorMessage(false);
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [phoneNumber, setErrorMessage, setOpenSnackbar, setSnackbarMessage]);

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    setLoading(true);

    if (emailCode.length === 0 && SMSCode.length === 0) {
      setSnackbarMessage('Digite pelo menos um dos códigos de verificação');
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoading(false);
      return;
    }

    const data: CodeValidationProps = {
      email: identificationEmail,
      phone_number: phoneNumber,
      sms_code: SMSCode.length !== 0 ? SMSCode : null,
      email_code: emailCode.length !== 0 ? emailCode : null,
    };

    try {
      const { detail } = await companyAPI.codeValidation(data);

      if (detail.description) {
        throw new Error(detail.description);
      }
      setLoading(false);
      handleNextStage(RegisterStage.DOCUMENTS_VERIFICATION);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
      setLoading(false);
    }
  };

  return (
    <InputBox component="form" id="authentication" onSubmit={handleSubmit}>
      <StyledText>{Constants.authenticationText}</StyledText>
      <StyledGrid container spacing={2}>
        <Grid item xs={12}>
          <Label>
            {Constants.emailVerification}
            <span>{identificationEmail}</span>
          </Label>
        </Grid>
        <Grid item xs={8}>
          <CustomTextField
            id="email-code"
            placeholder={Constants.typeEmailCode}
            value={emailCode}
            setValue={setEmailCode}
          />
        </Grid>
        <Grid item xs={4}>
          <Resend onClick={sendEmailCode}>{Constants.sendAgain}</Resend>
        </Grid>
      </StyledGrid>
      <StyledGrid container spacing={2}>
        <Grid item xs={12}>
          <Label>
            {Constants.smsVerification}
            <span>{phoneNumber}</span>
          </Label>
        </Grid>
        <Grid item xs={8}>
          <CustomTextField
            id="sms-code"
            placeholder={Constants.typeSMSCode}
            value={SMSCode}
            setValue={setSMSCode}
          />
        </Grid>
        <Grid item xs={4}>
          <Resend onClick={sendSMSCode}>{Constants.sendAgain}</Resend>
        </Grid>
      </StyledGrid>
      <ButtonBox>
        <StandardButton type="submit" disabled={loading}>
          {loading ? <CircularProgress size={22} /> : Constants.confirm}
        </StandardButton>
      </ButtonBox>
      {openSnackbar && <Snackbar />}
    </InputBox>
  );
}
