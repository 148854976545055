/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
import { AdvancedMarker, Map, useMap } from '@vis.gl/react-google-maps';
import { useEffect } from 'react';

import OSMarker from '../../../../assets/mapMarkers/os.svg';
import CustomMarker from './CustomMarker';

interface IOption {
  isUtilized: boolean;
  position: google.maps.LatLngLiteral;
  id: number;
}

interface CustomMapProps {
  markersData: IOption[];
  pinPlace: google.maps.LatLngLiteral;
  page: number;
  setPage: (value: number) => void;
  deactivateFilters?: () => void;
  showUnusedElements?: boolean;
}

export default function CustomMap({
  markersData,
  pinPlace,
  deactivateFilters,
  page,
  setPage,
  showUnusedElements,
}: CustomMapProps): JSX.Element {
  const map = useMap();
  const center = pinPlace;

  useEffect(() => {
    if (!map) return;
    const bounds = new google.maps.LatLngBounds();
    markersData.forEach((element) => {
      bounds.extend(element.position);
    });
    map.fitBounds(bounds);
  }, [map]);

  return (
    <Map
      mapId="93ba1580047addb8"
      defaultCenter={center}
      streetViewControl={false}
      mapTypeId="satellite"
      className="customMap"
      defaultZoom={15}
      minZoom={4}
    >
      <AdvancedMarker position={pinPlace} zIndex={10}>
        <img src={OSMarker} width={48} height={60} alt="marcador" />
      </AdvancedMarker>
      {markersData.map((marker, index) => (
        <CustomMarker
          key={marker.id}
          markerData={marker}
          markerIndex={index + 1}
          deactivateFilters={deactivateFilters}
          setPage={setPage}
          isSelectedMarker={index === page - 1}
          isUnusedMarker={showUnusedElements || false}
        />
      ))}
    </Map>
  );
}
