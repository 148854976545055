/* eslint-disable react-hooks/exhaustive-deps */
import { LinearProgress, TextFieldProps } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import ptBr from 'dayjs/locale/pt-br';
import { useEffect, useState } from 'react';

import { StepTour } from '../../api/enumerations';
import { SelectTextField } from '../../components/CustomInput';
import { TitleBox } from '../../components/UI/Box';
import { TitleTypography } from '../../components/UI/Typography';
import {
  Constants,
  calendarViewOptions,
  months,
  views,
} from '../../constants/agenda';
import { useTour } from '../../hooks/useTour';
import BlockAgendaDialog from './BlockAgenda';
import { Calendar } from './Calendar';
import FilterEngineers from './Engineers';
import useAgenda from './hooks';
import {
  CalendaContainer,
  DateText,
  FiltersContainer,
  FlexBox,
  LoadingBox,
  StyledTextField,
} from './styles';

export default function Agenda(): JSX.Element {
  const [openBlockAgenda, setOpenBlockAgenda] = useState(false);

  dayjs.locale(ptBr);
  const weekDay = dayjs().format('dddd');
  const currentDate = dayjs().format('DD [de] MMMM [de] YYYY');

  const {
    handleCheckboxChange,
    usersData,
    blockUsersData,
    engineersData,
    selectedEngineers,
    searchEngineerTerm,
    setSearchEngineerTerm,
    searchMonth,
    setSearchMonth,
    searchYear,
    setSearchYear,
    calendarView,
    setCalendarView,
    getAgendaEvents,
    engineersIdQuery,
    loading,
    newDate,
    setNewDate,
    updateEvents,
    setUpdateEvents,
  } = useAgenda();

  const { tourCompletion, setTutorialStep, setTourOn } = useTour();

  useEffect(() => {
    if (!tourCompletion.agenda.complete && !tourCompletion.skipTour.agenda) {
      setTutorialStep(StepTour.AGENDASTART);
      setTourOn(true);
    }
  }, [tourCompletion]);

  return (
    <>
      <TitleBox>
        <TitleTypography>{Constants.agenda}</TitleTypography>
      </TitleBox>
      {loading ? (
        <LoadingBox>
          <LinearProgress />
        </LoadingBox>
      ) : (
        <CalendaContainer>
          <FiltersContainer id="filter">
            <DateText>
              {Constants.today}
              {' - '}
              <span>
                {weekDay}
                {', '}
              </span>
              <span>{currentDate}</span>
            </DateText>
            <SelectTextField
              id="month-filter"
              label={Constants.month}
              value={searchMonth}
              setValue={setSearchMonth}
              selectOptions={months}
              allSelectable
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={Constants.year}
                format="YYYY"
                views={['year']}
                slots={{
                  textField:
                    StyledTextField as React.ComponentType<TextFieldProps>,
                }}
                value={searchYear ? dayjs(`${searchYear}-01-01`) : null}
                onChange={(date) => {
                  if (date !== null && typeof date !== 'undefined') {
                    const selectedYear = dayjs(date).year();
                    setSearchYear(selectedYear);
                  }
                }}
              />
            </LocalizationProvider>
            <SelectTextField
              id="calendar-view-filter"
              label={Constants.view}
              value={calendarView}
              setValue={setCalendarView}
              selectOptions={calendarViewOptions}
              allSelectable
            />
            <FilterEngineers
              usersData={usersData}
              handleCheckboxChange={handleCheckboxChange}
              selectedEngineers={selectedEngineers}
              searchEngineerTerm={searchEngineerTerm}
              setSearchEngineerTerm={setSearchEngineerTerm}
            />
          </FiltersContainer>
          <FlexBox>
            <Calendar
              view={views[calendarView]}
              month={searchMonth}
              setMonth={setSearchMonth}
              year={searchYear}
              setYear={setSearchYear}
              engineersData={engineersData}
              callback={getAgendaEvents}
              engineersIdQuery={engineersIdQuery}
              newDate={newDate}
              setNewDate={setNewDate}
              usersData={blockUsersData}
              updateEvents={updateEvents}
              setUpdateEvents={setUpdateEvents}
            />
            <BlockAgendaDialog
              usersData={blockUsersData}
              open={openBlockAgenda}
              setOpen={setOpenBlockAgenda}
              updateEvents={updateEvents}
              setUpdateEvents={setUpdateEvents}
            />
          </FlexBox>
        </CalendaContainer>
      )}
    </>
  );
}
