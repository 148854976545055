import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const InputTitle = styled(Typography)(() => ({
  paddingBottom: '4px',
  fontSize: '14px',
}));

export const StyledInput = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '18px',
  borderBottom: '1px solid #8B8C8E',
  inlineSize: 'fit-content',
  minWidth: '180px',
  paddingLeft: '8px',
  minHeight: '28px',
}));

export const SmallStyledInput = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '18px',
  borderBottom: '1px solid #8B8C8E',
  inlineSize: 'fit-content',
  minWidth: '100px',
  paddingLeft: '8px',
  minHeight: '28px',
}));

export const CoordGrid = styled(Grid)(() => ({
  marginTop: 12,
  display: 'flex',
  flexWrap: 'wrap',
}));

export const BoldTypography = styled(Typography)(() => ({
  fontWeight: '600',
}));
