import { Box, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DateText = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  fontSize: '24px',
  marginRight: '12px',
  span: {
    color: theme.palette.primary.dark,
    fontWeight: 600,
    fontSize: '24px',
  },
  [theme.breakpoints.down('xl')]: {
    width: '200px',
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: '18px',
    span: {
      paddingLeft: '4px',
      fontSize: '18px',
    },
  },
}));

export const CalendaContainer = styled(Box)(() => ({
  padding: '0 20px 30px 20px',
  '@media (max-height: 820px)': {
    marginTop: '-20px',
  },
}));

export const FiltersContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '1rem',
  alignItems: 'flex-start',
  position: 'relative',
  height: '60px',
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: '20px',
  width: '100%',
  marginTop: 40,
  position: 'relative',
  '@media (max-height: 820px)': {
    marginTop: 20,
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiInputBase-root': { borderRadius: '16px' },
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: '#595959 !important',
  },
}));

export const LoadingBox = styled(Box)(() => ({
  width: '100%',
  marginTop: 16,
}));
